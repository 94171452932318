import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { integrationsCardData } from '../integrationItems';
import styles from './IntegrationMain.module.scss';
import Image from '../../core-components/Image';
import IntegrationCard from './IntegrationCard/IntegrationCard';
import Input from '../../core-components/Input/Input';
import Button from '../../core-components/Button';
import cn from 'classnames';
import {
  pick,
  keys,
  mapKeys,
  pickBy,
  chain,
  omit,
  toPairs,
  orderBy,
  fromPairs,
  omitBy
} from 'lodash';
import SkeletonCard from './IntegrationCard/SkeletonCard';

const IntegrationMain = (props) => {
  const [integrations, setIntegrations] = useState({});
  const [sortedFilteredIntegrations, setSortedFilteredIntegrations] = useState({});
  const [filterType, setFilterType] = useState({
    ATS: false,
    HRMS: false
  });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (
      props?.integrationData &&
      props?.integrationData?.loading === false &&
      props?.frequentIntegrationData &&
      props?.frequentIntegrationData?.loading === false
    ) {
      getFilteredIntegrations();
    }
  }, [props?.integrationData, props?.frequentIntegrationData]);

  useEffect(() => {
    if (searchTerm !== '') {
      const filteredItems = filterObjectsByKey(searchTerm);
      setSortedFilteredIntegrations(filteredItems);
    } else {
      filterByType();
    }
  }, [searchTerm]);
  useEffect(() => {
    props.getViewBuyPackages({ limit: 50, offset: 0 });
    return () => {
      props.invalidateIntegrationData();
      props.invalidateFrequentIntegrations();
    };
  }, []);
  useEffect(() => {
    setSearchTerm('');
    filterByType();
  }, [filterType]);

  const filterByType = () => {
    if (filterType.ATS) {
      const filteredData = pickBy(integrations, { type: 'ATS' });
      setSortedFilteredIntegrations(filteredData);
    } else if (filterType.HRMS) {
      const filteredData = pickBy(integrations, { type: 'HRMS' });
      setSortedFilteredIntegrations(filteredData);
    } else {
      getFilteredIntegrations();
    }
  };
  const filterObjectsByKey = (searchString) => {
    const integrationType = filterType.ATS ? 'ATS' : filterType.HRMS ? 'HRMS' : undefined;
    return pickBy(integrations, (value, key) => {
      const searchKey = value.integrationData?.hrmsName || value.title;
      if (integrationType) {
        return (
          searchKey.toLowerCase().includes(searchString.toLowerCase()) &&
          value.type === integrationType
        );
      } else {
        return searchKey.toLowerCase().includes(searchString.toLowerCase());
      }
    });
  };

  const getFilteredIntegrations = () => {
    // Connected Integrations
    const integrationConnected = props.integrationData;
    const integrationData = integrationsCardData;
    const connectedIntegrations = pick(
      integrationData,
      keys(integrationConnected).map((key) => key.toUpperCase())
    );
    const sortedConnected = chain(connectedIntegrations)
      .toPairs()
      .sortBy(([key, value]) => key.toLowerCase())
      .fromPairs()
      .value();
    const connectIntegrationsAPIdata = mapKeys(integrationConnected, (_, key) => key.toUpperCase());
    Object.entries(sortedConnected).map(([key, value]) => {
      integrationData[key]['integrationData'] = connectIntegrationsAPIdata[key];
    });

    // Frequent Integrations
    const frequentIntegrationsData = props.frequentIntegrationData?.data;

    if (frequentIntegrationsData?.hasOwnProperty('zohoPeople')) {
      delete frequentIntegrationsData.zohoPeople;
    }
    const uppercaseKeys = mapKeys(frequentIntegrationsData, (value, key) => key.toUpperCase());
    const frequenttIntegrations = pick(integrationData, keys(uppercaseKeys));
    const frequentIntegrationsAPIdata = mapKeys(frequentIntegrationsData, (_, key) =>
      key.toUpperCase()
    );
    Object.entries(frequenttIntegrations).map(([key, value]) => {
      integrationData[key]['count'] = frequentIntegrationsAPIdata[key];
    });

    const frequentAfterOmit = omit(frequenttIntegrations, keys(sortedConnected));
    const items = toPairs(frequentAfterOmit);

    // Sort the items based on count and then alphabetically
    const sortedFrequentItems = orderBy(
      items,
      [(item) => item[1].count, (item) => item[0].toLowerCase()],
      ['desc', 'asc']
    );

    // Reconstruct the sorted object
    const sortedFrequent = fromPairs(sortedFrequentItems);

    // Only Listed, not coming soon
    const onlyListedAfterOmit = omit(
      integrationData,
      keys({ ...sortedConnected, ...sortedFrequent })
    );

    const filteredOnlyListedAfterOmit = omitBy(onlyListedAfterOmit, { comingSoon: true });
    const sortedListed = chain(filteredOnlyListedAfterOmit)
      .toPairs()
      .sortBy(([key, value]) => key.toLowerCase())
      .fromPairs()
      .value();

    // Coming soon
    const comingSoonItems = pickBy(integrationData, { comingSoon: true });
    const sortedComingSoon = chain(comingSoonItems)
      .toPairs()
      .sortBy(([key, value]) => key.toLowerCase())
      .fromPairs()
      .value();

    setIntegrations({
      ...sortedConnected,
      ...sortedFrequent,
      ...sortedListed,
      ...sortedComingSoon
    });
    setSortedFilteredIntegrations({
      ...sortedConnected,
      ...sortedFrequent,
      ...sortedListed,
      ...sortedComingSoon
    });
  };
  const integrationToIgnore = 'repute';

  const handleSearch = (event) => {
    const searchText = event.target.value.trim();
    setSearchTerm(searchText);
  };

  const caregoryFilterOptions = ['All Categories', 'ATS', 'HRMS'];

  const [integrationCardView, setIntegrationCardView] = useState({
    cardView: true,
    listView: false
  });

  return (
    <Container>
      <Row className={styles.filterHeader}>
        <div className={styles.dropDownContainer}>
          <div className={styles.category}>{'Category'}</div>
          <DropdownList
            value={caregoryFilterOptions[0].label}
            data={caregoryFilterOptions}
            name='integration_category'
            className={styles.widgetDropdownTime}
            containerClassName={'widgetDropdownTimeContainer'}
            textField='label'
            selectIcon={<Image name='dropdownSelect.svg' />}
            placeholder='All Categories'
            dataKey='value'
            onChange={(value) => {
              if (value === 'ATS') {
                setFilterType({ ATS: true, HRMS: false });
              } else if (value === 'HRMS') {
                setFilterType({ ATS: false, HRMS: true });
              } else {
                setFilterType({ ATS: false, HRMS: false });
              }
            }}
          />
        </div>
        <div className={styles.viewStyle}>
          {integrationCardView.cardView}
          <Button
            type='button'
            onClick={() => {
              setIntegrationCardView({ cardView: true, listView: false });
            }}
            className={cn(styles.buttonWithIcon, integrationCardView.cardView ? styles.active : '')}
          >
            {integrationCardView.cardView ? (
              <Image name='CardViewActive.svg' />
            ) : (
              <Image name='CardView.svg' />
            )}
            <span className={styles.buttonText}>Card View</span>
          </Button>
          <Button
            onClick={() => {
              setIntegrationCardView({ cardView: false, listView: true });
            }}
            className={cn(styles.buttonWithIcon, integrationCardView.listView ? styles.active : '')}
          >
            {integrationCardView.listView ? (
              <Image name='ListViewActive.svg' />
            ) : (
              <Image name='ListView.svg' />
            )}
            <span className={styles.buttonText}>List View</span>
          </Button>
          <div className={styles.searchInput}>
            <Image name='searchInputIcon.svg' />
            <Input
              placeholder={'Search Name'}
              className={styles.inputText}
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
      </Row>
      <div
        className={cn(
          styles.cardContainer,
          integrationCardView.cardView ? styles.gridView : styles.listView
        )}
      >
        {!props?.integrationData?.loading && !props?.frequentIntegrationData?.loading
          ? Object.entries(sortedFilteredIntegrations).map(([key, value]) => {
              return (
                <IntegrationCard
                  integrationKey={key}
                  cardDetails={value}
                  comingSoonIntegration={value?.comingSoon}
                  integrationCardView={integrationCardView}
                  integrationData={value?.integrationData}
                  history={props.history}
                />
              );
            })
          : Array(6)
              .fill()
              .map((_, index) => (
                <SkeletonCard key={index} isGridView={integrationCardView.cardView} />
              ))}
      </div>
    </Container>
  );
};

export default IntegrationMain;
