// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._ynWynG4q0QNyLzK0lyK {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `_ynWynG4q0QNyLzK0lyK`
};
export default ___CSS_LOADER_EXPORT___;
