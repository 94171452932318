// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x5cGL8UJOro0jEEu6Pjx {
  width: 100%;
  margin: auto;
}
.x5cGL8UJOro0jEEu6Pjx .sBqWBiohPdcbPjEuYeNw {
  margin-top: -190px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S {
  background: #ffffff;
  border-radius: 4px;
  font-family: Poppins, sans-serif;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .DA_DGGZPJkhFTwn64rZR {
  display: flex;
  justify-content: space-between;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .DA_DGGZPJkhFTwn64rZR .HZHavYmqY_i32iqgeVGU {
  padding-top: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .DA_DGGZPJkhFTwn64rZR .ujcSCG6XOmfho9y3ld7w {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 174px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  height: 40px;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm {
  border: 0px;
  padding: 0 24px 24px 24px;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE {
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 8px;
  overflow: hidden;
  margin: auto;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE .WvQJ2d62UacRRqaQkSvp {
  cursor: pointer;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE .DC4KKM4QU_J66ljWgl7g {
  cursor: not-allowed;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE .OBTyye18d9xc1R2XWapQ {
  text-align: center;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE thead {
  background-color: #f2f8ff;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE thead tr th {
  border: 0px;
  height: 57px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE thead tr .JPxigNMLGof4sAKRzE7Q {
  width: 300px;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE thead tr .DPLaYr3LMkUmsCtx2Din {
  text-align: right;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody tr {
  cursor: default;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody tr td {
  border: 0px;
  font-weight: 500;
  font-size: 14px;
  height: 57px;
  line-height: 21px;
  color: #333333;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody tr .JPxigNMLGof4sAKRzE7Q {
  width: 300px;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody tr .DPLaYr3LMkUmsCtx2Din {
  text-align: right;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody tr:hover {
  background-color: rgba(54, 108, 243, 0.1254901961);
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody .mJA3JJfJpLuaKiDoWnL_:hover {
  color: #366cf3;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody .mJA3JJfJpLuaKiDoWnL_:hover p {
  cursor: pointer;
  width: fit-content;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody .mXi34VtWvUgyTW_vcJBz {
  color: #999999;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody .ia5Weok8amEAkV6yPRr6 {
  text-align: center;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody .npsZhwLHtnQfiF7uwq54:hover {
  color: grey;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE tbody .npsZhwLHtnQfiF7uwq54:hover p {
  width: fit-content;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE .fMqECffCj5VN_YFDsJzd {
  text-align: center;
  padding-top: 64px;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE .fMqECffCj5VN_YFDsJzd .HrDsb47YEpHmQFOT8_wQ {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .OggwIALCyL8MDZpbEDNE .KaE4BACprWVElyPgSvrK {
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .kaUWq7fHmIa4OWRNDgC5 {
  flex-wrap: unset;
  padding: 24px 24px 15px;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .kaUWq7fHmIa4OWRNDgC5 .Sl14OETbNMKpTaMjcvNU {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.x5cGL8UJOro0jEEu6Pjx .JkvsML_Cs48MKX9CXg3S .Oew7dTx1LzI64VmtitAm .kaUWq7fHmIa4OWRNDgC5 .Sl14OETbNMKpTaMjcvNU .N1QzcP1IEVWf789LEpgQ {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px;
  -webkit-appearance: menulist;
}

.sbI8hyc_v3OZzNUYNunK {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  margin-bottom: 17px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"purchaseHistoryWrapper": `x5cGL8UJOro0jEEu6Pjx`,
	"heading": `sBqWBiohPdcbPjEuYeNw`,
	"purchaseHistoryCard": `JkvsML_Cs48MKX9CXg3S`,
	"purchaseHistoryCardHeader": `DA_DGGZPJkhFTwn64rZR`,
	"headingContent": `HZHavYmqY_i32iqgeVGU`,
	"dropdown": `ujcSCG6XOmfho9y3ld7w`,
	"tableCard": `Oew7dTx1LzI64VmtitAm`,
	"purchaseHistoryTable": `OggwIALCyL8MDZpbEDNE`,
	"pointerTypeCursor": `WvQJ2d62UacRRqaQkSvp`,
	"pointerTypeRestrict": `DC4KKM4QU_J66ljWgl7g`,
	"amountCol": `OBTyye18d9xc1R2XWapQ`,
	"alignCenter": `JPxigNMLGof4sAKRzE7Q`,
	"alignRight": `DPLaYr3LMkUmsCtx2Din`,
	"mainField": `mJA3JJfJpLuaKiDoWnL_`,
	"deletedUser": `mXi34VtWvUgyTW_vcJBz`,
	"amountLoaderCell": `ia5Weok8amEAkV6yPRr6`,
	"transactionRowDisable": `npsZhwLHtnQfiF7uwq54`,
	"noDataWrapper": `fMqECffCj5VN_YFDsJzd`,
	"noDataContent": `HrDsb47YEpHmQFOT8_wQ`,
	"amount": `KaE4BACprWVElyPgSvrK`,
	"paginationWrapper": `kaUWq7fHmIa4OWRNDgC5`,
	"showPagination": `Sl14OETbNMKpTaMjcvNU`,
	"selectPagination": `N1QzcP1IEVWf789LEpgQ`,
	"tabInfoText": `sbI8hyc_v3OZzNUYNunK`
};
export default ___CSS_LOADER_EXPORT___;
