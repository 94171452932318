// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gxaFSvtNsiBTClBsCGGl {
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw {
  border: none;
  cursor: pointer;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .Um7V8ueKfO1uZ2vajffv {
  display: flex;
  background: #ffffff;
  border: none;
  padding-left: 0;
  padding-right: 0;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .Um7V8ueKfO1uZ2vajffv .EEgpDO9yVcyyB4Awdtn5 {
  position: relative;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .Um7V8ueKfO1uZ2vajffv .EEgpDO9yVcyyB4Awdtn5 .r2OHtQmbLhamz7HlMqEg {
  position: absolute;
  top: -6px;
  left: 11px;
  font-size: 12px;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .Um7V8ueKfO1uZ2vajffv .BrIdenJglR5rAbNlIvFT {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 8px;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .Um7V8ueKfO1uZ2vajffv .BrIdenJglR5rAbNlIvFT .is1yFMYRCTf8KxZgQkUA {
  display: flex;
  text-align: right;
  font-weight: normal;
  font-size: 14px;
  color: #666666;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .Um7V8ueKfO1uZ2vajffv .BrIdenJglR5rAbNlIvFT .is1yFMYRCTf8KxZgQkUA .CWei2RZnumRUjcBTLxr2 {
  font-family: roboto;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .Um7V8ueKfO1uZ2vajffv .BrIdenJglR5rAbNlIvFT .is1yFMYRCTf8KxZgQkUA .unTgJdr7c8qSyHXksVpv {
  margin-right: 8px;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .lu8YnmKxGdBqQf7faOYm {
  padding-left: 0;
  padding-right: 0;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .lu8YnmKxGdBqQf7faOYm .SXw6A1FnRvTSJW4QFD09 {
  margin-left: 30px;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .lu8YnmKxGdBqQf7faOYm .SXw6A1FnRvTSJW4QFD09 .KJJfNb7e6JRkrxJcJDg3 {
  font-size: 15px;
  color: #aaaaaa;
  margin-top: 12px;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .lu8YnmKxGdBqQf7faOYm .SXw6A1FnRvTSJW4QFD09 .bGTs0Zbx9KfBE5A5kynW {
  border: none;
  padding: 0;
  display: flex;
  align-items: baseline;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .lu8YnmKxGdBqQf7faOYm .SXw6A1FnRvTSJW4QFD09 .bGTs0Zbx9KfBE5A5kynW .g8BQL39opjQdNv5A1XCd,
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .lu8YnmKxGdBqQf7faOYm .SXw6A1FnRvTSJW4QFD09 .bGTs0Zbx9KfBE5A5kynW .CQrq3sb0kT0crskEM7mL {
  font-weight: normal;
  font-size: 14px;
  color: #595959;
  margin-bottom: 12px;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .lu8YnmKxGdBqQf7faOYm .SXw6A1FnRvTSJW4QFD09 .bGTs0Zbx9KfBE5A5kynW ._gVEpVld5e8nsmX85IrH {
  margin-bottom: 0;
}
.gxaFSvtNsiBTClBsCGGl .E2dFQJK3pJDn2kyXtOCw .lu8YnmKxGdBqQf7faOYm .SXw6A1FnRvTSJW4QFD09 .bGTs0Zbx9KfBE5A5kynW .c8vPjjmOHtk0V6XZPu96 {
  margin-left: 18px;
}

.JqJY9gibGphPIuKykV6f label {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #595959 !important;
}

._gVEpVld5e8nsmX85IrH {
  display: flex;
  gap: 20px;
}

.P9_QVs38aJOtC3KeSr0R {
  color: #db3131;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addonsContainer": `gxaFSvtNsiBTClBsCGGl`,
	"addonsContainerCard": `E2dFQJK3pJDn2kyXtOCw`,
	"addonsContainerHeader": `Um7V8ueKfO1uZ2vajffv`,
	"iconContainer": `EEgpDO9yVcyyB4Awdtn5`,
	"plusIcon": `r2OHtQmbLhamz7HlMqEg`,
	"headingRightContainer": `BrIdenJglR5rAbNlIvFT`,
	"headingRight": `is1yFMYRCTf8KxZgQkUA`,
	"rupee": `CWei2RZnumRUjcBTLxr2`,
	"startFrom": `unTgJdr7c8qSyHXksVpv`,
	"cardBody": `lu8YnmKxGdBqQf7faOYm`,
	"listGroup": `SXw6A1FnRvTSJW4QFD09`,
	"headingDiv": `KJJfNb7e6JRkrxJcJDg3`,
	"listGroupItem": `bGTs0Zbx9KfBE5A5kynW`,
	"otherText": `g8BQL39opjQdNv5A1XCd`,
	"label": `CQrq3sb0kT0crskEM7mL`,
	"formGroup": `_gVEpVld5e8nsmX85IrH`,
	"listGroupInside": `c8vPjjmOHtk0V6XZPu96`,
	"formCheck": `JqJY9gibGphPIuKykV6f`,
	"required": `P9_QVs38aJOtC3KeSr0R`
};
export default ___CSS_LOADER_EXPORT___;
