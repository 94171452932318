// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.ZE0Vht_iFk0EuV5wSEg3 {
  color: #000000;
  font-weight: 500;
  font-size: 22px;
}

.yMq74HEGpcjpbA2vYpEx {
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: -10px;
}

.GGA3Au9hHsZpZfRcEYbp select {
  background: #ffffff;
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  height: 38px;
  width: 100%;
}

.pJw73bBTGExP8niYW_TX {
  color: red;
}

.McifECMiNqFAC4NWalZQ {
  margin-left: 20px;
}
.McifECMiNqFAC4NWalZQ label {
  font-weight: 500 !important;
}

.Gtcul9DxbvLPM3K5n99f {
  margin-top: 20px;
  padding: 0 !important;
  justify-content: flex-end !important;
}
@media screen and (max-width: 601px) {
  .Gtcul9DxbvLPM3K5n99f {
    position: fixed;
    left: 0;
    margin: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
  }
  .Gtcul9DxbvLPM3K5n99f button {
    height: 40px;
    margin: 0 !important;
  }
  .Gtcul9DxbvLPM3K5n99f .Wm4Yjnh0VPo6w3rXBAMD {
    flex: 1;
  }
  .Gtcul9DxbvLPM3K5n99f .D2uwsqdn8BbeDJF6mksQ {
    flex: 1;
  }
}

.awDbPFgunP5DVIOd2Eda {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.bgkN6lzFB9SdYUQ7ztkB {
  margin: 0 !important;
}

.KQSRns8ee8jJhFD1fSXU {
  padding-bottom: 40px !important;
}
@media screen and (max-width: 601px) {
  .KQSRns8ee8jJhFD1fSXU {
    padding-bottom: 65px !important;
  }
}

.mMWeriFjcdcWF6Vwzak0 {
  margin-top: 5px;
}

.cNuOr52KHhHVoeiDqOmp {
  margin-top: -5px;
}

.V7C2tlvkY827BOX51mAz {
  margin-top: "-5px";
}

.EPTI2c_xBHQ8mfWzktpm {
  color: red;
}

@media screen and (max-width: 993px) {
  .Fonay108DPtQiW70YQiN {
    margin-bottom: 1rem;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step1Title": `ZE0Vht_iFk0EuV5wSEg3`,
	"step1SubTitle": `yMq74HEGpcjpbA2vYpEx`,
	"dropdown": `GGA3Au9hHsZpZfRcEYbp`,
	"error": `pJw73bBTGExP8niYW_TX`,
	"radioBtn": `McifECMiNqFAC4NWalZQ`,
	"modalFooter": `Gtcul9DxbvLPM3K5n99f`,
	"activeBtn": `Wm4Yjnh0VPo6w3rXBAMD`,
	"disabledBtn": `D2uwsqdn8BbeDJF6mksQ`,
	"radioGroup": `awDbPFgunP5DVIOd2Eda`,
	"radioLabel": `bgkN6lzFB9SdYUQ7ztkB`,
	"modalBody": `KQSRns8ee8jJhFD1fSXU`,
	"customLabel": `mMWeriFjcdcWF6Vwzak0`,
	"customLabel1": `cNuOr52KHhHVoeiDqOmp`,
	"customFieldStyle": `V7C2tlvkY827BOX51mAz`,
	"customSpan": `EPTI2c_xBHQ8mfWzktpm`,
	"mbFor992": `Fonay108DPtQiW70YQiN`
};
export default ___CSS_LOADER_EXPORT___;
