// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QFeYC7l5KDEwtmRsxOLp {
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .QFeYC7l5KDEwtmRsxOLp {
    width: 1200px;
  }
}
.QFeYC7l5KDEwtmRsxOLp .gHjqCdghTbUMG_JnAWeT {
  margin: -190px auto 30px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 48px;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW {
  padding: 17px 24px 0px;
  font-family: Poppins, sans-serif;
  width: 49%;
  height: 660px;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW .mRTTzvhxkrJiZJwNCMb7 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW .mRTTzvhxkrJiZJwNCMb7 .JIGvbETCIdm57K5Fqk6e {
  padding-top: 11px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW .mRTTzvhxkrJiZJwNCMb7 ._m8ohHReZ9VW7Sq1VeTL {
  float: right;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW .mRTTzvhxkrJiZJwNCMb7 ._m8ohHReZ9VW7Sq1VeTL .O5P1r2QwcOkBoritRGUv {
  background: #2755fe;
  border: 1px solid #2755fe;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  height: 48px;
  width: 110px;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW form input {
  height: 44px;
  box-sizing: border-box;
  border-radius: 4px;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW form .uWOL3bO6I1TRrep1F9jp {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW form .uWOL3bO6I1TRrep1F9jp .HSLtRavWrioKrbQOVVhU {
  width: 205px;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
  margin-right: 9px;
  margin-bottom: 48px;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW form .uWOL3bO6I1TRrep1F9jp .uyCQRxh1eVR5_TTSS9qH {
  font-size: 18px;
  line-height: 27px;
  color: #666666;
  margin-bottom: 48px;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW form .uWOL3bO6I1TRrep1F9jp .egxRRxD8skbIGlffynLQ {
  margin-bottom: 13px;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW form .uWOL3bO6I1TRrep1F9jp img {
  height: 50px;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW form .TJ1HxdxzzJJOwJittV4B {
  margin-left: 205px;
  margin-bottom: 36px;
  display: flex;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW form .TJ1HxdxzzJJOwJittV4B .KJ_ykKOjbi9VNw5_HRTQ {
  height: 48px;
  width: 162px;
  background: #2755fe;
  border: 1px solid #2755fe;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 16px;
}
.QFeYC7l5KDEwtmRsxOLp .PKOEg4QDh1e200y6aYIt .Dw7t6Z2igR8U82fKMCwW form .TJ1HxdxzzJJOwJittV4B .K3gFqIn1Nx8CYYtVeMSY {
  height: 48px;
  width: 162px;
  background: #ffffff;
  border: 1px solid #2755fe;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #2755fe;
}

.guN9n4nzKT1BCgZKY0zs input {
  border: 1px solid #dc3545 !important;
  margin-bottom: 0 !important;
}

.PngSdTWeLxh0q21RTRLr {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
}

.oYSIsZBx6zV0iwW2F6MK select {
  width: 334px;
  height: 44px;
}

.oA4oCCs6nNnh1PPibvX9 {
  color: #333333;
  font-size: 24px;
  margin-top: 5px;
  margin-left: 5px;
  line-height: 28px;
  height: 28px;
  border: 1px transparent solid;
  background: transparent;
  border-radius: 50%;
  padding: 0 5px;
}
.oA4oCCs6nNnh1PPibvX9:hover {
  color: #2755fe;
  background: #ebefff;
  border: 1px #ebefff solid;
  border-radius: 50%;
  cursor: pointer;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileWrapper": `QFeYC7l5KDEwtmRsxOLp`,
	"profileHeading": `gHjqCdghTbUMG_JnAWeT`,
	"cardMain": `PKOEg4QDh1e200y6aYIt`,
	"cardInner": `Dw7t6Z2igR8U82fKMCwW`,
	"cardHead": `mRTTzvhxkrJiZJwNCMb7`,
	"cardHeading": `JIGvbETCIdm57K5Fqk6e`,
	"cardHeadBtn": `_m8ohHReZ9VW7Sq1VeTL`,
	"editBtn": `O5P1r2QwcOkBoritRGUv`,
	"cardParticular": `uWOL3bO6I1TRrep1F9jp`,
	"cardParticularTitle": `HSLtRavWrioKrbQOVVhU`,
	"cardParticularValue": `uyCQRxh1eVR5_TTSS9qH`,
	"cardParticularFieldValue": `egxRRxD8skbIGlffynLQ`,
	"buttonWrapper": `TJ1HxdxzzJJOwJittV4B`,
	"updateBtn": `KJ_ykKOjbi9VNw5_HRTQ`,
	"cancelBtn": `K3gFqIn1Nx8CYYtVeMSY`,
	"validationError": `guN9n4nzKT1BCgZKY0zs`,
	"validationErrorMsg": `PngSdTWeLxh0q21RTRLr`,
	"accessDropdown": `oYSIsZBx6zV0iwW2F6MK`,
	"addBtn": `oA4oCCs6nNnh1PPibvX9`
};
export default ___CSS_LOADER_EXPORT___;
