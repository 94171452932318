// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GAvWABsrkjJBfwT9kMRR {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.K9rAZQNrl_ai3D67YPrQ {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  width: calc(50% - 10px);
  margin: 24px 0 20px 0;
  padding: 24px;
}
.K9rAZQNrl_ai3D67YPrQ h6 {
  color: #333;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatsContainer": `GAvWABsrkjJBfwT9kMRR`,
	"chatContainer": `K9rAZQNrl_ai3D67YPrQ`
};
export default ___CSS_LOADER_EXPORT___;
