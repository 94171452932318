// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LkLNHBu988ENjIE6cDRg ._uQDZeTSdcl75lTjwl2P {
  font-size: 16px;
}
.LkLNHBu988ENjIE6cDRg .EBO26AV52tRFSwkwHHH5 {
  font-size: 16px;
  padding: 20px 20px 20px 0px;
}
.LkLNHBu988ENjIE6cDRg .EBO26AV52tRFSwkwHHH5 .Qbdk9x_Docp3PqKj0uTz {
  padding: 0px 9px;
  color: #2755fe;
}
.LkLNHBu988ENjIE6cDRg .EBO26AV52tRFSwkwHHH5 .Qbdk9x_Docp3PqKj0uTz:hover {
  padding: 0px 9px;
  color: #2755fe;
  cursor: pointer;
}

.LF5a9sa0wzZY5aavtZWh {
  color: #fff;
  margin: 10px 10px 6px 0px;
  width: 136px;
  height: 48px;
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer;
}

.y_2pWtoazG6L0MCCx6wA {
  margin-right: 45px;
  background: #ffffff;
  border: 1px solid #2755fe;
  border-radius: 5px;
  width: 136px;
  height: 48px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #2755fe;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyDiv": `LkLNHBu988ENjIE6cDRg`,
	"heading1": `_uQDZeTSdcl75lTjwl2P`,
	"heading2": `EBO26AV52tRFSwkwHHH5`,
	"aTag": `Qbdk9x_Docp3PqKj0uTz`,
	"btn": `LF5a9sa0wzZY5aavtZWh`,
	"backBtn": `y_2pWtoazG6L0MCCx6wA`
};
export default ___CSS_LOADER_EXPORT___;
