// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._2U7qxNrIMYGPg8v_9SD {
  padding: 0 32px;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  min-height: 658px;
}
@media screen and (max-width: 601px) {
  ._2U7qxNrIMYGPg8v_9SD {
    padding: 0 0 65px 0;
    box-shadow: none;
    border: none;
  }
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .R6lbaw54_3JVb3k1uISA {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}
@media screen and (max-width: 601px) {
  ._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .R6lbaw54_3JVb3k1uISA {
    padding-bottom: 12px;
  }
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .R6lbaw54_3JVb3k1uISA .n9OSFsX9nwU4l9OSDCZd {
  margin-right: 20px;
  color: #8c8c8c;
}
@media screen and (max-width: 601px) {
  ._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .R6lbaw54_3JVb3k1uISA .n9OSFsX9nwU4l9OSDCZd {
    margin-right: 0;
    margin-left: 20px;
  }
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .Rx_TiYWboQi6iwLPwxIk {
  color: #262626;
  font-size: 24px;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .gR5cvLgzIDwmVfCEeYCX {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #262626;
}
@media screen and (max-width: 601px) {
  ._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .gR5cvLgzIDwmVfCEeYCX {
    margin-top: 24px;
  }
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .ywrSwCYeei5LLsbNu3p5 {
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #999999;
  padding-bottom: 25px;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .AnIqDT_e8GDV5pT99NqY {
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  color: #262626;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .NVjnYggD0d70jHGX3LGR {
  font-weight: normal;
  font-size: 14px;
  color: #999999 !important;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .j506abY_WUgyTH1IF_WL {
  margin-top: 30px;
  margin-left: 50%;
  text-align: right;
  vertical-align: middle;
  display: flex;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .RmvRgu1tXTTl53A3fv_p {
  padding: 0px;
  height: 115px;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .RmvRgu1tXTTl53A3fv_p .A9gNEUYr3oi_m_m0UkEd {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .RmvRgu1tXTTl53A3fv_p .A9gNEUYr3oi_m_m0UkEd .Ys9KRWTs04nV2PZQeWdR {
  color: red;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .RmvRgu1tXTTl53A3fv_p .Emf7KMN5sAVvRU0nCcUl {
  font-family: Poppins;
  font-style: normal;
  margin-top: 15px;
  margin-left: -12px;
  color: red;
  font-size: 12px;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .RmvRgu1tXTTl53A3fv_p .r8WhTQQpxqVPRdX6MoC8 {
  width: 240px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  border-radius: 2px;
  text-align: center;
  color: #2755fe;
}
@media screen and (max-width: 993px) {
  ._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .RmvRgu1tXTTl53A3fv_p .r8WhTQQpxqVPRdX6MoC8 {
    width: 100%;
  }
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .RmvRgu1tXTTl53A3fv_p .r8WhTQQpxqVPRdX6MoC8 .ntsfn_p8l6ZyDbKr4VY0 {
  padding: 4px;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .JK50IwUV5Ri5kX9b6wqt {
  padding: 10px 0px 10px 0px;
  height: 78px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .JK50IwUV5Ri5kX9b6wqt .BuXB0RrU0v24zoNAsUPq {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .JK50IwUV5Ri5kX9b6wqt .acrZXavEuSpPaM3kff3Q {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #999999;
}
._2U7qxNrIMYGPg8v_9SD .EMHdXQ5mZQ3d6jip_Kr3 .JK50IwUV5Ri5kX9b6wqt .m7wg4XFUjneAAUmcGJYr {
  cursor: pointer;
  margin-top: 4px;
}
._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd {
  border: none;
  background-color: white;
}
._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .RLaERH5c63bMQ_0jg7iL {
  cursor: pointer;
}
._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .RLaERH5c63bMQ_0jg7iL :disabled {
  cursor: not-allowed;
}
._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .RLaERH5c63bMQ_0jg7iL label {
  color: #595959 !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .MwDubPWudIDadgT7UQAl {
  background: #ffffff;
  border-radius: 2px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2755fe;
}
._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .TtUgr1LPsiV8hJgKq3Vj {
  justify-content: unset !important;
}
._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .TtUgr1LPsiV8hJgKq3Vj button {
  border-radius: 2px;
  padding: 0.3em 1.4rem;
  margin: 4px;
}
._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .TtUgr1LPsiV8hJgKq3Vj .r6SHxDXGvJhutLV_5tMX {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .TtUgr1LPsiV8hJgKq3Vj .xgl0t_kscJOuBnpkuDq8 {
  background: #f5f5f5;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
@media screen and (max-width: 601px) {
  ._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .TtUgr1LPsiV8hJgKq3Vj {
    background: #ffffff;
    padding: 12px 16px;
    position: fixed;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
  }
  ._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .TtUgr1LPsiV8hJgKq3Vj button {
    height: 40px;
  }
  ._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .TtUgr1LPsiV8hJgKq3Vj .r6SHxDXGvJhutLV_5tMX {
    flex: 1;
  }
  ._2U7qxNrIMYGPg8v_9SD .zosDOHgsl6J9xmsJwkPd .TtUgr1LPsiV8hJgKq3Vj .xgl0t_kscJOuBnpkuDq8 {
    flex: 1;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"identityCard": `_2U7qxNrIMYGPg8v_9SD`,
	"identityBody": `EMHdXQ5mZQ3d6jip_Kr3`,
	"identityProgressContainer": `R6lbaw54_3JVb3k1uISA`,
	"stepText": `n9OSFsX9nwU4l9OSDCZd`,
	"identityHeading": `Rx_TiYWboQi6iwLPwxIk`,
	"identityTitle": `gR5cvLgzIDwmVfCEeYCX`,
	"identitySubtitle": `ywrSwCYeei5LLsbNu3p5`,
	"heading": `AnIqDT_e8GDV5pT99NqY`,
	"info": `NVjnYggD0d70jHGX3LGR`,
	"editDelete": `j506abY_WUgyTH1IF_WL`,
	"addIdentityCard": `RmvRgu1tXTTl53A3fv_p`,
	"idCheckName": `A9gNEUYr3oi_m_m0UkEd`,
	"required": `Ys9KRWTs04nV2PZQeWdR`,
	"aadhaarConfigError": `Emf7KMN5sAVvRU0nCcUl`,
	"addBtn": `r8WhTQQpxqVPRdX6MoC8`,
	"addSign": `ntsfn_p8l6ZyDbKr4VY0`,
	"idInfoCard": `JK50IwUV5Ri5kX9b6wqt`,
	"checkName": `BuXB0RrU0v24zoNAsUPq`,
	"checkInfo": `acrZXavEuSpPaM3kff3Q`,
	"badge": `m7wg4XFUjneAAUmcGJYr`,
	"identityFooter": `zosDOHgsl6J9xmsJwkPd`,
	"skipBtn": `RLaERH5c63bMQ_0jg7iL`,
	"goBtn": `MwDubPWudIDadgT7UQAl`,
	"identitySubmitBtn": `TtUgr1LPsiV8hJgKq3Vj`,
	"activeBtn": `r6SHxDXGvJhutLV_5tMX`,
	"disabledBtn": `xgl0t_kscJOuBnpkuDq8`
};
export default ___CSS_LOADER_EXPORT___;
