// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H7BNVpjkYmbz6E6ZIMGi {
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .H7BNVpjkYmbz6E6ZIMGi {
    width: 1200px;
  }
}
.H7BNVpjkYmbz6E6ZIMGi .ihFwrJkXMwXyhP1H9IcA {
  margin-top: -190px;
  display: flex;
  justify-content: space-between;
}
.H7BNVpjkYmbz6E6ZIMGi .ihFwrJkXMwXyhP1H9IcA .b1lMoG_cjxe4hpm5CC2C {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.H7BNVpjkYmbz6E6ZIMGi .ihFwrJkXMwXyhP1H9IcA .Ki2wcyUrhATmpnKpbZew {
  line-height: 20px;
  color: #ffffff;
  background-color: #2755fe;
}
.H7BNVpjkYmbz6E6ZIMGi .ihFwrJkXMwXyhP1H9IcA .Ki2wcyUrhATmpnKpbZew:hover {
  background-color: #1f44ca;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .avnJkbzuHx9CukASTMLZ {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
  padding: 24px 28px 0px;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal {
  border: 0px;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo {
  margin: auto;
  margin-top: 22px;
  margin-bottom: 24px;
  width: 97%;
  text-align: left;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo thead {
  background-color: #f2f8ff;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo thead tr th {
  border: 0px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 15px 25px;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo thead tr .fG7cqZ4fY0XWEqnfnwES {
  text-align: right;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr td {
  cursor: default;
  border: 0px;
  vertical-align: middle;
  padding: 15px 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  max-width: 220px;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr td .BsewSNyPVJNvZAlTGqGb {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-left: -4px;
  align-items: center;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr td .BsewSNyPVJNvZAlTGqGb .hmETd7DZGrS3CeiVAI46 {
  border: 1px transparent solid;
  background: transparent;
  padding: 3px;
  border-radius: 50%;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr td .BsewSNyPVJNvZAlTGqGb .hmETd7DZGrS3CeiVAI46:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr td .BsewSNyPVJNvZAlTGqGb .hmETd7DZGrS3CeiVAI46:disabled:hover {
  background: transparent !important;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr td .BsewSNyPVJNvZAlTGqGb .hmETd7DZGrS3CeiVAI46:hover {
  background: #e2ebf6;
  border: 1px #e2ebf6 solid;
  border-radius: 50%;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr td .BsewSNyPVJNvZAlTGqGb .hmETd7DZGrS3CeiVAI46:hover svg path {
  fill: #2755fe;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr td .BsewSNyPVJNvZAlTGqGb .Z2q9tP28VjdUzS4w8iAk {
  margin-left: 24px;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr .fG7cqZ4fY0XWEqnfnwES {
  text-align: right;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr:hover {
  background-color: #f5f5f5;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo tbody tr:hover .QNTYf7n1fA1nPUlxzXuS {
  color: #366cf3;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo .hmkkJRYrKqBtxtxXYmka {
  text-align: center;
  padding-top: 64px;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .tjrTdk3JLdcHnPUFfeSo .hmkkJRYrKqBtxtxXYmka .Ekkx1i8_0N5PAix16Z1w {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .j3dAXRodhKbF4cVmZQnZ {
  flex-wrap: unset;
  padding: 0 24px 15px;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .j3dAXRodhKbF4cVmZQnZ .V04SmHgKNMklqcTf4Df3 {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.H7BNVpjkYmbz6E6ZIMGi .GEQEZPnmqmHbyA94JKB4 .LE4i5lt6gNIjf_FuBCal .j3dAXRodhKbF4cVmZQnZ .V04SmHgKNMklqcTf4Df3 .QWX4MFFoj2lm_Qusp5L4 {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px;
  -webkit-appearance: menulist;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminWrapper": `H7BNVpjkYmbz6E6ZIMGi`,
	"adminHeader": `ihFwrJkXMwXyhP1H9IcA`,
	"adminHeading": `b1lMoG_cjxe4hpm5CC2C`,
	"addAdminButton": `Ki2wcyUrhATmpnKpbZew`,
	"adminCard": `GEQEZPnmqmHbyA94JKB4`,
	"adminCardHeading": `avnJkbzuHx9CukASTMLZ`,
	"tableCard": `LE4i5lt6gNIjf_FuBCal`,
	"administratorsTable": `tjrTdk3JLdcHnPUFfeSo`,
	"alignRight": `fG7cqZ4fY0XWEqnfnwES`,
	"icons": `BsewSNyPVJNvZAlTGqGb`,
	"icon": `hmETd7DZGrS3CeiVAI46`,
	"space": `Z2q9tP28VjdUzS4w8iAk`,
	"mainField": `QNTYf7n1fA1nPUlxzXuS`,
	"noDataWrapper": `hmkkJRYrKqBtxtxXYmka`,
	"noDataContent": `Ekkx1i8_0N5PAix16Z1w`,
	"paginationWrapper": `j3dAXRodhKbF4cVmZQnZ`,
	"showPagination": `V04SmHgKNMklqcTf4Df3`,
	"selectPagination": `QWX4MFFoj2lm_Qusp5L4`
};
export default ___CSS_LOADER_EXPORT___;
