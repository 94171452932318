// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z8uTsLpjcioMXzEzWdEB {
  max-width: 1200px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  margin-top: 15px;
  height: 100px;
}
.z8uTsLpjcioMXzEzWdEB ._ISPhG46EIVy3rqXQnNO {
  width: 1200px;
  padding: 0px 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.z8uTsLpjcioMXzEzWdEB ._ISPhG46EIVy3rqXQnNO .dGOeIkuxQAWJorRiqTDC {
  margin: 3px;
  background-color: rgba(61, 143, 255, 0.13);
  border-radius: 50%;
}
.z8uTsLpjcioMXzEzWdEB ._ISPhG46EIVy3rqXQnNO .dGOeIkuxQAWJorRiqTDC .s1gESPrF_99HtEI78dSy {
  height: 89px;
}
.z8uTsLpjcioMXzEzWdEB ._ISPhG46EIVy3rqXQnNO .LvrnLYmIMSvrL6gYKJaE {
  font-weight: 500;
  font-size: 18px;
  color: #666;
  width: 674px;
}
.z8uTsLpjcioMXzEzWdEB ._ISPhG46EIVy3rqXQnNO .LvrnLYmIMSvrL6gYKJaE .DB1Ku7QS9KIAcqdI2qOO {
  font-family: Roboto;
  font-weight: 700;
  font-size: 18px;
  color: #333;
}
.z8uTsLpjcioMXzEzWdEB ._ISPhG46EIVy3rqXQnNO .LvrnLYmIMSvrL6gYKJaE .zt3waWjEPUPryapu8OWv {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.z8uTsLpjcioMXzEzWdEB ._ISPhG46EIVy3rqXQnNO .LvrnLYmIMSvrL6gYKJaE .OjoR_v_NKl2mcsQzAsfB {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.z8uTsLpjcioMXzEzWdEB ._ISPhG46EIVy3rqXQnNO .o6z3MuS0VkvS9v7NNPJs {
  height: 74px;
}
.z8uTsLpjcioMXzEzWdEB:hover {
  cursor: pointer;
}

.EJ_B2d9zAw3ThXJaf7Pi {
  width: 164px;
  height: 48px;
  margin-right: 10px;
  border: #2755fe 1px solid;
  border-radius: 4px;
  color: #2755fe;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: #ffffff;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `z8uTsLpjcioMXzEzWdEB`,
	"divContainer": `_ISPhG46EIVy3rqXQnNO`,
	"firstCol": `dGOeIkuxQAWJorRiqTDC`,
	"firstColImg": `s1gESPrF_99HtEI78dSy`,
	"headingCol": `LvrnLYmIMSvrL6gYKJaE`,
	"subheadingCol": `DB1Ku7QS9KIAcqdI2qOO`,
	"amount": `zt3waWjEPUPryapu8OWv`,
	"rupee": `OjoR_v_NKl2mcsQzAsfB`,
	"imgCol": `o6z3MuS0VkvS9v7NNPJs`,
	"referNowBtn": `EJ_B2d9zAw3ThXJaf7Pi`
};
export default ___CSS_LOADER_EXPORT___;
