// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ks4nAFCw5o4OOz6zRLmM {
  width: 680px;
  margin: -190px auto 0 auto;
}
.Ks4nAFCw5o4OOz6zRLmM h4 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 30px;
}

.ysY7QEm3BI5u4COADGrd {
  margin: 0 auto 60px;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  width: 680px;
  padding: 24px;
}
.ysY7QEm3BI5u4COADGrd .vvPN6mRaWuFDcyHNDzNn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
}
.ysY7QEm3BI5u4COADGrd .vvPN6mRaWuFDcyHNDzNn .NJH9NNDtUnR9b3pL9cp8 {
  margin-top: 11px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}
.ysY7QEm3BI5u4COADGrd .wP9F78gIs_Si8lw4xwpm label {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #646c9a;
  margin-bottom: 6px;
}
.ysY7QEm3BI5u4COADGrd .wP9F78gIs_Si8lw4xwpm .w6x0nEr3rC5jBiVynQCv select {
  width: 632px !important;
}
.ysY7QEm3BI5u4COADGrd .wP9F78gIs_Si8lw4xwpm .olZmDJxPiTru3jHHMpf_ {
  height: 38px;
  background: transparent;
  font-family: Poppins, sans-serif;
  margin-left: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #2755fe;
}
.ysY7QEm3BI5u4COADGrd .wP9F78gIs_Si8lw4xwpm .olZmDJxPiTru3jHHMpf_:active, .ysY7QEm3BI5u4COADGrd .wP9F78gIs_Si8lw4xwpm .olZmDJxPiTru3jHHMpf_:hover {
  color: #2755fe !important;
  background-color: transparent !important;
  border-color: transparent !important;
  text-decoration: underline;
}
.ysY7QEm3BI5u4COADGrd .wP9F78gIs_Si8lw4xwpm .OBHPXUX3zAm4z4gbDn7g {
  display: flex;
  justify-content: flex-end;
  margin: 24px 0 0 24px;
}
.ysY7QEm3BI5u4COADGrd .wP9F78gIs_Si8lw4xwpm .OBHPXUX3zAm4z4gbDn7g .hXaj9oq5pyPVZJ2kg8zQ {
  background: #2755fe;
  border-radius: 5px;
  width: 136px;
  height: 48px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.ysY7QEm3BI5u4COADGrd .wP9F78gIs_Si8lw4xwpm .OBHPXUX3zAm4z4gbDn7g .SdhMF4tFq3ltyXKJuFfq {
  margin-left: 15px;
  background: #ffffff;
  border: 1px solid #2755fe;
  border-radius: 5px;
  width: 136px;
  height: 48px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-right: 16px;
  color: #2755fe;
}

.McsSeFctx8_hwSOpQJsF input {
  border: 1px solid #dc3545 !important;
  margin-bottom: 0 !important;
}

.cny9tj7nLT5rM2Htr2FQ {
  position: absolute;
  top: 4.3rem;
  font-size: 13px;
  color: #dc3545;
}

.V2lDC7R2Aht1qB4RmhE1 {
  margin-left: 8px;
  position: absolute;
  top: 0;
  left: 6.5rem;
}

.lJL2Fgb3VuxKBRnDjKvv {
  font-size: 13px;
  color: #dc3545;
}

.Ryt553XL9ZYjFnYlcwf8 {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #2755fe;
  font-weight: 400;
  font-size: 14px;
  margin-right: 5px;
}
.Ryt553XL9ZYjFnYlcwf8:hover {
  text-decoration: underline;
}
.Ryt553XL9ZYjFnYlcwf8:disabled {
  color: #7c8b99;
  cursor: not-allowed;
}
.Ryt553XL9ZYjFnYlcwf8:disabled:hover {
  text-decoration: none;
}

.itcK6Lev6GYQ96Lcg_Kw {
  font-size: 12px;
  padding: 3px 6px;
}

.KbkRVp6QrUIJXusVg8xk {
  padding-right: 5px;
  cursor: pointer;
}

.UcbqpwQfhDX6ZBCJqX7Z {
  margin-bottom: 32px;
}

.QqjQm0XDZ8AQPai8tgLs {
  margin-top: 5px;
  margin-bottom: 32px;
  color: #dc3545;
  font-size: 12px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `Ks4nAFCw5o4OOz6zRLmM`,
	"adminCard": `ysY7QEm3BI5u4COADGrd`,
	"cardHeader": `vvPN6mRaWuFDcyHNDzNn`,
	"cardHeading": `NJH9NNDtUnR9b3pL9cp8`,
	"adminForm": `wP9F78gIs_Si8lw4xwpm`,
	"accessDropdown": `w6x0nEr3rC5jBiVynQCv`,
	"addBtn": `olZmDJxPiTru3jHHMpf_`,
	"adminCardFooter": `OBHPXUX3zAm4z4gbDn7g`,
	"adminFormButton": `hXaj9oq5pyPVZJ2kg8zQ`,
	"cancelButton": `SdhMF4tFq3ltyXKJuFfq`,
	"validationError": `McsSeFctx8_hwSOpQJsF`,
	"validationErrorMsg": `cny9tj7nLT5rM2Htr2FQ`,
	"emailBouncedIcon": `V2lDC7R2Aht1qB4RmhE1`,
	"emailErrorMsg": `lJL2Fgb3VuxKBRnDjKvv`,
	"ChangePasswordBtn": `Ryt553XL9ZYjFnYlcwf8`,
	"tooltipContent": `itcK6Lev6GYQ96Lcg_Kw`,
	"changePasswordInfoIcon": `KbkRVp6QrUIJXusVg8xk`,
	"mb32": `UcbqpwQfhDX6ZBCJqX7Z`,
	"errorMessage": `QqjQm0XDZ8AQPai8tgLs`
};
export default ___CSS_LOADER_EXPORT___;
