import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Loader from '../../../core-components/Loader/Loader';
import styles from '../Login.module.scss';
import { errToastMessage, redirectToPreviousPathAfterLogin } from '../../../utils/utilities';

const MsRedirection = (props) => {

  const { location, googleMicrosoftLoginAPI, history } = props;
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const error = params.get('error');

  useEffect(() => {
    const handleLogin = (code) => {
      let session = '';
      try {
        session = window.sessionStorage.getItem('codeVerifier');
        if(session) session = window.btoa(session);
      } catch (err) {
        console.error('err: ', err);
        history.push('/signIn');
        console.error('err: ', err);
        errToastMessage('Something went wrong.');
      }
      googleMicrosoftLoginAPI('auth/microsoft-login', { code, session })
        .then(() => {
          sessionStorage.removeItem('codeVerifier');
          redirectToPreviousPathAfterLogin(props?.history);
        })
        .catch((err) => {
          history.push('/signIn');
          if (err?.data?.message) errToastMessage(err.data.message);
        });
    };

    if (code) {
      handleLogin(code);
    }
  }, [code]);

  useEffect(() => {
    if (error) {
      if (error !== 'access_denied') errToastMessage(error);
      history.push('/signIn');
    }
  }, [error]);

  return (
    <>
      <div className={styles.overlayLoader}>
        <Loader></Loader>
      </div>
    </>
  );
};

export default withRouter(MsRedirection);
