// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vplXaar_LppK1XdMaBAu {
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border: 1px solid #e6e6e6;
}

.FYOeSJZCwptSypYegXSg {
  margin: 30px 0 48px 0;
}

.wLUQPlxVMpI59CIWiqtf {
  background-color: #2755fe;
  color: white;
  padding: 0.5rem;
  border: none;
}
.wLUQPlxVMpI59CIWiqtf:hover {
  background-color: #1f44ca;
}

.DR2Vxk9PWOFwNLSSNrmR {
  color: #333333;
}

.vo2vqqAywrSNhyKNZAVw {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 180px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-left: 12px;
  height: 45px;
}
.vo2vqqAywrSNhyKNZAVw option {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
.vo2vqqAywrSNhyKNZAVw option:hover {
  background: #ffffff;
}

.JauYmjuAeHmK6bkygUX7 {
  background-color: #11a294 !important;
  color: white;
  padding: 0.5rem;
  border: none;
}

.LUWOYLqydYq8vLM7c_pn {
  background-color: white;
  color: #037bff;
  padding: 0.5rem;
  border: 1px #037bff solid;
}

.LUWOYLqydYq8vLM7c_pn:hover {
  background-color: #037bff;
  color: white;
}

.LUWOYLqydYq8vLM7c_pn:focus {
  color: #037bff;
}

.wLUQPlxVMpI59CIWiqtf:hover {
  background-color: #2755fe;
}

.JauYmjuAeHmK6bkygUX7:hover {
  background-color: #11a294;
}

.Rxku3KGUe4jW3vHzKBU6 {
  height: 130px;
  font-size: 15px;
  font-weight: 400;
}

.OATrPk4NtR_d1y9kIH9g {
  font-size: 1.25rem;
  padding-top: 15px;
}

.OOB5g4s53l878hlaNGsw {
  font-size: 1.25rem;
}

.mW_Ev9IXSMfwe47ijupa {
  min-height: 48px;
}

.FQNQbvz0UiFivOAhnrgC {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.LIJ0ywct1BdgtDwtYJFJ {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.GLVAvsy2y8k9iL2kwtgQ {
  position: relative;
}
.GLVAvsy2y8k9iL2kwtgQ .bQc1QK196l7zmhIaMOzV {
  position: absolute;
  z-index: 1;
  left: 13px;
  top: -10px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  background: #ffffff;
  padding: 0 4px;
}

.I_x3BGIm3qdFTYfJyhEH {
  width: 181px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 21px;
  height: 45px;
  box-shadow: none !important;
  border-radius: 4px;
  border: 1px solid #c2cfff !important;
}

.I_x3BGIm3qdFTYfJyhEH .fLkQpdqdYb1lthRImRRC {
  color: #333 !important;
}

.xHLDqDNEHbRMSU59gVqO {
  width: auto;
  height: 36px;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0 10px;
  border: none;
  border-radius: 8px;
  background: transparent;
}

.m2QJPNwwLPXO51CwJDYL {
  background: #f4f6ff !important;
}
.m2QJPNwwLPXO51CwJDYL span {
  color: #333 !important;
}

.xHLDqDNEHbRMSU59gVqO:hover {
  background-color: #f4f6ff;
}

.xHLDqDNEHbRMSU59gVqO:focus {
  box-shadow: unset;
}

.GMAg_UBEe5kQYj8VgBzn {
  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */ /* 142.857% */
}

.VKk_NqsZzNVV8WKATJ3k {
  display: flex;
  width: 508px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.hTtM5Y5FMieKxA6tQblb {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 273px;
  display: flex;
  padding-left: 12px;
  padding-right: 15px;
  height: 45px;
  align-items: center;
}
.hTtM5Y5FMieKxA6tQblb img {
  width: 14px;
  height: 14px;
}
.hTtM5Y5FMieKxA6tQblb .c5DdSshktbUBZR6tILEQ {
  border: none;
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 0 14px !important;
}
.hTtM5Y5FMieKxA6tQblb .c5DdSshktbUBZR6tILEQ:focus {
  box-shadow: unset;
}

.UaswADG4QiwuMhhvKgoR {
  margin-top: 1.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CFuNEcHfRyerdelBd4V_ {
  min-height: 386px;
}

.dKsRL6hc9FczhgibjcVQ {
  height: auto;
}

.xFDL5a7qNjy42D8z1UKw {
  display: grid;
  margin: 1rem 0;
}

._s3EdtP8BX8Y7OBqe7YY {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
  row-gap: 1.5rem;
}

._CktUBokB5xruST4x_1n {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 1rem;
}

.P6u9BzHUX2qDTMRaOopw {
  position: relative;
  padding: 20px 42px 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.P6u9BzHUX2qDTMRaOopw .gx4jZ9RSyKqUQkrt1kLP {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.P6u9BzHUX2qDTMRaOopw .gx4jZ9RSyKqUQkrt1kLP .sWZKUM63X2Oob8fHQYWz img {
  width: 64px;
  height: 64px;
}
.P6u9BzHUX2qDTMRaOopw .gx4jZ9RSyKqUQkrt1kLP .X43gMerMlgu8uB3TEwXB {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.P6u9BzHUX2qDTMRaOopw .gx4jZ9RSyKqUQkrt1kLP .X43gMerMlgu8uB3TEwXB .YlonpLr0d5mOAwwerQcH .g5QRA8Js9esxI4Hs2Nvc {
  color: #333;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.P6u9BzHUX2qDTMRaOopw .gx4jZ9RSyKqUQkrt1kLP .X43gMerMlgu8uB3TEwXB .YlonpLr0d5mOAwwerQcH .SqDksZaKD_aNbt1PIPng {
  display: none;
}
.P6u9BzHUX2qDTMRaOopw .gx4jZ9RSyKqUQkrt1kLP .X43gMerMlgu8uB3TEwXB .YlonpLr0d5mOAwwerQcH .JrbtcgV3qk37K5f7sEXw {
  display: none;
}
.P6u9BzHUX2qDTMRaOopw .gx4jZ9RSyKqUQkrt1kLP .X43gMerMlgu8uB3TEwXB .buzyWlT9UFn8a6yqGp40 {
  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.P6u9BzHUX2qDTMRaOopw .MociD4swpAXbTfTSMiOg {
  width: auto;
}
.P6u9BzHUX2qDTMRaOopw .tPQ2ptii5pW3diizKSNA {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.P6u9BzHUX2qDTMRaOopw .guscBzk9TfYkj_j4PrVR {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.cPslvVrt9V_Tus164Uo3 {
  position: relative;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
}
.cPslvVrt9V_Tus164Uo3 .gx4jZ9RSyKqUQkrt1kLP {
  display: flex;
  gap: 16px;
}
.cPslvVrt9V_Tus164Uo3 .gx4jZ9RSyKqUQkrt1kLP .sWZKUM63X2Oob8fHQYWz img {
  width: 54px;
  height: 54px;
}
.cPslvVrt9V_Tus164Uo3 .gx4jZ9RSyKqUQkrt1kLP .X43gMerMlgu8uB3TEwXB {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.cPslvVrt9V_Tus164Uo3 .gx4jZ9RSyKqUQkrt1kLP .X43gMerMlgu8uB3TEwXB .YlonpLr0d5mOAwwerQcH {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.cPslvVrt9V_Tus164Uo3 .gx4jZ9RSyKqUQkrt1kLP .X43gMerMlgu8uB3TEwXB .YlonpLr0d5mOAwwerQcH .g5QRA8Js9esxI4Hs2Nvc {
  color: #333;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.cPslvVrt9V_Tus164Uo3 .gx4jZ9RSyKqUQkrt1kLP .X43gMerMlgu8uB3TEwXB .YlonpLr0d5mOAwwerQcH .SqDksZaKD_aNbt1PIPng {
  display: block;
}
.cPslvVrt9V_Tus164Uo3 .gx4jZ9RSyKqUQkrt1kLP .X43gMerMlgu8uB3TEwXB .buzyWlT9UFn8a6yqGp40 {
  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.cPslvVrt9V_Tus164Uo3 .MociD4swpAXbTfTSMiOg {
  max-width: 886px;
}
.cPslvVrt9V_Tus164Uo3 .tPQ2ptii5pW3diizKSNA {
  display: none;
}
.cPslvVrt9V_Tus164Uo3 .guscBzk9TfYkj_j4PrVR {
  display: none;
}

.TRuQ_i0aGwSTXEMsTVnk {
  margin-top: 16px;
  color: #333;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.LHW54of86oxDhWDlJOVE {
  display: inline-flex;
  padding: 4px 10px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 40px;
  background: rgba(255, 170, 0, 0.1019607843);
  color: #fa0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.YGk9TwkKUN18Z58ji2W2 {
  display: inline-flex;
  padding: 4px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  background: rgba(10, 157, 60, 0.2392156863);
  color: #1d8a42;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.CM3_v74CQOSmQRIOpgrF {
  width: 164px;
  height: 40px;
  border-color: #2755fe;
}

.p3wF4JS0lmSW1cZVnOmu {
  color: white;
  background-color: #2755fe;
}

.GfrcNqNJgddEzuSL4iBt {
  color: #2755fe;
}

/* Skeleton Styles */
@keyframes VJm8BxHzvY1w9RJpdDMx {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #c0c0c0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
.ZaBRk02dJ6PAgqcF5VRz {
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: VJm8BxHzvY1w9RJpdDMx 1.5s infinite;
}

.X9yE2E8ZcppbvU0t7UjD {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #e0e0e0;
  animation: VJm8BxHzvY1w9RJpdDMx 1.5s infinite;
}

.K041YoDcDkQrbBSbQmzS {
  width: 164px;
  height: 40px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: VJm8BxHzvY1w9RJpdDMx 1.5s infinite;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `vplXaar_LppK1XdMaBAu`,
	"bodyCard": `FYOeSJZCwptSypYegXSg`,
	"connectButton": `wLUQPlxVMpI59CIWiqtf`,
	"dropDownPlaceholder": `DR2Vxk9PWOFwNLSSNrmR`,
	"dropdownStatuses": `vo2vqqAywrSNhyKNZAVw`,
	"connectedButton": `JauYmjuAeHmK6bkygUX7`,
	"configureButton": `LUWOYLqydYq8vLM7c_pn`,
	"bodyText": `Rxku3KGUe4jW3vHzKBU6`,
	"title": `OATrPk4NtR_d1y9kIH9g`,
	"listTitle": `OOB5g4s53l878hlaNGsw`,
	"subtitle": `mW_Ev9IXSMfwe47ijupa`,
	"bodyHeader": `FQNQbvz0UiFivOAhnrgC`,
	"listBodyHeader": `LIJ0ywct1BdgtDwtYJFJ`,
	"dropDownContainer": `GLVAvsy2y8k9iL2kwtgQ`,
	"category": `bQc1QK196l7zmhIaMOzV`,
	"widgetDropdownTime": `I_x3BGIm3qdFTYfJyhEH`,
	"rw-placeholder": `fLkQpdqdYb1lthRImRRC`,
	"buttonWithIcon": `xHLDqDNEHbRMSU59gVqO`,
	"active": `m2QJPNwwLPXO51CwJDYL`,
	"buttonText": `GMAg_UBEe5kQYj8VgBzn`,
	"viewStyle": `VKk_NqsZzNVV8WKATJ3k`,
	"searchInput": `hTtM5Y5FMieKxA6tQblb`,
	"inputText": `c5DdSshktbUBZR6tILEQ`,
	"filterHeader": `UaswADG4QiwuMhhvKgoR`,
	"gridHeight": `CFuNEcHfRyerdelBd4V_`,
	"listHeight": `dKsRL6hc9FczhgibjcVQ`,
	"cardContainer": `xFDL5a7qNjy42D8z1UKw`,
	"gridView": `_s3EdtP8BX8Y7OBqe7YY`,
	"listView": `_CktUBokB5xruST4x_1n`,
	"gridViewInner": `P6u9BzHUX2qDTMRaOopw`,
	"cardHeaderContainer": `gx4jZ9RSyKqUQkrt1kLP`,
	"cardImage": `sWZKUM63X2Oob8fHQYWz`,
	"titleContainer": `X43gMerMlgu8uB3TEwXB`,
	"titleBox": `YlonpLr0d5mOAwwerQcH`,
	"titleText": `g5QRA8Js9esxI4Hs2Nvc`,
	"comingSoonTitle": `SqDksZaKD_aNbt1PIPng`,
	"connectedTitle": `JrbtcgV3qk37K5f7sEXw`,
	"subtitleText": `buzyWlT9UFn8a6yqGp40`,
	"details": `MociD4swpAXbTfTSMiOg`,
	"comingSoon": `tPQ2ptii5pW3diizKSNA`,
	"connected": `guscBzk9TfYkj_j4PrVR`,
	"listViewInner": `cPslvVrt9V_Tus164Uo3`,
	"cardDetails": `TRuQ_i0aGwSTXEMsTVnk`,
	"comingSoonPill": `LHW54of86oxDhWDlJOVE`,
	"connectedPill": `YGk9TwkKUN18Z58ji2W2`,
	"actionButton": `CM3_v74CQOSmQRIOpgrF`,
	"primary": `p3wF4JS0lmSW1cZVnOmu`,
	"outline": `GfrcNqNJgddEzuSL4iBt`,
	"skeletonLine": `ZaBRk02dJ6PAgqcF5VRz`,
	"skeleton-loading": `VJm8BxHzvY1w9RJpdDMx`,
	"skeletonCircle": `X9yE2E8ZcppbvU0t7UjD`,
	"skeletonButton": `K041YoDcDkQrbBSbQmzS`
};
export default ___CSS_LOADER_EXPORT___;
