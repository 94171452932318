// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QrWZWopojLg7VEHR7BTc {
  border: 1px solid #2755fe;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2755fe;
  width: 136px;
  margin-right: 15px;
}
.QrWZWopojLg7VEHR7BTc:hover {
  background-color: #ffffff;
  color: #2755fe;
}
.QrWZWopojLg7VEHR7BTc:active {
  background-color: #ffffff !important;
  color: #2755fe !important;
}

.gu497tePsIJtB0DxUg9s {
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  margin-right: 0px;
}
.gu497tePsIJtB0DxUg9s:hover {
  background: #2755fe;
  color: #ffffff;
}

.tgRD4P5ZSTdwr3Q0M0OO {
  background: #d3455b;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  margin-right: 0px;
}
.tgRD4P5ZSTdwr3Q0M0OO:hover {
  background-color: #e97070;
}
.tgRD4P5ZSTdwr3Q0M0OO:active {
  background: #e97070 !important;
}

.yrx79wWUuo19uFVvttGa {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
}
.yrx79wWUuo19uFVvttGa .dRIBWMotchLptmMNhtO_ {
  padding-left: 8px;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  line-height: 21px;
  color: #999999;
  display: flex;
  align-items: center;
}

.lPmEgwOb4L_QAypPn4HN {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
}

.BpRhyEkkGnbR2qwHUY8y {
  background: #ffffff;
  border: 1px solid #c2cfff;
  border-radius: 4px;
  padding: 12px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  width: 100%;
  height: 38px;
  margin-bottom: 25px;
}
.BpRhyEkkGnbR2qwHUY8y:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.BpRhyEkkGnbR2qwHUY8y.HjlxYuMHLEbBnOGNu8Ir:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.RfU7CIR06mEAdKMup1P7 {
  width: 100%;
  margin-top: -20px;
  font-size: 14px;
  color: #dc3545;
  margin-bottom: 15px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancelButton": `QrWZWopojLg7VEHR7BTc`,
	"submitButton": `gu497tePsIJtB0DxUg9s`,
	"deleteButton": `tgRD4P5ZSTdwr3Q0M0OO`,
	"deleteInfo": `yrx79wWUuo19uFVvttGa`,
	"deleteInfoContent": `dRIBWMotchLptmMNhtO_`,
	"ErrorMsg": `lPmEgwOb4L_QAypPn4HN`,
	"inputField": `BpRhyEkkGnbR2qwHUY8y`,
	"errInput": `HjlxYuMHLEbBnOGNu8Ir`,
	"catErr": `RfU7CIR06mEAdKMup1P7`
};
export default ___CSS_LOADER_EXPORT___;
