// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.es5uYWqEBQjSMqozfcpg {
  line-height: 24px;
}
.es5uYWqEBQjSMqozfcpg .Fe1_LZk6pHi9yFH04G2U .ag6gdg5nK48UJN8bzYK9 {
  text-align: center;
}
.es5uYWqEBQjSMqozfcpg .Fe1_LZk6pHi9yFH04G2U .qv7qzkN9IqZHWRg78KkB {
  padding-left: 0;
}
.es5uYWqEBQjSMqozfcpg .Fe1_LZk6pHi9yFH04G2U .dA6VXsrxepouUtvBdTsS {
  padding-left: 0;
  margin-top: 10px;
  font-size: 14px;
  color: #999999;
}
.es5uYWqEBQjSMqozfcpg .Fe1_LZk6pHi9yFH04G2U .fYYzzssQVv6caseXM4Eb {
  padding-left: 0;
  font-size: 14px;
  margin-top: 10px;
  color: #e97070;
}
.es5uYWqEBQjSMqozfcpg .Fe1_LZk6pHi9yFH04G2U .ALKMtzvEN76WELUJ4ROa {
  margin-top: 18px;
}
.es5uYWqEBQjSMqozfcpg .kiGzLLsvB7JAAh3tiP47 {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
}
.es5uYWqEBQjSMqozfcpg .nlsQ1Fg6nBi4ySIdbMxC {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.es5uYWqEBQjSMqozfcpg .jHozZz4sKluA4D8IhZOQ button {
  border-radius: 2px;
  padding: 5px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.es5uYWqEBQjSMqozfcpg .jHozZz4sKluA4D8IhZOQ .uOPrm79VfZeBl9aIhE9a {
  background-color: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.es5uYWqEBQjSMqozfcpg .jHozZz4sKluA4D8IhZOQ .oLKKK9Ab0n90J_tC7Vqz {
  background: #f5f5f5;
  color: #bfbfbf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
.es5uYWqEBQjSMqozfcpg .jHozZz4sKluA4D8IhZOQ .DCmi72vL3it9vR4I1Pha {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
}
.es5uYWqEBQjSMqozfcpg .jHozZz4sKluA4D8IhZOQ .YwAkisqA8E07rNbhm6tZ {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
  cursor: not-allowed;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationModal": `es5uYWqEBQjSMqozfcpg`,
	"confirModalBody": `Fe1_LZk6pHi9yFH04G2U`,
	"questionImage": `ag6gdg5nK48UJN8bzYK9`,
	"confirmTextContainer": `qv7qzkN9IqZHWRg78KkB`,
	"confirmTextBody": `dA6VXsrxepouUtvBdTsS`,
	"confirmTextBodyRed": `fYYzzssQVv6caseXM4Eb`,
	"consent": `ALKMtzvEN76WELUJ4ROa`,
	"confirmModalHeading": `kiGzLLsvB7JAAh3tiP47`,
	"confirmText": `nlsQ1Fg6nBi4ySIdbMxC`,
	"confirmModalFooter": `jHozZz4sKluA4D8IhZOQ`,
	"activeConfirmBtn": `uOPrm79VfZeBl9aIhE9a`,
	"disabledConfirmBtn": `oLKKK9Ab0n90J_tC7Vqz`,
	"cancelConfirmBtn": `DCmi72vL3it9vR4I1Pha`,
	"disabledCancelConfirmBtn": `YwAkisqA8E07rNbhm6tZ`
};
export default ___CSS_LOADER_EXPORT___;
