// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wUOSr9pGNxunF4DEWGDA {
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .wUOSr9pGNxunF4DEWGDA {
    width: 1200px;
  }
}
.wUOSr9pGNxunF4DEWGDA .CxhYqKAco_LuS_kgJRxW {
  display: flex;
  justify-content: space-between;
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.wUOSr9pGNxunF4DEWGDA .CxhYqKAco_LuS_kgJRxW .KWrDVpwgqTYgAj0PelhE {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.wUOSr9pGNxunF4DEWGDA .RRLIqlRnwQjxf8OPd1Iz {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 24px auto 32px;
  padding: 48px;
  font-family: Poppins, sans-serif;
  min-height: 695px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"creditContainer": `wUOSr9pGNxunF4DEWGDA`,
	"header": `CxhYqKAco_LuS_kgJRxW`,
	"heading": `KWrDVpwgqTYgAj0PelhE`,
	"creditCard": `RRLIqlRnwQjxf8OPd1Iz`
};
export default ___CSS_LOADER_EXPORT___;
