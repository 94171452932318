// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MpX_bfosT33yCjso_ps5 {
  height: 50vh;
  display: flex;
  justify-content: center;
}

.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql {
  padding: 0 24px 24px 24px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .Odim_8BxuDs4YrYel3ol {
  text-align: right;
  color: #2755fe;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL {
  padding: 0px 10px;
  width: 100%;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .srXCP4cpevO1FqNlJH9w {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(51, 51, 51, 0.7);
  margin-bottom: 20px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .nGL5Jnsi6CEYaV9JWpd9 {
  display: flex;
  margin-top: 3px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .nGL5Jnsi6CEYaV9JWpd9 .ymKSMxJ3cUF5zP8uXder {
  width: 250px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .YX6PXO8cxIFhYADsWPP2 {
  display: flex;
  align-items: center;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .YX6PXO8cxIFhYADsWPP2 .n7_UdY_j5r6YeAfeNwWR {
  height: 42px;
  width: 70px;
  border: 1px solid #ebedf2;
  border-radius: 4px;
  padding: 0 10px;
  color: #4c4c4c;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .YX6PXO8cxIFhYADsWPP2 .nxcHdD7IMvq5MaI3lnEB {
  color: #808080;
  margin-top: 25px;
  margin-left: 18px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .vta96pO0Bw8f7YUHDUHD {
  display: flex;
  justify-content: space-between;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .QrtlkFBFvXPaz_B60lGF {
  float: bottom;
  color: red;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .APtLsOOVXh93Zl4FvP79 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .APtLsOOVXh93Zl4FvP79 .n7_UdY_j5r6YeAfeNwWR {
  height: 42px;
  width: 225px;
  border: 1px solid #ebedf2;
  border-radius: 4px;
  padding: 0 10px;
  color: #4c4c4c;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .APtLsOOVXh93Zl4FvP79 .KylQHabf4uKkXTSIBqMZ {
  height: 22px;
  width: 22px;
  margin: 8px 5px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .APtLsOOVXh93Zl4FvP79 .UO2rQ9PFzgM9bvivjOy6 {
  display: flex;
  flex-direction: column;
  color: red;
  align-items: center;
  font-size: 13px;
  margin: 5px 0px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .J9Dm0_u5QGTijrvXIquL .APtLsOOVXh93Zl4FvP79 .UO2rQ9PFzgM9bvivjOy6 .NLLTN_q5Bwda7SmqxCWE {
  margin: 0px 5px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G {
  padding: 0px 10px;
  width: 100%;
  color: #808080 !important;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .srXCP4cpevO1FqNlJH9w {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(51, 51, 51, 0.7);
  margin-bottom: 20px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .nGL5Jnsi6CEYaV9JWpd9 {
  display: flex;
  margin-top: 3px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .nGL5Jnsi6CEYaV9JWpd9 .ymKSMxJ3cUF5zP8uXder {
  margin-right: 50px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .YX6PXO8cxIFhYADsWPP2 {
  display: flex;
  align-items: center;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .YX6PXO8cxIFhYADsWPP2 .n7_UdY_j5r6YeAfeNwWR {
  height: 42px;
  width: 70px;
  border: 1px solid #ebedf2;
  border-radius: 4px;
  padding: 0 10px;
  color: #4c4c4c;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .YX6PXO8cxIFhYADsWPP2 .nxcHdD7IMvq5MaI3lnEB {
  color: #808080;
  margin-top: 25px;
  margin-left: 18px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .vta96pO0Bw8f7YUHDUHD {
  display: flex;
  justify-content: space-between;
  width: 56%;
  margin-left: 18px;
  margin-top: 7px;
  margin-bottom: 6px;
  align-items: baseline;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .QrtlkFBFvXPaz_B60lGF {
  float: bottom;
  color: red;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .APtLsOOVXh93Zl4FvP79 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .APtLsOOVXh93Zl4FvP79 .n7_UdY_j5r6YeAfeNwWR {
  height: 42px;
  width: 225px;
  border: 1px solid #ebedf2;
  border-radius: 4px;
  padding: 0 10px;
  color: #4c4c4c;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .APtLsOOVXh93Zl4FvP79 .KylQHabf4uKkXTSIBqMZ {
  height: 24px;
  width: 24px;
  margin: 8px 5px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .APtLsOOVXh93Zl4FvP79 .UO2rQ9PFzgM9bvivjOy6 {
  display: flex;
  flex-direction: column;
  color: red;
  align-items: center;
  font-size: 13px;
  margin: 5px 0px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .wbEoDgqjqpAs8CdsLBmt .rgbReXA_9hFyawrtPD5G .APtLsOOVXh93Zl4FvP79 .UO2rQ9PFzgM9bvivjOy6 .NLLTN_q5Bwda7SmqxCWE {
  margin: 0px 5px;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .mPkNduKhv_gS9NtPtln6 {
  text-align: right;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .mPkNduKhv_gS9NtPtln6 .kGNcHHlwiqyGldPS2DTQ {
  margin-right: 24px;
  background: #ffffff;
  border: 1px solid #2755fe;
  border-radius: 5px;
  width: 136px;
  height: 48px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #2755fe;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .mPkNduKhv_gS9NtPtln6 .kGNcHHlwiqyGldPS2DTQ:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .mPkNduKhv_gS9NtPtln6 .NdBWwgl2ugoVp51VBYcC {
  color: #fff;
  width: 136px;
  height: 48px;
  background-color: #2755fe;
  border-color: #2755fe;
  cursor: pointer;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .mPkNduKhv_gS9NtPtln6 .tlV1eAlpA3STT_3JqBVS {
  color: #fff;
  width: 136px;
  height: 48px;
  background-color: #388e3c;
  border-color: #388e3c;
  cursor: pointer;
}
.Qf0Ysaux7_f0PEH7QrY9 .AFtKBPpR3LkOZqiMqOql .mPkNduKhv_gS9NtPtln6 .LFM54_hQysso2_Z0mKqK {
  color: #fff;
  width: 136px;
  height: 48px;
  border: 1px solid #2755fe;
  cursor: not-allowed;
}

.nxSnVwxDATiBZL5aIPdU {
  margin-right: -250px;
  color: #dc3545;
  font-size: 14px;
  position: absolute;
  top: 100%;
}

.mPkNduKhv_gS9NtPtln6 .LFM54_hQysso2_Z0mKqK {
  background-color: #2755fe;
  opacity: 0.5;
  cursor: not-allowed;
}

.Y50sQBDmvim_jamI1RNR {
  padding: 0 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  margin-bottom: 17px;
}
.Y50sQBDmvim_jamI1RNR a {
  color: #2755fe;
  display: inline-block;
  margin-left: 5px;
}

.y16DNTs_Zivqv0ABO_WG {
  padding: 25px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 8px;
  margin-bottom: 48px;
}

.dOdZdbPzMCvs1HStjlHw {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #212529;
}

.soPd_HSY2Bab8kXOGL1R {
  margin-top: 6px;
}

.VbvUlyL_HlL9EMu17zGL {
  width: 250px;
  margin-right: 0 !important;
  padding: 0 !important;
}
.VbvUlyL_HlL9EMu17zGL:last-child {
  width: auto;
  padding-right: 15px !important;
}

.e3EBzlPoDvwBZSg3eXmL {
  box-shadow: none;
  padding: 0;
}
.e3EBzlPoDvwBZSg3eXmL .ymKSMxJ3cUF5zP8uXder,
.e3EBzlPoDvwBZSg3eXmL .VbvUlyL_HlL9EMu17zGL {
  width: 200px !important;
}
.e3EBzlPoDvwBZSg3eXmL .ymKSMxJ3cUF5zP8uXder input[type=checkbox],
.e3EBzlPoDvwBZSg3eXmL .ymKSMxJ3cUF5zP8uXder input[type=radio],
.e3EBzlPoDvwBZSg3eXmL .VbvUlyL_HlL9EMu17zGL input[type=checkbox],
.e3EBzlPoDvwBZSg3eXmL .VbvUlyL_HlL9EMu17zGL input[type=radio] {
  margin-right: 5px;
}
.e3EBzlPoDvwBZSg3eXmL .y1lw0OKK6jshif7xDwNe {
  margin-left: -110px;
}
.e3EBzlPoDvwBZSg3eXmL .YX6PXO8cxIFhYADsWPP2 {
  padding-bottom: 10px;
}

.hV2yiVTtMps8BddFfoVs {
  padding: 24px !important;
}
.hV2yiVTtMps8BddFfoVs a {
  margin-left: 10px;
}

.XB1SrOSHKwQjXJYMMaqA {
  margin: 0 !important;
  font-size: 12px;
}

.FFQlesnuN9BaUOKOPVvQ {
  margin-left: 4px !important;
  cursor: pointer;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `MpX_bfosT33yCjso_ps5`,
	"outerdiv": `Qf0Ysaux7_f0PEH7QrY9`,
	"formStyle": `AFtKBPpR3LkOZqiMqOql`,
	"knowMore": `Odim_8BxuDs4YrYel3ol`,
	"fieldWrapper": `wbEoDgqjqpAs8CdsLBmt`,
	"flexDivContainer": `J9Dm0_u5QGTijrvXIquL`,
	"subTitle": `srXCP4cpevO1FqNlJH9w`,
	"options": `nGL5Jnsi6CEYaV9JWpd9`,
	"optionsField": `ymKSMxJ3cUF5zP8uXder`,
	"optionPasswordExpiry": `YX6PXO8cxIFhYADsWPP2`,
	"inputField": `n7_UdY_j5r6YeAfeNwWR`,
	"textField": `nxcHdD7IMvq5MaI3lnEB`,
	"optionDomainWhitelisted": `vta96pO0Bw8f7YUHDUHD`,
	"domainError": `QrtlkFBFvXPaz_B60lGF`,
	"domainWhitelistToogleField": `APtLsOOVXh93Zl4FvP79`,
	"image": `KylQHabf4uKkXTSIBqMZ`,
	"chipsDiv": `UO2rQ9PFzgM9bvivjOy6`,
	"chips": `NLLTN_q5Bwda7SmqxCWE`,
	"flexDivContainerGreyed": `rgbReXA_9hFyawrtPD5G`,
	"buttons": `mPkNduKhv_gS9NtPtln6`,
	"cancelBtn": `kGNcHHlwiqyGldPS2DTQ`,
	"activeBtn": `NdBWwgl2ugoVp51VBYcC`,
	"activeBtnSA": `tlV1eAlpA3STT_3JqBVS`,
	"disabledBtn": `LFM54_hQysso2_Z0mKqK`,
	"errorDiv": `nxSnVwxDATiBZL5aIPdU`,
	"headerContent": `Y50sQBDmvim_jamI1RNR`,
	"boxContainer": `y16DNTs_Zivqv0ABO_WG`,
	"sectionHeader": `dOdZdbPzMCvs1HStjlHw`,
	"sectionIcon": `soPd_HSY2Bab8kXOGL1R`,
	"radioClassName": `VbvUlyL_HlL9EMu17zGL`,
	"boxContainerSA": `e3EBzlPoDvwBZSg3eXmL`,
	"inputContainerSA": `y1lw0OKK6jshif7xDwNe`,
	"knowMoreSA": `hV2yiVTtMps8BddFfoVs`,
	"tooltipTextSA": `XB1SrOSHKwQjXJYMMaqA`,
	"fontAwesomeIconSA": `FFQlesnuN9BaUOKOPVvQ`
};
export default ___CSS_LOADER_EXPORT___;
