// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oS64upl4x2b8j9NvFb0P {
  margin: auto;
  width: 100%;
  margin-bottom: 50px;
}
@media only screen and (max-width: 1200px) {
  .oS64upl4x2b8j9NvFb0P {
    width: 1200px;
  }
}
.oS64upl4x2b8j9NvFb0P .CiJpZVP8bCnNIx9H1EiF {
  box-shadow: 0px 2px 12px 0px rgba(51, 51, 51, 0.08);
  border: none;
}

.HlgdOrZSgAkT7MRKRiC6 {
  margin: 0px 24px;
}
.HlgdOrZSgAkT7MRKRiC6 .wbW41RICwRrN_8hUMC1b {
  margin-top: 12px;
}
.HlgdOrZSgAkT7MRKRiC6 .wbW41RICwRrN_8hUMC1b thead {
  background-color: #f2f8ff;
}
.HlgdOrZSgAkT7MRKRiC6 .wbW41RICwRrN_8hUMC1b thead tr th {
  border: 0px;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 21px 10px 16px 10px;
}
.HlgdOrZSgAkT7MRKRiC6 .wbW41RICwRrN_8hUMC1b tbody {
  padding-left: 10px;
}
.HlgdOrZSgAkT7MRKRiC6 .wbW41RICwRrN_8hUMC1b tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.HlgdOrZSgAkT7MRKRiC6 .wbW41RICwRrN_8hUMC1b tbody tr {
  cursor: default;
}
.HlgdOrZSgAkT7MRKRiC6 .wbW41RICwRrN_8hUMC1b tbody tr td {
  border: 0px;
  vertical-align: middle !important;
  padding: 18px 10px 19px 10px;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  text-align: left;
}
.HlgdOrZSgAkT7MRKRiC6 .wbW41RICwRrN_8hUMC1b tbody tr:hover {
  background-color: #f5f5f5;
}

.mzvh7fbhnSbygenIjxqI {
  flex-wrap: unset;
  padding: 24px 24px 15px;
}
.mzvh7fbhnSbygenIjxqI .HyabM1UttYdxLGvq3xMf {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.mzvh7fbhnSbygenIjxqI .HyabM1UttYdxLGvq3xMf .HM5aenOSH3KFwFAPT6xH {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px !important;
}
.mzvh7fbhnSbygenIjxqI .HyabM1UttYdxLGvq3xMf .HM5aenOSH3KFwFAPT6xH:focus {
  outline-color: #2855ff !important;
}

.d1LXuv3scD1M6GKHngda {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.ocWPyLHjE7pr2CvpefEg {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 380px;
}
.ocWPyLHjE7pr2CvpefEg .SuPlnv8pYuaPtPOb68db {
  color: #333333 !important;
  width: 181px;
  margin-right: 17px;
  position: relative;
  z-index: 0;
}
.ocWPyLHjE7pr2CvpefEg .SuPlnv8pYuaPtPOb68db .d12pxkaR7xlUj06VZiqZ {
  z-index: 1;
  position: absolute;
  left: 8px;
  top: -14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  background: #ffffff;
  padding: 4px;
}

.Oz2GC2lZVwvJjqqk_uv0 {
  color: #000;
  font-size: 14px;
}

.A2np_y3Fd81zieYVDCLu {
  color: #333333 !important;
  font-size: 14px;
  height: 45px !important;
  border-color: #c2cfff;
}

.F5WU9dL5Vv6DqNPxKd9N {
  font-family: roboto;
}

.fvL9pcLmJTUhh6zyZqyQ {
  font-size: 14px;
  font-weight: 400;
  color: #333;
}

.a3oRT8KBg0NIZf_5OySX {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

.XIPB31mdOOcwLRnQtK16 {
  width: 200px;
  color: #333333;
}

.eRexKoGs4BwuyhLRV7_1 {
  color: #2755fe;
}
.h44BFt9rrO9cFwA7Mu3F {
  color: #1f44ca;
}

.TxuzE1jGr2mXbe3icok5 {
  display: flex;
  justify-content: center;
}

.CpfgK8ldRw4W7JeVv_jG {
  cursor: pointer;
}

.yKlvAWS2LG0Bi_K9vUJb {
  color: #999999;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alumniTabsContainer": `oS64upl4x2b8j9NvFb0P`,
	"cardContainer": `CiJpZVP8bCnNIx9H1EiF`,
	"recentTableContainer": `HlgdOrZSgAkT7MRKRiC6`,
	"recentTable": `wbW41RICwRrN_8hUMC1b`,
	"paginationWrapper": `mzvh7fbhnSbygenIjxqI`,
	"showPagination": `HyabM1UttYdxLGvq3xMf`,
	"selectPagination": `HM5aenOSH3KFwFAPT6xH`,
	"row": `d1LXuv3scD1M6GKHngda`,
	"dateAndDropdownFilters": `ocWPyLHjE7pr2CvpefEg`,
	"filterItem": `SuPlnv8pYuaPtPOb68db`,
	"entityLabel": `d12pxkaR7xlUj06VZiqZ`,
	"dropDownPlaceholder": `Oz2GC2lZVwvJjqqk_uv0`,
	"dropDownStyle": `A2np_y3Fd81zieYVDCLu`,
	"rupeeFont": `F5WU9dL5Vv6DqNPxKd9N`,
	"statusChips": `fvL9pcLmJTUhh6zyZqyQ`,
	"descContainer": `a3oRT8KBg0NIZf_5OySX`,
	"userRow": `XIPB31mdOOcwLRnQtK16`,
	"linkStyle": `eRexKoGs4BwuyhLRV7_1`,
	"linkStylehover": `h44BFt9rrO9cFwA7Mu3F`,
	"textCenter": `TxuzE1jGr2mXbe3icok5`,
	"deleteIcon": `CpfgK8ldRw4W7JeVv_jG`,
	"deletedUser": `yKlvAWS2LG0Bi_K9vUJb`
};
export default ___CSS_LOADER_EXPORT___;
