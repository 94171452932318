// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.njBCx5oH3yW7zClMVYxf {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: -190px auto 190px;
  font-family: Poppins, sans-serif;
  min-height: 375px;
  max-width: 504px;
}
.njBCx5oH3yW7zClMVYxf .lkmRTvGrrHL29tac_5A4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.njBCx5oH3yW7zClMVYxf .lkmRTvGrrHL29tac_5A4 ._YxG9LAFM7z36wdAwaJI {
  font-weight: 600;
  font-size: 24px;
  color: #2755fe;
  margin-top: 24px;
  line-height: 32px;
}
.njBCx5oH3yW7zClMVYxf .lkmRTvGrrHL29tac_5A4 .yagOPRJDbKRhqx24iu_w {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
  margin-top: 8px;
  line-height: 32px;
  font-family: roboto;
}
.njBCx5oH3yW7zClMVYxf .lkmRTvGrrHL29tac_5A4 .pLzSSBXfPOLNW4TDAwAq button {
  width: auto;
  min-width: 130px;
  font-size: 14px;
  margin-right: 8px;
  margin-top: 56px;
}
.njBCx5oH3yW7zClMVYxf .lkmRTvGrrHL29tac_5A4 .qyMY8FTrLcMJzptdGTs9 {
  margin-top: 50px;
}
.njBCx5oH3yW7zClMVYxf .lkmRTvGrrHL29tac_5A4 .qyMY8FTrLcMJzptdGTs9 a {
  color: #2755fe;
  font-weight: normal;
  padding: inherit;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
}

.uCCOT8EdWbbGiXp8afLG {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}
.uCCOT8EdWbbGiXp8afLG:hover {
  color: red;
  cursor: pointer;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `njBCx5oH3yW7zClMVYxf`,
	"body": `lkmRTvGrrHL29tac_5A4`,
	"payment_title": `_YxG9LAFM7z36wdAwaJI`,
	"status_description": `yagOPRJDbKRhqx24iu_w`,
	"btnContainer": `pLzSSBXfPOLNW4TDAwAq`,
	"help": `qyMY8FTrLcMJzptdGTs9`,
	"crossBtn": `uCCOT8EdWbbGiXp8afLG`
};
export default ___CSS_LOADER_EXPORT___;
