// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.js53n_erhcRjvo1S4e7Y {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px;
  align-items: center;
  justify-content: space-between;
}

.pY_RxtVpZYoY5caYyM7n {
  font-size: 18px;
  font-weight: 500;
}

.L3QJxck3sHrdJSLQXPaN {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 260px;
  display: flex;
  padding-left: 12px;
  padding-right: 15px;
  height: 44px;
}
.L3QJxck3sHrdJSLQXPaN .VpOyJfbUKrqrgKvwhK_b {
  border: none;
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 0 14px !important;
}
.L3QJxck3sHrdJSLQXPaN .VpOyJfbUKrqrgKvwhK_b:focus {
  box-shadow: unset;
}

.QL9zwiFxb74i4RYeYRp8 {
  margin-top: 12px;
}
.QL9zwiFxb74i4RYeYRp8 thead {
  background-color: #f2f8ff;
}
.QL9zwiFxb74i4RYeYRp8 thead tr th {
  border: 0px;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 21px 10px 16px 10px;
}
.QL9zwiFxb74i4RYeYRp8 tbody {
  padding-left: 10px;
}
.QL9zwiFxb74i4RYeYRp8 tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.QL9zwiFxb74i4RYeYRp8 tbody tr {
  cursor: default;
}
.QL9zwiFxb74i4RYeYRp8 tbody tr td {
  border: 0px;
  vertical-align: middle !important;
  padding: 18px 10px 19px 10px;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  text-align: left;
}
.QL9zwiFxb74i4RYeYRp8 tbody tr:hover {
  background-color: #f5f5f5;
}

.cPKBJxAxpkE1GOGk2ZVo {
  flex-wrap: unset;
  padding: 24px 24px 15px;
}
.cPKBJxAxpkE1GOGk2ZVo .EGEAMV3qAofJetTjDlds {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  font-weight: 400;
}
.cPKBJxAxpkE1GOGk2ZVo .EGEAMV3qAofJetTjDlds .iwU_EkcIzzvjVsJ5RPba {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #666666 !important;
  text-align: center !important;
  margin: 0 6px !important;
  font-weight: 400;
}
.cPKBJxAxpkE1GOGk2ZVo .EGEAMV3qAofJetTjDlds .iwU_EkcIzzvjVsJ5RPba:focus {
  outline-color: #2855ff !important;
}

.bjrKdVUQDhDzu6f1U4v_ {
  max-width: 20ch;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.OfcJAOTNKbso6Z02_zIe {
  max-width: 25ch;
  white-space: pre-wrap;
  word-wrap: break-word;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `js53n_erhcRjvo1S4e7Y`,
	"title": `pY_RxtVpZYoY5caYyM7n`,
	"searchInput": `L3QJxck3sHrdJSLQXPaN`,
	"inputText": `VpOyJfbUKrqrgKvwhK_b`,
	"referralTable": `QL9zwiFxb74i4RYeYRp8`,
	"paginationWrapper": `cPKBJxAxpkE1GOGk2ZVo`,
	"showPagination": `EGEAMV3qAofJetTjDlds`,
	"selectPagination": `iwU_EkcIzzvjVsJ5RPba`,
	"wrapCell": `bjrKdVUQDhDzu6f1U4v_`,
	"wrapCell25": `OfcJAOTNKbso6Z02_zIe`
};
export default ___CSS_LOADER_EXPORT___;
