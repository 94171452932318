// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MM8poDqWzjWj98OuM5BF {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1.5rem;
}
.MM8poDqWzjWj98OuM5BF .uTVzHHhCPF25VHXVHLqa {
  display: flex;
}
.MM8poDqWzjWj98OuM5BF .u8MycsGZTY__HjbnFHsR {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: Poppins, sans-serif;
  color: #333333;
  cursor: pointer;
  padding: 24px 16px 24px 16px;
}
.MM8poDqWzjWj98OuM5BF .u8MycsGZTY__HjbnFHsR:first-child {
  padding-left: 24px;
}
.MM8poDqWzjWj98OuM5BF .u8MycsGZTY__HjbnFHsR .aSIZwcT6HtHl82_cCaUs {
  color: #2755fe;
  border-bottom: 3px solid #2755fe;
  padding-bottom: 6px;
}

.VZ9bzotQ2U0RvcibemuQ {
  background: white;
  border: 1px solid #2755fe;
  color: #2755fe;
  margin-left: 10px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabComponentWrapper": `MM8poDqWzjWj98OuM5BF`,
	"tabContainer": `uTVzHHhCPF25VHXVHLqa`,
	"tabStyles": `u8MycsGZTY__HjbnFHsR`,
	"tabActive": `aSIZwcT6HtHl82_cCaUs`,
	"alumniBtn": `VZ9bzotQ2U0RvcibemuQ`
};
export default ___CSS_LOADER_EXPORT___;
