// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TMUpiTx0v8NPKAnyapFg {
  width: 100%;
  height: 100px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.uzeAVItVgoMMM5Le4Gxw {
  margin-top: 15px;
  font-size: 20px;
  color: #12a999;
  text-align: center;
}

.NO4qNT44TD1eZbXUvxKn {
  margin-top: 25px;
  font-size: 15px;
  color: black;
  text-align: center;
}

.OO4450WlWbgSDi9SyDW6 {
  width: 100%;
  margin-top: 35px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.Fc_UJOxco575Lthczxw7 {
  width: 120px;
}

.paUZQY2HpsyZrrAq0pqR {
  padding: 0px !important;
  margin: 0px !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkIcon": `TMUpiTx0v8NPKAnyapFg`,
	"thankYouText": `uzeAVItVgoMMM5Le4Gxw`,
	"reachOutText": `NO4qNT44TD1eZbXUvxKn`,
	"footer": `OO4450WlWbgSDi9SyDW6`,
	"cancelBtn": `Fc_UJOxco575Lthczxw7`,
	"modalBody": `paUZQY2HpsyZrrAq0pqR`
};
export default ___CSS_LOADER_EXPORT___;
