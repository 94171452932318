// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.fKr13UCPQcfY492RmCGr {
  color: #ffffff;
  text-align: left;
}
.fKr13UCPQcfY492RmCGr p:first-child {
  font-size: 16px;
}
.fKr13UCPQcfY492RmCGr p:last-child {
  font-weight: normal;
  font-size: 14px;
}

.uElDlTHvEKvjUIngnvJT {
  display: flex;
  justify-content: space-between;
  font-size: 16px !important;
  font-style: normal !important;
}
.uElDlTHvEKvjUIngnvJT .zja1SNDiDJ7PXnjsfrBg {
  color: #9eb3ff;
  font-weight: 600;
  border: none;
  background: transparent;
  padding: 0;
  letter-spacing: -1px;
}

.bUDmn6FwfklIPO3DfF4Q {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
}

.QMkF6N5J46LkSgc9W56p .ofNMelKhsHS_476UQNjB {
  color: #333333 !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgvCameraFooterTextContainer": `fKr13UCPQcfY492RmCGr`,
	"bgvCameraFooterButtonContainer": `uElDlTHvEKvjUIngnvJT`,
	"retakeBtn": `zja1SNDiDJ7PXnjsfrBg`,
	"confirmBtn": `bUDmn6FwfklIPO3DfF4Q`,
	"bgvCameraErrorBodyContainer": `QMkF6N5J46LkSgc9W56p`,
	"bgvCameraErrorTextContainer": `ofNMelKhsHS_476UQNjB`
};
export default ___CSS_LOADER_EXPORT___;
