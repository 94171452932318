// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kaQK3jNjzK3Wwkc6ysjw {
  width: 100%;
}

.em2z5OIzSQEFkZtPLDqT {
  width: 100%;
  display: flex;
  align-items: center;
}

.GLm703WvlYnEuZir8P9m {
  margin-left: 10px;
}
.GLm703WvlYnEuZir8P9m h5 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #212529;
}
.GLm703WvlYnEuZir8P9m p {
  color: rgba(51, 51, 51, 0.7);
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.k9Ol7SI5CrB_G0EPeUBE {
  width: 100%;
  padding-left: 42px !important;
  margin-top: 10px !important;
}

.XQq5aqHMm01RNkoxMnm5 {
  padding-left: 42px;
  padding-top: 24px;
}
.XQq5aqHMm01RNkoxMnm5 h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
}
.XQq5aqHMm01RNkoxMnm5 p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(102, 102, 102, 0.8);
}
.XQq5aqHMm01RNkoxMnm5 p a {
  color: #2755FE;
}

.Jc7M4GIDymwQMTuLeV3l span {
  color: #2755FE !important;
}

.sDKQYAaoQUy14BCq2ClR {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529 !important;
}

.pbD4UDSnBKEzir6WNgoC {
  background: rgba(9, 128, 120, 0.1);
  border-radius: 4px;
  color: #098078;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.vkwjz9bQVEPBlmhFWbMI {
  color: #646C9A;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 5px;
}
.vkwjz9bQVEPBlmhFWbMI span {
  color: #d3455b;
}

.aAFqNuCwRhTcAUPBIK5L {
  padding-left: 32px;
}

.xZTPgqj5dilUBSefImWj {
  width: 340px;
  display: flex;
  position: relative;
}

.V2jpev1hWdAymehU4IwZ {
  width: 42px;
  min-width: 42px;
  height: 38px;
  border-radius: 4px 0 0 4px;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 38px;
  font-family: Roboto;
  color: #212529;
  font-size: 18px;
}

.E4t0jaUY4TM4hJITVK4i {
  width: 298px;
  position: relative;
  margin-left: -2px;
  height: 38px;
}

.h0P0j7xWsMCXKHARc10C {
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
  margin-left: -2px;
  padding: 0 15px;
  position: relative;
  width: 340px;
}
.h0P0j7xWsMCXKHARc10C:focus, .h0P0j7xWsMCXKHARc10C:active, .h0P0j7xWsMCXKHARc10C:focus-visible {
  border-color: #2684FF;
  outline-color: #2684FF;
}
.h0P0j7xWsMCXKHARc10C::placeholder {
  font-weight: 400;
  color: #ccc;
}

.Hc3Y4EJV4aEUfGmmd1IO {
  position: absolute !important;
  right: 2px;
  width: 36px !important;
  padding: 0 !important;
  height: 24px;
  background: #fff;
  border: none;
  margin-top: 8px;
  border-left: 1px solid #ccc;
}
.Hc3Y4EJV4aEUfGmmd1IO:focus, .Hc3Y4EJV4aEUfGmmd1IO:active {
  outline: none !important;
}
.Hc3Y4EJV4aEUfGmmd1IO img {
  width: 20px;
}

.jQFyRIxnU8ZIBToBY0UG {
  margin-left: 20px;
  position: relative;
  padding-left: 30px;
  color: rgba(102, 102, 102, 0.8);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.Kyd4LbXo1BbMFe0zAiCo {
  position: absolute;
  left: 0;
  width: 25px;
}

.VxIG9UpH6lsVkbCoWUtI {
  position: absolute;
  top: calc(100% - 15px);
  color: #dc3545;
  padding: 5px;
  font-size: 14px;
  line-height: 14px;
}

.gXwoJGE_QmV28MArNAuz {
  margin-left: 5px;
}

.oBgmlkw5NuCMBlVreWk6 {
  font-size: 11px;
  line-height: 12px;
  padding: 2px;
  max-width: 200px;
}

.trkzfjcSSItkrbesqMcW {
  font-family: Roboto;
  font-weight: 700;
}

.Nvy6KyNxAW0rbsuLMcEX {
  border-color: #dc3545;
}
.Nvy6KyNxAW0rbsuLMcEX:focus {
  outline-color: #dc3545;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controllerContainer": `kaQK3jNjzK3Wwkc6ysjw`,
	"header": `em2z5OIzSQEFkZtPLDqT`,
	"headerContent": `GLm703WvlYnEuZir8P9m`,
	"actionsContainer": `k9Ol7SI5CrB_G0EPeUBE`,
	"customConfigContainer": `XQq5aqHMm01RNkoxMnm5`,
	"radioBtn": `Jc7M4GIDymwQMTuLeV3l`,
	"radioLabel": `sDKQYAaoQUy14BCq2ClR`,
	"recommendedBadge": `pbD4UDSnBKEzir6WNgoC`,
	"amountUptoLabel": `vkwjz9bQVEPBlmhFWbMI`,
	"customAmountContainer": `aAFqNuCwRhTcAUPBIK5L`,
	"dropdownContainer": `xZTPgqj5dilUBSefImWj`,
	"currencySymbol": `V2jpev1hWdAymehU4IwZ`,
	"amountDropdown": `E4t0jaUY4TM4hJITVK4i`,
	"amountInput": `h0P0j7xWsMCXKHARc10C`,
	"inputArrowBtn": `Hc3Y4EJV4aEUfGmmd1IO`,
	"information": `jQFyRIxnU8ZIBToBY0UG`,
	"infoImage": `Kyd4LbXo1BbMFe0zAiCo`,
	"errMeg": `VxIG9UpH6lsVkbCoWUtI`,
	"infoIcon": `gXwoJGE_QmV28MArNAuz`,
	"amountInfoTooltip": `oBgmlkw5NuCMBlVreWk6`,
	"robotoFont": `trkzfjcSSItkrbesqMcW`,
	"errInput": `Nvy6KyNxAW0rbsuLMcEX`
};
export default ___CSS_LOADER_EXPORT___;
