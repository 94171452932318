// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nqCi_pKQ4CmHkKhS2rnb {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;
}

.LgwM32YTLaIuYIyQQKOT {
  margin-top: 10px;
  cursor: pointer;
}

.lcJfhhZ6pVdRc1BHjcTA {
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerWrapper": `nqCi_pKQ4CmHkKhS2rnb`,
	"marginTop": `LgwM32YTLaIuYIyQQKOT`,
	"bannerImage": `lcJfhhZ6pVdRc1BHjcTA`
};
export default ___CSS_LOADER_EXPORT___;
