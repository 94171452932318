// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GAG3AAikCcOw0evB8kow {
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 36px !important;
  color: #333333;
}

.QgmYQRVmveHdnMl544gt {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #333333;
}

.awespfnHZrpn_5Ogwfoa::placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  opacity: 0.4 !important;
  color: #000000 !important;
}

.r9Yz6AIweYZMm_nM6lj8 {
  width: 240px;
  height: 48px;
  background-color: #2755fe;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  color: #ffffff;
}

.f8Quy6NRWqwKJplvquJq {
  padding-top: 10px !important;
}

.akvUAIgwrrfGK8hUf_j8 .modal-content {
  border-radius: 8px;
}
.akvUAIgwrrfGK8hUf_j8 hr {
  margin-bottom: 10px !important;
  margin-top: 0px !important;
}
.akvUAIgwrrfGK8hUf_j8 ._5AgIL1WkW4hyRwZwsvbA {
  width: 798px !important;
  position: relative;
  left: -30px;
}

.vfTpHV6xTJXtC3vAWsqh {
  width: 200px;
  height: 120px;
  background-color: #ecf2f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ysLf96L0xDMRpF9aag20 {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #333333 !important;
}

.Y_aBaA5bDloCHkGe0Z2A {
  display: flex;
  gap: 5px;
}

.stAxLgR4X0zipAYlhbTp {
  position: relative;
  top: -1px;
}

.jlKE76g4Lja4kwnL6XNV {
  margin-left: 25px;
  margin-top: 25px;
}
.jlKE76g4Lja4kwnL6XNV h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #333333;
}
.jlKE76g4Lja4kwnL6XNV td {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding-right: 30px !important;
  color: #333333;
}
.jlKE76g4Lja4kwnL6XNV .tkD4_CbYHyV2JGzLZJzQ {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.ByqX2fxIvK0HBlXi9z0Q {
  display: flex;
  margin-bottom: 20px;
}

.rR19q33e2VAMSpTMOnKF {
  width: 200px;
  margin-right: 20px;
}

.mF0jin7YDadeLGyR5TQi {
  font-size: 0.9em;
}
.mF0jin7YDadeLGyR5TQi td {
  padding: 2px 10px 2px 0;
}

.FCBHdFm3fI5m1iJOo_Gk {
  width: 120px;
  margin-right: 20px;
}
.FCBHdFm3fI5m1iJOo_Gk input {
  text-align: center;
}

.iHC5zm4mRCvA1sxHeLB5 {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9em;
}

.tNTMOAPRc2TGXURSMG0A {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.lG3MW7AiHcD4gqiySTHQ {
  border: 1px solid grey;
  border-radius: 5px;
}
.lG3MW7AiHcD4gqiySTHQ span {
  color: #2755fe !important;
}

.FXiTp_a6HilB6KfNqifJ {
  color: #28a745;
}

.bk7noZNAQDicbmwWLi7C {
  padding: 10px 30px;
  width: 164px;
  height: 48px;
  background-color: #2755fe;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.C7a34qtPNtC8nKnX16tL {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.eSJWGgTDOOHubWsD1ETD {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.jFVOblgqZKId2S0BFIaH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.GQr31fQYKHMNl8Etxp3w {
  display: flex;
  gap: 5px;
}

.am48E8AIs0bKa_Bffr_0 {
  font-weight: bold;
  font-size: 1.2em;
  color: #36aa53;
  margin-bottom: 10px;
}

.b45LpaDdqT0SAE9b9R0n {
  text-decoration: line-through;
  color: #333333;
  padding-top: 2px;
}

.FwDhSzwf2uEMWkWr5QdL {
  width: 612px !important;
}

.ljfCgV4ulT1hvhR2Xj4m {
  display: flex;
  justify-content: center;
}

.V_v1fFMIOMFEXM5_YMv9 {
  display: flex;
  justify-content: center;
}
.V_v1fFMIOMFEXM5_YMv9 hr {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  color: grey;
  border-style: dashed none none none;
  border-width: 3px;
  width: 80%;
}
.V_v1fFMIOMFEXM5_YMv9 .gC3kNnYB7uVjogdu8UDL {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}
.V_v1fFMIOMFEXM5_YMv9 .gC3kNnYB7uVjogdu8UDL .S84IikAPzVhmXtcnmP2G {
  width: 52px;
  height: 52px;
}
.V_v1fFMIOMFEXM5_YMv9 .gC3kNnYB7uVjogdu8UDL .t2WrmTXfGrMaU6piSwH2 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.V_v1fFMIOMFEXM5_YMv9 .gC3kNnYB7uVjogdu8UDL .OxgnKJgOcQS4BNeEIPxn {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.WY21FuYRUuzXFzz4YhRi {
  display: flex;
}
.WY21FuYRUuzXFzz4YhRi .VpVUN8UHEHsN8TfI5Rro {
  width: 164px;
  height: 48px;
  background-color: #2755fe;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitle": `GAG3AAikCcOw0evB8kow`,
	"inputLabel": `QgmYQRVmveHdnMl544gt`,
	"inputArea": `awespfnHZrpn_5Ogwfoa`,
	"requestButton": `r9Yz6AIweYZMm_nM6lj8`,
	"reqModalBody": `f8Quy6NRWqwKJplvquJq`,
	"rentModal": `akvUAIgwrrfGK8hUf_j8`,
	"fullWidthLine": `_5AgIL1WkW4hyRwZwsvbA`,
	"laptopIcon": `vfTpHV6xTJXtC3vAWsqh`,
	"label": `ysLf96L0xDMRpF9aag20`,
	"infoLabel": `Y_aBaA5bDloCHkGe0Z2A`,
	"infoIcon": `stAxLgR4X0zipAYlhbTp`,
	"laptopDetails": `jlKE76g4Lja4kwnL6XNV`,
	"specificationText": `tkD4_CbYHyV2JGzLZJzQ`,
	"laptopInfo": `ByqX2fxIvK0HBlXi9z0Q`,
	"laptopImage": `rR19q33e2VAMSpTMOnKF`,
	"specsTable": `mF0jin7YDadeLGyR5TQi`,
	"quantityInput": `FCBHdFm3fI5m1iJOo_Gk`,
	"configInfo": `iHC5zm4mRCvA1sxHeLB5`,
	"rentInfo": `tNTMOAPRc2TGXURSMG0A`,
	"counterBtns": `lG3MW7AiHcD4gqiySTHQ`,
	"rentAmount": `FXiTp_a6HilB6KfNqifJ`,
	"buyBtn": `bk7noZNAQDicbmwWLi7C`,
	"laptopSpecification": `C7a34qtPNtC8nKnX16tL`,
	"laptopSpecificationValue": `eSJWGgTDOOHubWsD1ETD`,
	"priceSection": `jFVOblgqZKId2S0BFIaH`,
	"priceContainer": `GQr31fQYKHMNl8Etxp3w`,
	"price": `am48E8AIs0bKa_Bffr_0`,
	"mrp": `b45LpaDdqT0SAE9b9R0n`,
	"successModal": `FwDhSzwf2uEMWkWr5QdL`,
	"body": `ljfCgV4ulT1hvhR2Xj4m`,
	"modalContainer": `V_v1fFMIOMFEXM5_YMv9`,
	"modalBody": `gC3kNnYB7uVjogdu8UDL`,
	"checkIcon": `S84IikAPzVhmXtcnmP2G`,
	"title": `t2WrmTXfGrMaU6piSwH2`,
	"infoText": `OxgnKJgOcQS4BNeEIPxn`,
	"footer": `WY21FuYRUuzXFzz4YhRi`,
	"closeBtn": `VpVUN8UHEHsN8TfI5Rro`
};
export default ___CSS_LOADER_EXPORT___;
