// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q7xtenpqKnMsRp7Pbrvf {
  margin-top: 48px;
  margin-bottom: 154px;
  min-height: 646px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.q7xtenpqKnMsRp7Pbrvf .TQo0KRHqzsqRNxux9lO0,
.q7xtenpqKnMsRp7Pbrvf .p32nnDijlHkXKV91Sb03 {
  padding-right: 0;
  padding-left: 0;
}
@media screen and (max-width: 601px) {
  .q7xtenpqKnMsRp7Pbrvf .TQo0KRHqzsqRNxux9lO0 {
    display: none;
  }
}

@media screen and (max-width: 601px) {
  .q7xtenpqKnMsRp7Pbrvf {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    min-height: 100%;
  }
}
.Wm90tSmlqFWz44bCFZUu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  flex-direction: column;
}

.wpvdkgyU6hRmtZ0hphnI {
  width: 100%;
}

.eu7ckypdi2g60wPF5uEw {
  flex: 1;
  width: 100%;
}

.GUAhzTOz8W3iaat3YLi8 {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.f_Su9FrTmBkQgbHHBfsz {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10001;
  background: rgba(0, 6, 28, 0.8);
  width: 100%;
  height: 100%;
}

.jTit21JjIhrhLzby4OFg {
  background: linear-gradient(90deg, #0844a6 0%, #086590 48.96%, #088077 100%);
  width: 195px;
  top: 0;
  color: white;
  position: fixed;
  right: -195px;
  height: 100%;
  transition: right 0.3s;
  z-index: 10002;
}

.jTit21JjIhrhLzby4OFg.TR0yUIT_oNU50udhO6Cs {
  right: 0;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgvHomeContainer": `q7xtenpqKnMsRp7Pbrvf`,
	"bgvHomeSidepanelContainer": `TQo0KRHqzsqRNxux9lO0`,
	"bgvHomeBodyContainer": `p32nnDijlHkXKV91Sb03`,
	"bgvPage": `Wm90tSmlqFWz44bCFZUu`,
	"bgvHeader": `wpvdkgyU6hRmtZ0hphnI`,
	"bgvBody": `eu7ckypdi2g60wPF5uEw`,
	"bgvFooter": `GUAhzTOz8W3iaat3YLi8`,
	"sidebarOverlay": `f_Su9FrTmBkQgbHHBfsz`,
	"sidebar": `jTit21JjIhrhLzby4OFg`,
	"open": `TR0yUIT_oNU50udhO6Cs`
};
export default ___CSS_LOADER_EXPORT___;
