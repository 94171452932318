// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HgZJ7Y_UzmzgvMuAFmw5 ._bMocdGRmvSoZp3vkagh {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}
.HgZJ7Y_UzmzgvMuAFmw5 .Mk7Dnyj38c9ohagVkplm {
  display: flex;
  font-weight: 400 !important;
  padding-bottom: 2.5rem;
}
.HgZJ7Y_UzmzgvMuAFmw5 .Mk7Dnyj38c9ohagVkplm input[type=radio] {
  width: 15px !important;
  height: 15px !important;
}
.HgZJ7Y_UzmzgvMuAFmw5 .SC4tJjKZcPB3p3535fbR {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}
.HgZJ7Y_UzmzgvMuAFmw5 .wcmQrqF5PuQiEVIP5NH8 {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.HgZJ7Y_UzmzgvMuAFmw5 .L7hNhi3mx68gkqtc8Vlo {
  position: absolute;
  bottom: -15px;
  right: 5px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #c5c5c5;
}
.HgZJ7Y_UzmzgvMuAFmw5 .smDSWOlF_NqT4qyiwwSI {
  display: flex;
  margin-bottom: 15px;
}
.HgZJ7Y_UzmzgvMuAFmw5 p {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  margin-bottom: 5px !important;
  color: #212529 !important;
}
.HgZJ7Y_UzmzgvMuAFmw5 .NcSAE0OGd1gqgXxnhcSV .aQO8PM2HPq8WN0svKQ82 {
  max-width: 180px;
  object-fit: cover;
  margin-bottom: 15px;
}
.HgZJ7Y_UzmzgvMuAFmw5 .NcSAE0OGd1gqgXxnhcSV .ItZgIwfo7qXaaHfCshKO {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  margin-bottom: 5px !important;
  color: #212529 !important;
}
.HgZJ7Y_UzmzgvMuAFmw5 .nj84gQuZOvvYJJX9jxov {
  font-size: 12px;
  color: #d3445b;
}
.HgZJ7Y_UzmzgvMuAFmw5 .L5LZDtv8CYXP2GNslDoK {
  margin-top: 10px;
}

.D6EPBMGXmGyLt8qRt_qM {
  border-color: red;
}

.f_IF7bDWj_O2RF6H9ez3 {
  margin-right: 24px;
}

.EMo7i_YoWOEC4cLAgPTR {
  font-weight: 500;
  font-size: 16px;
  color: #212529;
  margin-bottom: 5px;
}

.ROacCsrHJLrKHLaWnXED {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  margin-bottom: 5px !important;
  color: #212529 !important;
}

.YpdDxk2mXhYS_pbSGl7r {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.idER6yYny177ch1wLnAV {
  max-width: 540px;
  position: relative;
}

.KgrwyZzt3MeWSWf8wtkp {
  width: 150px;
  height: 120px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.KgrwyZzt3MeWSWf8wtkp:hover {
  cursor: pointer;
  border: 2px solid #2755fe;
}

.CQ8lXEESANBflfty1PCa {
  width: 80%;
  height: 80%;
}

.CQ8lXEESANBflfty1PCa img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.diRZI9NYO70AUB7gr7gf {
  border: 2px solid #000000;
}

.WRlpQibLC3HFx2YA5czD {
  color: #2755fe;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  transform: translateY(-50%) translateX(50%);
}

.qkJgnb_k_ga92NXIaSBM {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 12px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emailContentWrapper": `HgZJ7Y_UzmzgvMuAFmw5`,
	"heading": `_bMocdGRmvSoZp3vkagh`,
	"emailRadioStyles": `Mk7Dnyj38c9ohagVkplm`,
	"emailContentheading": `SC4tJjKZcPB3p3535fbR`,
	"infoCircleIcon": `wcmQrqF5PuQiEVIP5NH8`,
	"hrNoteCharacterLimit": `L7hNhi3mx68gkqtc8Vlo`,
	"customRadioAndCheckGroup": `smDSWOlF_NqT4qyiwwSI`,
	"companyLogo": `NcSAE0OGd1gqgXxnhcSV`,
	"logoUrl": `aQO8PM2HPq8WN0svKQ82`,
	"logoFileText": `ItZgIwfo7qXaaHfCshKO`,
	"imageSizeText": `nj84gQuZOvvYJJX9jxov`,
	"useLogoInInviteMail": `L5LZDtv8CYXP2GNslDoK`,
	"checkboxStyle": `D6EPBMGXmGyLt8qRt_qM`,
	"cBoxMargin": `f_IF7bDWj_O2RF6H9ez3`,
	"sectionHeader": `EMo7i_YoWOEC4cLAgPTR`,
	"brandNameLabel": `ROacCsrHJLrKHLaWnXED`,
	"sectionSubHeader": `YpdDxk2mXhYS_pbSGl7r`,
	"input": `idER6yYny177ch1wLnAV`,
	"logoContainer": `KgrwyZzt3MeWSWf8wtkp`,
	"logo": `CQ8lXEESANBflfty1PCa`,
	"active": `diRZI9NYO70AUB7gr7gf`,
	"select": `WRlpQibLC3HFx2YA5czD`,
	"companyLogos": `qkJgnb_k_ga92NXIaSBM`
};
export default ___CSS_LOADER_EXPORT___;
