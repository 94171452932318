// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ax8_NOtG2YqNi2Oz9WJx {
  border: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  font-family: Poppins, sans-serif;
}
.Ax8_NOtG2YqNi2Oz9WJx .v76iGlUeca3PeWmHEd2B {
  padding: 0;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL {
  font-size: 14px;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL li {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .R_Zu0iUlnwtHuqXqgpWB {
  background-color: #f5f7ff;
  color: #2755fe;
  border-left: 5px solid #2755fe;
  font-weight: 600;
  cursor: pointer;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .R_Zu0iUlnwtHuqXqgpWB div {
  display: flex;
  align-items: center;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .R_Zu0iUlnwtHuqXqgpWB div span {
  margin-left: 25px;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .pr0k7UB0PumJZtzMx3eZ {
  color: #999999;
  cursor: not-allowed;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .pr0k7UB0PumJZtzMx3eZ div {
  display: flex;
  align-items: center;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .pr0k7UB0PumJZtzMx3eZ div span {
  margin-left: 25px;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .JomXLkdi_G4bh0xzmbF9 {
  color: #333333;
  font-weight: 600;
  cursor: pointer;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .JomXLkdi_G4bh0xzmbF9 div {
  display: flex;
  align-items: center;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .JomXLkdi_G4bh0xzmbF9 div span {
  margin-left: 25px;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .JomXLkdi_G4bh0xzmbF9:hover {
  color: #2755fe;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .JomXLkdi_G4bh0xzmbF9:hover div svg path {
  fill: #2755fe !important;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .JmQ3VBMxjsee4ik7r_xC div {
  display: flex;
  align-items: center;
  color: #999999;
  font-size: 12px;
  text-transform: uppercase;
}
.Ax8_NOtG2YqNi2Oz9WJx .wesIIH5OjlF_CcNfgPNL .JmQ3VBMxjsee4ik7r_xC div span {
  margin-left: 29px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidePanelCard": `Ax8_NOtG2YqNi2Oz9WJx`,
	"sidePanelCardBody": `v76iGlUeca3PeWmHEd2B`,
	"listGroup": `wesIIH5OjlF_CcNfgPNL`,
	"activeList": `R_Zu0iUlnwtHuqXqgpWB`,
	"normalList": `pr0k7UB0PumJZtzMx3eZ`,
	"filledList": `JomXLkdi_G4bh0xzmbF9`,
	"secureList": `JmQ3VBMxjsee4ik7r_xC`
};
export default ___CSS_LOADER_EXPORT___;
