// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VyVaRVb23yvA20Imuv_n {
  opacity: 0.6;
  background-color: rgba(0, 0, 0, 0.5);
}

.A1Xo84B5crPEpeaJ5NYf {
  color: rgba(0, 0, 0, 0.5);
}

.BrKHOsBLKlIPBx71wMna {
  font-size: 16px;
}

.iz7tP7G0990zGyeFEJYm {
  border: solid 1px;
}

.Yzls3HmMRtOVoRB7Mg37 {
  display: flex;
  gap: 6px;
  margin-left: 460px;
}

.jDGvqNnW_5Jp8IucD3uQ {
  color: #fff;
  border-radius: 2px;
  min-width: 90px;
  background-color: #2755fe;
  border-color: #2755fe;
  cursor: pointer;
}
.jDGvqNnW_5Jp8IucD3uQ:hover {
  background-color: #1d41c5;
  border-color: #1d41c5;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `VyVaRVb23yvA20Imuv_n`,
	"note": `A1Xo84B5crPEpeaJ5NYf`,
	"createMannuallyconfirmText": `BrKHOsBLKlIPBx71wMna`,
	"btnOutline": `iz7tP7G0990zGyeFEJYm`,
	"btnContainer": `Yzls3HmMRtOVoRB7Mg37`,
	"onCreateAutomaticallyBtn": `jDGvqNnW_5Jp8IucD3uQ`
};
export default ___CSS_LOADER_EXPORT___;
