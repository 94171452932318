// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YmRNZlZ3QveKqKt83UTo {
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.INFZehrkgw426uO1Ik3N {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: #333333;
  margin: 12px 0 0px;
}

.ziktVvsWXcJopwAqSMqK {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #999999;
}

.VYosv1h223AcuGHyKNFg {
  color: #2755fe;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  margin-top: 20px;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.ZmOY9KyDxpSqjEDvO2De {
  display: flex;
  margin-top: 30px;
  justify-content: center !important;
}

.GiybFy1BirjonRO0JkYq {
  display: flex;
  justify-content: center;
  align-items: center;
}
.GiybFy1BirjonRO0JkYq .cPAwWqKsxGL30HHkzwyQ {
  height: 44px;
  width: 189px;
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 16px;
}
.GiybFy1BirjonRO0JkYq .TN_pKFKrOJ7C2no3Ra4h {
  height: 44px;
  width: 189px;
  background: #ffffff;
  border: 1px solid #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #2755fe;
}
.GiybFy1BirjonRO0JkYq .jCwEnZNeS96ZaKoxM4Dz {
  height: 44px;
  width: 189px;
  background: #ffffff;
  padding: 0px !important;
  border: 1px solid #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin-right: 20px;
  font-weight: 500;
  line-height: 20px;
  color: #2755fe;
}
.GiybFy1BirjonRO0JkYq .jCwEnZNeS96ZaKoxM4Dz:hover {
  background: #ffffff;
  color: #2755fe !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": `YmRNZlZ3QveKqKt83UTo`,
	"bgvLinkSentText": `INFZehrkgw426uO1Ik3N`,
	"subContent": `ziktVvsWXcJopwAqSMqK`,
	"whatsNextText": `VYosv1h223AcuGHyKNFg`,
	"footer": `ZmOY9KyDxpSqjEDvO2De`,
	"buttonFooter": `GiybFy1BirjonRO0JkYq`,
	"submitButton": `cPAwWqKsxGL30HHkzwyQ`,
	"cancelButton": `TN_pKFKrOJ7C2no3Ra4h`,
	"buyRentButton": `jCwEnZNeS96ZaKoxM4Dz`
};
export default ___CSS_LOADER_EXPORT___;
