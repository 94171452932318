// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yZf2nAw3n0H2BaWX4yv7 {
  max-width: 1200px;
  margin-bottom: 100px !important;
  margin: auto;
  height: auto;
}
@media only screen and (max-width: 1200px) {
  .yZf2nAw3n0H2BaWX4yv7 {
    width: 1200px;
  }
}
.yZf2nAw3n0H2BaWX4yv7 .pcHZZ_DxG5qhYS7Jq1hg {
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.yZf2nAw3n0H2BaWX4yv7 .pcHZZ_DxG5qhYS7Jq1hg .SKEgZ5P1DK4hc16hIypB {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.ONrYkFMp7bWoVUblzB6t {
  margin-top: 12rem;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alumniWrapperWrapper": `yZf2nAw3n0H2BaWX4yv7`,
	"subHeading": `pcHZZ_DxG5qhYS7Jq1hg`,
	"header": `SKEgZ5P1DK4hc16hIypB`,
	"saContainer": `ONrYkFMp7bWoVUblzB6t`
};
export default ___CSS_LOADER_EXPORT___;
