// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wWG0SQDYqmjPPNU4TTLO {
  margin: auto !important;
}

.JSSzFcSWk5iAyAQXYHMs {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: -190px auto 190px;
  font-family: Poppins, sans-serif;
  min-height: 375px;
  max-width: 504px;
}
.JSSzFcSWk5iAyAQXYHMs .sjYl33eFM69IbOkcAjOu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.JSSzFcSWk5iAyAQXYHMs .sjYl33eFM69IbOkcAjOu .dg18t3CLUAEImF6BqWT_ {
  font-weight: 600;
  font-size: 24px;
  color: #2755fe;
  margin-top: 24px;
  line-height: 32px;
}
.JSSzFcSWk5iAyAQXYHMs .sjYl33eFM69IbOkcAjOu .vvbUHbNSTKpv3kctCCeY {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
  margin-top: 8px;
  line-height: 32px;
  font-family: roboto;
}
.JSSzFcSWk5iAyAQXYHMs .sjYl33eFM69IbOkcAjOu .KDoAnCKVViq0V5lCkuhd button {
  width: auto;
  min-width: 130px;
  font-size: 14px;
  margin-right: 8px;
  margin-top: 56px;
}
.JSSzFcSWk5iAyAQXYHMs .sjYl33eFM69IbOkcAjOu .wrpv_LHUP5g_eVLCVpxQ {
  margin-top: 50px;
}
.JSSzFcSWk5iAyAQXYHMs .sjYl33eFM69IbOkcAjOu .wrpv_LHUP5g_eVLCVpxQ a {
  color: #2755fe;
  font-weight: normal;
  padding: inherit;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
}

.TW36VRIkZptU6lAOW0Ap {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}
.TW36VRIkZptU6lAOW0Ap:hover {
  color: red;
  cursor: pointer;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bulkUploadCard": `wWG0SQDYqmjPPNU4TTLO`,
	"card": `JSSzFcSWk5iAyAQXYHMs`,
	"body": `sjYl33eFM69IbOkcAjOu`,
	"payment_title": `dg18t3CLUAEImF6BqWT_`,
	"status_description": `vvbUHbNSTKpv3kctCCeY`,
	"btnContainer": `KDoAnCKVViq0V5lCkuhd`,
	"help": `wrpv_LHUP5g_eVLCVpxQ`,
	"crossBtn": `TW36VRIkZptU6lAOW0Ap`
};
export default ___CSS_LOADER_EXPORT___;
