// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wdhAq40erWyfmWDLnNtM {
  max-width: 1200px;
  font-family: Poppins, sans-serif;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .wdhAq40erWyfmWDLnNtM {
    width: 1200px;
  }
}
.wdhAq40erWyfmWDLnNtM .REP2VhR7du251LZwGOuh {
  margin-top: -190px;
  display: flex;
  justify-content: space-between;
  min-height: 600px;
  margin-bottom: 30px;
}
.wdhAq40erWyfmWDLnNtM .REP2VhR7du251LZwGOuh .u3JCmUrm9iLMQwnobEtl {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.wdhAq40erWyfmWDLnNtM .DwvUK_cmHhJbhXCiG2ug {
  margin-top: unset;
  border: unset;
}

.xjF2x3LljbGsZVcDVDcV {
  font-family: Poppins;
  padding: 20px;
}
.xjF2x3LljbGsZVcDVDcV .u3JCmUrm9iLMQwnobEtl {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.xjF2x3LljbGsZVcDVDcV .N5VvZT6Bsfi2DjuD9ZAp label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #646c9a !important;
  margin-bottom: 6px;
}
.xjF2x3LljbGsZVcDVDcV .N5VvZT6Bsfi2DjuD9ZAp label span {
  color: red;
}
.xjF2x3LljbGsZVcDVDcV .N5VvZT6Bsfi2DjuD9ZAp .S55WxVZ8N9onE48z4lwk {
  justify-content: space-between !important;
}
.xjF2x3LljbGsZVcDVDcV .N5VvZT6Bsfi2DjuD9ZAp .nFe3d9N8c2qXDwIvh13A {
  margin-top: 20px;
}
.xjF2x3LljbGsZVcDVDcV .N5VvZT6Bsfi2DjuD9ZAp .h8aHqoJOIbcNektlU_6r {
  width: 120px;
  margin-top: 20px;
}
.xjF2x3LljbGsZVcDVDcV .N5VvZT6Bsfi2DjuD9ZAp .nsCUUVELrTU1lAgjmnw9 {
  color: #595959;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}
.xjF2x3LljbGsZVcDVDcV .N5VvZT6Bsfi2DjuD9ZAp .hT_zCVfiEMuEORYCIlSw {
  color: #595959;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}
.xjF2x3LljbGsZVcDVDcV .N5VvZT6Bsfi2DjuD9ZAp ._iFYVamzECxIBdKZ6cgi {
  color: #788896;
  font-weight: 500;
  font-size: 14px;
  margin: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.xjF2x3LljbGsZVcDVDcV .N5VvZT6Bsfi2DjuD9ZAp .PSnU_HCgmnICoVa2Iwy_ {
  margin-right: 8px;
  height: 13px;
  width: 13px;
  color: #9ed689;
}

.BQdqXui2HxDP9TnKlwng {
  border: 1px solid #4b5c6b;
  border-radius: 4px;
  width: 100%;
  padding-right: 0px !important;
  display: inline-block;
  margin-bottom: 32px;
}
.BQdqXui2HxDP9TnKlwng .RiNBIY3GyiPZjhbwJ15V {
  float: right;
  line-height: 38px !important;
  margin-bottom: 0 !important;
  background: #ebedf2;
  border: 1px solid #e7e9ef;
  border-radius: 0px 4px 4px 0px;
  width: 80px;
  text-align: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.BQdqXui2HxDP9TnKlwng .RiNBIY3GyiPZjhbwJ15V .YKkPepYJeWvSy8wNGcCP {
  display: none;
}
.BQdqXui2HxDP9TnKlwng .VxcMGuXJQwfDnfnxF8Kp {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  font-family: Poppins, sans-serif;
  padding: 12px;
}
.BQdqXui2HxDP9TnKlwng .dex5o6x2gdG2Rm3cw1HY {
  border: none;
}

.XP1fRxqmCfw7jvluPF2M {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.ryeR3EUjeV0D82RsWEhQ {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #1aae9f;
}

.vzuW1p54_sl11m1NiopL {
  background-color: #2755fe;
}

.vzuW1p54_sl11m1NiopL:hover {
  background-color: #1f44ca;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardSection": `wdhAq40erWyfmWDLnNtM`,
	"addCandidateCard": `REP2VhR7du251LZwGOuh`,
	"heading": `u3JCmUrm9iLMQwnobEtl`,
	"failedCard": `DwvUK_cmHhJbhXCiG2ug`,
	"addCandidate": `xjF2x3LljbGsZVcDVDcV`,
	"form": `N5VvZT6Bsfi2DjuD9ZAp`,
	"form-row": `S55WxVZ8N9onE48z4lwk`,
	"uploadFile": `nFe3d9N8c2qXDwIvh13A`,
	"buttn": `h8aHqoJOIbcNektlU_6r`,
	"instructionLabel": `nsCUUVELrTU1lAgjmnw9`,
	"instructionHeading": `hT_zCVfiEMuEORYCIlSw`,
	"instructionItem": `_iFYVamzECxIBdKZ6cgi`,
	"checkItemSvg": `PSnU_HCgmnICoVa2Iwy_`,
	"fileInput": `BQdqXui2HxDP9TnKlwng`,
	"customFileUpload": `RiNBIY3GyiPZjhbwJ15V`,
	"stepOneFile": `YKkPepYJeWvSy8wNGcCP`,
	"fileName": `VxcMGuXJQwfDnfnxF8Kp`,
	"consentFile": `dex5o6x2gdG2Rm3cw1HY`,
	"errors": `XP1fRxqmCfw7jvluPF2M`,
	"successMessage": `ryeR3EUjeV0D82RsWEhQ`,
	"accentColor": `vzuW1p54_sl11m1NiopL`
};
export default ___CSS_LOADER_EXPORT___;
