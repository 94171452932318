// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vU43vzSiGrPGCFfecDKE {
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .vU43vzSiGrPGCFfecDKE {
    width: 1200px;
  }
}
.vU43vzSiGrPGCFfecDKE .i5D_ShZo0RmW6Qb82Phs {
  margin-top: -190px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd {
  background: #ffffff;
  min-height: 700px;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
  padding: 16px 28px 0 24px;
  font-family: Poppins;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .Dd18BQm0Eg4YLuEpTzMY {
  padding: 0.5rem 0;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .Dd18BQm0Eg4YLuEpTzMY .znRGrKSdTgGTnU7UCeTA {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 260px;
  display: flex;
  padding-left: 12px;
  padding-right: 15px;
  height: 44px;
  margin-left: 16px;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .Dd18BQm0Eg4YLuEpTzMY .znRGrKSdTgGTnU7UCeTA .gjLiZD_almynXozcpvro {
  border: none;
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 0 14px !important;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .Dd18BQm0Eg4YLuEpTzMY .znRGrKSdTgGTnU7UCeTA .gjLiZD_almynXozcpvro:focus {
  box-shadow: unset;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK {
  margin-top: 16px;
  border: 0px;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh {
  margin: auto;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh thead {
  background-color: #f2f8ff;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh thead tr th {
  border: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh thead tr th .RaWblFOezoYiFUMVHm1e {
  display: flex;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh thead tr th .RaWblFOezoYiFUMVHm1e .gZRFyTMCB200OYk4iltY {
  margin-left: 10px;
  display: inline-grid;
  cursor: pointer;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody tr {
  cursor: default;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody tr td {
  border: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody tr td .zGU3X4Vj2Ls2fantt9Yd {
  display: flex;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody tr td .zGU3X4Vj2Ls2fantt9Yd ._kC2NBgGNh7HpPD_CLuH {
  width: 32px;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody tr td .zGU3X4Vj2Ls2fantt9Yd .rjH4AgfrNEXbburj8QcW {
  font-size: 14px;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody tr td .zGU3X4Vj2Ls2fantt9Yd .rjH4AgfrNEXbburj8QcW .OQaUkPms8o6eoL1cDh6o {
  color: #333333;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody tr td .zGU3X4Vj2Ls2fantt9Yd .rjH4AgfrNEXbburj8QcW .OQaUkPms8o6eoL1cDh6o:hover {
  cursor: pointer;
  color: #366cf3 !important;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody tr td .zGU3X4Vj2Ls2fantt9Yd .rjH4AgfrNEXbburj8QcW .Bwxlts8nV0B9X8fTUnRE {
  display: flex;
  color: #666666;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody tr:hover {
  background-color: #f5f5f5;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody .cgHpUWb0ciabjh3bCpLx:hover {
  color: #366cf3;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh tbody .cgHpUWb0ciabjh3bCpLx:hover p {
  cursor: pointer;
  width: fit-content;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh .UmwWj6Pk5_x5YaJmKzkh {
  text-align: center;
  padding-top: 64px;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .oXeXe4pCVi3jZaG6mbkh .UmwWj6Pk5_x5YaJmKzkh .gd0k9mMkZNa0caINULL2 {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .RnhmxSjM9OsCvCfvGHrX {
  flex-wrap: unset;
  padding: 24px 24px 15px;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .RnhmxSjM9OsCvCfvGHrX .FPxUwgplkp4RjJBDHg0W {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .hxPes2a8hz7BylByv0WK .RnhmxSjM9OsCvCfvGHrX .FPxUwgplkp4RjJBDHg0W .HBr7oQLqh_sqLK1zK6of {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px;
  -webkit-appearance: menulist;
}
.vU43vzSiGrPGCFfecDKE .rHWTzwhRVsoEjsoxgiWd .qbrijAfRPllGEZQI30ZO {
  text-align: center;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"insufficienciesWrapper": `vU43vzSiGrPGCFfecDKE`,
	"heading": `i5D_ShZo0RmW6Qb82Phs`,
	"insufficienciesCard": `rHWTzwhRVsoEjsoxgiWd`,
	"navHeader": `Dd18BQm0Eg4YLuEpTzMY`,
	"searchInput": `znRGrKSdTgGTnU7UCeTA`,
	"inputText": `gjLiZD_almynXozcpvro`,
	"tableCard": `hxPes2a8hz7BylByv0WK`,
	"insufficienciesTable": `oXeXe4pCVi3jZaG6mbkh`,
	"tableHeaderSort": `RaWblFOezoYiFUMVHm1e`,
	"tableHeaderSortImg": `gZRFyTMCB200OYk4iltY`,
	"tableTitleBox": `zGU3X4Vj2Ls2fantt9Yd`,
	"tableTitleLogo": `_kC2NBgGNh7HpPD_CLuH`,
	"tableTitleContent": `rjH4AgfrNEXbburj8QcW`,
	"tableTitleContentInfo": `OQaUkPms8o6eoL1cDh6o`,
	"tableTitleContentCount": `Bwxlts8nV0B9X8fTUnRE`,
	"mainField": `cgHpUWb0ciabjh3bCpLx`,
	"noDataWrapper": `UmwWj6Pk5_x5YaJmKzkh`,
	"noDataContent": `gd0k9mMkZNa0caINULL2`,
	"paginationWrapper": `RnhmxSjM9OsCvCfvGHrX`,
	"showPagination": `FPxUwgplkp4RjJBDHg0W`,
	"selectPagination": `HBr7oQLqh_sqLK1zK6of`,
	"textCenter": `qbrijAfRPllGEZQI30ZO`
};
export default ___CSS_LOADER_EXPORT___;
