// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vXN1LsOMFMBfwcKLT0FF {
  padding: 6px 0 0 24px !important;
}

.bUYvWXEmklfz5ubHZkcY {
  padding: 24px 24px 0 24px !important;
}

.v4_yWskWwXp_lMNIsLQP {
  position: relative;
  max-width: 720px !important;
  justify-content: center;
}

.G34hN4LCeGk64hEI9Knp {
  box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.1) !important;
  height: 88px;
  padding: 20px 24px 20px 0 !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.eto4AkBRTf2zaWF0ozSh {
  font-weight: 500;
  font-family: Poppins;
  font-size: 20px !important;
  color: #333333;
}

.rVgh4GtoUOCB71jfeDpY {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.Es49CQwdRxih5yXcN4GK {
  background-color: rgba(39, 85, 254, 0.1);
  padding: 12px 4px;
  border-radius: 8px;
  margin: 0 24px 0 0;
}
.Es49CQwdRxih5yXcN4GK ul {
  margin-bottom: 0px !important;
  font-weight: 400;
  color: #666666;
  font-size: 12px;
  margin: 0 0 0 -20px;
}
.Es49CQwdRxih5yXcN4GK ul li {
  font-size: 12px;
  font-weight: 400;
}

.gzgrzAb9y68sr0ogzcjy {
  font-weight: 500;
  color: #333333;
  font-size: 14px;
}
.gzgrzAb9y68sr0ogzcjy .SEoQcJCgEzhwEe3f4m8A {
  color: red;
}

.ztG5AJHfHshzZTN4A28R {
  margin-bottom: 0.5rem;
}

.LiAYF3xA1xbPdlyPEQSH {
  display: flex;
  width: 25%;
  align-items: center;
  margin-top: 6px;
}

.v3yAmUDcBCCIUT7mqvSw {
  height: 38px;
  width: 150px;
  border-color: #dfe1e5;
  padding-left: 10px;
  border-style: solid;
  border-width: 1px;
  font-weight: 400;
  color: #333;
  font-size: 14px;
}

.v3yAmUDcBCCIUT7mqvSw:focus {
  outline: none !important;
  border-color: #2684ff !important; /* set your desired focus color here */
  box-shadow: 0 0 0 1px #2684ff !important; /* optional: add a box-shadow */
}

.lk1mPL1n3LUB296rDe3V {
  display: flex;
  flex-direction: column;
}

.IDu8P7r119iRKNViblaE {
  margin-left: 20px;
  margin-bottom: 6px;
  width: 550px;
}

.dhNCxAin1vqIQjGGI80j {
  border: 1px solid red !important;
}

.QFVzEOiD_SuakuCMl_1c {
  display: flex;
  align-items: flex-start;
}
.QFVzEOiD_SuakuCMl_1c input {
  margin: 0.25rem 0.5rem 0 0;
}

.H5InIaZ2UPthTzCDJUQO {
  display: flex;
}
.H5InIaZ2UPthTzCDJUQO .tjji1sclEPwDTFTXR3PF {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.H5InIaZ2UPthTzCDJUQO .sUyhmQrUr70lJvWAIMr8 {
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
  margin: 0 0 0 0.5rem;
}
.H5InIaZ2UPthTzCDJUQO .sUyhmQrUr70lJvWAIMr8.g3EqsNzyzlm6a7sCODmQ {
  background: rgba(250, 173, 20, 0.1254901961);
  color: #f9a500;
}
.H5InIaZ2UPthTzCDJUQO .sUyhmQrUr70lJvWAIMr8.jndg2ruNL2MGAgEBozKZ {
  background: rgba(39, 85, 254, 0.2);
  color: #2755fe;
}
.H5InIaZ2UPthTzCDJUQO .sUyhmQrUr70lJvWAIMr8.AfP6jN_vjvsS9wNbpGya {
  background: #d1efec;
  color: #207868;
}

.LTldrNSRL2UYtGl3jXql {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
}

.bPQhSGBJox0VCvdDhZcN {
  display: flex;
  flex-direction: row;
}

.l_pz5TfH5ApscnBR0_nw {
  display: flex;
  align-items: center;
  margin-left: 0.4rem;
}

.p1GZYEcoYFixZ2uSlxi5 {
  background: #2755fe;
  border-radius: 5px;
  width: 136px;
  height: 48px;
  font-family: Poppins, sans-serif;
  line-height: 20px;
  color: #ffffff;
  font-weight: 400;
}
.p1GZYEcoYFixZ2uSlxi5:hover {
  background: #2148d7;
}

.oqsJ6aC8Ilvp7xz6TzXC {
  border: 1px rgba(39, 85, 254, 0.5019607843) solid !important;
  border-radius: 5px !important;
  font-family: Poppins, sans-serif;
  line-height: 20px;
  margin-right: 14px;
  width: 136px;
  height: 48px;
  color: #2755fe !important;
  font-weight: 400 !important;
  background-color: white;
}
.oqsJ6aC8Ilvp7xz6TzXC:hover {
  background: white;
}
.oqsJ6aC8Ilvp7xz6TzXC:disabled {
  opacity: 0.5;
  background: white;
  cursor: not-allowed;
}

.BjhdVvOxYYmEMN8XjnXr {
  pointer-events: none;
  cursor: not-allowed;
  margin-bottom: 2px !important;
}

.Dk1nbJNC2dfIxxEQH9aH {
  width: 100%;
  padding: 0.2rem 0.4rem;
  margin-bottom: 2px !important;
  font-size: 14px;
  font-weight: 400;
}

.glVwU0EdCqQoqH4KzBlT {
  border: red 1px solid;
}
.glVwU0EdCqQoqH4KzBlT:focus {
  outline: none;
}

.XbX5bb2TWAe_InKAKint {
  display: flex;
}

.JYu5PnYb0Dub_lLgq9kA {
  display: flex;
  flex-direction: column;
  min-width: 35%;
  margin: 10px 0px;
}

.gxgsEpyTngbs_YMiEw3X {
  margin-right: 27px;
  width: 200px;
}

.ZCZo3XRdawlOA8T4zwKQ {
  margin-top: 20px;
  max-height: 389px;
  overflow: auto;
  padding-left: 6px;
}

.m_fGLQtdckooaimZ9bqd {
  margin-top: 20px;
  max-height: 355px;
  overflow: auto;
  padding-left: 6px;
}

.BoP5GbQkwm0cMo8MYSAH {
  margin-top: 20px;
  max-height: 317px;
  overflow: auto;
  padding-left: 6px;
}

.mIIdaU8eYWCtqHVX3ro_ {
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  margin: 0.5rem 0;
}

.bja0JcaJWDyMkFscgvgQ {
  padding-top: 10px;
}
.bja0JcaJWDyMkFscgvgQ .Ggw37FLh7QBIe_sm3CQF {
  margin-right: 5px;
  margin-top: -1px;
}
.bja0JcaJWDyMkFscgvgQ .VsCxFbdbXbs1goEoeUUk {
  color: #d3455b;
  font-size: 14px;
  font-weight: 500;
}
.bja0JcaJWDyMkFscgvgQ .VsCxFbdbXbs1goEoeUUk .ehMgkEH7pAWn1mDh30Tx {
  text-decoration: underline;
  font-weight: 600;
}
.bja0JcaJWDyMkFscgvgQ .VsCxFbdbXbs1goEoeUUk .ehMgkEH7pAWn1mDh30Tx:hover {
  cursor: pointer;
}

.hCGIsEYE8DrspMG7091c {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.By3HwvG6dGo9jSPOuQg5 {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: -190px auto 190px;
  font-family: Poppins, sans-serif;
  min-height: 375px;
  max-width: 570px;
}
.By3HwvG6dGo9jSPOuQg5 .YlgbeZ5sgbneGseGLtMQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.By3HwvG6dGo9jSPOuQg5 .YlgbeZ5sgbneGseGLtMQ .Qh0YdyCT8MPHc52vpqnP {
  height: 50px;
  width: 50px;
}
.By3HwvG6dGo9jSPOuQg5 .YlgbeZ5sgbneGseGLtMQ .eto4AkBRTf2zaWF0ozSh {
  color: #2755fe;
  font-weight: 600;
  font-size: 24px;
  margin: 25px 0px;
  line-height: 32px;
}
.By3HwvG6dGo9jSPOuQg5 .YlgbeZ5sgbneGseGLtMQ .OuBYTqds77gJ0kWiWr9J {
  color: #898989;
  margin-bottom: 20px;
}
.By3HwvG6dGo9jSPOuQg5 .YlgbeZ5sgbneGseGLtMQ .iEN9vWsNcWriDIUW23bY {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 25px;
}
.By3HwvG6dGo9jSPOuQg5 .YlgbeZ5sgbneGseGLtMQ .iEN9vWsNcWriDIUW23bY .r_WL999YVaHX3_GE4F3w {
  font-weight: 700;
}
.By3HwvG6dGo9jSPOuQg5 .YlgbeZ5sgbneGseGLtMQ .gb6UTRsRQlmcnPN3Ahfw button {
  width: auto;
  min-width: 130px;
  font-size: 14px;
  margin-right: 8px;
  margin-top: 25px;
  color: white;
  background-color: #2755fe;
}
.By3HwvG6dGo9jSPOuQg5 .YlgbeZ5sgbneGseGLtMQ .Lyl5s7VPofqtCPgwfe_N {
  margin-top: 25px;
}
.By3HwvG6dGo9jSPOuQg5 .YlgbeZ5sgbneGseGLtMQ .Lyl5s7VPofqtCPgwfe_N .vNtsWFPJ_YjoUBknDX1E {
  color: #2755fe;
  font-weight: normal;
  padding: inherit;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
}

.N4Kd7jrMUjZGGE2UDlFr {
  pointer-events: none;
  opacity: 0.5;
}

.rhA2GruVSh9oQ0peOrVZ {
  color: "#808080";
  margin-left: 1rem;
}

._E7viA4Tx8mvsCnzVCUn {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #f5222d;
  margin: 0;
}

.Ybn6HoC_W70hYqwIkbOA {
  margin-left: 20px;
  margin-bottom: 6px;
}

.bVoHc7K9SO98lIBZChQi {
  margin: 0 1rem 1rem 0;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": `vXN1LsOMFMBfwcKLT0FF`,
	"modalHeader": `bUYvWXEmklfz5ubHZkcY`,
	"paymentRequestModal": `v4_yWskWwXp_lMNIsLQP`,
	"paymentRequestModalFooter": `G34hN4LCeGk64hEI9Knp`,
	"title": `eto4AkBRTf2zaWF0ozSh`,
	"content": `rVgh4GtoUOCB71jfeDpY`,
	"contentList": `Es49CQwdRxih5yXcN4GK`,
	"dateTitle": `gzgrzAb9y68sr0ogzcjy`,
	"required": `SEoQcJCgEzhwEe3f4m8A`,
	"topTitle": `ztG5AJHfHshzZTN4A28R`,
	"dateField": `LiAYF3xA1xbPdlyPEQSH`,
	"datePicker": `v3yAmUDcBCCIUT7mqvSw`,
	"checkboxContainer": `lk1mPL1n3LUB296rDe3V`,
	"emailsContainer": `IDu8P7r119iRKNViblaE`,
	"errorBoundary": `dhNCxAin1vqIQjGGI80j`,
	"contactCheckbox": `QFVzEOiD_SuakuCMl_1c`,
	"checkboxTitle": `H5InIaZ2UPthTzCDJUQO`,
	"name": `tjji1sclEPwDTFTXR3PF`,
	"tags": `sUyhmQrUr70lJvWAIMr8`,
	"AdminTags": `g3EqsNzyzlm6a7sCODmQ`,
	"HrOPsTags": `jndg2ruNL2MGAgEBozKZ`,
	"FinanceTags": `AfP6jN_vjvsS9wNbpGya`,
	"checkboxSubtitle": `LTldrNSRL2UYtGl3jXql`,
	"checkboxLabel": `bPQhSGBJox0VCvdDhZcN`,
	"bouncedEmailDiv": `l_pz5TfH5ApscnBR0_nw`,
	"sendButton": `p1GZYEcoYFixZ2uSlxi5`,
	"cancelButton": `oqsJ6aC8Ilvp7xz6TzXC`,
	"disabled": `BjhdVvOxYYmEMN8XjnXr`,
	"multiEmail": `Dk1nbJNC2dfIxxEQH9aH`,
	"multiEmailError": `glVwU0EdCqQoqH4KzBlT`,
	"row": `XbX5bb2TWAe_InKAKint`,
	"requestorContainer": `JYu5PnYb0Dub_lLgq9kA`,
	"requestSelect": `gxgsEpyTngbs_YMiEw3X`,
	"contactPaymentContainer": `ZCZo3XRdawlOA8T4zwKQ`,
	"contactPaymentContainerError": `m_fGLQtdckooaimZ9bqd`,
	"contactPaymentContainerSA": `BoP5GbQkwm0cMo8MYSAH`,
	"subLabel": `mIIdaU8eYWCtqHVX3ro_`,
	"zohoWarningContainer": `bja0JcaJWDyMkFscgvgQ`,
	"warningRedIcon": `Ggw37FLh7QBIe_sm3CQF`,
	"zohoWarning": `VsCxFbdbXbs1goEoeUUk`,
	"cta": `ehMgkEH7pAWn1mDh30Tx`,
	"footerContainer": `hCGIsEYE8DrspMG7091c`,
	"card": `By3HwvG6dGo9jSPOuQg5`,
	"body": `YlgbeZ5sgbneGseGLtMQ`,
	"image": `Qh0YdyCT8MPHc52vpqnP`,
	"referenceText": `OuBYTqds77gJ0kWiWr9J`,
	"warningText": `iEN9vWsNcWriDIUW23bY`,
	"noteText": `r_WL999YVaHX3_GE4F3w`,
	"btnContainer": `gb6UTRsRQlmcnPN3Ahfw`,
	"help": `Lyl5s7VPofqtCPgwfe_N`,
	"contactUs": `vNtsWFPJ_YjoUBknDX1E`,
	"maskDiv": `N4Kd7jrMUjZGGE2UDlFr`,
	"dateInfoIcon": `rhA2GruVSh9oQ0peOrVZ`,
	"error": `_E7viA4Tx8mvsCnzVCUn`,
	"emailChipContainer": `Ybn6HoC_W70hYqwIkbOA`,
	"chips": `bVoHc7K9SO98lIBZChQi`
};
export default ___CSS_LOADER_EXPORT___;
