// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y3QtTOOaBNr56YzyELkz {
  display: grid;
  width: 165px;
  margin: auto;
  margin-top: 258px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.y3QtTOOaBNr56YzyELkz img {
  margin: auto;
  margin-bottom: 24px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noRecordContainer": `y3QtTOOaBNr56YzyELkz`
};
export default ___CSS_LOADER_EXPORT___;
