// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GgqXMyYNC2HQImBOrz9h {
  padding: 5px;
}

.zU8HbFldL1A3EbuP2UeH {
  padding: 5px;
  left: 20px;
  top: 12px;
  position: absolute;
}

.fh2QcfBWAP6H3OjILaOH {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #595959;
  padding-left: 16px;
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.jYEbAsTsS64dVPIrWi5D {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #2755fe;
  padding-left: 16px;
  height: 48px;
  background: #f5f7ff;
  border-left: 6px solid #2755fe;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.gLDbHImZn7EacU7M357J {
  margin-bottom: 0px !important;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 24px;
}

.gLDbHImZn7EacU7M357J:focus {
  margin-bottom: 0px !important;
  border: none;
  box-shadow: none;
}

.ZMHp6UfWxN3Lm9h4hNPM {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #999999;
  padding-left: 16px;
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.qK0wcyuOktxCQZqmWAsa {
  padding: 10px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": `GgqXMyYNC2HQImBOrz9h`,
	"searchIcon": `zU8HbFldL1A3EbuP2UeH`,
	"countryName": `fh2QcfBWAP6H3OjILaOH`,
	"selectedCountryName": `jYEbAsTsS64dVPIrWi5D`,
	"countryCodeSearch": `gLDbHImZn7EacU7M357J`,
	"noResults": `ZMHp6UfWxN3Lm9h4hNPM`,
	"noResultsImg": `qK0wcyuOktxCQZqmWAsa`
};
export default ___CSS_LOADER_EXPORT___;
