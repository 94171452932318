// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._hE6przF37q3fjyGcXMX {
  padding-top: 0.5rem;
}

.BZoR1KZOJFyYJw_bz5mg {
  margin: 40px 0px;
}

.AqkwhSjXvI9t6SCzXkDt {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.eHhGlcqvZL08wzsYXRgo {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.GRZkdYYmLSu94sI3W_WO {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  color: #2755fe;
  border: 1px solid #2755fe;
}

.gfR0imZniVczn9GzQdSx {
  background-color: #037bff;
  color: white;
  padding: 0.5rem;
  border: none;
}

.Cn2y03NIfetGIdKE64T0 {
  background-color: #1bae9f;
  color: white;
  padding: 0.5rem;
  border: none;
}

.gfR0imZniVczn9GzQdSx:hover {
  background-color: #076dda;
}

.Cn2y03NIfetGIdKE64T0:hover {
  background-color: #11a294;
}

.HRY3joe05d1eFYyDYdeG {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.RhaTgE4nBcL3rXst_zke {
  border: 1px solid red;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": `_hE6przF37q3fjyGcXMX`,
	"generateTokenButton": `BZoR1KZOJFyYJw_bz5mg`,
	"generateTokenButton__heading": `AqkwhSjXvI9t6SCzXkDt`,
	"step1FooterContainer": `eHhGlcqvZL08wzsYXRgo`,
	"goBackBtn": `GRZkdYYmLSu94sI3W_WO`,
	"connectButton": `gfR0imZniVczn9GzQdSx`,
	"connectedButton": `Cn2y03NIfetGIdKE64T0`,
	"listItems": `HRY3joe05d1eFYyDYdeG`,
	"removeButton": `RhaTgE4nBcL3rXst_zke`
};
export default ___CSS_LOADER_EXPORT___;
