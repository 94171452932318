// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dlGiqQDdWlSrhkIhBrCy {
  padding: 20px 20px 10px 20px;
}
.dlGiqQDdWlSrhkIhBrCy .mLXaAVvIx7VuQt45Llh7 {
  font-weight: 600;
  line-height: 26px;
  font-weight: 600;
  font-size: 18px;
  color: #464457;
}
.dlGiqQDdWlSrhkIhBrCy .XBNSDvakDJmuCLm7gmsg {
  border-radius: 0;
  padding: 8.5px 20px;
  margin-bottom: -1px;
  z-index: 1;
}
.dlGiqQDdWlSrhkIhBrCy .XBNSDvakDJmuCLm7gmsg .CbE3V8En3pUWIbhkw_ij {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.dlGiqQDdWlSrhkIhBrCy .XBNSDvakDJmuCLm7gmsg .CbE3V8En3pUWIbhkw_ij .BKvTiBlXX_orioxZQuSe {
  vertical-align: unset;
  margin-right: 6px;
}
.dlGiqQDdWlSrhkIhBrCy .g1Zcd7hSNbCK2eC1AT5d {
  background-color: #fffbe6 !important;
  color: #595959 !important;
  border-color: #ffe58f !important;
}
.dlGiqQDdWlSrhkIhBrCy .Ys4m2YwQ6GtvgKu4olWu {
  border-color: #ffe3e3 !important;
  background-color: rgba(255, 227, 227, 0.6) !important;
  color: #464457 !important;
  font-weight: 400 !important;
}
.dlGiqQDdWlSrhkIhBrCy .mZA_Afwz40xZZviP4Ufg {
  color: #d6af2d !important;
}
.dlGiqQDdWlSrhkIhBrCy .Al_xBShgjDfjP_jKFQRb {
  color: rgb(228, 83, 83) !important;
}
.dlGiqQDdWlSrhkIhBrCy .vHR9NtlUswwXphYv0HaU {
  border-radius: 0;
}

.jWRQK3RVkEgD_drf1kvE {
  padding: 20px 8px;
  border: none;
  border-radius: 0;
}
.jWRQK3RVkEgD_drf1kvE .OzzkgS17vLkNpoaMBg32 {
  margin-left: 0px;
}
.jWRQK3RVkEgD_drf1kvE .OzzkgS17vLkNpoaMBg32 .Q3TpSVMt5X3ByxcFJARy {
  font-weight: 500;
  color: #595959;
  font-size: 14px;
}
.jWRQK3RVkEgD_drf1kvE .OzzkgS17vLkNpoaMBg32 .n5MyUwbsCcLbyU9cCFKN {
  font-size: 14px;
}
.jWRQK3RVkEgD_drf1kvE .OzzkgS17vLkNpoaMBg32 .CjlIwMWotH9f4gXLkocw {
  margin-left: 5px;
  color: #777676;
}
.jWRQK3RVkEgD_drf1kvE .OzzkgS17vLkNpoaMBg32 .BTmHRDKwDvzvjP2Dd7gf {
  height: 23px;
}
.jWRQK3RVkEgD_drf1kvE .OzzkgS17vLkNpoaMBg32 .fHtfV6KD_6z4R9dMdziw {
  display: flex;
}
.jWRQK3RVkEgD_drf1kvE .OzzkgS17vLkNpoaMBg32 .fHtfV6KD_6z4R9dMdziw .Ydn2YfQJLDAO5ale4h9W {
  color: #2755fe;
  font-size: 14px;
  cursor: pointer;
  align-self: center;
  margin-left: 5px;
  cursor: pointer;
}

.XtVUkvuhM0ncY8AYSV7j {
  background: #ffe3e3;
  margin-left: 5px;
  color: #666666;
  font-size: 12px;
  font-weight: 500;
}

.SG18d434xOm7thSKRQgJ {
  margin-left: 5px;
  background: rgba(250, 173, 20, 0.2);
  color: #666666;
  font-size: 12px;
  font-weight: 500;
}

.PUG2p0X8CkyCi97a0H0g {
  border-radius: 0;
  border-bottom: none;
  border-left: none;
  border-right: none;
  padding: 15px 25px;
}

.lBJDiDmu17dsa2gAtlCS {
  color: #6a6a6c;
  padding: 5px 8px 4px 8px;
  line-height: 18px;
  font-size: 14px;
}
.lBJDiDmu17dsa2gAtlCS .MIKpIFuUndUxDMtBETWe {
  margin-top: 15px;
}
.lBJDiDmu17dsa2gAtlCS .MIKpIFuUndUxDMtBETWe .cSvb5WhO831QlFzmG19D {
  text-align: right;
  color: #6a6a6c;
  font-size: 14px;
}
.lBJDiDmu17dsa2gAtlCS .MIKpIFuUndUxDMtBETWe .kxP1btEoLMXIPCBzUW1a {
  margin-left: 40px;
}
.lBJDiDmu17dsa2gAtlCS .UPpm_U4eNhiwUr5KsoIL {
  color: #595959;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}
.lBJDiDmu17dsa2gAtlCS .c4Rg7CI4LpckHrdTve4H {
  margin: 8px 0px !important;
}

.cSvb5WhO831QlFzmG19D {
  font-weight: 500;
  color: #333333;
  font-size: 16px;
  font-family: "Roboto";
}

.qRqnKoFUezDzeuSA2hT7 {
  font-weight: 500;
  color: #333333;
  font-size: 16px;
  font-family: "Roboto";
}

.vD7a17S1VtLGaExHmGpk {
  font-weight: 500 !important;
  color: #333333 !important;
  font-size: 16px !important;
  font-family: "Roboto";
}

.suwODPYQ5pDj9yzjH_A4 {
  font-weight: 400;
  margin-left: 0.2rem;
}

.aHDeIeHMXfFDhvCOHSyC {
  font-size: 13px;
  margin-left: 10px;
  text-align: left;
}
.aHDeIeHMXfFDhvCOHSyC .cvNsru4CqN98zjRtAbs5 {
  margin-bottom: 10px;
  margin-top: 5px;
}
.aHDeIeHMXfFDhvCOHSyC .cvNsru4CqN98zjRtAbs5 .sE3x4ZqB37zIWcrgbFXo {
  font-family: "Roboto";
}

.b_UJNW5gO72BKvvPbF4l {
  font-weight: 500 !important;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 7px;
  color: #666666 !important;
}

.MIKpIFuUndUxDMtBETWe {
  display: flex;
  align-items: end;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `dlGiqQDdWlSrhkIhBrCy`,
	"containerHeader": `mLXaAVvIx7VuQt45Llh7`,
	"bannerCardContainer": `XBNSDvakDJmuCLm7gmsg`,
	"bannerDivContainer": `CbE3V8En3pUWIbhkw_ij`,
	"warningRedIcon": `BKvTiBlXX_orioxZQuSe`,
	"lowBalance": `g1Zcd7hSNbCK2eC1AT5d`,
	"criticalLow": `Ys4m2YwQ6GtvgKu4olWu`,
	"criticalLowColorText": `mZA_Afwz40xZZviP4Ufg`,
	"lowBalanceColorText": `Al_xBShgjDfjP_jKFQRb`,
	"arrearsCardContainer": `vHR9NtlUswwXphYv0HaU`,
	"arrearsCardSubContainer": `jWRQK3RVkEgD_drf1kvE`,
	"arrearsRowContainer": `OzzkgS17vLkNpoaMBg32`,
	"heading": `Q3TpSVMt5X3ByxcFJARy`,
	"tooltip": `n5MyUwbsCcLbyU9cCFKN`,
	"tooltipIcon": `CjlIwMWotH9f4gXLkocw`,
	"tooltipLockIcon": `BTmHRDKwDvzvjP2Dd7gf`,
	"accordionContainerHeader": `fHtfV6KD_6z4R9dMdziw`,
	"viewBreakDown": `Ydn2YfQJLDAO5ale4h9W`,
	"criticalPill": `XtVUkvuhM0ncY8AYSV7j`,
	"lowPill": `SG18d434xOm7thSKRQgJ`,
	"arrearsCardBreakdownContainer": `PUG2p0X8CkyCi97a0H0g`,
	"arrearBreakdownContainer": `lBJDiDmu17dsa2gAtlCS`,
	"row": `MIKpIFuUndUxDMtBETWe`,
	"amount": `cSvb5WhO831QlFzmG19D`,
	"list": `kxP1btEoLMXIPCBzUW1a`,
	"blackColor": `UPpm_U4eNhiwUr5KsoIL`,
	"hrTag": `c4Rg7CI4LpckHrdTve4H`,
	"amountTotalText": `qRqnKoFUezDzeuSA2hT7`,
	"amountTotal": `vD7a17S1VtLGaExHmGpk`,
	"bannerText": `suwODPYQ5pDj9yzjH_A4`,
	"tooltipLockedAmount": `aHDeIeHMXfFDhvCOHSyC`,
	"locketText": `cvNsru4CqN98zjRtAbs5`,
	"rupeeSym": `sE3x4ZqB37zIWcrgbFXo`,
	"containerSubHeader": `b_UJNW5gO72BKvvPbF4l`
};
export default ___CSS_LOADER_EXPORT___;
