// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PN85esjuKelSnPrl7r50 {
  padding: 16px 46px;
  background-color: rgba(66, 132, 91, 0.1019607843);
  border-radius: 4px;
  gap: 10px;
  border: none;
  margin-bottom: 1.25rem;
}
.PN85esjuKelSnPrl7r50 .b8PwH56IGKIFOfqvB6_D {
  display: flex;
  align-items: center;
}
.PN85esjuKelSnPrl7r50 .b8PwH56IGKIFOfqvB6_D .oaqCP096jEunH0NVu1ix {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.PN85esjuKelSnPrl7r50 .b8PwH56IGKIFOfqvB6_D .m5jZpKg3sUCkT_Sss3pM {
  height: 20px;
  width: 20px;
  margin-left: 4px;
}
.PN85esjuKelSnPrl7r50 .tfqJjtnYkNsvjBAogv7x {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #666666;
  font-weight: 400;
}
.PN85esjuKelSnPrl7r50 .tfqJjtnYkNsvjBAogv7x span {
  font-family: "Roboto";
  font-weight: 500;
  color: #333333;
}

.PIg2H9aVCZ9BnrV1FIVw {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: normal;
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS {
  padding: 2rem;
}
@media screen and (max-width: 601px) {
  .PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS {
    padding: 18px;
  }
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .x2Ucx4ybx1Nnd_sakdFd {
  color: #262626;
  font-size: 24px;
  margin-top: 30px;
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .xD5O5aQpF5OYuXMPv71_,
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .W6XZKQZPjGxTtDPuZ1SX,
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .QMvy94pKwzBVh2h6vgN5 {
  margin-top: 24px;
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .xD5O5aQpF5OYuXMPv71_ h4,
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .W6XZKQZPjGxTtDPuZ1SX h4,
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .QMvy94pKwzBVh2h6vgN5 h4 {
  color: #262626;
  font-size: 16px;
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .xD5O5aQpF5OYuXMPv71_ p,
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .W6XZKQZPjGxTtDPuZ1SX p,
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .QMvy94pKwzBVh2h6vgN5 p {
  color: #999999;
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .xD5O5aQpF5OYuXMPv71_ a {
  color: #2755fe;
  font-weight: 600;
  text-decoration: none;
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .dy3Pk1LbJ9DqfbuXAboJ {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
  margin-left: 15px;
  width: 237px;
  font-size: 17px;
  margin-top: 2rem;
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .Y9TQAYf0NwlsBHCv3Q45 .Nr5HTrOf8HcdQXOHlasf {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 5px 8px;
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .Y9TQAYf0NwlsBHCv3Q45 .Nr5HTrOf8HcdQXOHlasf:focus {
  outline: 0;
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .Y9TQAYf0NwlsBHCv3Q45 textarea::placeholder {
  font-size: 14px;
  color: #bfbfbf;
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .Y9TQAYf0NwlsBHCv3Q45 .mW48Z_hNxRCUCYStahTw {
  margin-top: 10px;
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.PIg2H9aVCZ9BnrV1FIVw .hmb_UbYu2fON3jZ7pAGS .Y9TQAYf0NwlsBHCv3Q45 .yyIE4sP41Ck7wmrP1ezQ {
  margin-top: 10px;
  background: #f5f5f5;
  color: #bfbfbf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}

.dujfKyPIUk16JoKY5DKt {
  animation: nJAda1KkIwzBA1TZFUWs 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes nJAda1KkIwzBA1TZFUWs {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successReferralCard": `PN85esjuKelSnPrl7r50`,
	"titleContainer": `b8PwH56IGKIFOfqvB6_D`,
	"titleText": `oaqCP096jEunH0NVu1ix`,
	"titleIcon": `m5jZpKg3sUCkT_Sss3pM`,
	"infoText": `tfqJjtnYkNsvjBAogv7x`,
	"feedbackCard": `PIg2H9aVCZ9BnrV1FIVw`,
	"feebackCardBody": `hmb_UbYu2fON3jZ7pAGS`,
	"allDoneText": `x2Ucx4ybx1Nnd_sakdFd`,
	"verifiedSoonSection": `xD5O5aQpF5OYuXMPv71_`,
	"expectSection": `W6XZKQZPjGxTtDPuZ1SX`,
	"howDoingSection": `QMvy94pKwzBVh2h6vgN5`,
	"googleRatingBtn": `dy3Pk1LbJ9DqfbuXAboJ`,
	"feedbackForm": `Y9TQAYf0NwlsBHCv3Q45`,
	"feedbackTextarea": `Nr5HTrOf8HcdQXOHlasf`,
	"activeBtn": `mW48Z_hNxRCUCYStahTw`,
	"disabledBtn": `yyIE4sP41Ck7wmrP1ezQ`,
	"jiggle": `dujfKyPIUk16JoKY5DKt`,
	"shake": `nJAda1KkIwzBA1TZFUWs`
};
export default ___CSS_LOADER_EXPORT___;
