// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E8hWaaSJlHcCTYWymE4R {
  max-width: 1200px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  width: 1374px;
  margin-top: 24px;
  margin-bottom: 48px;
}
@media only screen and (max-width: 1200px) {
  .E8hWaaSJlHcCTYWymE4R {
    width: 1200px;
  }
}
.E8hWaaSJlHcCTYWymE4R .EY8e56TUHfbAZ9O70PoA {
  text-decoration: none;
}
.E8hWaaSJlHcCTYWymE4R .kUPdqUN1hVZq2MLJXlF4 {
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 18px 24px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
}
.E8hWaaSJlHcCTYWymE4R .kUPdqUN1hVZq2MLJXlF4 .W05W1K67bhmmVM0BnTQb {
  font-size: 16px;
  line-height: 24px;
  color: #464457;
}
.E8hWaaSJlHcCTYWymE4R .kUPdqUN1hVZq2MLJXlF4 .jcPYQuFMCgf46sgD3wjG {
  font-size: 14px;
  line-height: 21px;
}
.E8hWaaSJlHcCTYWymE4R .kUPdqUN1hVZq2MLJXlF4 .jcPYQuFMCgf46sgD3wjG a {
  color: #646c9a;
}
.E8hWaaSJlHcCTYWymE4R .nL6F9ibUUQV5v1svAPF2 {
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  margin: 24px auto;
  width: 96%;
  text-align: center;
}
.E8hWaaSJlHcCTYWymE4R .nL6F9ibUUQV5v1svAPF2 thead {
  background: #ffffff;
}
.E8hWaaSJlHcCTYWymE4R .nL6F9ibUUQV5v1svAPF2 thead tr th {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  padding: 14px;
  border: 1px solid #f0f0f0;
}
.E8hWaaSJlHcCTYWymE4R .nL6F9ibUUQV5v1svAPF2 tbody tr:nth-child(odd) {
  background-color: #f7f8fa;
}
.E8hWaaSJlHcCTYWymE4R .nL6F9ibUUQV5v1svAPF2 tbody tr {
  cursor: default;
}
.E8hWaaSJlHcCTYWymE4R .nL6F9ibUUQV5v1svAPF2 tbody tr td {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #212529;
  padding: 14px;
  border: 1px solid #f0f0f0;
}
.E8hWaaSJlHcCTYWymE4R .nL6F9ibUUQV5v1svAPF2 tbody tr .GSRJLRPawxTeJdp0My_C {
  font-weight: 500;
  color: #2755fe;
}
.E8hWaaSJlHcCTYWymE4R .nL6F9ibUUQV5v1svAPF2 tbody tr .GSRJLRPawxTeJdp0My_C p {
  cursor: pointer;
  width: fit-content;
  margin: auto;
}
.E8hWaaSJlHcCTYWymE4R .nL6F9ibUUQV5v1svAPF2 tbody tr .GSRJLRPawxTeJdp0My_C p:hover {
  text-decoration: underline;
  color: #1f44ca;
}
.E8hWaaSJlHcCTYWymE4R .nL6F9ibUUQV5v1svAPF2 tbody tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recentlyAddedCard": `E8hWaaSJlHcCTYWymE4R`,
	"noTextDecoration": `EY8e56TUHfbAZ9O70PoA`,
	"cardHeader": `kUPdqUN1hVZq2MLJXlF4`,
	"heading": `W05W1K67bhmmVM0BnTQb`,
	"viewAllLink": `jcPYQuFMCgf46sgD3wjG`,
	"recentlyAddedTable": `nL6F9ibUUQV5v1svAPF2`,
	"name": `GSRJLRPawxTeJdp0My_C`
};
export default ___CSS_LOADER_EXPORT___;
