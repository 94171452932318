// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZYyQnFzLeAT7E_cJHvx9 {
  padding: 0 32px;
  box-shadow: 0px 0px 16px rgba(0, 45, 211, 0.25);
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
@media screen and (max-width: 601px) {
  .ZYyQnFzLeAT7E_cJHvx9 {
    padding: 0 0 65px 0;
    box-shadow: none;
    border: none;
  }
}
@media screen and (max-width: 601px) {
  .ZYyQnFzLeAT7E_cJHvx9 .KUOk_IMBms4gyqxdUeI7 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 120px);
    padding: 16px;
  }
}
.ZYyQnFzLeAT7E_cJHvx9 .MwSscsAhxudCrizQ5zrf,
.ZYyQnFzLeAT7E_cJHvx9 .wwpvQQuBjfXCP5NNBjgQ {
  display: flex;
  align-items: center;
}
.ZYyQnFzLeAT7E_cJHvx9 .wwpvQQuBjfXCP5NNBjgQ {
  margin-bottom: 10px;
}
.ZYyQnFzLeAT7E_cJHvx9 .FOpTDNgtf41LeEzAzrdK {
  margin-right: 20px;
  color: #8c8c8c;
}
.ZYyQnFzLeAT7E_cJHvx9 .DY_HfSj8zZtkHdHPPEch {
  margin-left: 20px;
  color: #8c8c8c;
}
.ZYyQnFzLeAT7E_cJHvx9 .YLfwgWNLxEF0CE_hq5RA {
  color: #262626;
  font-size: 24px;
}
.ZYyQnFzLeAT7E_cJHvx9 .Za0eY9Q1CHbM33envHkR {
  font-size: 16px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 32px;
}
.ZYyQnFzLeAT7E_cJHvx9 .LidPSQ6YZOX5ZDM_KCyw {
  font-size: 16px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 32px;
  color: orange;
}
.ZYyQnFzLeAT7E_cJHvx9 .aBlSxyB5pnEL_60lzRQ2 {
  background: #ffffff;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  color: #2755fe;
  padding: 8px 16px;
  text-align: center;
  border: none;
  width: 100%;
  font-size: 16px;
}
.ZYyQnFzLeAT7E_cJHvx9 .aBlSxyB5pnEL_60lzRQ2:active {
  background: #ffffff !important;
  color: #2755fe !important;
  border: none !important;
}
.ZYyQnFzLeAT7E_cJHvx9 .aG6lt3ue_KpL1xWak1bw {
  margin-top: 120px !important;
}
.ZYyQnFzLeAT7E_cJHvx9 .aG6lt3ue_KpL1xWak1bw .zae5FD65llHVLOtgMPHw label {
  color: #595959 !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
}
.ZYyQnFzLeAT7E_cJHvx9 .aG6lt3ue_KpL1xWak1bw .mWnDGykEX3038IOTv5OZ {
  margin-top: 30px !important;
  justify-content: unset !important;
  padding: 0 15px;
}
.ZYyQnFzLeAT7E_cJHvx9 .aG6lt3ue_KpL1xWak1bw .mWnDGykEX3038IOTv5OZ button {
  border-radius: 2px;
  padding-left: 0;
}
.ZYyQnFzLeAT7E_cJHvx9 .aG6lt3ue_KpL1xWak1bw .mWnDGykEX3038IOTv5OZ .pwODb3vGx6juXAnmHyFD {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
  padding: 0.3em 1.4rem;
}
.ZYyQnFzLeAT7E_cJHvx9 .aG6lt3ue_KpL1xWak1bw .mWnDGykEX3038IOTv5OZ .BEYNAwqRkXbD5XctD1uz {
  background: #f5f5f5;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
  padding: 0.3em 1.4rem;
}
@media screen and (max-width: 601px) {
  .ZYyQnFzLeAT7E_cJHvx9 .aG6lt3ue_KpL1xWak1bw .mWnDGykEX3038IOTv5OZ {
    position: fixed;
    left: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .ZYyQnFzLeAT7E_cJHvx9 .aG6lt3ue_KpL1xWak1bw .mWnDGykEX3038IOTv5OZ button {
    height: 40px;
  }
  .ZYyQnFzLeAT7E_cJHvx9 .aG6lt3ue_KpL1xWak1bw .mWnDGykEX3038IOTv5OZ .pwODb3vGx6juXAnmHyFD {
    flex: 1;
  }
  .ZYyQnFzLeAT7E_cJHvx9 .aG6lt3ue_KpL1xWak1bw .mWnDGykEX3038IOTv5OZ .BEYNAwqRkXbD5XctD1uz {
    flex: 1;
  }
}
@media screen and (max-width: 601px) {
  .ZYyQnFzLeAT7E_cJHvx9 .mWnDGykEX3038IOTv5OZ button {
    width: 100%;
    height: 440px;
  }
}
.ZYyQnFzLeAT7E_cJHvx9 .V2JbBdRl4tFVAHahZej2 .szyszHwHahqcts5iALO5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #262626;
}
.ZYyQnFzLeAT7E_cJHvx9 .V2JbBdRl4tFVAHahZej2 .Gbr_3iQDmPIvjSxJz13h {
  color: #f5222d;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"educationCard": `ZYyQnFzLeAT7E_cJHvx9`,
	"educationCardBody": `KUOk_IMBms4gyqxdUeI7`,
	"educationProgressContainer": `MwSscsAhxudCrizQ5zrf`,
	"educationProgressContainerMobile": `wwpvQQuBjfXCP5NNBjgQ`,
	"stepText": `FOpTDNgtf41LeEzAzrdK`,
	"stepTextMobile": `DY_HfSj8zZtkHdHPPEch`,
	"educationHeading": `YLfwgWNLxEF0CE_hq5RA`,
	"addHeadingInfo": `Za0eY9Q1CHbM33envHkR`,
	"noEducationHeadingInfo": `LidPSQ6YZOX5ZDM_KCyw`,
	"addEducationBtn": `aBlSxyB5pnEL_60lzRQ2`,
	"educationFooterContainer": `aG6lt3ue_KpL1xWak1bw`,
	"checkBoxContainer": `zae5FD65llHVLOtgMPHw`,
	"educationSubmitBtn": `mWnDGykEX3038IOTv5OZ`,
	"activeBtn": `pwODb3vGx6juXAnmHyFD`,
	"disabledBtn": `BEYNAwqRkXbD5XctD1uz`,
	"addEducationRow": `V2JbBdRl4tFVAHahZej2`,
	"grade": `szyszHwHahqcts5iALO5`,
	"mandatory": `Gbr_3iQDmPIvjSxJz13h`
};
export default ___CSS_LOADER_EXPORT___;
