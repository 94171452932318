// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cvf1_M5WIV9SZ7xxrqTw {
  padding: 32px;
  box-shadow: 0px 0px 16px rgba(0, 45, 211, 0.25);
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
@media screen and (max-width: 601px) {
  .Cvf1_M5WIV9SZ7xxrqTw {
    padding: 16px;
    box-shadow: none;
    border: none;
    padding-bottom: 65px !important;
  }
}
.Cvf1_M5WIV9SZ7xxrqTw .FuKWN2FqCZPadQk_fckq {
  padding: 0;
}
.Cvf1_M5WIV9SZ7xxrqTw .y3O1PJyKlrXcLLD5Ctpu,
.Cvf1_M5WIV9SZ7xxrqTw .jHfohtg1eF7ryco1dyGg {
  display: flex;
  align-items: center;
}
.Cvf1_M5WIV9SZ7xxrqTw .jHfohtg1eF7ryco1dyGg {
  margin-bottom: 10px;
}
.Cvf1_M5WIV9SZ7xxrqTw .xxkIl7EeMGHZBYdWXMk8 {
  margin-right: 20px;
  color: #8c8c8c;
}
.Cvf1_M5WIV9SZ7xxrqTw .Oel3XiP9RcLgAH8JkSeP {
  margin-left: 20px;
  color: #8c8c8c;
}
.Cvf1_M5WIV9SZ7xxrqTw .T_yJV4a6UcFSj5gPTkiT {
  font-size: 24px;
  line-height: 32px;
  color: #262626;
}
.Cvf1_M5WIV9SZ7xxrqTw .MMzaBnngO4k3KUOwDe91 {
  color: #999999;
  font-style: italic;
  margin-top: 15px;
}
.Cvf1_M5WIV9SZ7xxrqTw form {
  margin-top: 25px;
}
.Cvf1_M5WIV9SZ7xxrqTw form label {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #333333;
  margin-bottom: 6px;
}
.Cvf1_M5WIV9SZ7xxrqTw form label span {
  color: red;
}
.Cvf1_M5WIV9SZ7xxrqTw form .McBhRA4fFx9FcFLl7AKD {
  justify-content: space-between !important;
}
.Cvf1_M5WIV9SZ7xxrqTw form .c_37SNLyTIZb4C9LelTQ {
  justify-content: unset !important;
  margin-top: 90px !important;
}
.Cvf1_M5WIV9SZ7xxrqTw form .c_37SNLyTIZb4C9LelTQ button {
  border-radius: 2px;
  padding: 0.3em 1.4rem;
}
.Cvf1_M5WIV9SZ7xxrqTw form .c_37SNLyTIZb4C9LelTQ .nwHfECiZOSZYg7OByymo {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
  height: 40px;
}
.Cvf1_M5WIV9SZ7xxrqTw form .c_37SNLyTIZb4C9LelTQ .bdMPngTSbc1Lii8B9HJ_ {
  background: #f5f5f5;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
@media screen and (max-width: 601px) {
  .Cvf1_M5WIV9SZ7xxrqTw form .c_37SNLyTIZb4C9LelTQ {
    background: #ffffff;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 12px 16px;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    display: flex;
    align-items: center;
  }
  .Cvf1_M5WIV9SZ7xxrqTw form .c_37SNLyTIZb4C9LelTQ .nwHfECiZOSZYg7OByymo {
    flex: 1;
  }
  .Cvf1_M5WIV9SZ7xxrqTw form .c_37SNLyTIZb4C9LelTQ .bdMPngTSbc1Lii8B9HJ_ {
    flex: 1;
  }
}

.YQZePnInknNMay41t4eM {
  padding-top: 27px;
}

.hNGu82zb4hiXqZkL2H2W {
  font-size: 14px;
  color: #dc3545;
  margin-top: -11px !important;
  margin-bottom: 1rem;
}

.OdTwaKyGX0YLQ4GBNh_D input {
  border: 1px solid #dc3545 !important;
  margin-bottom: 0 !important;
}

.Edm8oePET0zI1qKtorVf {
  background: #ffffff;
  border: 1px solid #ebedf2;
  border-radius: 4px;
  box-sizing: border-box;
  height: 38px;
  text-align: center;
  padding: 7px;
  color: #595959;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.jqVPP0_8GGdNqC4GBcM3 {
  align-content: right;
  padding: 5px;
  float: right;
}

.hyRpT9bFtWQstt9F7CAg {
  flex: 0 0 40%;
  max-width: 40%;
}

@media screen and (max-width: 601px) {
  .MMzaBnngO4k3KUOwDe91 {
    display: none;
  }
}
@media screen and (max-width: 993px) {
  .IYGSrnuV0Ub4E_EDc7qI {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactDetailsCard": `Cvf1_M5WIV9SZ7xxrqTw`,
	"contactDetailsCardBody": `FuKWN2FqCZPadQk_fckq`,
	"contactDetailProgressContainer": `y3O1PJyKlrXcLLD5Ctpu`,
	"contactDetailProgressContainerMobile": `jHfohtg1eF7ryco1dyGg`,
	"stepText": `xxkIl7EeMGHZBYdWXMk8`,
	"stepTextMobile": `Oel3XiP9RcLgAH8JkSeP`,
	"contactDetailHeading": `T_yJV4a6UcFSj5gPTkiT`,
	"basicDetailInfo": `MMzaBnngO4k3KUOwDe91`,
	"form-row": `McBhRA4fFx9FcFLl7AKD`,
	"basicDetailSubmitBtn": `c_37SNLyTIZb4C9LelTQ`,
	"activeBtn": `nwHfECiZOSZYg7OByymo`,
	"disabledBtn": `bdMPngTSbc1Lii8B9HJ_`,
	"phoneNumber": `YQZePnInknNMay41t4eM`,
	"emailErrorMsg": `hNGu82zb4hiXqZkL2H2W`,
	"emailError": `OdTwaKyGX0YLQ4GBNh_D`,
	"mobileCountryCodes": `Edm8oePET0zI1qKtorVf`,
	"countryCodeArrow": `jqVPP0_8GGdNqC4GBcM3`,
	"mobCountryCodeDropDown": `hyRpT9bFtWQstt9F7CAg`,
	"countryCodeDropDown": `IYGSrnuV0Ub4E_EDc7qI`
};
export default ___CSS_LOADER_EXPORT___;
