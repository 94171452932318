// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vePW30iV_tbkadTc7JUt {
  margin-top: -24px;
}
@media only screen and (max-width: 1024px) {
  .vePW30iV_tbkadTc7JUt {
    margin-left: 150px;
  }
}
.vePW30iV_tbkadTc7JUt .FuCuE16fE1VFsnSHIkcG {
  padding: 12px 20px 14px 20px;
  display: flex;
  justify-content: space-between;
}
.vePW30iV_tbkadTc7JUt .FuCuE16fE1VFsnSHIkcG .aTGLxLkQ8jeSvPXrwBUY {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #333333;
  padding-top: 11px;
}
.vePW30iV_tbkadTc7JUt .FuCuE16fE1VFsnSHIkcG button .jOWnek00N7ladxU3uceQ {
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0;
  padding: 13px 11px 15px 11px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg {
  border: 0px;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC {
  margin: auto;
  width: 96%;
  margin-bottom: 24px;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC thead {
  background-color: #f2f8ff;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC thead tr th {
  border: 0px;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 18px 20px 19px 20px;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC thead tr .NOYUvMB5nRAJRDMKu2uv {
  width: 400px;
  text-align: end;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC thead tr .EGQVJoe_AZ_X2XawgMyr {
  width: 400px;
  text-align: Center;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC thead tr ._43VvPEqDtDrdvmj_XrOb {
  text-align: Center;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC thead tr .jvFs5GQMpinJV8P0s4Jh {
  text-align: end;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr {
  cursor: default;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr td {
  border: 0px;
  vertical-align: middle;
  padding: 16px 20px 16px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr td .dmm1Yay3M2obehDadvDw {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr td .dmm1Yay3M2obehDadvDw .hfABWPmWj0XUWuW2IYEE {
  margin-left: 10px;
  border: 1px transparent solid;
  background: transparent;
  padding: 3px;
  border-radius: 50%;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr td .dmm1Yay3M2obehDadvDw .hfABWPmWj0XUWuW2IYEE:hover {
  background: #e2ebf6;
  border: 1px #e2ebf6 solid;
  border-radius: 50%;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr td .dmm1Yay3M2obehDadvDw .hfABWPmWj0XUWuW2IYEE:hover svg path {
  fill: #2755fe;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr .NOYUvMB5nRAJRDMKu2uv {
  width: 400px;
  text-align: end;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr .EGQVJoe_AZ_X2XawgMyr {
  width: 400px;
  text-align: Center;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr ._43VvPEqDtDrdvmj_XrOb {
  text-align: Center;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr .UR3_Jkt8oEsI3zVPZpRW {
  cursor: pointer;
  color: #366cf3;
  text-align: end;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr:hover {
  background-color: #f5f5f5;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC tbody tr:hover .spSY15us8PPqdQje7TNn {
  color: #366cf3;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC .O7NbYoZoS5V7zK52_XzQ {
  text-align: center;
  padding-top: 64px;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .ovUaB6g41kcAil0Id7qC .O7NbYoZoS5V7zK52_XzQ .uc48kwEcZesVmTiOIEYk {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .t3_60aB0LfoPKGe3VyQ8 {
  flex-wrap: unset;
  padding: 0 24px 15px;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .t3_60aB0LfoPKGe3VyQ8 .ZJyIqt35rvZG1YqJwkdX {
  padding-right: 100px;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  display: contents;
}
.vePW30iV_tbkadTc7JUt .h9aPKHY3sOCDaqIZbgWg .t3_60aB0LfoPKGe3VyQ8 .ZJyIqt35rvZG1YqJwkdX .BqJXjbBFRcRhslaQ6NRB {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px;
  -webkit-appearance: menulist;
}

.eu8QDmiZXhwcPeMORISQ {
  background: #2755fe;
}

.eu8QDmiZXhwcPeMORISQ:hover {
  background-color: #1f44ca;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageCatCard": `vePW30iV_tbkadTc7JUt`,
	"manageCatCardHeader": `FuCuE16fE1VFsnSHIkcG`,
	"headerComp": `aTGLxLkQ8jeSvPXrwBUY`,
	"createButton": `jOWnek00N7ladxU3uceQ`,
	"tableCard": `h9aPKHY3sOCDaqIZbgWg`,
	"manageCategoryTable": `ovUaB6g41kcAil0Id7qC`,
	"alignRight": `NOYUvMB5nRAJRDMKu2uv`,
	"alignCenter": `EGQVJoe_AZ_X2XawgMyr`,
	"textCenter": `_43VvPEqDtDrdvmj_XrOb`,
	"textEnd": `jvFs5GQMpinJV8P0s4Jh`,
	"icons": `dmm1Yay3M2obehDadvDw`,
	"icon": `hfABWPmWj0XUWuW2IYEE`,
	"highlighted": `UR3_Jkt8oEsI3zVPZpRW`,
	"mainField": `spSY15us8PPqdQje7TNn`,
	"noDataWrapper": `O7NbYoZoS5V7zK52_XzQ`,
	"noDataContent": `uc48kwEcZesVmTiOIEYk`,
	"paginationWrapper": `t3_60aB0LfoPKGe3VyQ8`,
	"showPagination": `ZJyIqt35rvZG1YqJwkdX`,
	"selectPagination": `BqJXjbBFRcRhslaQ6NRB`,
	"accentColor": `eu8QDmiZXhwcPeMORISQ`
};
export default ___CSS_LOADER_EXPORT___;
