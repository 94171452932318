// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hc7m5xf20hlL8WnqgZb5 {
  padding: 18px 18px 0px 18px;
  display: flex;
  align-items: center;
}
.hc7m5xf20hlL8WnqgZb5 .R50rcb42wSl7xMZ7Qw_4 {
  margin-bottom: 0;
}
.hc7m5xf20hlL8WnqgZb5 .R50rcb42wSl7xMZ7Qw_4 .YPDjEh5E14fMbxxxDlLU {
  font-family: roboto;
  font-size: 14px;
  color: #000000;
  font-weight: normal;
}
.hc7m5xf20hlL8WnqgZb5 .R50rcb42wSl7xMZ7Qw_4 .UmOymIBVLl0kzC8A_Y3s {
  font-size: 14px;
  color: #000000;
  font-weight: normal;
}

.dZlpyFbf_VXlOCa_sBzE {
  padding: 18px 18px;
  border: 1px solid rgba(51, 51, 51, 0.1);
}
.dZlpyFbf_VXlOCa_sBzE .rZpHtrPsJG3xLT3i1iAA {
  font-weight: 600;
  font-size: 20px;
  color: #464457;
  margin-bottom: 12px;
}
.dZlpyFbf_VXlOCa_sBzE .NeC8GIikaPosrkkH0ThA .YPDjEh5E14fMbxxxDlLU {
  font-family: roboto;
}
.dZlpyFbf_VXlOCa_sBzE .NeC8GIikaPosrkkH0ThA .dgddhyZXRq4j_n6nQ7p_ {
  font-size: 14px;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.dZlpyFbf_VXlOCa_sBzE .NeC8GIikaPosrkkH0ThA .dgddhyZXRq4j_n6nQ7p_ .pPMJWRyWeYO2zYkOZMBA,
.dZlpyFbf_VXlOCa_sBzE .NeC8GIikaPosrkkH0ThA .dgddhyZXRq4j_n6nQ7p_ .p0c8hoSomjnfp4_UP1Lh {
  font-weight: normal;
}
.dZlpyFbf_VXlOCa_sBzE .NeC8GIikaPosrkkH0ThA .aqLSPuJNEOT8kx87iI_A,
.dZlpyFbf_VXlOCa_sBzE .NeC8GIikaPosrkkH0ThA .nf9ik4Mf0yLqC2dpFoNG {
  font-weight: normal;
  font-size: 12px;
  color: #666666;
  margin-bottom: 8px;
}
.dZlpyFbf_VXlOCa_sBzE .NeC8GIikaPosrkkH0ThA .nf9ik4Mf0yLqC2dpFoNG {
  margin-bottom: 1rem;
}
.dZlpyFbf_VXlOCa_sBzE .NeC8GIikaPosrkkH0ThA .H_iJx6jiCdbrKN6w0lFX {
  display: flex;
  justify-content: space-between;
  color: #333333;
  font-size: 14px;
}
.dZlpyFbf_VXlOCa_sBzE .NeC8GIikaPosrkkH0ThA .prqZEVGqTuJfs2ULOeMn {
  font-weight: normal;
}
.dZlpyFbf_VXlOCa_sBzE .NeC8GIikaPosrkkH0ThA .Rfvsa6PT6FxyqO5BHKXv {
  color: #999999;
  font-size: 14px;
}
.dZlpyFbf_VXlOCa_sBzE .WdOWyM8qycX99oJ3lJ8o .H9iEQbSbCzTf2n7Ey8C2 {
  width: 100%;
  background: #2755fe;
  border-radius: 2px;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dZlpyFbf_VXlOCa_sBzE .WdOWyM8qycX99oJ3lJ8o .H9iEQbSbCzTf2n7Ey8C2 .YPDjEh5E14fMbxxxDlLU {
  font-family: roboto;
}

.t6L4FPDsgoORzBaO1CQ1 {
  border: 1px solid rgba(51, 51, 51, 0.1);
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"creditBalanceContainer": `hc7m5xf20hlL8WnqgZb5`,
	"formGroup": `R50rcb42wSl7xMZ7Qw_4`,
	"rupee": `YPDjEh5E14fMbxxxDlLU`,
	"creditBalanceText": `UmOymIBVLl0kzC8A_Y3s`,
	"summaryDetailsContainer": `dZlpyFbf_VXlOCa_sBzE`,
	"heading": `rZpHtrPsJG3xLT3i1iAA`,
	"packageDetailsContainer": `NeC8GIikaPosrkkH0ThA`,
	"packageNameContainer": `dgddhyZXRq4j_n6nQ7p_`,
	"subTypeName": `pPMJWRyWeYO2zYkOZMBA`,
	"costContainer": `p0c8hoSomjnfp4_UP1Lh`,
	"candidateCount": `aqLSPuJNEOT8kx87iI_A`,
	"costPerCandidate": `nf9ik4Mf0yLqC2dpFoNG`,
	"summaryCreditBalance": `H_iJx6jiCdbrKN6w0lFX`,
	"fontWeightNormal": `prqZEVGqTuJfs2ULOeMn`,
	"taxText": `Rfvsa6PT6FxyqO5BHKXv`,
	"addBtnContainer": `WdOWyM8qycX99oJ3lJ8o`,
	"payBtn": `H9iEQbSbCzTf2n7Ey8C2`,
	"creditTdsContainer": `t6L4FPDsgoORzBaO1CQ1`
};
export default ___CSS_LOADER_EXPORT___;
