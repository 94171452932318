// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/welcome_modal_greentick.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KA_IHAjybehPDMxhrFKZ {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  padding: 0 !important;
}
@media screen and (max-width: 601px) {
  .KA_IHAjybehPDMxhrFKZ {
    font-size: 0.8em;
  }
}
.KA_IHAjybehPDMxhrFKZ .WNHn_A0sOtAM3XI43u8E {
  background: linear-gradient(90deg, #0844a6 0%, #086590 48.96%, #088077 100%);
  width: 100%;
  margin: 0;
  padding: 32px 0 0 50px;
}
@media only screen and (max-width: 995px) {
  .KA_IHAjybehPDMxhrFKZ .WNHn_A0sOtAM3XI43u8E {
    padding: 38px 0 0 38px;
  }
}
.KA_IHAjybehPDMxhrFKZ .WNHn_A0sOtAM3XI43u8E .omJFwNZAHYoKiKD3Z8OY {
  display: flex;
  padding: 0;
}
@media only screen and (max-width: 995px) {
  .KA_IHAjybehPDMxhrFKZ .WNHn_A0sOtAM3XI43u8E .omJFwNZAHYoKiKD3Z8OY {
    justify-content: space-between;
  }
}
.KA_IHAjybehPDMxhrFKZ .WNHn_A0sOtAM3XI43u8E .omJFwNZAHYoKiKD3Z8OY .XIyLD1vic1iFEJB2CDTC {
  width: 130px;
  height: 180px;
  background-color: linear-gradient(90deg, #0844a6 0%, #086590 48.96%, #088077 100%);
  display: flex;
  align-items: center;
  align-content: center;
  margin-right: 50px;
  justify-content: center;
}
@media only screen and (max-width: 995px) {
  .KA_IHAjybehPDMxhrFKZ .WNHn_A0sOtAM3XI43u8E .omJFwNZAHYoKiKD3Z8OY .XIyLD1vic1iFEJB2CDTC {
    width: 113px;
  }
}
.KA_IHAjybehPDMxhrFKZ .WNHn_A0sOtAM3XI43u8E .omJFwNZAHYoKiKD3Z8OY .fnfmHANsXY3u1z5axCqw {
  margin: 0 0 -34px 88px !important;
}
@media only screen and (max-width: 995px) {
  .KA_IHAjybehPDMxhrFKZ .WNHn_A0sOtAM3XI43u8E .omJFwNZAHYoKiKD3Z8OY .fnfmHANsXY3u1z5axCqw {
    margin: 0 0 -34px 45px;
  }
}
.KA_IHAjybehPDMxhrFKZ .a10QAban7yXQdKyHuYGa {
  padding: 0 30px;
  margin: 0;
  margin-top: 40px !important;
}
.KA_IHAjybehPDMxhrFKZ .a10QAban7yXQdKyHuYGa .trkmFOEVwBpbKtyObXI9 {
  padding: 0;
}
.KA_IHAjybehPDMxhrFKZ .a10QAban7yXQdKyHuYGa h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 995px) {
  .KA_IHAjybehPDMxhrFKZ .a10QAban7yXQdKyHuYGa h2 {
    font-size: 19px;
  }
}
.KA_IHAjybehPDMxhrFKZ .a10QAban7yXQdKyHuYGa .f5rgCVd9jkAF1Iu1MlTN,
.KA_IHAjybehPDMxhrFKZ .a10QAban7yXQdKyHuYGa .LjI0Zx853Pga66vcyhUU,
.KA_IHAjybehPDMxhrFKZ .a10QAban7yXQdKyHuYGa .Xw4Np4uwJ3IaAVvRbhsw {
  margin-bottom: 30px;
}
.KA_IHAjybehPDMxhrFKZ .a10QAban7yXQdKyHuYGa .LjI0Zx853Pga66vcyhUU ul {
  padding-left: 22px;
  list-style-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.KA_IHAjybehPDMxhrFKZ .a10QAban7yXQdKyHuYGa .Xw4Np4uwJ3IaAVvRbhsw a {
  color: #2755fe;
  font-weight: 600;
  text-decoration: none;
}

.AflmyKdNtXF03wzzLMOj {
  justify-content: flex-start;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}
.AflmyKdNtXF03wzzLMOj button {
  padding: 8px 25px;
  background: #2755fe;
  font-family: Poppins, sans-serif;
}
@media screen and (max-width: 601px) {
  .AflmyKdNtXF03wzzLMOj button {
    font-size: 1em;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgvHomeModalBody": `KA_IHAjybehPDMxhrFKZ`,
	"homeModalBodyHeader": `WNHn_A0sOtAM3XI43u8E`,
	"homeModalHeaderContentContainer": `omJFwNZAHYoKiKD3Z8OY`,
	"homeModalSVLogo": `XIyLD1vic1iFEJB2CDTC`,
	"homeModalSVIllustration": `fnfmHANsXY3u1z5axCqw`,
	"homeModalBodyContent": `a10QAban7yXQdKyHuYGa`,
	"homeModalBodyContentContainer": `trkmFOEVwBpbKtyObXI9`,
	"welcomeTopContent": `f5rgCVd9jkAF1Iu1MlTN`,
	"welcomeMiddleContent": `LjI0Zx853Pga66vcyhUU`,
	"welcomeBottomContent": `Xw4Np4uwJ3IaAVvRbhsw`,
	"modalFooter": `AflmyKdNtXF03wzzLMOj`
};
export default ___CSS_LOADER_EXPORT___;
