// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QrGUddL7FPdH8eKXYtQZ p {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.QrGUddL7FPdH8eKXYtQZ .A13FnLbzrR9kSwSKTukB {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  padding: 1px 8px;
  border-radius: 2px;
  margin-left: 8px;
}
.QrGUddL7FPdH8eKXYtQZ .MabS0EZFyDQbytZsPRBm {
  color: #52c41a;
  border: 1px solid #b7eb8f;
  background: #f6ffed;
}
.QrGUddL7FPdH8eKXYtQZ .EruCDCWb3Mp22cXlNb8i {
  color: #595959;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
}
.QrGUddL7FPdH8eKXYtQZ .jMBaQzN4bK8hvobLf0sW {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.QrGUddL7FPdH8eKXYtQZ .xmp87QQMdy3XKxh84Fxg {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}

.UiDdZFxHLzeZkJAcFJ3k {
  text-align: right;
}
.UiDdZFxHLzeZkJAcFJ3k p {
  margin-bottom: 5px;
}
.UiDdZFxHLzeZkJAcFJ3k .ZWqRkrW9oSHLssB9yiew {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.UiDdZFxHLzeZkJAcFJ3k .djH3IXZ2W5470Qx3nqgf {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
.UiDdZFxHLzeZkJAcFJ3k .lg8qkPMDqhzmpv0VDXbf {
  color: #389e0d;
}
.UiDdZFxHLzeZkJAcFJ3k .WnZObDsZcx3TWSLPjqt1 {
  color: #333333;
}
.UiDdZFxHLzeZkJAcFJ3k .Q1JIhbQsy9GrHCyfViFy {
  font-family: roboto;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsContainer": `QrGUddL7FPdH8eKXYtQZ`,
	"tag": `A13FnLbzrR9kSwSKTukB`,
	"activeTag": `MabS0EZFyDQbytZsPRBm`,
	"inactiveTag": `EruCDCWb3Mp22cXlNb8i`,
	"addedRemovedBy": `jMBaQzN4bK8hvobLf0sW`,
	"note": `xmp87QQMdy3XKxh84Fxg`,
	"priceContainer": `UiDdZFxHLzeZkJAcFJ3k`,
	"date": `ZWqRkrW9oSHLssB9yiew`,
	"amount": `djH3IXZ2W5470Qx3nqgf`,
	"amountCredit": `lg8qkPMDqhzmpv0VDXbf`,
	"amountDebit": `WnZObDsZcx3TWSLPjqt1`,
	"rupee": `Q1JIhbQsy9GrHCyfViFy`
};
export default ___CSS_LOADER_EXPORT___;
