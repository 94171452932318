// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.duMXRNU_aVKMWaexLRDJ {
  border-top: none;
  padding: 0 24px 24px 24px;
  border: none !important;
}
.duMXRNU_aVKMWaexLRDJ .O0ZUpoxgq1PYEBZVEgwb {
  min-width: 100%;
  max-width: 100%;
  padding: 25px 20px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.duMXRNU_aVKMWaexLRDJ .O0ZUpoxgq1PYEBZVEgwb .vIW_cYAbBtGrLurJkMuM {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.duMXRNU_aVKMWaexLRDJ .O0ZUpoxgq1PYEBZVEgwb .SsjuoGmRHtFtU9gtDxzv {
  width: 100%;
  position: relative;
}
.duMXRNU_aVKMWaexLRDJ .uwAJaRFmaQGim0EB27BW {
  display: flex;
  justify-content: flex-end;
}
.duMXRNU_aVKMWaexLRDJ .uwAJaRFmaQGim0EB27BW .rq28_I1DvnFNfJZrjPgT {
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  height: 48px;
  margin-right: 0px;
}
.duMXRNU_aVKMWaexLRDJ .uwAJaRFmaQGim0EB27BW .rq28_I1DvnFNfJZrjPgT:hover {
  background: #2755fe;
  color: #ffffff;
}
.duMXRNU_aVKMWaexLRDJ .uwAJaRFmaQGim0EB27BW .rq28_I1DvnFNfJZrjPgT:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.duMXRNU_aVKMWaexLRDJ .uwAJaRFmaQGim0EB27BW .HK9KcDy7tUujyWX8Jz7U {
  margin-left: 15px;
  background: #ffffff;
  border: 1px solid #2755fe;
  border-radius: 5px;
  width: 136px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #2755fe;
  margin-right: 24px;
  height: 48px;
}
.duMXRNU_aVKMWaexLRDJ .uwAJaRFmaQGim0EB27BW .HK9KcDy7tUujyWX8Jz7U:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.Fi7anGBLxZzls4dd0Zfn {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  margin-bottom: 17px;
}

.LWrzEXMveuwBwI4MV6nx {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.GOkQKp7a80Ok3166fXi0 {
  font-weight: 500;
  font-size: 16px;
  color: #212529;
}

.SlEZKqiO8qtXQOAzKj_2 {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.PF0Gw_mGqLJtk0lEol7O {
  max-width: 540px;
  position: relative;
}

.TlZyvIUXJbdnTlKhxaUJ {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #c5c5c5;
}

.n3xyQ24kApLBPwKaHqGH {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.tvGcVyZT2exBZHdVjDoX {
  width: 150px;
  height: 120px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tvGcVyZT2exBZHdVjDoX:hover {
  cursor: pointer;
  border: 2px solid #2755fe;
}

.t_JpcyCP4M5xLblMOzwM {
  width: 80%;
  height: 80%;
}

.t_JpcyCP4M5xLblMOzwM img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.IukcfErg1xlRhMRLYdrA {
  border: 2px solid #000000;
}

.KtrMLB8awKJmmmmG_jA5 {
  color: #2755fe;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  transform: translateY(-50%) translateX(50%);
}

.R0DREz778GbQ_JEcY3XK {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 12px;
}

.aK07QKXXD1a215htxATb {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.c6Abn5jJJU9O8O5XtUjc {
  width: 129px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(223, 225, 229, 0.8745098039);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  margin-top: 10px;
}

.c6Abn5jJJU9O8O5XtUjc:hover {
  cursor: pointer;
}

.hPVVYOyLgEb6NsTO5hHj {
  height: 28px;
  width: 28px;
  border-radius: 4px;
  border: 1px solid #c0c0c0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LQ78tEdYZWci9RusKQ6O {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-left: 5px;
}

.DMtY8zbA_WGVqDpX9gH5 {
  position: absolute;
  z-index: 1000;
  bottom: 40px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1215686275);
}

.OnW_XUR56eGzlttzklvA {
  margin: 8px !important;
}

.Y8HNRvSyPCrDOJTDxk77 {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 110px !important;
}

.A2KiHG0umymKF1jjqVi3 {
  position: absolute;
  top: 60px;
  right: 32px;
  width: 106px !important;
}

.riYCEb3HJtyFoqOtJDcg {
  position: absolute;
  top: 60px;
  left: 40px;
  width: 149px !important;
}

.sn1VDAGttDOgm2haxEWr {
  position: absolute;
  top: 440px;
  left: 30px;
  width: 115px !important;
}

.qr9FXLhc6rFGQPcU_3BX::before {
  display: inline;
  content: "⚠ ";
}

.qr9FXLhc6rFGQPcU_3BX {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.x2GwfoaF2bJ5iLa8Wdrg {
  font-size: 20px;
  color: white;
  font-weight: 600;
  background-color: #2755fe;
  text-align: center;
  padding: 10px;
  font-family: Poppins, sans-serif;
  margin-bottom: 0;
}

.e54kLMQRQHx9YnIwFbxm {
  width: 540px;
  border-bottom: 1px solid #dfe1e5;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"communicationCard": `duMXRNU_aVKMWaexLRDJ`,
	"communicationContainer": `O0ZUpoxgq1PYEBZVEgwb`,
	"ContentArea": `vIW_cYAbBtGrLurJkMuM`,
	"previewArea": `SsjuoGmRHtFtU9gtDxzv`,
	"buttonGroup": `uwAJaRFmaQGim0EB27BW`,
	"submitButton": `rq28_I1DvnFNfJZrjPgT`,
	"cancelButton": `HK9KcDy7tUujyWX8Jz7U`,
	"tabInfoText": `Fi7anGBLxZzls4dd0Zfn`,
	"fields": `LWrzEXMveuwBwI4MV6nx`,
	"sectionHeader": `GOkQKp7a80Ok3166fXi0`,
	"sectionSubHeader": `SlEZKqiO8qtXQOAzKj_2`,
	"input": `PF0Gw_mGqLJtk0lEol7O`,
	"brandQuoteCharacterLimit": `TlZyvIUXJbdnTlKhxaUJ`,
	"customRadioAndCheckGroup": `n3xyQ24kApLBPwKaHqGH`,
	"logoContainer": `tvGcVyZT2exBZHdVjDoX`,
	"logo": `t_JpcyCP4M5xLblMOzwM`,
	"active": `IukcfErg1xlRhMRLYdrA`,
	"select": `KtrMLB8awKJmmmmG_jA5`,
	"companyLogos": `R0DREz778GbQ_JEcY3XK`,
	"columns": `aK07QKXXD1a215htxATb`,
	"pickerInput": `c6Abn5jJJU9O8O5XtUjc`,
	"pickerBox": `hPVVYOyLgEb6NsTO5hHj`,
	"pickerText": `LQ78tEdYZWci9RusKQ6O`,
	"colorPicker": `DMtY8zbA_WGVqDpX9gH5`,
	"hr33": `OnW_XUR56eGzlttzklvA`,
	"placeholder1": `Y8HNRvSyPCrDOJTDxk77`,
	"placeholder2": `A2KiHG0umymKF1jjqVi3`,
	"placeholder3": `riYCEb3HJtyFoqOtJDcg`,
	"placeholder4": `sn1VDAGttDOgm2haxEWr`,
	"logoError": `qr9FXLhc6rFGQPcU_3BX`,
	"previewTitle": `x2GwfoaF2bJ5iLa8Wdrg`,
	"liner": `e54kLMQRQHx9YnIwFbxm`
};
export default ___CSS_LOADER_EXPORT___;
