// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NfTLYgU3JfPPjK9I5xn5 {
  min-height: 600px;
  margin-bottom: 30px;
}
.NfTLYgU3JfPPjK9I5xn5 .t4T4pM25pzmRWmrPSYMw {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  margin-left: 20px;
}
.NfTLYgU3JfPPjK9I5xn5 .CAC8Zzjot80cbPDOIQTp {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
}
.NfTLYgU3JfPPjK9I5xn5 .CAC8Zzjot80cbPDOIQTp .x5XNy64TixwQBSzvWoCu {
  margin-top: 5px;
  margin-right: 25px;
}
.NfTLYgU3JfPPjK9I5xn5 .CAC8Zzjot80cbPDOIQTp .RIM0L14EQkevwEpICn1V {
  margin-right: 12px;
  border-color: #6558f5;
  color: #2755fe;
}
.NfTLYgU3JfPPjK9I5xn5 .CAC8Zzjot80cbPDOIQTp .RIM0L14EQkevwEpICn1V:hover {
  background-color: #1f44ca;
  color: white;
}
.NfTLYgU3JfPPjK9I5xn5 .CAC8Zzjot80cbPDOIQTp .y3NIhSVxhQkcoP8_78zq {
  background-color: #2755fe;
}
.NfTLYgU3JfPPjK9I5xn5 .CAC8Zzjot80cbPDOIQTp .y3NIhSVxhQkcoP8_78zq:hover {
  background-color: #1f44ca;
}
.NfTLYgU3JfPPjK9I5xn5 .CAC8Zzjot80cbPDOIQTp .FVML4SiqqXt3zaui0FCj {
  background: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.26);
  margin-right: 4px;
}
.NfTLYgU3JfPPjK9I5xn5 .pAJveEFGfKFShoXYkI4J {
  font-family: Poppins;
  padding-top: 22px;
  padding-left: 2px;
}
.NfTLYgU3JfPPjK9I5xn5 .pAJveEFGfKFShoXYkI4J .thKDQx2sByLrMwzYiLCH {
  margin-left: 12px;
  font-size: 16px;
}
.NfTLYgU3JfPPjK9I5xn5 .pAJveEFGfKFShoXYkI4J .mRPU9I9Ev1bBPuBwSNdm {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.NfTLYgU3JfPPjK9I5xn5 .pAJveEFGfKFShoXYkI4J .mRPU9I9Ev1bBPuBwSNdm .z5eqsarPSSrxgC4iDLE5 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 12px;
}
.NfTLYgU3JfPPjK9I5xn5 .pAJveEFGfKFShoXYkI4J .mRPU9I9Ev1bBPuBwSNdm .z5eqsarPSSrxgC4iDLE5 .N3Mhsuc8pDzPh7LADFBE {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  flex-direction: row;
}
.NfTLYgU3JfPPjK9I5xn5 .pAJveEFGfKFShoXYkI4J .mRPU9I9Ev1bBPuBwSNdm .z5eqsarPSSrxgC4iDLE5 .vq4Fz3KemaULgSuaiorQ {
  background-color: #2755fe;
  border: none;
  margin-top: 50px;
}
.NfTLYgU3JfPPjK9I5xn5 .pAJveEFGfKFShoXYkI4J .mRPU9I9Ev1bBPuBwSNdm .z5eqsarPSSrxgC4iDLE5 .vq4Fz3KemaULgSuaiorQ:hover {
  background-color: #1f44ca;
  cursor: pointer;
}
.NfTLYgU3JfPPjK9I5xn5 .pAJveEFGfKFShoXYkI4J .mRPU9I9Ev1bBPuBwSNdm .z5eqsarPSSrxgC4iDLE5 .vZ0B2XVg43Bj8wxxhwfI {
  margin-top: 12px;
  color: red;
  width: 100%;
  font-size: 80%;
}
.NfTLYgU3JfPPjK9I5xn5 .pAJveEFGfKFShoXYkI4J .mRPU9I9Ev1bBPuBwSNdm .z5eqsarPSSrxgC4iDLE5 .poUVzE_C9YUTQKtjspKw {
  margin-top: 10px;
}
.NfTLYgU3JfPPjK9I5xn5 .pAJveEFGfKFShoXYkI4J .mRPU9I9Ev1bBPuBwSNdm .z5eqsarPSSrxgC4iDLE5 .poUVzE_C9YUTQKtjspKw .N3Mhsuc8pDzPh7LADFBE {
  color: #757e87;
}
.NfTLYgU3JfPPjK9I5xn5 .pAJveEFGfKFShoXYkI4J .LBVmZh9yL0rW2tJRo8Sn {
  border-left: 2px solid #9eadba;
  height: 100%;
  position: absolute;
}

.r8BuCZ2EjwtIN6A46KIa .uAkvRjKhRUHpUEzCvAIk {
  font-weight: 600;
  font-size: 14px;
}
.r8BuCZ2EjwtIN6A46KIa .GNHeB0IKQRycpFTjNxi3 {
  background-color: #c3cfd9;
}
.r8BuCZ2EjwtIN6A46KIa .Uhh9IwXK_Lwo2Fz6A5WG {
  background-color: #96c3ec;
}
.r8BuCZ2EjwtIN6A46KIa .QwihXo_Hjz33ZgMBlkcd {
  color: #2b3a46;
  font-size: 12px;
}

.zNr4kzvJobxBVb5q4hVD {
  border: 1px solid red;
  width: 120px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addCandidateCard": `NfTLYgU3JfPPjK9I5xn5`,
	"heading": `t4T4pM25pzmRWmrPSYMw`,
	"buttons": `CAC8Zzjot80cbPDOIQTp`,
	"footerLabel": `x5XNy64TixwQBSzvWoCu`,
	"backButton": `RIM0L14EQkevwEpICn1V`,
	"nextButton": `y3NIhSVxhQkcoP8_78zq`,
	"disableNextButton": `FVML4SiqqXt3zaui0FCj`,
	"addCandidate": `pAJveEFGfKFShoXYkI4J`,
	"columns": `thKDQx2sByLrMwzYiLCH`,
	"secondheader": `mRPU9I9Ev1bBPuBwSNdm`,
	"filters": `z5eqsarPSSrxgC4iDLE5`,
	"label": `N3Mhsuc8pDzPh7LADFBE`,
	"approveBtn": `vq4Fz3KemaULgSuaiorQ`,
	"errorLabel": `vZ0B2XVg43Bj8wxxhwfI`,
	"filterItems": `poUVzE_C9YUTQKtjspKw`,
	"divider": `LBVmZh9yL0rW2tJRo8Sn`,
	"candidatesTable": `r8BuCZ2EjwtIN6A46KIa`,
	"header": `uAkvRjKhRUHpUEzCvAIk`,
	"noDataHeader": `GNHeB0IKQRycpFTjNxi3`,
	"dataHeader": `Uhh9IwXK_Lwo2Fz6A5WG`,
	"rowData": `QwihXo_Hjz33ZgMBlkcd`,
	"errorField": `zNr4kzvJobxBVb5q4hVD`
};
export default ___CSS_LOADER_EXPORT___;
