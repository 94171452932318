// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xdj4mUKUeqdhG0V6Pbbx {
  font-family: Poppins !important;
  font-style: normal !important;
}
.Xdj4mUKUeqdhG0V6Pbbx .By2K6cCLr4q5IROvlOUA {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 0px;
}
.Xdj4mUKUeqdhG0V6Pbbx .MKo5E_r69fXZCuAJ8XmA {
  margin-top: 12px;
  margin-left: 32px;
}
.Xdj4mUKUeqdhG0V6Pbbx .MKo5E_r69fXZCuAJ8XmA .rSrJuSCNUGQcSrJaEVsg {
  padding-bottom: 6px;
}
.Xdj4mUKUeqdhG0V6Pbbx .MKo5E_r69fXZCuAJ8XmA .rSrJuSCNUGQcSrJaEVsg label {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #595959 !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"idPackageComp": `Xdj4mUKUeqdhG0V6Pbbx`,
	"checkHeading": `By2K6cCLr4q5IROvlOUA`,
	"checkContainer": `MKo5E_r69fXZCuAJ8XmA`,
	"formCheck": `rSrJuSCNUGQcSrJaEVsg`
};
export default ___CSS_LOADER_EXPORT___;
