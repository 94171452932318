// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l99n4amgpSaAzN5yGKir {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.l99n4amgpSaAzN5yGKir .wOhwg1j3a5bt1dRU7l_o {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.l99n4amgpSaAzN5yGKir .V9wP7GkvPeOeNN8SswYm {
  font-family: roboto;
}

.LSe60IvcplXWIzH039wQ {
  border: none;
  padding: 25px 30px 18px !important;
}
.LSe60IvcplXWIzH039wQ .J97PV8cmVKWjFneMuJpV {
  background: #ffffff;
  border: 1px solid #2755fe;
  box-sizing: border-box;
  border-radius: 2px;
  height: 32px;
  color: #2755fe;
}
.LSe60IvcplXWIzH039wQ .nFz0zlDSicOcQEXVYLDo {
  background: #2755fe;
  height: 32px;
  border-radius: 2px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `l99n4amgpSaAzN5yGKir`,
	"title": `wOhwg1j3a5bt1dRU7l_o`,
	"rupee": `V9wP7GkvPeOeNN8SswYm`,
	"modalFooter": `LSe60IvcplXWIzH039wQ`,
	"cancelBtn": `J97PV8cmVKWjFneMuJpV`,
	"confirmBtn": `nFz0zlDSicOcQEXVYLDo`
};
export default ___CSS_LOADER_EXPORT___;
