// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RN735ys_AB0gbaD5XxTg {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  padding: 20px 24px;
  margin: 20px 0;
  font-family: Poppins, sans-serif;
}
.RN735ys_AB0gbaD5XxTg .lqVbhPqekoHtWm7o7qQB {
  display: flex;
  flex-direction: column;
}
.RN735ys_AB0gbaD5XxTg .lqVbhPqekoHtWm7o7qQB .PAppQ8SQSygoTTFw2mPU {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}
.RN735ys_AB0gbaD5XxTg .lqVbhPqekoHtWm7o7qQB .PAppQ8SQSygoTTFw2mPU .jyPCMwcLBEQg_qQyDjcW {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.RN735ys_AB0gbaD5XxTg .lqVbhPqekoHtWm7o7qQB .PAppQ8SQSygoTTFw2mPU .BdXVC_pcEuKlGHa2Z8Z5 {
  border-radius: 30px;
  background: #e4f8e5;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #348a38;
  padding: 8px 12px;
}
.RN735ys_AB0gbaD5XxTg .lqVbhPqekoHtWm7o7qQB .OgKliSuaOyt8WCCn0jGi {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.RN735ys_AB0gbaD5XxTg .lqVbhPqekoHtWm7o7qQB .OgKliSuaOyt8WCCn0jGi .nFzEGkUmr6gC0Df3ivGp {
  color: #666666;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  margin: 0.5rem 0;
}
.RN735ys_AB0gbaD5XxTg .lqVbhPqekoHtWm7o7qQB .aE0UH3pGflmNzcYApU3y {
  width: 1032px;
  height: 182px;
  display: flex;
  margin-top: 18px;
  align-items: center;
  margin-left: 20px;
}
.RN735ys_AB0gbaD5XxTg .lqVbhPqekoHtWm7o7qQB .aE0UH3pGflmNzcYApU3y .AYooYs8BjpRNxYC7mj19 {
  width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.RN735ys_AB0gbaD5XxTg .lqVbhPqekoHtWm7o7qQB .aE0UH3pGflmNzcYApU3y .AYooYs8BjpRNxYC7mj19 .uaDISN3D01wCsNFLvMry {
  color: #333333;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}
.RN735ys_AB0gbaD5XxTg .lqVbhPqekoHtWm7o7qQB .aE0UH3pGflmNzcYApU3y .AYooYs8BjpRNxYC7mj19 .tFVDBOuW2m5n8EaitdyI {
  padding: 12px 25px 12px 8px;
}

.Z5UYxJjVLGHjC7cXlwCp {
  display: flex;
  align-items: center;
  padding: 0 36px;
}

.n3Mx2WPqW82dKooCFnmH {
  flex: 1;
  width: 2px;
  height: 200px;
  border-left: 1px solid #c0c0c0; /* Adjust the style as needed */
}

.pLYq9g4UBapo6Bm4345l {
  top: 50%;
  padding: 0 10px; /* Adjust the padding as needed */
}

.EweQk47EguyQ3fQedqAi {
  font-family: "Roboto";
}

.wujODnAFZpu7OCmAvlJS {
  margin-top: 15px;
}

.hSRFvza9miLJvOYcDKkM {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.bryuttKhy7h3nm1HCCmQ {
  background-color: #2755fe;
  width: 142px;
}

.Oe2UG3dhIWcEqCzr5G3G {
  border: 1px solid #2755fe;
  color: #2755fe;
  width: 142px;
  background-color: white;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoPilotWrapper": `RN735ys_AB0gbaD5XxTg`,
	"containerFlex": `lqVbhPqekoHtWm7o7qQB`,
	"headingContainer": `PAppQ8SQSygoTTFw2mPU`,
	"headingText": `jyPCMwcLBEQg_qQyDjcW`,
	"headingPill": `BdXVC_pcEuKlGHa2Z8Z5`,
	"subtextContainer": `OgKliSuaOyt8WCCn0jGi`,
	"subtext": `nFzEGkUmr6gC0Df3ivGp`,
	"stepContainer": `aE0UH3pGflmNzcYApU3y`,
	"stepBox": `AYooYs8BjpRNxYC7mj19`,
	"stepText": `uaDISN3D01wCsNFLvMry`,
	"stepIcon": `tFVDBOuW2m5n8EaitdyI`,
	"lineContainer": `Z5UYxJjVLGHjC7cXlwCp`,
	"line": `n3Mx2WPqW82dKooCFnmH`,
	"text": `pLYq9g4UBapo6Bm4345l`,
	"rupeeSym": `EweQk47EguyQ3fQedqAi`,
	"marginTop25": `wujODnAFZpu7OCmAvlJS`,
	"footer": `hSRFvza9miLJvOYcDKkM`,
	"setupBtn": `bryuttKhy7h3nm1HCCmQ`,
	"remindLaterBtn": `Oe2UG3dhIWcEqCzr5G3G`
};
export default ___CSS_LOADER_EXPORT___;
