// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RE5k_hxWxrU3_Z1V0Ndi {
  display: flex;
  align-items: center;
  padding: 1rem 24px;
  max-width: 100% !important;
}
.RE5k_hxWxrU3_Z1V0Ndi .ZpxVriaJ75XybJz7INSK {
  padding: 10px 0px;
  display: flex;
}
.RE5k_hxWxrU3_Z1V0Ndi .ZpxVriaJ75XybJz7INSK .GMmD52ZW_PcDenJtXuMd {
  display: flex;
  align-items: center;
  margin-right: 132px;
}
.RE5k_hxWxrU3_Z1V0Ndi .ZpxVriaJ75XybJz7INSK .GMmD52ZW_PcDenJtXuMd .hvuv3XQgXIfcWTLyEppI {
  padding: 0px 10px;
  display: flex;
}

.BXYqIusj0o6K87i2ELfG {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.IHLPjk1og6JslV4bibfw {
  width: 65%;
}

.uxeepybIXV0Mr7XLKvbi {
  width: 24px;
  background-color: white;
  color: #2755fe;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 0 0 2px rgba(128, 128, 128, 0.1);
  cursor: pointer;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.cnC7213ugPF3doyEG5yh {
  color: white;
  background-color: #0a9d3c;
  border-color: #0a9d3c;
  box-shadow: 0 0 0 2px #0a9d3c;
}

.r5gbNMvklNY0nYhdq6x8 .uxeepybIXV0Mr7XLKvbi {
  color: white;
  background-color: #2755fe;
  border-color: #2755fe;
  box-shadow: 0 0 0 2px #2755fe;
}

.GMmD52ZW_PcDenJtXuMd:not(:last-child) > span::after {
  width: 120px;
  position: absolute;
  margin-left: 12px;
  margin-top: 10px;
}

.A4Kf3QzpQT3nPm4n2JJZ:not(:last-child) > span::after {
  content: "";
  border-bottom: 2px solid #22a74f;
  border-bottom-style: solid;
}

.O9z6Go00BCN_FBp0ga45:not(:last-child) > span::after {
  content: "";
  border-bottom: 2px solid grey;
  border-bottom-style: dashed;
}

.N5YZazKpnLKajXgq7gI4 {
  width: 25.5%;
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
  font-size: 16px;
  font-family: Poppins, sans-serif;
}
.N5YZazKpnLKajXgq7gI4:hover {
  background: #ffffff;
  color: #2755fe;
  border: none;
}
.N5YZazKpnLKajXgq7gI4:active {
  background: #ffffff !important;
  color: #2755fe !important;
  border: none !important;
}
.N5YZazKpnLKajXgq7gI4:focus {
  box-shadow: none !important;
}

.iGTWqoHtHqBgZSyXzecQ {
  border-radius: 2px;
  width: 25.5%;
  color: #fff;
  background-color: #2755fe;
  border-color: #2755fe;
  cursor: pointer;
}
.iGTWqoHtHqBgZSyXzecQ:hover {
  background-color: #1d41c5;
  border-color: #1d41c5;
}

.O0XFbwj9c2y4PKv9lWYQ {
  border-radius: 2px;
  width: 25.5%;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}

.wEc58vpKFubGUqbikLBQ {
  display: none;
}

.vbf8epR2SogIa7lPEywy {
  background: #2755fe;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  width: 8.5rem;
}

.pt90y4f2AfG1yHan77ED {
  margin-right: 10px;
  border: #2755fe 1px solid;
  border-radius: 2px;
  color: #2755fe;
  background-color: #ffffff;
}

.pt90y4f2AfG1yHan77ED:hover,
.pt90y4f2AfG1yHan77ED:focus,
.pt90y4f2AfG1yHan77ED:disabled {
  color: #214adb;
  background-color: #ffffff;
  border: #214adb 1px solid;
}

.vbf8epR2SogIa7lPEywy:disabled {
  background: #2755fe;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepperRow": `RE5k_hxWxrU3_Z1V0Ndi`,
	"stepWrapper": `ZpxVriaJ75XybJz7INSK`,
	"stepBlock": `GMmD52ZW_PcDenJtXuMd`,
	"circleWrapper": `hvuv3XQgXIfcWTLyEppI`,
	"stepperRight": `BXYqIusj0o6K87i2ELfG`,
	"stepperLeft": `IHLPjk1og6JslV4bibfw`,
	"circle": `uxeepybIXV0Mr7XLKvbi`,
	"circleCovered": `cnC7213ugPF3doyEG5yh`,
	"selected": `r5gbNMvklNY0nYhdq6x8`,
	"stepBlockGreen": `A4Kf3QzpQT3nPm4n2JJZ`,
	"stepBlockGrey": `O9z6Go00BCN_FBp0ga45`,
	"cancelBtn": `N5YZazKpnLKajXgq7gI4`,
	"activeBtn": `iGTWqoHtHqBgZSyXzecQ`,
	"disabledBtn": `O0XFbwj9c2y4PKv9lWYQ`,
	"firstStep": `wEc58vpKFubGUqbikLBQ`,
	"payBtn": `vbf8epR2SogIa7lPEywy`,
	"requestPaymentBtn": `pt90y4f2AfG1yHan77ED`
};
export default ___CSS_LOADER_EXPORT___;
