// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RfuryAkHoitcTogP_MLg {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 17px;
}
.RfuryAkHoitcTogP_MLg .nYwbYXURyXgEH_vvDXP4 {
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  background-color: #fff;
  padding: 25px 15px 25px 26px;
  width: 187px;
  border-radius: 4px;
  cursor: pointer;
}
.RfuryAkHoitcTogP_MLg .nYwbYXURyXgEH_vvDXP4 .mAxqaFyxmIImjnZZP4XQ {
  font-family: Poppins, sans-serif;
  font-weight: 500;
}
.RfuryAkHoitcTogP_MLg .nYwbYXURyXgEH_vvDXP4 .mAxqaFyxmIImjnZZP4XQ h4 {
  font-size: 24px;
  line-height: 36px;
  color: #646c9a;
  margin: 0;
  margin-bottom: 4px;
}
.RfuryAkHoitcTogP_MLg .nYwbYXURyXgEH_vvDXP4 .mAxqaFyxmIImjnZZP4XQ p {
  font-size: 14px;
  line-height: 21px;
  color: #a7abc3;
  margin: 0;
}

.wZZHGlZpWULU1ddVKD4P {
  display: flex;
  justify-content: space-between;
  margin: 55px 0px 35px 0px;
}
.wZZHGlZpWULU1ddVKD4P .DPaNRB2wYNweXoEOxJNb {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.wZZHGlZpWULU1ddVKD4P button .RKqMIEQqYiO2N7ArincN {
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 21px;
  color: #ffffff;
  width: 164px;
  height: 45px;
  text-align: center;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardCardMain": `RfuryAkHoitcTogP_MLg`,
	"dashboardCardParent": `nYwbYXURyXgEH_vvDXP4`,
	"dashboardCard": `mAxqaFyxmIImjnZZP4XQ`,
	"subHeading": `wZZHGlZpWULU1ddVKD4P`,
	"header": `DPaNRB2wYNweXoEOxJNb`,
	"addCandidateBtn": `RKqMIEQqYiO2N7ArincN`
};
export default ___CSS_LOADER_EXPORT___;
