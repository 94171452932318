// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rc8LmqRahv_y_Kfy1pNZ {
  max-width: 771px;
  margin-top: -200px;
  margin-bottom: 170px;
}
.rc8LmqRahv_y_Kfy1pNZ .WvgvxtVWBgwMoPEZNJff {
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
}
.rc8LmqRahv_y_Kfy1pNZ .VPHSuQdwfaY0RlqO3xbc ol {
  background-color: #fff;
  font-size: 14px;
}
.rc8LmqRahv_y_Kfy1pNZ .VPHSuQdwfaY0RlqO3xbc ol .N_TYs3cG_pXHLUw2GhQP a {
  color: #8c8c8c;
  font-weight: 400;
}
.rc8LmqRahv_y_Kfy1pNZ .VPHSuQdwfaY0RlqO3xbc ol .N_TYs3cG_pXHLUw2GhQP .lr_Bldpq_Uazjh7E10su {
  color: #595959;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu {
  padding: 0 24px 24px 24px;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .JMmuAvu7u7cErZO0BqNP {
  font-size: 18px;
  margin-bottom: 16px;
  color: #333333;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .Pjz1Yc0sFFGgxZ7qfxFk {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .BL4tQ10aTfBCYR4HH8Gv {
  color: #333333;
  font-size: 14px;
  margin-top: 24px;
  margin-bottom: 14px;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .OauR2SSpSIOra4KeFdq1 {
  margin-top: 24px;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc {
  margin: unset;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc .hM1Ffb8fvmnR2Pd55QGf {
  border-radius: unset;
  background-color: #fff;
  border-color: #d9d9d9;
  cursor: pointer;
  min-width: 75px;
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 40px;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc .hM1Ffb8fvmnR2Pd55QGf:active,
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc :focus {
  border: 1px solid #2755fe;
  color: #2755fe !important;
  box-shadow: none !important;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc .hM1Ffb8fvmnR2Pd55QGf:hover {
  z-index: unset;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc .MigAvmRFQUlZ0mPT6Bk9 {
  margin-bottom: 6px;
  border-radius: unset;
  background-color: #fff;
  border-color: #d9d9d9;
  border-left: none;
  cursor: pointer;
  min-width: 75px;
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 40px;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc .MigAvmRFQUlZ0mPT6Bk9:hover {
  color: #333333;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc .ILEyQ_9iEOYJJD_DLnmk {
  margin-bottom: 6px;
  height: 40px;
  animation: wRqEltMHeszDBCDsuwE3 0.75s none 0s 1;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc .ILEyQ_9iEOYJJD_DLnmk input {
  height: unset;
  border-left: none;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc input:focus {
  outline: 1px solid #2755fe;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc input:focus .IXO4OI5pzFiLyBXuZQEK {
  outline: 1px solid #2755fe;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .reEibdJoehjwqoVGHoAc .XUFycuX6ls5Da13J3_Zg span {
  background-color: #fff;
  border-radius: 0;
  font-family: roboto;
  border-right: none;
  font-weight: 500;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .h8FtNDAPTlQPOtqF7CRJ {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #f5222d;
  margin: 0;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .h8FtNDAPTlQPOtqF7CRJ .XUFycuX6ls5Da13J3_Zg {
  font-family: Helvetica;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .rnED6_57edyo0qFbTBjN {
  margin-top: 41px;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .Aw6Chm_VsZzsvJ20qYpU {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  padding: 6px 0;
  max-width: 370px;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .Aw6Chm_VsZzsvJ20qYpU .TP3z5tOmdrOxedewm27t {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 14px;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .xRiOX8KXWCcNxOJSpaRS {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 0;
  max-width: 370px;
  margin-bottom: 16px;
}
.rc8LmqRahv_y_Kfy1pNZ .eQDaDB7LX3BfbNI4ppVu .LgJcRAHt7dP7LW7F3SuC {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 0;
  margin-bottom: 48px;
  border-top: 1px solid rgba(51, 51, 51, 0.1);
  max-width: 370px;
}
.rc8LmqRahv_y_Kfy1pNZ .MA7N6uZjquNJDOl9Q55Y {
  margin-top: 16px;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
}
.rc8LmqRahv_y_Kfy1pNZ .MA7N6uZjquNJDOl9Q55Y .k4LxJ0VJC0TDCGO8wKRV .I9JCuTCpYggLobZtIMQL {
  color: #333333;
  font-weight: 600;
  font-size: 16px;
}
.rc8LmqRahv_y_Kfy1pNZ .MA7N6uZjquNJDOl9Q55Y .k4LxJ0VJC0TDCGO8wKRV ul li {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}
.rc8LmqRahv_y_Kfy1pNZ .XUFycuX6ls5Da13J3_Zg {
  font-family: roboto;
}
.rc8LmqRahv_y_Kfy1pNZ .GWQ8iGzQw_3zuhiawQ5a .KZMZLLUvSSKDqXBBwK28 {
  width: 100%;
  background: #2755fe;
  border-radius: 2px;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rc8LmqRahv_y_Kfy1pNZ .GWQ8iGzQw_3zuhiawQ5a .KZMZLLUvSSKDqXBBwK28 .XUFycuX6ls5Da13J3_Zg {
  font-family: roboto;
  margin-left: 6px;
}

@keyframes wRqEltMHeszDBCDsuwE3 {
  0% {
    opacity: 0;
    width: 50px;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `rc8LmqRahv_y_Kfy1pNZ`,
	"card": `WvgvxtVWBgwMoPEZNJff`,
	"breadcrumb": `VPHSuQdwfaY0RlqO3xbc`,
	"breadcrumb_item": `N_TYs3cG_pXHLUw2GhQP`,
	"active": `lr_Bldpq_Uazjh7E10su`,
	"body": `eQDaDB7LX3BfbNI4ppVu`,
	"title": `JMmuAvu7u7cErZO0BqNP`,
	"balance": `Pjz1Yc0sFFGgxZ7qfxFk`,
	"amount": `BL4tQ10aTfBCYR4HH8Gv`,
	"summary": `OauR2SSpSIOra4KeFdq1`,
	"radioButtonContainer": `reEibdJoehjwqoVGHoAc`,
	"btn": `hM1Ffb8fvmnR2Pd55QGf`,
	"other": `MigAvmRFQUlZ0mPT6Bk9`,
	"input": `ILEyQ_9iEOYJJD_DLnmk`,
	"animateSearchTabView": `wRqEltMHeszDBCDsuwE3`,
	"input-group-prepend": `IXO4OI5pzFiLyBXuZQEK`,
	"rupee": `XUFycuX6ls5Da13J3_Zg`,
	"error": `h8FtNDAPTlQPOtqF7CRJ`,
	"continue": `rnED6_57edyo0qFbTBjN`,
	"row": `Aw6Chm_VsZzsvJ20qYpU`,
	"tdsOptions": `TP3z5tOmdrOxedewm27t`,
	"tax": `xRiOX8KXWCcNxOJSpaRS`,
	"total": `LgJcRAHt7dP7LW7F3SuC`,
	"note": `MA7N6uZjquNJDOl9Q55Y`,
	"card_body": `k4LxJ0VJC0TDCGO8wKRV`,
	"h5": `I9JCuTCpYggLobZtIMQL`,
	"addBtnContainer": `GWQ8iGzQw_3zuhiawQ5a`,
	"payBtn": `KZMZLLUvSSKDqXBBwK28`
};
export default ___CSS_LOADER_EXPORT___;
