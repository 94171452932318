// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gczx6TBai3rr3zouB5oq {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #000000;
}

.x02rgcSzlcQypsRC12Io {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
  margin-bottom: -10px;
}

.cvEwWI1uCuFhFmS906F9 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #999999;
}
.cvEwWI1uCuFhFmS906F9 .I64n1gndXLn4vV1yoCkn {
  color: #000000;
}

.VWS9ez5G78eD9msQsZUd {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #262626;
}

.CM54U7haRlXWpKi8wiLV {
  font-family: Poppins, sans-serif;
  font-style: normal;
}
.CM54U7haRlXWpKi8wiLV .yYAz2nurLj2N09AIWEGl {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  align-items: center;
  color: #000000;
}
.CM54U7haRlXWpKi8wiLV .sO7X6fGOrvXZKQuuvDTA {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: #595959;
}
.CM54U7haRlXWpKi8wiLV .ZRVVm2Gy0VKduYOCLF4b {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #333333;
}
.CM54U7haRlXWpKi8wiLV label {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  margin-bottom: 6px;
}
.CM54U7haRlXWpKi8wiLV label span {
  color: red;
}
.CM54U7haRlXWpKi8wiLV .iBpKeQHZ1ooyHchVS6kf {
  justify-content: space-between !important;
}
.CM54U7haRlXWpKi8wiLV .s4xYVqmNBK9SSuSiI43_ {
  justify-content: flex-end !important;
}
@media screen and (max-width: 601px) {
  .CM54U7haRlXWpKi8wiLV .s4xYVqmNBK9SSuSiI43_ {
    position: fixed;
    left: 0;
    margin: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
  }
  .CM54U7haRlXWpKi8wiLV .s4xYVqmNBK9SSuSiI43_ button {
    height: 40px;
    margin: 0 !important;
  }
  .CM54U7haRlXWpKi8wiLV .s4xYVqmNBK9SSuSiI43_ .sfjZnyoWMzLQwtdqjnaI {
    flex: 1;
  }
  .CM54U7haRlXWpKi8wiLV .s4xYVqmNBK9SSuSiI43_ .vvqCU3kw0NMF8EpSZZIp {
    flex: 1;
  }
}
.CM54U7haRlXWpKi8wiLV .OR0HPR96JHr6dkz65P4w {
  padding-top: 27px;
}
.CM54U7haRlXWpKi8wiLV .bB4rx1wCVUdYLk5xpRpk {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
}
.CM54U7haRlXWpKi8wiLV .ENgjQXVXsrjemdfU1rf9 input {
  border: 1px solid #dc3545 !important;
  margin-bottom: 0 !important;
}
.CM54U7haRlXWpKi8wiLV .YFkxGaox5fOAsFile3tQ {
  background: #ffffff;
  border: 1px solid #ebedf2;
  border-radius: 4px;
  box-sizing: border-box;
  height: 38px;
  text-align: center;
  padding: 7px;
  color: #595959;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.CM54U7haRlXWpKi8wiLV .E8RO0bdl0w34WM8f08Og {
  align-content: right;
  padding: 5px;
  float: right;
}
.CM54U7haRlXWpKi8wiLV .Y4JKxHRZ_QBHKGOXhe8U {
  flex: 0 0 40%;
  max-width: 40%;
}

.ic1CPzzJhvv5Jx_ndkQ2 {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
  margin-bottom: 30px !important;
}

.o8pzXTwZVYqNa4Nla703 {
  margin-top: -11px !important;
  margin-bottom: 30px !important;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #fa8c16;
}

.x5SU5MLqDasTIn9YnhKs {
  margin-top: -25px !important;
  margin-bottom: 30px !important;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #fa8c16;
}

.VXYx_nDazkZFFNKs7Yve {
  padding-bottom: 40px !important;
}
@media screen and (max-width: 601px) {
  .VXYx_nDazkZFFNKs7Yve {
    padding-bottom: 65px !important;
  }
}

@media screen and (max-width: 993px) {
  .sBlreQlDbH0ly9glHW9P {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"employmentModalTitle": `gczx6TBai3rr3zouB5oq`,
	"employmentModalSubTitle": `x02rgcSzlcQypsRC12Io`,
	"employmentModalInfo": `cvEwWI1uCuFhFmS906F9`,
	"bold": `I64n1gndXLn4vV1yoCkn`,
	"employmentModalStepTitle": `VWS9ez5G78eD9msQsZUd`,
	"hrDetailsStepForm": `CM54U7haRlXWpKi8wiLV`,
	"employmentModalTypeTitle": `yYAz2nurLj2N09AIWEGl`,
	"refTittle": `sO7X6fGOrvXZKQuuvDTA`,
	"refLabel": `ZRVVm2Gy0VKduYOCLF4b`,
	"form-row": `iBpKeQHZ1ooyHchVS6kf`,
	"employmentSubmitBtn": `s4xYVqmNBK9SSuSiI43_`,
	"activeBtn": `sfjZnyoWMzLQwtdqjnaI`,
	"disabledBtn": `vvqCU3kw0NMF8EpSZZIp`,
	"phoneNumber": `OR0HPR96JHr6dkz65P4w`,
	"emailErrorMsg": `bB4rx1wCVUdYLk5xpRpk`,
	"emailError": `ENgjQXVXsrjemdfU1rf9`,
	"mobileCountryCodes": `YFkxGaox5fOAsFile3tQ`,
	"countryCodeArrow": `E8RO0bdl0w34WM8f08Og`,
	"mobCountryCodeDropDown": `Y4JKxHRZ_QBHKGOXhe8U`,
	"errorMsg": `ic1CPzzJhvv5Jx_ndkQ2`,
	"subLabel": `o8pzXTwZVYqNa4Nla703`,
	"phoneSubLabel": `x5SU5MLqDasTIn9YnhKs`,
	"modalBody": `VXYx_nDazkZFFNKs7Yve`,
	"countryCodeDropDown": `sBlreQlDbH0ly9glHW9P`
};
export default ___CSS_LOADER_EXPORT___;
