// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QHMLIi0jD0hWXH0tvttY {
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .QHMLIi0jD0hWXH0tvttY {
    width: 1200px;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"packagesWrapper": `QHMLIi0jD0hWXH0tvttY`
};
export default ___CSS_LOADER_EXPORT___;
