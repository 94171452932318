// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A63bHzRXA5nI0tAGqa8s {
  padding: 32px;
  box-shadow: 0px 0px 16px rgba(0, 45, 211, 0.25);
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
@media screen and (max-width: 601px) {
  .A63bHzRXA5nI0tAGqa8s {
    padding: 16px 16px 65px 16px;
  }
}
.A63bHzRXA5nI0tAGqa8s .Aw2KyqO3WC4kKcUzCjyg select {
  background: #ffffff;
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  height: 38px;
  width: 200px;
}
.A63bHzRXA5nI0tAGqa8s .e8UjgiEvlrPHKR6oqAAc {
  color: red;
}
.A63bHzRXA5nI0tAGqa8s .Z9TvezO0g3XSzde9O0MH {
  width: 223px;
  height: 65px !important;
}
.A63bHzRXA5nI0tAGqa8s .hbdO04Ox1tpI4WSgdQ4c {
  padding: 0;
}
.A63bHzRXA5nI0tAGqa8s .hbdO04Ox1tpI4WSgdQ4c .dtkk9qocGt_EpHuu8yR4 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.A63bHzRXA5nI0tAGqa8s .hbdO04Ox1tpI4WSgdQ4c .dtkk9qocGt_EpHuu8yR4 a {
  color: #2755fe;
  font-weight: 600;
  text-decoration: none;
}
.A63bHzRXA5nI0tAGqa8s .Q8LKR50cStsy58jVYLgv {
  color: #262626;
  font-size: 24px;
  line-height: 32px;
}
@media screen and (max-width: 601px) {
  .A63bHzRXA5nI0tAGqa8s .Q8LKR50cStsy58jVYLgv {
    font-size: 20px;
  }
}
.A63bHzRXA5nI0tAGqa8s .GmGH7AvmcdGFPiIciZUg,
.A63bHzRXA5nI0tAGqa8s .tvnuYVwgi_6l9CrC99If {
  display: flex;
  align-items: center;
}
.A63bHzRXA5nI0tAGqa8s .tvnuYVwgi_6l9CrC99If {
  margin-bottom: 10px;
}
.A63bHzRXA5nI0tAGqa8s .ff6As2kaevmdll3meJod {
  margin-right: 20px;
  color: #8c8c8c;
}
.A63bHzRXA5nI0tAGqa8s .luWZqU0ulXeMXm93gKam {
  margin-left: 20px;
  color: #8c8c8c;
}
.A63bHzRXA5nI0tAGqa8s .CNKoB3Q0FJ4m7srZ0_vD {
  margin-top: 40px;
}
.A63bHzRXA5nI0tAGqa8s .CNKoB3Q0FJ4m7srZ0_vD span {
  font-weight: 600;
  color: #262626;
  font-size: 16px;
}
.A63bHzRXA5nI0tAGqa8s .CNKoB3Q0FJ4m7srZ0_vD p {
  color: #999999;
}
.A63bHzRXA5nI0tAGqa8s .PAhH4moA6LVHMYsyyfHs {
  margin-top: 50px;
}
.A63bHzRXA5nI0tAGqa8s .PAhH4moA6LVHMYsyyfHs p {
  color: #595959;
}
.A63bHzRXA5nI0tAGqa8s .PAhH4moA6LVHMYsyyfHs a {
  color: #2755fe;
  text-decoration: none;
}
.A63bHzRXA5nI0tAGqa8s .Cc9kbmY_Fqg02PEDrF4H {
  display: flex;
  width: 100%;
  margin: 0;
}
.A63bHzRXA5nI0tAGqa8s .Cc9kbmY_Fqg02PEDrF4H .sY2SWvuWAytmrB0yzRzT {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.A63bHzRXA5nI0tAGqa8s .Cc9kbmY_Fqg02PEDrF4H .sY2SWvuWAytmrB0yzRzT p {
  margin-bottom: 5px;
}
.A63bHzRXA5nI0tAGqa8s .Cc9kbmY_Fqg02PEDrF4H .sY2SWvuWAytmrB0yzRzT a {
  color: #2755fe;
  font-weight: 600;
  text-decoration: none;
}
.A63bHzRXA5nI0tAGqa8s .Cc9kbmY_Fqg02PEDrF4H .ws72dbrJOCd_RVGFV82d {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 0;
  align-self: center;
  max-width: fit-content;
  padding: 0;
}
.A63bHzRXA5nI0tAGqa8s form {
  margin-top: 25px;
}
.A63bHzRXA5nI0tAGqa8s form label {
  font-weight: 600;
  line-height: 21px;
  color: #333333;
  margin-bottom: 6px;
}
.A63bHzRXA5nI0tAGqa8s form label span {
  color: red;
}
.A63bHzRXA5nI0tAGqa8s form .NOaccrDlRg1JPtQn1SF_ {
  justify-content: space-between !important;
}
.A63bHzRXA5nI0tAGqa8s form .G_gCKEwKKKtQZRua82YS {
  margin-bottom: 0 !important;
}
.A63bHzRXA5nI0tAGqa8s form .G_gCKEwKKKtQZRua82YS input {
  border: 1px solid #f5222d !important;
}
.A63bHzRXA5nI0tAGqa8s form .u7NybqP6_fn8uq6hZ_2f {
  font-size: 14px;
  font-weight: 600;
  color: #f5222d;
  margin-bottom: 32px;
}
.A63bHzRXA5nI0tAGqa8s form .n0mcqmiWqs9JPdpIaoLO {
  margin-top: 40px !important;
}
@media only screen and (min-width: 600px) {
  .A63bHzRXA5nI0tAGqa8s form .n0mcqmiWqs9JPdpIaoLO {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 601px) {
  .A63bHzRXA5nI0tAGqa8s form .n0mcqmiWqs9JPdpIaoLO {
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .A63bHzRXA5nI0tAGqa8s form .n0mcqmiWqs9JPdpIaoLO button {
    height: 40px;
  }
}
.A63bHzRXA5nI0tAGqa8s form .n0mcqmiWqs9JPdpIaoLO button {
  border-radius: 2px;
  padding: 0.3em 1.4rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (max-width: 601px) {
  .A63bHzRXA5nI0tAGqa8s form .n0mcqmiWqs9JPdpIaoLO button {
    flex: auto;
  }
}
.A63bHzRXA5nI0tAGqa8s form .n0mcqmiWqs9JPdpIaoLO .tmnkfDVj0xmm4YfE2DrI {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.A63bHzRXA5nI0tAGqa8s form .n0mcqmiWqs9JPdpIaoLO .KItgxmXXBlvSIjQQlhQc {
  background: #f5f5f5;
  color: #bfbfbf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}

.WSveiml_PETxcB_XfOEg {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"consentDetailsCard": `A63bHzRXA5nI0tAGqa8s`,
	"dropdown": `Aw2KyqO3WC4kKcUzCjyg`,
	"redColor": `e8UjgiEvlrPHKR6oqAAc`,
	"citySelectorParentDiv": `Z9TvezO0g3XSzde9O0MH`,
	"consentDetailsCardBody": `hbdO04Ox1tpI4WSgdQ4c`,
	"consentDoneContainer": `dtkk9qocGt_EpHuu8yR4`,
	"consentDetailHeading": `Q8LKR50cStsy58jVYLgv`,
	"consentDetailProgressContainer": `GmGH7AvmcdGFPiIciZUg`,
	"consentDetailProgressContainerMobile": `tvnuYVwgi_6l9CrC99If`,
	"stepText": `ff6As2kaevmdll3meJod`,
	"stepTextMobile": `luWZqU0ulXeMXm93gKam`,
	"almostDoneText": `CNKoB3Q0FJ4m7srZ0_vD`,
	"agreeText": `PAhH4moA6LVHMYsyyfHs`,
	"consentFormView": `Cc9kbmY_Fqg02PEDrF4H`,
	"textContainer": `sY2SWvuWAytmrB0yzRzT`,
	"imageContainer": `ws72dbrJOCd_RVGFV82d`,
	"form-row": `NOaccrDlRg1JPtQn1SF_`,
	"nameError": `G_gCKEwKKKtQZRua82YS`,
	"emailErrorMsg": `u7NybqP6_fn8uq6hZ_2f`,
	"consentDetailSubmitBtn": `n0mcqmiWqs9JPdpIaoLO`,
	"activeBtn": `tmnkfDVj0xmm4YfE2DrI`,
	"disabledBtn": `KItgxmXXBlvSIjQQlhQc`,
	"consentSubText": `WSveiml_PETxcB_XfOEg`
};
export default ___CSS_LOADER_EXPORT___;
