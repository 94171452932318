// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BHCU_CCeM43gDqyHQmKE {
  padding: 24px;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
}
.BHCU_CCeM43gDqyHQmKE .fSIIagA65X90AJ68XMbe {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0;
}
.BHCU_CCeM43gDqyHQmKE .hl4Ore3o0Nyb7epAC8kM {
  color: #666666;
}
.BHCU_CCeM43gDqyHQmKE .lCP6Ob6rbrbqBAN0ZkK7 {
  color: #faad14;
}
.BHCU_CCeM43gDqyHQmKE h5 {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333333;
}
.BHCU_CCeM43gDqyHQmKE .XODu2HgyzfW8w8ma0eW5 {
  font-family: roboto;
}
.BHCU_CCeM43gDqyHQmKE .vRMqn1zEstLZQDXhqHbV {
  margin-top: 50px;
}
.BHCU_CCeM43gDqyHQmKE .vRMqn1zEstLZQDXhqHbV .hFzfZLulbYAtvnULP71H {
  width: 100%;
  background: #2755fe;
  border-radius: 2px;
  color: #ffffff;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"availableBalanceContainer": `BHCU_CCeM43gDqyHQmKE`,
	"availableBalanceText": `fSIIagA65X90AJ68XMbe`,
	"positiveBalance": `hl4Ore3o0Nyb7epAC8kM`,
	"negativeBalance": `lCP6Ob6rbrbqBAN0ZkK7`,
	"rupee": `XODu2HgyzfW8w8ma0eW5`,
	"actionContainer": `vRMqn1zEstLZQDXhqHbV`,
	"addCreditBtn": `hFzfZLulbYAtvnULP71H`
};
export default ___CSS_LOADER_EXPORT___;
