// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N7XjStwTncvrs83XPklG {
  padding: 29px 0;
  padding-bottom: 7px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.N7XjStwTncvrs83XPklG .xnDM2ejiU6I9mnWTicR4 {
  position: relative;
}
.N7XjStwTncvrs83XPklG .xnDM2ejiU6I9mnWTicR4 .o5ssUBPSIm9gCZ7o3Bs1 {
  position: absolute;
  top: -6px;
  left: 11px;
  font-size: 12px;
}
.N7XjStwTncvrs83XPklG .GBB2Z3sua7HCW2fSAycj {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.N7XjStwTncvrs83XPklG .OJ2p1i7DpvR_vtrTPU_N {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.N7XjStwTncvrs83XPklG .OJ2p1i7DpvR_vtrTPU_N .XeS3B3b_Qhhyxxmrm5vg {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}
.N7XjStwTncvrs83XPklG .OJ2p1i7DpvR_vtrTPU_N .mCCC918PQjoMpyouffRp {
  color: #666666;
  font-family: roboto;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"booleanContainer": `N7XjStwTncvrs83XPklG`,
	"iconContainer": `xnDM2ejiU6I9mnWTicR4`,
	"plusIcon": `o5ssUBPSIm9gCZ7o3Bs1`,
	"heading": `GBB2Z3sua7HCW2fSAycj`,
	"checkboxFormGrp": `OJ2p1i7DpvR_vtrTPU_N`,
	"label": `XeS3B3b_Qhhyxxmrm5vg`,
	"rupee": `mCCC918PQjoMpyouffRp`
};
export default ___CSS_LOADER_EXPORT___;
