// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cn1DYBFi4eee_vD5o1XQ {
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
}

.NDwVbb0zA2s7sJJXnM2q {
  display: flex;
  align-items: flex-start;
}

.eLhGCO5B1A5cKjEGJMaq {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.Ru2_t3Z7lJlJFwjMt066 {
  margin-left: 0.5rem;
  border-radius: 1rem;
  font-size: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5rem;
}
.Ru2_t3Z7lJlJFwjMt066.FIXHt0dIOEXQjG73YmF2 {
  color: #1f44ca;
  border: 1px solid #366cf3;
}
.Ru2_t3Z7lJlJFwjMt066.kMSnE51UJ89xqoEw4Hfg {
  color: #7f8a93;
  border: 1px solid #9eadba;
}

.L2FruRIBrYEXsMEhNFQp {
  cursor: pointer;
}

.oeEnGcWaPrzVV_dzsl29 {
  text-decoration: none;
  color: #333333 !important;
  cursor: pointer;
}

.oeEnGcWaPrzVV_dzsl29:hover {
  color: #2755fe !important;
}

.jHIcKbZv1f5xod01vugQ {
  background: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 0.2rem;
}

.X_eTrHp4cCfX74dMJBb5 {
  color: #1bae9f !important;
}

.tuQAyDZJugaC869n4vOk {
  color: #d3455b !important;
}

.xSw99UQi8LoSYWBzWUBJ {
  width: 100%;
}
.xSw99UQi8LoSYWBzWUBJ .DQqWyJJQG1jMnN3mTo3i {
  margin-top: -190px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U {
  background: #ffffff;
  border-radius: 4px;
  font-family: Poppins, sans-serif;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .HUeUFXCSqd3a_CEYiXfQ {
  display: flex;
  justify-content: space-between;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .HUeUFXCSqd3a_CEYiXfQ .ZiwlfwnpsjnhB2ObNCrb {
  padding-top: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .HUeUFXCSqd3a_CEYiXfQ .hk7LrTnMOuE6N41ocJ2O {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 174px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  height: 40px;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 {
  border: 0px;
  padding: 0 24px 24px 24px;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q {
  margin: auto;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 8px;
  overflow: hidden;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q thead {
  background-color: #f2f8ff;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q thead tr th {
  border: 0px;
  height: 57px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q thead tr .cITeqf2ftAZn31Gwp2Si {
  text-align: center;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q thead tr .XaKmXYBqxwdxmXI5hikA {
  text-align: right;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q thead tr .Iay0zTnwI4jJAffZt1S8 {
  text-align: left;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody .XaKmXYBqxwdxmXI5hikA {
  text-align: right;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody .Iay0zTnwI4jJAffZt1S8 {
  text-align: left;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody tr {
  cursor: default;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody tr td {
  border: 0px;
  font-weight: 500;
  font-size: 14px;
  height: 57px;
  line-height: 21px;
  color: #333333;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody tr .cITeqf2ftAZn31Gwp2Si {
  text-align: center;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody tr .XaKmXYBqxwdxmXI5hikA {
  text-align: right;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody tr .Iay0zTnwI4jJAffZt1S8 {
  text-align: left;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody tr:hover {
  background-color: rgba(54, 108, 243, 0.1254901961);
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody .a4UELEuXtr1LtaQFiF9e:hover {
  color: #366cf3;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody .a4UELEuXtr1LtaQFiF9e:hover p {
  cursor: pointer;
  width: fit-content;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q tbody .x44Jl3cqD_p6PHR3U894 {
  color: #999999;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q .dYhwJeWIA9kle5ePduqR {
  cursor: pointer;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q .Kmen3D7e74zmcLYUkPgX {
  text-align: center;
  padding-top: 64px;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q .Kmen3D7e74zmcLYUkPgX .GQskLmZk5WIndidXulT9 {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .uGi_51ztZuN3HmVBbi8q .cn1DYBFi4eee_vD5o1XQ {
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .MgmrX6MOEJ_nr3EsIMFR {
  flex-wrap: unset;
  padding: 24px 24px 15px;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .MgmrX6MOEJ_nr3EsIMFR .eeeopEWDwTVWHxRI1Evz {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.xSw99UQi8LoSYWBzWUBJ .F6Y1QgYpqQrDq3Ptc9_U .w9mLU8DWdorN7DnpOw95 .MgmrX6MOEJ_nr3EsIMFR .eeeopEWDwTVWHxRI1Evz .asucNTRJHstCNDXS7lJw {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px;
  -webkit-appearance: menulist;
}

.A2sls7S8ZOHxyhSfWoDy {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  margin-bottom: 17px;
}
.A2sls7S8ZOHxyhSfWoDy a {
  color: #2755fe;
  display: inline-block;
  margin-left: 5px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amount": `cn1DYBFi4eee_vD5o1XQ`,
	"txnContainer": `NDwVbb0zA2s7sJJXnM2q`,
	"statusContainer": `eLhGCO5B1A5cKjEGJMaq`,
	"txnPill": `Ru2_t3Z7lJlJFwjMt066`,
	"pendingTxn": `FIXHt0dIOEXQjG73YmF2`,
	"otherTxn": `kMSnE51UJ89xqoEw4Hfg`,
	"cursorPointer": `L2FruRIBrYEXsMEhNFQp`,
	"txnLink": `oeEnGcWaPrzVV_dzsl29`,
	"popOverMenu": `jHIcKbZv1f5xod01vugQ`,
	"greenLink": `X_eTrHp4cCfX74dMJBb5`,
	"redLink": `tuQAyDZJugaC869n4vOk`,
	"usageLogsWrapper": `xSw99UQi8LoSYWBzWUBJ`,
	"heading": `DQqWyJJQG1jMnN3mTo3i`,
	"usageLogsCard": `F6Y1QgYpqQrDq3Ptc9_U`,
	"purchaseHistoryCardHeader": `HUeUFXCSqd3a_CEYiXfQ`,
	"headingContent": `ZiwlfwnpsjnhB2ObNCrb`,
	"dropdown": `hk7LrTnMOuE6N41ocJ2O`,
	"tableCard": `w9mLU8DWdorN7DnpOw95`,
	"usageLogsTable": `uGi_51ztZuN3HmVBbi8q`,
	"alignCenter": `cITeqf2ftAZn31Gwp2Si`,
	"alignRight": `XaKmXYBqxwdxmXI5hikA`,
	"alignLeft": `Iay0zTnwI4jJAffZt1S8`,
	"mainField": `a4UELEuXtr1LtaQFiF9e`,
	"deletedUser": `x44Jl3cqD_p6PHR3U894`,
	"pointerTypeCursor": `dYhwJeWIA9kle5ePduqR`,
	"noDataWrapper": `Kmen3D7e74zmcLYUkPgX`,
	"noDataContent": `GQskLmZk5WIndidXulT9`,
	"paginationWrapper": `MgmrX6MOEJ_nr3EsIMFR`,
	"showPagination": `eeeopEWDwTVWHxRI1Evz`,
	"selectPagination": `asucNTRJHstCNDXS7lJw`,
	"headerContent": `A2sls7S8ZOHxyhSfWoDy`
};
export default ___CSS_LOADER_EXPORT___;
