// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CRKKzoUEmhMaULCfrupN {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  padding: 6px 0;
  max-width: 350px;
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.CRKKzoUEmhMaULCfrupN .I7cUckj7B9yG3xRcMniA {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 14px;
}
.CRKKzoUEmhMaULCfrupN .RnJl4yvc2IV1Ksr0hjVI {
  float: right;
  font-family: roboto;
  color: #999999;
  font-size: 14px;
}
.CRKKzoUEmhMaULCfrupN .wvK4y_HyLVAvCOeGAxBN {
  margin-right: -58px;
}
.CRKKzoUEmhMaULCfrupN .IXHX8QVZx4h33mgRz_3Q {
  margin-right: -116px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tdsConainer": `CRKKzoUEmhMaULCfrupN`,
	"tdsOptions": `I7cUckj7B9yG3xRcMniA`,
	"tdsCost": `RnJl4yvc2IV1Ksr0hjVI`,
	"tdsCostBoth": `wvK4y_HyLVAvCOeGAxBN`,
	"tdsCostNotBoth": `IXHX8QVZx4h33mgRz_3Q`
};
export default ___CSS_LOADER_EXPORT___;
