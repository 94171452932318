// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chy_YZyL6EZu2b3ZWMGw {
  width: 100%;
  padding: 24px 48px;
}
.Chy_YZyL6EZu2b3ZWMGw .MBVsIfdcysdCGrRZx1bs {
  margin-bottom: 16px;
}
.Chy_YZyL6EZu2b3ZWMGw .MBVsIfdcysdCGrRZx1bs h1 {
  font-weight: 500;
  font-size: 24px;
  color: #464457;
}

.HH83HHY2kgLxqcNSHge_ {
  color: #2755fe;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedPackageContainer": `Chy_YZyL6EZu2b3ZWMGw`,
	"customizeHeading": `MBVsIfdcysdCGrRZx1bs`,
	"goButton": `HH83HHY2kgLxqcNSHge_`
};
export default ___CSS_LOADER_EXPORT___;
