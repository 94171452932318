// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xq1SQ2f681emy6b99_4N {
  position: relative;
  margin-top: -190px;
}

.AJ0eOtIi0afOP_MBYV1J {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  min-height: 440px;
}

.bSInqzrJfcXCOcG6uGLA {
  padding: 0 0 18px 0;
  margin: auto;
  max-width: 1200px;
}
.bSInqzrJfcXCOcG6uGLA .RDZERSZrl1isavOqZwPm {
  display: flex;
}
.bSInqzrJfcXCOcG6uGLA .Kr46aDkvkVlfcfjJUrWS {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.bSInqzrJfcXCOcG6uGLA .txuCmnLWD45fEVYn5XPD {
  display: flex;
  border-radius: 5px;
  gap: 1rem;
  margin: 30px 0 0 0;
}

.P_5S8mpMTYb4RJBaMX2A {
  width: 70%;
  background-color: white;
  position: relative;
}
.P_5S8mpMTYb4RJBaMX2A .Ck9yLd8oInyQ2geuYRjI {
  height: 40px;
  position: absolute;
  z-index: 1;
  right: 32px;
  top: 20px;
}
.P_5S8mpMTYb4RJBaMX2A .Ck9yLd8oInyQ2geuYRjI:hover {
  cursor: pointer;
}

.QrQF638OS1uVO2Q1F693 .Ra_5cHYsvyyYBOyyTn_5 {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  right: 12px;
  top: 58px;
  background-color: grey;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 3px 5px;
  z-index: 2;
}

.QrQF638OS1uVO2Q1F693:hover .Ra_5cHYsvyyYBOyyTn_5 {
  visibility: visible;
}

.B8mKRIEdmxLBYGcCSlE9 {
  width: 30%;
}
.B8mKRIEdmxLBYGcCSlE9 .ewHIUNGi3Z8IJBgwoZAQ {
  background: white;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
}
.B8mKRIEdmxLBYGcCSlE9 .ewHIUNGi3Z8IJBgwoZAQ .PG7Od3n_jDardd0zLdyk {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.B8mKRIEdmxLBYGcCSlE9 .Cw7KngGmyedR0QJam0DT {
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}
.B8mKRIEdmxLBYGcCSlE9 .Cw7KngGmyedR0QJam0DT .ap0HCjNyHDWKHgjVcPLZ {
  color: white;
}
.B8mKRIEdmxLBYGcCSlE9 .ceuy91iLsW5D5Z1pxlHS {
  background-color: #d3455b;
}
.B8mKRIEdmxLBYGcCSlE9 .s9PNmTg9I0PTDhWRf4IQ {
  background-color: #2c88d9;
}
.B8mKRIEdmxLBYGcCSlE9 .xsmZN0nliT73jfPd5VBw {
  background-color: #f7c235;
}
.B8mKRIEdmxLBYGcCSlE9 .qd6TvvRpHRO61cnLUK7Q {
  display: flex;
}
.B8mKRIEdmxLBYGcCSlE9 .sFT0E1DVZRgpsMBojtvg {
  justify-content: space-between;
}
.B8mKRIEdmxLBYGcCSlE9 .uIAFCOgIoh3h4GycoNQd {
  align-items: center;
}
.B8mKRIEdmxLBYGcCSlE9 .iQvLlOhlBUmMwIvjjdAP {
  flex-direction: column;
}
.B8mKRIEdmxLBYGcCSlE9 .hiBcZ89f3GOqZ1dgLhqo {
  font-size: 12px;
}
.B8mKRIEdmxLBYGcCSlE9 .KZWAXxeDUmPXL6x_oJ_K {
  font-weight: 600;
}
.B8mKRIEdmxLBYGcCSlE9 .TLewHb9BH5pxTJOJJSeG {
  color: #788896;
}
.B8mKRIEdmxLBYGcCSlE9 .H_QtSEOP2Hib3hjVjw1R {
  font-size: 18px;
}
.B8mKRIEdmxLBYGcCSlE9 .fsv2wLtu9HswaiF7_MHM {
  font-size: 11px;
}
.B8mKRIEdmxLBYGcCSlE9 .TLaPOQcMepjEIt2eJpuj {
  background-color: #788896;
  color: #ffffff;
  border-radius: 20px;
  padding: 0.2rem 0.8rem;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
.B8mKRIEdmxLBYGcCSlE9 .O2BYMGoD10JZxxhuPNCn {
  padding-right: 0.5rem;
}
.B8mKRIEdmxLBYGcCSlE9 .MhKOlFxp16998vS3XECV {
  font-size: 14px;
}

.AEZkzpyqdqpMJdNnpYD9 {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.Y6yFY6mxgX1EXfPBpi_f {
  position: relative;
  min-height: 20px;
  margin: auto 0;
}

.wVeUWo70EhOTP_3LsS0_ {
  max-width: 1200px;
  margin: auto;
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 3px 2px 13px rgba(82, 103, 65, 0.16);
  display: flex;
  justify-content: flex-end;
}

.dx6FP1x9VTwPYzc80oOd {
  background: #2755fe;
  border: 1px #2755fe solid;
  color: white;
  text-align: center;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.dx6FP1x9VTwPYzc80oOd:hover {
  background: #234ee9;
  border: 1px #234ee9 solid;
}

.YMZluszOPXYoKBB6DnqO {
  background: #1f1630;
  padding-bottom: 20px;
  min-width: 1200px;
  font-family: Poppins, sans-serif;
}

.wwvHUoe7zI5elHYDO09m {
  min-height: 25vh;
}

.dR6jVcei7FoyO4DjvF0r {
  min-height: 47vh;
}

.G6wPrVSxctIOcIPi9T2t {
  min-height: 40vh;
}

.BEpIFtCdIzrhLfHxXOUM {
  width: 1.8rem;
  height: 1.8rem;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentContainer": `xq1SQ2f681emy6b99_4N`,
	"loaderContainer": `AJ0eOtIi0afOP_MBYV1J`,
	"container": `bSInqzrJfcXCOcG6uGLA`,
	"header": `RDZERSZrl1isavOqZwPm`,
	"pageHeader": `Kr46aDkvkVlfcfjJUrWS`,
	"mainContainer": `txuCmnLWD45fEVYn5XPD`,
	"RenderLink": `P_5S8mpMTYb4RJBaMX2A`,
	"downloadIcon": `Ck9yLd8oInyQ2geuYRjI`,
	"tooltip": `QrQF638OS1uVO2Q1F693`,
	"tooltiptext": `Ra_5cHYsvyyYBOyyTn_5`,
	"orderDetails": `B8mKRIEdmxLBYGcCSlE9`,
	"requestDetails": `ewHIUNGi3Z8IJBgwoZAQ`,
	"taxSubContainer": `PG7Od3n_jDardd0zLdyk`,
	"statusBanner": `Cw7KngGmyedR0QJam0DT`,
	"bannerTextWhite": `ap0HCjNyHDWKHgjVcPLZ`,
	"redBanner": `ceuy91iLsW5D5Z1pxlHS`,
	"blueBanner": `s9PNmTg9I0PTDhWRf4IQ`,
	"yellowBanner": `xsmZN0nliT73jfPd5VBw`,
	"flex": `qd6TvvRpHRO61cnLUK7Q`,
	"contentBetween": `sFT0E1DVZRgpsMBojtvg`,
	"itemCenter": `uIAFCOgIoh3h4GycoNQd`,
	"flexCol": `iQvLlOhlBUmMwIvjjdAP`,
	"textSmall": `hiBcZ89f3GOqZ1dgLhqo`,
	"fontSemibold": `KZWAXxeDUmPXL6x_oJ_K`,
	"textGray": `TLewHb9BH5pxTJOJJSeG`,
	"textLg": `H_QtSEOP2Hib3hjVjw1R`,
	"textXs": `fsv2wLtu9HswaiF7_MHM`,
	"tag": `TLaPOQcMepjEIt2eJpuj`,
	"tdsRadio": `O2BYMGoD10JZxxhuPNCn`,
	"tdsAmount": `MhKOlFxp16998vS3XECV`,
	"bottomFix": `AEZkzpyqdqpMJdNnpYD9`,
	"payContainer": `Y6yFY6mxgX1EXfPBpi_f`,
	"payRibbon": `wVeUWo70EhOTP_3LsS0_`,
	"payButton": `dx6FP1x9VTwPYzc80oOd`,
	"footerDiv": `YMZluszOPXYoKBB6DnqO`,
	"footerDivHeight25": `wwvHUoe7zI5elHYDO09m`,
	"footerDivHeight47": `dR6jVcei7FoyO4DjvF0r`,
	"footerDivHeight80": `G6wPrVSxctIOcIPi9T2t`,
	"payIcon": `BEpIFtCdIzrhLfHxXOUM`
};
export default ___CSS_LOADER_EXPORT___;
