// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zEzMnb9eKn0nnX4Gu3Hn {
  max-width: 1200px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  margin-top: 15px;
  height: 100px;
}
.zEzMnb9eKn0nnX4Gu3Hn .cJepVpRLWBIpFKvCxa58 {
  width: 1200px;
  padding: 0px 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.zEzMnb9eKn0nnX4Gu3Hn .cJepVpRLWBIpFKvCxa58 .vaaGTK2giSYS4mjqnu4L {
  display: flex;
  align-items: center;
  width: 822px;
  justify-content: space-between;
}

.AasHoMFZgC8xrcY9KslV {
  margin: 3px;
  background-color: rgba(61, 143, 255, 0.13);
  border-radius: 50%;
}
.AasHoMFZgC8xrcY9KslV .eoAxFENxwSILUrr7c877 {
  height: 90px;
}

.oH0Fm2uLGNVhxp8eNw6s {
  font-weight: 500;
  font-size: 18px;
  color: #666;
  width: 598px;
}

.BIvrqvyzank5vH_RX4QQ {
  width: 164px;
  height: 48px;
  margin-right: 10px;
  border: #2755fe 1px solid;
  border-radius: 4px;
  color: #2755fe;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: #fff;
  margin-top: 7px;
}
.BIvrqvyzank5vH_RX4QQ:hover {
  cursor: pointer;
}

.GDvXkVs7VtSpsSJN1hlm {
  color: #a7abc3;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}
.GDvXkVs7VtSpsSJN1hlm:hover {
  cursor: pointer;
}

.LzWasLYwLteMJ36bM5yt {
  color: #333;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

.jV43o7yy48XDCd7j_pb8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 6px;
}

.Qq9uqQWK3ao5dRoWq00e {
  min-width: 150px;
}

.YzepnZWmNUXdQB0dL8b0 {
  height: 48px;
  border-radius: 12px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `zEzMnb9eKn0nnX4Gu3Hn`,
	"divContainer": `cJepVpRLWBIpFKvCxa58`,
	"divSubContainer": `vaaGTK2giSYS4mjqnu4L`,
	"firstCol": `AasHoMFZgC8xrcY9KslV`,
	"firstColImg": `eoAxFENxwSILUrr7c877`,
	"headingCol": `oH0Fm2uLGNVhxp8eNw6s`,
	"connectBtn": `BIvrqvyzank5vH_RX4QQ`,
	"teamCTA": `GDvXkVs7VtSpsSJN1hlm`,
	"slackText": `LzWasLYwLteMJ36bM5yt`,
	"ctaContainer": `jV43o7yy48XDCd7j_pb8`,
	"imgDivContainer": `Qq9uqQWK3ao5dRoWq00e`,
	"profileImg": `YzepnZWmNUXdQB0dL8b0`
};
export default ___CSS_LOADER_EXPORT___;
