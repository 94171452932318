// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xOtMuMCqLnxP4cctVF4P {
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 80px);
}

._RtfxvYNnqrW2gmydYLM {
  height: 80px !important;
  background: linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%);
}
._RtfxvYNnqrW2gmydYLM .BUttbR0ryiqWwOf31hvA {
  height: 100%;
  display: flex;
}
._RtfxvYNnqrW2gmydYLM .BUttbR0ryiqWwOf31hvA img {
  margin: auto;
}

.aP7e7WN6uWwgaSmHX1O_ {
  cursor: pointer;
  text-decoration: none;
}
.aP7e7WN6uWwgaSmHX1O_:hover {
  text-decoration: none;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorContainer": `xOtMuMCqLnxP4cctVF4P`,
	"header": `_RtfxvYNnqrW2gmydYLM`,
	"headerLogo": `BUttbR0ryiqWwOf31hvA`,
	"anchorText": `aP7e7WN6uWwgaSmHX1O_`
};
export default ___CSS_LOADER_EXPORT___;
