// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lv54s7axFS2UYBlajFA6 {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 50px;
}
@media only screen and (max-width: 1200px) {
  .lv54s7axFS2UYBlajFA6 {
    width: 1200px;
  }
}
.lv54s7axFS2UYBlajFA6 .S2kspNciZ9WtTgKXvff3 {
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
}
.lv54s7axFS2UYBlajFA6 .bqRce2o7GU0BPDQZlC2n {
  margin-top: -190px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 10px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageTagAndCategoryContainer": `lv54s7axFS2UYBlajFA6`,
	"cardContainer": `S2kspNciZ9WtTgKXvff3`,
	"heading": `bqRce2o7GU0BPDQZlC2n`
};
export default ___CSS_LOADER_EXPORT___;
