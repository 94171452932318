// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r5b4e_dg959ddTrku5my {
  border-top: none;
  padding: 0 24px 24px 24px;
  border: none !important;
}
.r5b4e_dg959ddTrku5my .cloXiBVtQZ7He_btV8bL {
  min-width: 100%;
  max-width: 100%;
  padding: 25px 20px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 48px;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
}
.r5b4e_dg959ddTrku5my .n9fREOI_Gr8L5VQcl0m_ {
  display: flex;
  justify-content: flex-end;
}
.r5b4e_dg959ddTrku5my .n9fREOI_Gr8L5VQcl0m_ .jhbhOCG6q5OItSiEToq8 {
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  height: 48px;
  margin-right: 0px;
}
.r5b4e_dg959ddTrku5my .n9fREOI_Gr8L5VQcl0m_ .jhbhOCG6q5OItSiEToq8:hover {
  background: #2755fe;
  color: #ffffff;
}
.r5b4e_dg959ddTrku5my .n9fREOI_Gr8L5VQcl0m_ .jhbhOCG6q5OItSiEToq8:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.r5b4e_dg959ddTrku5my .n9fREOI_Gr8L5VQcl0m_ .TEAH2aI5JwT7ZNTQW_S3 {
  margin-left: 15px;
  background: #ffffff;
  border: 1px solid #2755fe;
  border-radius: 5px;
  width: 136px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #2755fe;
  margin-right: 24px;
  height: 48px;
}
.r5b4e_dg959ddTrku5my .n9fREOI_Gr8L5VQcl0m_ .TEAH2aI5JwT7ZNTQW_S3:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.Mq3FAUq6I2wSNlFkcqn5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  margin-bottom: 17px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"communicationCard": `r5b4e_dg959ddTrku5my`,
	"communicationContainer": `cloXiBVtQZ7He_btV8bL`,
	"buttonGroup": `n9fREOI_Gr8L5VQcl0m_`,
	"submitButton": `jhbhOCG6q5OItSiEToq8`,
	"cancelButton": `TEAH2aI5JwT7ZNTQW_S3`,
	"tabInfoText": `Mq3FAUq6I2wSNlFkcqn5`
};
export default ___CSS_LOADER_EXPORT___;
