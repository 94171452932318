// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m4bPS71EtI4FTOlwCEHl {
  display: inline-flex;
  width: 100%;
}

.yqQuG6DA7u8SIInfNktG {
  width: 50%;
  margin-left: 10px;
}

.QTjHckCu671yex38YrKb {
  margin-left: 16px;
  margin-right: 56px;
}

.ZTqZkelrTSPqR60B6HTc {
  border: 1px solid red;
}

.aAjlKs57DNwrIfKJwvzw {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
}

.bkXcmUbEBsEyoxxk77Jg {
  background-color: #2755fe;
  color: white;
  padding: 0.5rem;
  border: none;
}

.BrbHn7Eb2NIrqJB7KR80 {
  background-color: #1bae9f;
  color: white;
  padding: 0.5rem;
  border: none;
}

.bkXcmUbEBsEyoxxk77Jg:hover {
  background-color: #1f44ca;
}

.BrbHn7Eb2NIrqJB7KR80:hover {
  background-color: #11a294;
}

.wR52bmYmrXgp_a2vOtb8 {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.mpRNvLjAx5vFNe39TJQh {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  color: #2755fe;
  border: 1px solid #2755fe;
}

.HiS744KehXQmMtBLKGjt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.P9mH2vzXRXnDNSmEMRMq {
  margin: 40px 0px;
}

.rjrewbl8z1oVNpblZQ7D {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.dcD41HIY4JbDAN90iy6c {
  display: flex;
  width: 34%;
}

.QsY9mEc3pmO7wkJjJOvg {
  height: 25px;
  margin: 6px 9px 4px 0px;
}

.tmStD3VhxqlRrKIceWcQ {
  height: 38px;
  margin-left: -10px;
  background-color: #e9ecef;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formMainDiv": `m4bPS71EtI4FTOlwCEHl`,
	"formField": `yqQuG6DA7u8SIInfNktG`,
	"parentDivConnectButton": `QTjHckCu671yex38YrKb`,
	"removeButton": `ZTqZkelrTSPqR60B6HTc`,
	"validationErrorMsg": `aAjlKs57DNwrIfKJwvzw`,
	"connectButton": `bkXcmUbEBsEyoxxk77Jg`,
	"connectedButton": `BrbHn7Eb2NIrqJB7KR80`,
	"step1FooterContainer": `wR52bmYmrXgp_a2vOtb8`,
	"goBackBtn": `mpRNvLjAx5vFNe39TJQh`,
	"listItems": `HiS744KehXQmMtBLKGjt`,
	"generateTokenButton": `P9mH2vzXRXnDNSmEMRMq`,
	"generateTokenButton__heading": `rjrewbl8z1oVNpblZQ7D`,
	"rowDisplay": `dcD41HIY4JbDAN90iy6c`,
	"tooltipLockIcon": `QsY9mEc3pmO7wkJjJOvg`,
	"iconBg": `tmStD3VhxqlRrKIceWcQ`
};
export default ___CSS_LOADER_EXPORT___;
