// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SnK_rd0ymi_uZzIta956 {
  margin-top: -24px;
}
@media only screen and (max-width: 1024px) {
  .SnK_rd0ymi_uZzIta956 {
    margin-left: 150px;
  }
}
.SnK_rd0ymi_uZzIta956 .tZhIjPEAiqJUvUO5TH9c {
  padding: 12px 20px 14px 20px;
  display: flex;
  justify-content: space-between;
}
.SnK_rd0ymi_uZzIta956 .tZhIjPEAiqJUvUO5TH9c .WAXdMfnqXHdXCnkMdNeo {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #333333;
  padding-top: 11px;
}
.SnK_rd0ymi_uZzIta956 .tZhIjPEAiqJUvUO5TH9c button .OGJLpnJNXTtsTTngK8FM {
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0;
  padding: 13px 11px 15px 11px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 {
  border: 0px;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb {
  margin: auto;
  width: 96%;
  margin-bottom: 24px;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb thead {
  background-color: #f2f8ff;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb thead tr th {
  border: 0px;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 18px 20px 19px 20px;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb thead tr .xxSWJA7RLtHoeK2AmFw2 {
  width: 250px;
  text-align: end;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb thead tr .P1PA08GA7JBRrMsgcBah {
  width: 450px;
  text-align: center;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb tbody tr {
  cursor: default;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb tbody tr td {
  border: 0px;
  vertical-align: middle;
  padding: 16px 20px 16px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb tbody tr td .B9j8jnDyePEmexHdOCQa {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb tbody tr td .B9j8jnDyePEmexHdOCQa .EfLOIbtJU1Gq_RxxuLU7 {
  margin-left: 10px;
  border: 1px transparent solid;
  background: transparent;
  padding: 3px;
  border-radius: 50%;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb tbody tr td .B9j8jnDyePEmexHdOCQa .EfLOIbtJU1Gq_RxxuLU7:hover {
  background: #e2ebf6;
  border: 1px #e2ebf6 solid;
  border-radius: 50%;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb tbody tr td .B9j8jnDyePEmexHdOCQa .EfLOIbtJU1Gq_RxxuLU7:hover svg path {
  fill: #2755fe;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb tbody tr .xxSWJA7RLtHoeK2AmFw2 {
  width: 250px;
  text-align: right;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb tbody tr .P1PA08GA7JBRrMsgcBah {
  text-align: center;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb tbody tr:hover {
  background-color: #f5f5f5;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb tbody tr:hover .ZOczWuzcyY53EXEwPASu {
  color: #366cf3;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb .raQlZDImOUUkriJdwK0I {
  text-align: center;
  padding-top: 64px;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .SBRUr9vobsJezATTePvb .raQlZDImOUUkriJdwK0I .cnhgp2MczsFQBN47Zuaw {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .uNVoXJQQOjlQ1io5cI_e {
  flex-wrap: unset;
  padding: 0 24px 15px;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .uNVoXJQQOjlQ1io5cI_e .YUe_X4SPtZWPzWGyrg8A {
  padding-right: 100px;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  display: contents;
}
.SnK_rd0ymi_uZzIta956 .VzGPZ7Lf4tKCizf9aE19 .uNVoXJQQOjlQ1io5cI_e .YUe_X4SPtZWPzWGyrg8A .TVKfxosXPzohmnYAcnH5 {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px;
  -webkit-appearance: menulist;
}

.Y7jzjRnE4lgixSni7D1q {
  background-color: #2755fe;
}

.Y7jzjRnE4lgixSni7D1q:hover {
  background-color: #1f44ca;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageCatCard": `SnK_rd0ymi_uZzIta956`,
	"manageCatCardHeader": `tZhIjPEAiqJUvUO5TH9c`,
	"headerComp": `WAXdMfnqXHdXCnkMdNeo`,
	"createButton": `OGJLpnJNXTtsTTngK8FM`,
	"tableCard": `VzGPZ7Lf4tKCizf9aE19`,
	"manageCategoryTable": `SBRUr9vobsJezATTePvb`,
	"alignRight": `xxSWJA7RLtHoeK2AmFw2`,
	"alignCenter": `P1PA08GA7JBRrMsgcBah`,
	"icons": `B9j8jnDyePEmexHdOCQa`,
	"icon": `EfLOIbtJU1Gq_RxxuLU7`,
	"mainField": `ZOczWuzcyY53EXEwPASu`,
	"noDataWrapper": `raQlZDImOUUkriJdwK0I`,
	"noDataContent": `cnhgp2MczsFQBN47Zuaw`,
	"paginationWrapper": `uNVoXJQQOjlQ1io5cI_e`,
	"showPagination": `YUe_X4SPtZWPzWGyrg8A`,
	"selectPagination": `TVKfxosXPzohmnYAcnH5`,
	"accentColor": `Y7jzjRnE4lgixSni7D1q`
};
export default ___CSS_LOADER_EXPORT___;
