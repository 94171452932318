// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mN0H5ksyRbQh2A65Sg0E {
  font-family: Poppins !important;
  font-style: normal !important;
}
.mN0H5ksyRbQh2A65Sg0E .h8byperfgYBsPG9U6bug {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 0px;
}
.mN0H5ksyRbQh2A65Sg0E .rfdbLiMf99c8TywyVlxP {
  margin-left: 32px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}
.mN0H5ksyRbQh2A65Sg0E .QK5fGeUuCcBWJ5wsno6Z {
  margin-top: 12px;
  margin-left: 32px;
}
.mN0H5ksyRbQh2A65Sg0E .QK5fGeUuCcBWJ5wsno6Z .X_IvWUWzIkzdVxuuMjMX {
  padding-bottom: 6px;
}
.mN0H5ksyRbQh2A65Sg0E .QK5fGeUuCcBWJ5wsno6Z .X_IvWUWzIkzdVxuuMjMX label {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #595959 !important;
}
.mN0H5ksyRbQh2A65Sg0E .QK5fGeUuCcBWJ5wsno6Z .X_IvWUWzIkzdVxuuMjMX input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  color: #bfbfbf !important;
}
.mN0H5ksyRbQh2A65Sg0E .LUFsHISPD6l0kdSQd3kt {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #bfbfbf;
}
.mN0H5ksyRbQh2A65Sg0E .LUFsHISPD6l0kdSQd3kt .ZAZabTQr8Bn8ht5iLEMJ {
  font-weight: 500;
  font-size: 12px;
  color: #999999;
  margin-left: 8px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"idPackageComp": `mN0H5ksyRbQh2A65Sg0E`,
	"checkHeading": `h8byperfgYBsPG9U6bug`,
	"subTitle": `rfdbLiMf99c8TywyVlxP`,
	"checkContainer": `QK5fGeUuCcBWJ5wsno6Z`,
	"formCheck": `X_IvWUWzIkzdVxuuMjMX`,
	"pCheckHeading": `LUFsHISPD6l0kdSQd3kt`,
	"pCheckSubHeading": `ZAZabTQr8Bn8ht5iLEMJ`
};
export default ___CSS_LOADER_EXPORT___;
