// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wR04M8hCRa14Z0tWLBFc {
  padding: 20px 20px 0px 20px;
}
.wR04M8hCRa14Z0tWLBFc .RML9ZIivF04qy6fMXWL6 {
  font-weight: 600 !important;
  line-height: 26px;
  font-size: 18px;
  margin: 0 0 1rem 0;
}

.jzDldWvihcDq1Otz1JPV {
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin: 1rem 0;
}

.nZ7erOtAqeq60sBIUk_i {
  border: 1px solid #2755fe !important;
}

.tTz78PqpCf6dQR6tjqV0 {
  background-color: white;
  border-bottom: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}
.tTz78PqpCf6dQR6tjqV0 .uqMFl5QLeADWNkpkVgsl {
  padding: 0rem;
}

.OcXSYT_4mMOWAlgo7DHj {
  font-weight: 500 !important;
  line-height: 26px;
  font-size: 16px;
  color: #666666 !important;
}

.H6sm1fzaKiB1txBUidEs {
  font-weight: 600;
  font-size: 20px;
}

.tVeOeBAwNUoPOfq7zXD4 {
  color: #666666;
  font-size: 14px;
  font-weight: 600;
}

.YjQrJYorIxjWFLRP76HB {
  background: #ffe3e3;
  color: #666666;
  font-size: 12px;
  font-weight: 500;
}

.qk6DZBu5UlKKnwajI_DT {
  background: rgba(250, 173, 20, 0.2);
  color: #666666;
  font-size: 12px;
  font-weight: 500;
}

.WWaozzJfp__PPhs1EI3v {
  text-decoration: underline;
  color: #2755fe;
  font-weight: 600;
  font-size: 12px;
}

.Sud5rCaIZsyteDbfFiKN {
  font-weight: 500;
  color: #2755fe;
}

.QjOhkVcFJAJ074rnSOYC {
  padding: 0 0.75rem !important;
}

.ezqaBtp7eQzvX5JH7WiK {
  padding: 0 !important;
}

.NIuVcsWtauiJvxjA1U9j {
  margin: 0 0 0.75rem 0 !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `wR04M8hCRa14Z0tWLBFc`,
	"containerHeader": `RML9ZIivF04qy6fMXWL6`,
	"accordionCard": `jzDldWvihcDq1Otz1JPV`,
	"cardActive": `nZ7erOtAqeq60sBIUk_i`,
	"accordionHeader": `tTz78PqpCf6dQR6tjqV0`,
	"accordionTitle": `uqMFl5QLeADWNkpkVgsl`,
	"sectionSubTitle": `OcXSYT_4mMOWAlgo7DHj`,
	"sectionTitle": `H6sm1fzaKiB1txBUidEs`,
	"currentBalance": `tVeOeBAwNUoPOfq7zXD4`,
	"criticalPill": `YjQrJYorIxjWFLRP76HB`,
	"lowPill": `qk6DZBu5UlKKnwajI_DT`,
	"viewCheck": `WWaozzJfp__PPhs1EI3v`,
	"rechargeText": `Sud5rCaIZsyteDbfFiKN`,
	"tierCard": `QjOhkVcFJAJ074rnSOYC`,
	"tierContainer": `ezqaBtp7eQzvX5JH7WiK`,
	"tierCol": `NIuVcsWtauiJvxjA1U9j`
};
export default ___CSS_LOADER_EXPORT___;
