// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yy8DERDm4xkMyDh8WzGw {
  max-width: 1200px;
  width: 100%;
  height: 110px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(82, 103, 65, 0.16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.yMxxeH05twQc9Qh1C4pe {
  height: 80%;
  padding: 15px;
  display: inline-block;
}

.yMxxeH05twQc9Qh1C4pe img {
  height: 100%;
  max-width: 120px;
  object-fit: contain;
}

.iSHJoGJo4rTABDMyK9wg {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  margin-left: 20px;
}

.RAmLSv4lUFdmxLYEYmjh {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #333333;
}

.nt6xKQMKU_EHFElZ2lB7 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #666666;
  display: flex;
  min-width: 700px;
  gap: 8px;
  width: 100%;
}

.qCR4JSfpq0FK88T8WOwP {
  display: flex;
  gap: 18px;
}
.qCR4JSfpq0FK88T8WOwP button {
  border-radius: 2px;
  width: 140px;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  font-weight: 400;
}

.y3omTJR3xkRv5odNGDTb {
  background: #ffffff;
  border: 1px solid #2755fe;
  color: #2755fe;
}
.y3omTJR3xkRv5odNGDTb:hover {
  background: #2755fe;
  color: #ffffff;
}

.Q7IYHCHBh9qU5MrEKvgb {
  background: #2755fe;
  border: 1px solid #2755fe;
  color: #ffffff;
  width: auto !important;
  min-width: 140px;
}

.MUPf4wNgoLEg7neKW5qY,
.J87zKntoAhxopIY91OBz {
  width: 40px !important;
  height: 40px;
  background: rgba(153, 153, 153, 0.168627451);
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.ehjjWQmfe1GpT2mqAX62 {
  color: #333333;
  font-size: 24px;
}

.MUPf4wNgoLEg7neKW5qY:hover .ehjjWQmfe1GpT2mqAX62 {
  color: #ffffff;
}

.QbAEKj0oBQtS1VO_A47W {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.y91RERDgvKTHWayVdeCH {
  position: absolute;
  background: #ffffff;
  display: none;
  border-radius: 4px;
  min-width: 120px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  z-index: 1000;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1607843137);
  padding: 5px;
}

.E05aO4RT0dk1u5iMsUEE {
  padding: 5px 15px;
  cursor: pointer;
  width: 100%;
  z-index: 1000;
  text-align: left;
}

.E05aO4RT0dk1u5iMsUEE:hover {
  background: #ebefff;
  color: #2755fe;
}

.MUPf4wNgoLEg7neKW5qY:hover + .y91RERDgvKTHWayVdeCH {
  display: block;
}

.y91RERDgvKTHWayVdeCH:hover {
  display: block;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `yy8DERDm4xkMyDh8WzGw`,
	"logo": `yMxxeH05twQc9Qh1C4pe`,
	"body": `iSHJoGJo4rTABDMyK9wg`,
	"header": `RAmLSv4lUFdmxLYEYmjh`,
	"info": `nt6xKQMKU_EHFElZ2lB7`,
	"buttonGroup": `qCR4JSfpq0FK88T8WOwP`,
	"addManual": `y3omTJR3xkRv5odNGDTb`,
	"connect": `Q7IYHCHBh9qU5MrEKvgb`,
	"more": `MUPf4wNgoLEg7neKW5qY`,
	"spinnerContainer": `J87zKntoAhxopIY91OBz`,
	"horizontalButton": `ehjjWQmfe1GpT2mqAX62`,
	"orText": `QbAEKj0oBQtS1VO_A47W`,
	"dropdown": `y91RERDgvKTHWayVdeCH`,
	"dropdownItem": `E05aO4RT0dk1u5iMsUEE`
};
export default ___CSS_LOADER_EXPORT___;
