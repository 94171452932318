// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VXNshfMB7MqwpxJ60B6X {
  position: absolute;
  color: #fff;
  background: rgba(177, 175, 175, 0.4196078431);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  right: 0;
  top: 0;
  transform: translateY(-50%) translateX(50%);
  cursor: pointer;
}

.VXNshfMB7MqwpxJ60B6X:hover {
  background: #959292;
  color: white;
}

.C7pMQgOgHmTFAYfO7HY2 {
  position: absolute;
  color: white;
  transform: translateY(-10%) translateX(10%);
}

.ia39tcMIoM4aVz9xMt4y {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 216px;
  border: "1px dashed #000";
  border-radius: 4px;
  gap: 3px;
  cursor: pointer;
}

.ZPbL_7NmBHu1pwdIRir3 {
  font-size: 24px;
  font-weight: 400;
}

.hvjbfe2A6_1npDP5DJm4 {
  border-top: none;
  padding: 0 24px 24px 24px;
  border: none !important;
}
.hvjbfe2A6_1npDP5DJm4 .DPgQC7JloZvY9pfNz_SP {
  min-width: 100%;
  max-width: 100%;
  padding: 25px 20px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  gap: 20px;
}
.hvjbfe2A6_1npDP5DJm4 .n_akdBxZhbEyczu7DxuQ {
  display: flex;
  justify-content: flex-end;
}
.hvjbfe2A6_1npDP5DJm4 .n_akdBxZhbEyczu7DxuQ .kncbBO5O_CAmTieD5m2C {
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  height: 48px;
  margin-right: 0px;
}
.hvjbfe2A6_1npDP5DJm4 .n_akdBxZhbEyczu7DxuQ .kncbBO5O_CAmTieD5m2C:hover {
  background: #2755fe;
  color: #ffffff;
}
.hvjbfe2A6_1npDP5DJm4 .n_akdBxZhbEyczu7DxuQ .kncbBO5O_CAmTieD5m2C:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.hvjbfe2A6_1npDP5DJm4 .n_akdBxZhbEyczu7DxuQ .xCkDfei0u0T4ber5CnfO {
  margin-left: 15px;
  background: #ffffff;
  border: 1px solid #2755fe;
  border-radius: 5px;
  width: 136px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #2755fe;
  margin-right: 24px;
  height: 48px;
}
.hvjbfe2A6_1npDP5DJm4 .n_akdBxZhbEyczu7DxuQ .xCkDfei0u0T4ber5CnfO:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.jGTRI5kOAbVun11Ui87g {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  margin-bottom: 17px;
}

.IjrDOBxe14r5YgZP33YX {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.iJkyNjoTt8oGEiJI9Geh {
  font-weight: 500;
  font-size: 16px;
  color: #212529;
}

.mrHOsqUheAxJp5I95ZVe {
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}

.OJMuc7PpXJe_5m0G7VgQ {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.Hac0_Jud2m7MdRggR3Vm {
  font-size: 12px;
  margin-top: 20px;
  color: #2755fe;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

.CapbFZ92VJrHOF6JohbG {
  background: #6a778b;
  color: #d9d9d9;
}

.TqKSww9r904RCufprcVd {
  max-width: 540px;
}

.PrtCtlKebWXcjPcwzs4s {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.NhaOv1gpebGhIym3CB2M {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.Oc2fjyjZ31xCl2QwZQHL {
  position: relative;
}

.PsXGoPaOoIVidY5T3IPY {
  width: 216px;
}

.hKp1MQ3KT4cBXPg2ENjm {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.hKp1MQ3KT4cBXPg2ENjm .qeKNL7DzKfktrwtw1fJf {
  border: 2px solid #cfc3fb;
  color: white;
  background: #2755fe;
  border: 1px solid #2755fe;
  width: 136px;
}
.hKp1MQ3KT4cBXPg2ENjm .dQ5R_UGLDNUaC94GjkVA {
  color: #2755fe;
  background: #ffffff;
  border: 1px solid #2755fe;
  border-radius: 5px;
  width: 136px;
}
.hKp1MQ3KT4cBXPg2ENjm .dQ5R_UGLDNUaC94GjkVA:hover {
  background: #2755fe;
  color: #ffffff;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoClose": `VXNshfMB7MqwpxJ60B6X`,
	"closex": `C7pMQgOgHmTFAYfO7HY2`,
	"logo": `ia39tcMIoM4aVz9xMt4y`,
	"logoIcon": `ZPbL_7NmBHu1pwdIRir3`,
	"communicationCard": `hvjbfe2A6_1npDP5DJm4`,
	"communicationContainer": `DPgQC7JloZvY9pfNz_SP`,
	"buttonGroup": `n_akdBxZhbEyczu7DxuQ`,
	"submitButton": `kncbBO5O_CAmTieD5m2C`,
	"cancelButton": `xCkDfei0u0T4ber5CnfO`,
	"tabInfoText": `jGTRI5kOAbVun11Ui87g`,
	"fields": `IjrDOBxe14r5YgZP33YX`,
	"sectionHeader": `iJkyNjoTt8oGEiJI9Geh`,
	"sectionSubHeader": `mrHOsqUheAxJp5I95ZVe`,
	"logoContainer": `OJMuc7PpXJe_5m0G7VgQ`,
	"sample": `Hac0_Jud2m7MdRggR3Vm`,
	"light": `CapbFZ92VJrHOF6JohbG`,
	"input": `TqKSww9r904RCufprcVd`,
	"inputError": `PrtCtlKebWXcjPcwzs4s`,
	"logoError": `NhaOv1gpebGhIym3CB2M`,
	"imageUpload": `Oc2fjyjZ31xCl2QwZQHL`,
	"widthMax": `PsXGoPaOoIVidY5T3IPY`,
	"btnContainer": `hKp1MQ3KT4cBXPg2ENjm`,
	"cancelLogoBtn": `qeKNL7DzKfktrwtw1fJf`,
	"deleteLogoBtn": `dQ5R_UGLDNUaC94GjkVA`
};
export default ___CSS_LOADER_EXPORT___;
