// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.Z4RI5appPEAofFrG7aNP {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  width: 1200px;
  height: 850px;
  border-radius: 4px;
  margin: -145px auto 5px;
  font-family: Poppins, sans-serif;
}
@media only screen and (max-width: 1024px) {
  .Z4RI5appPEAofFrG7aNP {
    margin-left: 150px;
  }
}

.fk9RCYMu9CGftZQ5Zd7e {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.tYBYUMsGH21Z8Y2RNQP3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 35px;
  padding-bottom: 0px !important;
  border: none !important;
  background-color: white;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 36px !important;
}

.iUVTNJIM03K1pxwRiRTr {
  max-height: 690px;
  overflow-y: auto;
  padding-top: 10px !important;
  padding: 15px;
  padding-bottom: 0 !important;
}

.Sh6zMSDmCN6S1f3_FOb4 {
  margin-bottom: 20px;
  width: 362px !important;
  height: 320px !important;
  border: 1px solid #e9ecef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
.Sh6zMSDmCN6S1f3_FOb4 .wBUt3RZ8LN7ZHKqWhOBs {
  height: 143px;
  padding-left: 15px !important;
}
.Sh6zMSDmCN6S1f3_FOb4 .wBUt3RZ8LN7ZHKqWhOBs .aEU9AcSSjMlh8978Phe_ {
  display: flex;
  gap: 5px;
}
.Sh6zMSDmCN6S1f3_FOb4 .zBRYHS8ZcJpIdk4BQaC6 {
  padding-top: 15px;
  padding-left: 15px;
}
.Sh6zMSDmCN6S1f3_FOb4 .foHjdstdN6n5qa_46cfp {
  display: flex;
  height: 176px !important;
  border-bottom: 1px solid #e9ecef;
}
.Sh6zMSDmCN6S1f3_FOb4 .sEYk5OJgRo0Toz8eocCR {
  width: 174px;
  height: 174px;
  background-color: #ecf2f6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Sh6zMSDmCN6S1f3_FOb4 .UVU1gbfICyqCV3lnYo55 {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.Sh6zMSDmCN6S1f3_FOb4 .Lzf5tda2BSs6wBmzLNCt {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.Sh6zMSDmCN6S1f3_FOb4 .hui2F5tj1mqxg2KtrZul {
  line-height: 28px;
}
.Sh6zMSDmCN6S1f3_FOb4 .KaQBYJCejWVahXFToFqW {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.Sh6zMSDmCN6S1f3_FOb4 .LBLFJVz7CIkb9tjm6VzV {
  background-color: #2755fe;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  width: 328px;
  height: 44px;
}

.QUhBo947chyROnLNFJNc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.rItPo9CFe5D1t0sJBLDF {
  font-weight: bold;
  font-size: 1.2em;
  color: #36aa53;
  margin-bottom: 10px;
}

.S5bSjldnpNL6PIStfK6b {
  text-decoration: line-through;
  color: #333333;
  padding-top: 2px;
}

.e6d_yADo6kHLwdsLQPps {
  width: 1134px;
  height: 56px;
  background-color: rgba(235, 199, 105, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-left: 15px;
}

.F_bTdd_MtJVWaRRWFtNY {
  position: relative;
  top: -2px;
}

.lN8bEbj_q64pg3YBQrAj {
  color: #333333;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  opacity: 1 !important;
}

.rnVOP6GrRp9uZjZRVIQr {
  color: #2755fe;
  text-decoration: underline;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}
.rnVOP6GrRp9uZjZRVIQr:hover {
  text-decoration: none;
}

.tWlPCoQjQJnisq6_gna4 {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  width: 1200px;
  border-radius: 4px;
  height: 295px;
  margin: 10px auto 5px;
  font-family: Poppins, sans-serif;
  padding-top: 15px !important;
  padding-left: 15px !important;
}
@media only screen and (max-width: 1024px) {
  .tWlPCoQjQJnisq6_gna4 {
    margin-left: 150px;
  }
}
.tWlPCoQjQJnisq6_gna4 .K2D5J3mHBkJK9bwXKUxj {
  background-color: #ffffff;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  border-bottom: none !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 36px !important;
}
.tWlPCoQjQJnisq6_gna4 .A51DZeAgt9I5FaEBpub5 {
  border: none !important;
}
.tWlPCoQjQJnisq6_gna4 .TsD6CgDe5SbTorIZtfUF {
  margin-top: 15px;
  padding-top: 0 !important;
}

.tWlPCoQjQJnisq6_gna4 td {
  padding: 13px 0;
  border-bottom: 1px solid #e9ecef;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.tWlPCoQjQJnisq6_gna4 td:first-child {
  font-weight: bold;
  width: 150px;
}
.tWlPCoQjQJnisq6_gna4 tr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.tWlPCoQjQJnisq6_gna4 strong {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.DM1LuIWAfk5vhfsNc62y {
  margin-bottom: 20px;
}
.DM1LuIWAfk5vhfsNc62y .CmjaqEVmlhjyZebyMQoQ {
  background: #ffffff !important;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  width: 1200px;
  border-radius: 4px;
  margin: 10px auto 68px;
  font-family: Poppins, sans-serif;
  min-height: 84px;
  padding-left: 15px;
}
@media only screen and (max-width: 1024px) {
  .DM1LuIWAfk5vhfsNc62y .CmjaqEVmlhjyZebyMQoQ {
    margin-left: 150px;
  }
}
.DM1LuIWAfk5vhfsNc62y .CmjaqEVmlhjyZebyMQoQ .QGnMiGfsFcJV05jNyspJ {
  background-color: #ffffff !important;
  border-bottom: none !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 20px;
}
.DM1LuIWAfk5vhfsNc62y .CmjaqEVmlhjyZebyMQoQ .drp2ctYNI3TGLBN0iWAW {
  padding-top: 0 !important;
  margin-top: 10px;
  padding-left: 25px;
}
.DM1LuIWAfk5vhfsNc62y .CmjaqEVmlhjyZebyMQoQ .Wskqyw0X47RfoJLmkjDw {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
.DM1LuIWAfk5vhfsNc62y .CmjaqEVmlhjyZebyMQoQ .i9ezvxQ0bdXFDn_Apsm2 {
  background-color: #fff;
  border-bottom: none;
  cursor: pointer;
}
.DM1LuIWAfk5vhfsNc62y .CmjaqEVmlhjyZebyMQoQ .i9ezvxQ0bdXFDn_Apsm2:hover {
  background-color: #f8f9fa;
}
.DM1LuIWAfk5vhfsNc62y .CmjaqEVmlhjyZebyMQoQ .i9ezvxQ0bdXFDn_Apsm2 h3 {
  margin-bottom: 0;
}
.DM1LuIWAfk5vhfsNc62y .tvPWqbUuWCGCNsCDV160 {
  padding: 0;
  color: #000;
}
.DM1LuIWAfk5vhfsNc62y .tvPWqbUuWCGCNsCDV160:focus {
  box-shadow: none;
}
.DM1LuIWAfk5vhfsNc62y .tvPWqbUuWCGCNsCDV160 .tGiARHM21xTHdt_r8unO {
  display: inline-block;
  transition: transform 0.3s;
}
.DM1LuIWAfk5vhfsNc62y .tvPWqbUuWCGCNsCDV160[aria-expanded=true] .tGiARHM21xTHdt_r8unO {
  transform: rotate(180deg);
}

.Os59pps4qnVq4mc31zXg {
  list-style-type: none;
  padding-left: 0;
}
.Os59pps4qnVq4mc31zXg li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.Os59pps4qnVq4mc31zXg li:before {
  content: "•";
  position: absolute;
  left: 0;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerCard": `Z4RI5appPEAofFrG7aNP`,
	"mainCard": `fk9RCYMu9CGftZQ5Zd7e`,
	"cardHeader": `tYBYUMsGH21Z8Y2RNQP3`,
	"laptopList": `iUVTNJIM03K1pxwRiRTr`,
	"laptopCard": `Sh6zMSDmCN6S1f3_FOb4`,
	"laptopCardBody": `wBUt3RZ8LN7ZHKqWhOBs`,
	"priceContainer": `aEU9AcSSjMlh8978Phe_`,
	"laptopDetailsSection": `zBRYHS8ZcJpIdk4BQaC6`,
	"laptopCardHeader": `foHjdstdN6n5qa_46cfp`,
	"laptopIcon": `sEYk5OJgRo0Toz8eocCR`,
	"laptopName": `UVU1gbfICyqCV3lnYo55`,
	"laptopSpecification": `Lzf5tda2BSs6wBmzLNCt`,
	"lineHeight28": `hui2F5tj1mqxg2KtrZul`,
	"laptopSpecificationValue": `KaQBYJCejWVahXFToFqW`,
	"buyButton": `LBLFJVz7CIkb9tjm6VzV`,
	"priceSection": `QUhBo947chyROnLNFJNc`,
	"price": `rItPo9CFe5D1t0sJBLDF`,
	"mrp": `S5bSjldnpNL6PIStfK6b`,
	"requestSection": `e6d_yADo6kHLwdsLQPps`,
	"searchIconBox": `F_bTdd_MtJVWaRRWFtNY`,
	"requestText": `lN8bEbj_q64pg3YBQrAj`,
	"raiseRequestBtn": `rnVOP6GrRp9uZjZRVIQr`,
	"faqCard": `tWlPCoQjQJnisq6_gna4`,
	"faqHeader": `K2D5J3mHBkJK9bwXKUxj`,
	"noBorder": `A51DZeAgt9I5FaEBpub5`,
	"faqCardBody": `TsD6CgDe5SbTorIZtfUF`,
	"termsAccordion": `DM1LuIWAfk5vhfsNc62y`,
	"termsCard": `CmjaqEVmlhjyZebyMQoQ`,
	"termsHeader": `QGnMiGfsFcJV05jNyspJ`,
	"termsCardBody": `drp2ctYNI3TGLBN0iWAW`,
	"termsTitle": `Wskqyw0X47RfoJLmkjDw`,
	"card-header": `i9ezvxQ0bdXFDn_Apsm2`,
	"accordionToggle": `tvPWqbUuWCGCNsCDV160`,
	"caret": `tGiARHM21xTHdt_r8unO`,
	"termsList": `Os59pps4qnVq4mc31zXg`
};
export default ___CSS_LOADER_EXPORT___;
