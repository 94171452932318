// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NclSSLbwLYPzTrmYNFJE {
  font-family: Poppins, sans-serif;
  height: 64px;
  background: linear-gradient(90deg, #0844a6 0%, #086590 48.96%, #088077 100%);
}
.NclSSLbwLYPzTrmYNFJE .sbIB8k5Bkq99cH9fsuSW {
  height: 100%;
  align-items: center;
}
.NclSSLbwLYPzTrmYNFJE .xOU7RwlqI4oxeXiskCYn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
}
@media screen and (max-width: 601px) {
  .NclSSLbwLYPzTrmYNFJE .xOU7RwlqI4oxeXiskCYn {
    position: relative;
    height: 48px;
    padding-right: 40px;
  }
}
.NclSSLbwLYPzTrmYNFJE .TI41t_rWPbrICV71lJsZ {
  display: flex;
  align-items: center;
}
.NclSSLbwLYPzTrmYNFJE .TI41t_rWPbrICV71lJsZ img {
  margin-right: 15px;
}
.NclSSLbwLYPzTrmYNFJE .TI41t_rWPbrICV71lJsZ span {
  font-size: 1.25em;
  color: #ffffff;
}
@media screen and (max-width: 601px) {
  .NclSSLbwLYPzTrmYNFJE {
    height: 48px;
  }
  .NclSSLbwLYPzTrmYNFJE .iEykhli1gjkBRKeWVP5K {
    width: 34px;
  }
  .NclSSLbwLYPzTrmYNFJE .GocWlaDxc7kEj1f5acRi {
    font-size: 14px !important;
    font-weight: 500;
    padding: 0 !important;
    line-height: 18px;
  }
}

.evD9MzPfC7A2OoyB1oLT {
  display: none;
  position: absolute;
  right: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 601px) {
  .evD9MzPfC7A2OoyB1oLT {
    display: block;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgvHeaderContainer": `NclSSLbwLYPzTrmYNFJE`,
	"bgvHeader": `sbIB8k5Bkq99cH9fsuSW`,
	"bgvHeaderItem": `xOU7RwlqI4oxeXiskCYn`,
	"headerItemLeft": `TI41t_rWPbrICV71lJsZ`,
	"logoImg": `iEykhli1gjkBRKeWVP5K`,
	"companyName": `GocWlaDxc7kEj1f5acRi`,
	"sidebarToggle": `evD9MzPfC7A2OoyB1oLT`
};
export default ___CSS_LOADER_EXPORT___;
