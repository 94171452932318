// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eX9Nbr2DEoUoxiBg2zWu {
  max-width: 1200px;
  margin: auto;
  position: relative;
  margin: -190px auto 24px;
}
@media only screen and (max-width: 1200px) {
  .eX9Nbr2DEoUoxiBg2zWu {
    width: 1200px;
  }
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 10px auto 7rem;
  padding: 24px 24px 33px 24px;
  font-family: Poppins, sans-serif;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .lXY2PNfm3u_nvl9Hc6uf {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .lXY2PNfm3u_nvl9Hc6uf .SKoR5KAo8egyLXmEjHGM {
  cursor: pointer;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .nq5SqbAEIl2fZCmHLgwE {
  color: #333333;
  font-size: 20px;
  font-weight: 500;
  height: 30px;
  white-space: nowrap;
  display: flex;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .rPDMXbA0YSYHpaE8IsfA {
  background: #f2f7ff;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 12px;
  margin-left: 8px;
  width: 157px;
  height: 28px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .rPDMXbA0YSYHpaE8IsfA .pAILvhtZ9id4aW4pXgGg {
  color: #2755fe;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  height: 20px;
  text-decoration: underline;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .TdNBJ1asBuvU6S2LkGUi {
  color: #333333;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .ZhYoLKJ3NmuIlA1zrMp9 {
  padding-top: 24px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .IFUn1fxcWaco78BJtIb2 {
  margin-top: 8px;
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .gsc9QZwFMwAfOijopBN2 {
  height: 1px;
  background: #dfe1e5;
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 1128px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .x4VCVRF7u9opwhut2SlV {
  color: #666666;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .XOCeHqGtKSEIKTeXyeYm {
  color: #333333;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q {
  display: flex;
  gap: 24px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q .SggZiTx6c64TUEo2llad {
  height: 62px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q .RKVZjrQeal2YYP2RTyoA {
  height: 70px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q .WR1yi_ZrNBOatKbQOhGu {
  max-height: 84px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q .ecrP1OIWIA962OH2uBdH {
  max-height: 90px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL {
  width: 360px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL .zWeIyLV1TUoJE_jK0YYi {
  width: 100%;
  margin: 0.25rem 0px;
  font-size: 80%;
  color: #dc3545;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL .EXu5aA1YudYwJiWtlpK_ {
  margin-bottom: 0 !important;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL .A4pCW3b06I57xGsEPXn9 {
  font-size: 14px;
  border: 1px solid;
  border-color: #dee0e5;
  border-radius: 4px;
  height: 38px !important;
  box-shadow: none !important;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL .Ssb8FuOJW_2PAdQUSHvl {
  display: flex;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dee0e5;
  border-radius: 4px;
  height: 38px !important;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL .Ssb8FuOJW_2PAdQUSHvl .ss01OTdpJSbSEmHrpLUH {
  width: 260px !important;
  height: 36px !important;
  border: none !important;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL .Ssb8FuOJW_2PAdQUSHvl .JW33sJiaoGq6ExtmeDNp {
  height: 36px;
  min-height: 36px;
  background-color: rgba(39, 85, 254, 0.0509803922);
  border: none;
  border-color: none;
  border-radius: 0px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL .Ssb8FuOJW_2PAdQUSHvl .vux7yb5SDxOZAYrfC2ux {
  width: 100px;
  height: 36px !important;
  padding: 0 !important;
  background-color: rgba(39, 85, 254, 0.0509803922);
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL .oLtOoxUeEAPNX3bN9ja8 {
  width: 100px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL .ZMKXBPtjOzsNNQNAHwj6 {
  margin-left: 2px;
  margin-top: 4px;
  display: flex;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL .ZMKXBPtjOzsNNQNAHwj6 .E8Z5KAyMGwasBRLgbrzy {
  border: 2px solid;
  border-color: #ff0000;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q ._f5LNumtXtZGTQ0xpPeL .ZMKXBPtjOzsNNQNAHwj6 .im141Fvnw0PjFpI3JM21 {
  align-self: center;
  color: #333333;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .jJ0imIdwppWB72RTgv0Q .FenDZ5MxPGuQ7f8HeXBl {
  color: #e97070;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .TMEpiorCLCe20_L0Ha8z {
  max-height: 180px !important;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .KZq7o7H7lPzrdPpIeSaj {
  max-height: 162px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .w53qc_PBYEUsMRQeKOIp {
  max-height: 296px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .vhjbFgU9t46PaFcdj6M0 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .vhjbFgU9t46PaFcdj6M0 .fA9N_Y9AUzmqt7MYDGml {
  color: #2755fe;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 16px;
  width: 164px;
  border-radius: 2px;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .vhjbFgU9t46PaFcdj6M0 .fA9N_Y9AUzmqt7MYDGml:hover {
  color: white;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .vhjbFgU9t46PaFcdj6M0 .VTcrq2969e6MT5GJeg7Q {
  display: flex;
  gap: 1rem;
}
.eX9Nbr2DEoUoxiBg2zWu .yYzFUXP69c5ycDMmvVuY .vhjbFgU9t46PaFcdj6M0 .Ocbu6yZ8flLmRWshVeAd {
  background-color: #2755fe;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 16px;
  width: 164px;
  border-radius: 2px;
}

.LDyYZuVPEoSiTKwnUpAm {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  display: flex;
}

.wP9G7J7iMEv_qLXNXUyD {
  margin-top: 5px;
}

.OPdiJ8wbejpxVWs5E8RA, .SzmpYHPhW5qCRDyVQv0R {
  border: none;
  border-radius: none;
  width: 338px !important;
  background-color: white;
  font-size: var(--para-font-size);
  font-weight: normal;
  line-height: 45px;
  height: 38px;
  width: 100%;
  color: #4c4c4c;
  padding: 0 12px;
}
.OPdiJ8wbejpxVWs5E8RA:focus, .SzmpYHPhW5qCRDyVQv0R:focus {
  outline: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.OPdiJ8wbejpxVWs5E8RA:hover, .SzmpYHPhW5qCRDyVQv0R:hover {
  cursor: pointer;
}
.OPdiJ8wbejpxVWs5E8RA::placeholder, .SzmpYHPhW5qCRDyVQv0R::placeholder {
  color: red;
}
.OPdiJ8wbejpxVWs5E8RA input:disabled, .SzmpYHPhW5qCRDyVQv0R input:disabled {
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  color: -internal-light-dark-color(rgb(84, 84, 84), rgb(170, 170, 170));
}
.OPdiJ8wbejpxVWs5E8RA input:disabled:hover, .SzmpYHPhW5qCRDyVQv0R input:disabled:hover {
  cursor: not-allowed;
}

.SzmpYHPhW5qCRDyVQv0R::placeHolder {
  color: black;
}

.Ocbu6yZ8flLmRWshVeAd:disabled:hover {
  cursor: not-allowed;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recordsWrapper": `eX9Nbr2DEoUoxiBg2zWu`,
	"recordsCard": `yYzFUXP69c5ycDMmvVuY`,
	"headerContainer": `lXY2PNfm3u_nvl9Hc6uf`,
	"deleteButton": `SKoR5KAo8egyLXmEjHGM`,
	"headerTitle": `nq5SqbAEIl2fZCmHLgwE`,
	"viewRequestContainer": `rPDMXbA0YSYHpaE8IsfA`,
	"viewAllRequestText": `pAILvhtZ9id4aW4pXgGg`,
	"sectionTitle": `TdNBJ1asBuvU6S2LkGUi`,
	"padding24": `ZhYoLKJ3NmuIlA1zrMp9`,
	"subInfoText": `IFUn1fxcWaco78BJtIb2`,
	"horizontalDivider": `gsc9QZwFMwAfOijopBN2`,
	"fieldLabelText": `x4VCVRF7u9opwhut2SlV`,
	"fieldText": `XOCeHqGtKSEIKTeXyeYm`,
	"rowForFields": `jJ0imIdwppWB72RTgv0Q`,
	"height62": `SggZiTx6c64TUEo2llad`,
	"height70": `RKVZjrQeal2YYP2RTyoA`,
	"height84": `WR1yi_ZrNBOatKbQOhGu`,
	"height90": `ecrP1OIWIA962OH2uBdH`,
	"fieldContainer": `_f5LNumtXtZGTQ0xpPeL`,
	"errorMessage": `zWeIyLV1TUoJE_jK0YYi`,
	"noBottomMargin": `EXu5aA1YudYwJiWtlpK_`,
	"dropDownStyle": `A4pCW3b06I57xGsEPXn9`,
	"phoneFieldContainer": `Ssb8FuOJW_2PAdQUSHvl`,
	"phoneField": `ss01OTdpJSbSEmHrpLUH`,
	"customInnerStyle": `JW33sJiaoGq6ExtmeDNp`,
	"countryCodeDropDown": `vux7yb5SDxOZAYrfC2ux`,
	"dateField": `oLtOoxUeEAPNX3bN9ja8`,
	"staffIdCheckBoxContainer": `ZMKXBPtjOzsNNQNAHwj6`,
	"checkBoxField": `E8Z5KAyMGwasBRLgbrzy`,
	"staffIdText": `im141Fvnw0PjFpI3JM21`,
	"required": `FenDZ5MxPGuQ7f8HeXBl`,
	"maxHeight": `TMEpiorCLCe20_L0Ha8z`,
	"personalDetailsContainer": `KZq7o7H7lPzrdPpIeSaj`,
	"backgroundDetailsContainer": `w53qc_PBYEUsMRQeKOIp`,
	"footer": `vhjbFgU9t46PaFcdj6M0`,
	"cancelButton": `fA9N_Y9AUzmqt7MYDGml`,
	"btnContainer": `VTcrq2969e6MT5GJeg7Q`,
	"saveButton": `Ocbu6yZ8flLmRWshVeAd`,
	"fieldBorder": `LDyYZuVPEoSiTKwnUpAm`,
	"datePickerCalendarIcon": `wP9G7J7iMEv_qLXNXUyD`,
	"datePicker": `OPdiJ8wbejpxVWs5E8RA`,
	"datePickerBlack": `SzmpYHPhW5qCRDyVQv0R`
};
export default ___CSS_LOADER_EXPORT___;
