// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/LoginPageFooter.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cPeVAYTys6YXvd1L3k3c {
  width: 100%;
  margin: 30px 0px;
}
.cPeVAYTys6YXvd1L3k3c .eI92BC3ap2C2eHkUpx6i {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
  color: #333333;
}
.cPeVAYTys6YXvd1L3k3c .eI92BC3ap2C2eHkUpx6i .aXI0rU1znCkgZkgR7kgW {
  height: 200px;
  margin: 50px;
}
.cPeVAYTys6YXvd1L3k3c .eI92BC3ap2C2eHkUpx6i .Ah6qz1dGoIRJoMpEusQh {
  width: auto;
  background: none;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #2755fe;
  padding: 0;
  text-align: center;
  margin-top: 24px;
  box-shadow: unset;
  height: 20px;
}
@media only screen and (max-width: 670px) {
  .cPeVAYTys6YXvd1L3k3c .eI92BC3ap2C2eHkUpx6i .Ah6qz1dGoIRJoMpEusQh {
    width: 100%;
  }
}
.cPeVAYTys6YXvd1L3k3c .eI92BC3ap2C2eHkUpx6i .U1qWnF56JYb01C__771b {
  width: 100%;
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  height: 48px;
  margin-top: 20px;
}
.cPeVAYTys6YXvd1L3k3c .K0nYvDXEnjdsSG_iRSeF .EOsjcn0h1YnpK0RZriy8 {
  margin-bottom: 24px;
}
.cPeVAYTys6YXvd1L3k3c .K0nYvDXEnjdsSG_iRSeF .EOsjcn0h1YnpK0RZriy8 .iKSH9fj0k2h6z4X2ysu8 {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 44px;
  color: #333333;
  margin-top: 4px;
  margin-bottom: 44px;
}
.cPeVAYTys6YXvd1L3k3c .K0nYvDXEnjdsSG_iRSeF .EOsjcn0h1YnpK0RZriy8 .IvHpXg9nT5F58qyvVBnW {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  margin-top: 24px;
}
.cPeVAYTys6YXvd1L3k3c .K0nYvDXEnjdsSG_iRSeF .EOsjcn0h1YnpK0RZriy8 .dlkGCvKQt58oRFevUHuQ {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #00a72a;
  margin-top: 50px;
  margin-bottom: 60px;
}
.cPeVAYTys6YXvd1L3k3c .K0nYvDXEnjdsSG_iRSeF .EOsjcn0h1YnpK0RZriy8 .dlkGCvKQt58oRFevUHuQ svg {
  margin-right: 18px;
}
.cPeVAYTys6YXvd1L3k3c .K0nYvDXEnjdsSG_iRSeF .EOsjcn0h1YnpK0RZriy8 p {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #333333;
  margin: 0;
}
.cPeVAYTys6YXvd1L3k3c .K0nYvDXEnjdsSG_iRSeF .EOsjcn0h1YnpK0RZriy8 .U1qWnF56JYb01C__771b {
  width: 100%;
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  height: 48px;
  margin-top: 0;
}
.cPeVAYTys6YXvd1L3k3c .K0nYvDXEnjdsSG_iRSeF .M7XAe2ccAEaU3zo6wfst {
  margin-top: -24px;
  margin-bottom: 14px;
  font-size: 11px;
  color: #dc3545;
  text-align: left;
}
.cPeVAYTys6YXvd1L3k3c .K0nYvDXEnjdsSG_iRSeF label {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4c4c4c;
  margin-bottom: 9px;
}
.cPeVAYTys6YXvd1L3k3c .K0nYvDXEnjdsSG_iRSeF input {
  background: #ffffff;
  border: 1px solid #c2cfff;
  border-radius: 4px;
}
.cPeVAYTys6YXvd1L3k3c .K0nYvDXEnjdsSG_iRSeF button {
  width: 100%;
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  height: 48px;
  margin-top: 4px;
}
.cPeVAYTys6YXvd1L3k3c .ocDPj_c8jW3gcr4hFpzW {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: 100% 310px;
}
@media only screen and (max-width: 670px) {
  .cPeVAYTys6YXvd1L3k3c .ocDPj_c8jW3gcr4hFpzW {
    min-width: 670px;
  }
}
.cPeVAYTys6YXvd1L3k3c .ocDPj_c8jW3gcr4hFpzW .JWR5XeZ21ZixtaoT5qPH p {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
}
.cPeVAYTys6YXvd1L3k3c .ocDPj_c8jW3gcr4hFpzW .CvKrdvNuP3p67vC8oNun {
  padding-top: 124px;
  margin-bottom: 16px;
}
.cPeVAYTys6YXvd1L3k3c .ocDPj_c8jW3gcr4hFpzW .UPEaGkGYwbtQ5PEyEp9v {
  margin-top: 16px;
  padding-bottom: 50px;
}

.jvRWL00LlQ2W4DO5AUA8 {
  font-size: 16px;
  color: #dc3545;
  margin-top: 10px;
}

.vZ6L0c7vM1v8oKkzuK_Q {
  max-width: 1440px !important;
  width: 640px !important;
  margin: auto !important;
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forgotPasswordWrapper": `cPeVAYTys6YXvd1L3k3c`,
	"passwordSet": `eI92BC3ap2C2eHkUpx6i`,
	"passwordSetIcon": `aXI0rU1znCkgZkgR7kgW`,
	"forgotPasswordBtn": `Ah6qz1dGoIRJoMpEusQh`,
	"goBackBtn": `U1qWnF56JYb01C__771b`,
	"passwordEmailCard": `K0nYvDXEnjdsSG_iRSeF`,
	"emailMessage": `EOsjcn0h1YnpK0RZriy8`,
	"recoveryEmail": `iKSH9fj0k2h6z4X2ysu8`,
	"information": `IvHpXg9nT5F58qyvVBnW`,
	"successMessage": `dlkGCvKQt58oRFevUHuQ`,
	"errorMsg": `M7XAe2ccAEaU3zo6wfst`,
	"footer": `ocDPj_c8jW3gcr4hFpzW`,
	"footerContent": `JWR5XeZ21ZixtaoT5qPH`,
	"footerContentMail": `CvKrdvNuP3p67vC8oNun`,
	"footerContentAddress": `UPEaGkGYwbtQ5PEyEp9v`,
	"errorMessage": `jvRWL00LlQ2W4DO5AUA8`,
	"resetPasswordWrapper": `vZ6L0c7vM1v8oKkzuK_Q`
};
export default ___CSS_LOADER_EXPORT___;
