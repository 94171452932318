// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e6WzhijFwmQCoSlnfFTo {
  line-height: 24px;
}
.e6WzhijFwmQCoSlnfFTo .Ufbl4lhSlDksKSVSJjAM .sRBHwyDkb_yvlJINhjFg {
  text-align: center;
}
.e6WzhijFwmQCoSlnfFTo .Ufbl4lhSlDksKSVSJjAM .Vo2D4NSGs3MoibR9bvjd {
  padding-left: 0;
}
.e6WzhijFwmQCoSlnfFTo .g_46fcmsoGCDPLq4gtlw {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
}
.e6WzhijFwmQCoSlnfFTo .ZvZ4kz7RCSibJZtOISKw {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.e6WzhijFwmQCoSlnfFTo .ok_HOk3zTxX62lGGFork button {
  border-radius: 2px;
  padding: 5px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.e6WzhijFwmQCoSlnfFTo .ok_HOk3zTxX62lGGFork .LmKWMeIMKfDi2sjuX3Pg {
  background-color: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.e6WzhijFwmQCoSlnfFTo .ok_HOk3zTxX62lGGFork .v5mVfs0lN0CQGi1fSzxg {
  background: #f5f5f5;
  color: #bfbfbf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
.e6WzhijFwmQCoSlnfFTo .ok_HOk3zTxX62lGGFork .WTHr1ZaNsXTPuRlqjNiX {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
}
.e6WzhijFwmQCoSlnfFTo .ok_HOk3zTxX62lGGFork .b_93eDRfXAQZQ9uJQKIl {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
  cursor: not-allowed;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"consentConfirmModal": `e6WzhijFwmQCoSlnfFTo`,
	"confirModalBody": `Ufbl4lhSlDksKSVSJjAM`,
	"questionImage": `sRBHwyDkb_yvlJINhjFg`,
	"confirmTextContainer": `Vo2D4NSGs3MoibR9bvjd`,
	"confirmModalHeading": `g_46fcmsoGCDPLq4gtlw`,
	"confirmText": `ZvZ4kz7RCSibJZtOISKw`,
	"confirmModalFooter": `ok_HOk3zTxX62lGGFork`,
	"activeConfirmBtn": `LmKWMeIMKfDi2sjuX3Pg`,
	"disabledConfirmBtn": `v5mVfs0lN0CQGi1fSzxg`,
	"cancelConfirmBtn": `WTHr1ZaNsXTPuRlqjNiX`,
	"disabledCancelConfirmBtn": `b_93eDRfXAQZQ9uJQKIl`
};
export default ___CSS_LOADER_EXPORT___;
