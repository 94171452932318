// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pUQok90ivHFtau4w5cYh {
  border-radius: unset;
  color: #2755fe;
  padding: 1px 8px;
  border-color: rgba(153, 153, 153, 0.2);
}
.pUQok90ivHFtau4w5cYh:disabled {
  cursor: not-allowed;
  color: rgba(8, 8, 8, 0.2);
}
.pUQok90ivHFtau4w5cYh:disabled:hover {
  background-color: transparent;
  color: rgba(8, 8, 8, 0.2);
  border: transparent;
}

.fuLO1IQSZALPdbJKBksG {
  color: #2755fe;
  padding: 1px 16px;
  border-radius: unset;
}
.fuLO1IQSZALPdbJKBksG:hover {
  background-color: transparent;
  color: #2755fe;
  border: transparent;
}

._4lsFnDPKQHr7iwMnkWk {
  width: 36px !important;
  height: 36px !important;
  border-radius: 4px !important;
  font-size: 30px !important;
  display: flex !important;
  align-items: center !important;
  border-color: rgba(153, 153, 153, 0.2) !important;
  background-color: #ffffff !important;
  justify-content: center !important;
}
._4lsFnDPKQHr7iwMnkWk:hover {
  background-color: #ffffff !important;
  color: #2755fe !important;
}

.Z6RcWe928hLmSv61_nJs {
  margin-inline: 12px !important;
  border-color: rgba(153, 153, 153, 0.2) !important;
  background-color: #ffffff !important;
  width: 64px !important;
  border-radius: 4px !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counterButton": `pUQok90ivHFtau4w5cYh`,
	"numberButton": `fuLO1IQSZALPdbJKBksG`,
	"size36": `_4lsFnDPKQHr7iwMnkWk`,
	"customNumberButton": `Z6RcWe928hLmSv61_nJs`
};
export default ___CSS_LOADER_EXPORT___;
