// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W69IrFwHFtSx1x0UN1hu .FRx99W5Fj1XgJhJOhtgw {
  display: block;
  margin: 0 auto;
  border: none;
  height: 100vh;
  width: 100vw;
}
.W69IrFwHFtSx1x0UN1hu .K4jZHqPD8I51qSaeTBqf {
  margin: 0px;
  background: #0e0e0e;
  position: relative;
  height: 100vh;
}
.W69IrFwHFtSx1x0UN1hu .P6WGHmU3Iw92sjrwq7Uy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100vh;
  pointer-events: none;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"renderDocuments": `W69IrFwHFtSx1x0UN1hu`,
	"iframe": `FRx99W5Fj1XgJhJOhtgw`,
	"imgDiv": `K4jZHqPD8I51qSaeTBqf`,
	"img": `P6WGHmU3Iw92sjrwq7Uy`
};
export default ___CSS_LOADER_EXPORT___;
