// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fRG2Ornhk0HNuxDgCoMo {
  padding-top: 0.5rem;
}

.kv3QxUVB5ajmLLlxLHrw {
  margin: 40px 0px;
}

.aPo2reGyLqEUWWz2X4x8 {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.uWYvizD4DvWSkuu0e7st {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.KbLjodYKbwxxcdDWhApR {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  color: #2755fe;
  border: 1px solid #2755fe;
}

.kdy6YW9h7UVRT6Rk0BZk {
  background-color: #037bff;
  color: white;
  padding: 0.5rem;
  border: none;
}

.F5bOOkVV7iTrkPmlqU_f {
  background-color: #1bae9f;
  color: white;
  padding: 0.5rem;
  border: none;
}

.kdy6YW9h7UVRT6Rk0BZk:hover {
  background-color: #076dda;
}

.F5bOOkVV7iTrkPmlqU_f:hover {
  background-color: #11a294;
}

.cTO4T59y3LMdP5OzU_xB {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.Wd8RSM7K7pzkwlbtlQmK {
  border: 1px solid red;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": `fRG2Ornhk0HNuxDgCoMo`,
	"generateTokenButton": `kv3QxUVB5ajmLLlxLHrw`,
	"generateTokenButton__heading": `aPo2reGyLqEUWWz2X4x8`,
	"step1FooterContainer": `uWYvizD4DvWSkuu0e7st`,
	"goBackBtn": `KbLjodYKbwxxcdDWhApR`,
	"connectButton": `kdy6YW9h7UVRT6Rk0BZk`,
	"connectedButton": `F5bOOkVV7iTrkPmlqU_f`,
	"listItems": `cTO4T59y3LMdP5OzU_xB`,
	"removeButton": `Wd8RSM7K7pzkwlbtlQmK`
};
export default ___CSS_LOADER_EXPORT___;
