// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cFEVPRI9whTzN3ZrUPM3 {
  filter: drop-shadow(1px 0px 0px rgba(225, 225, 225, 0.5)) drop-shadow(-1px 0px 0px rgba(225, 225, 225, 0.5)) drop-shadow(0px 1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(0px -1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(1px 1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(-1px -1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(-1px 1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(1px -1px 0px rgba(225, 225, 225, 0.5));
  margin-top: 3px;
}

.OwrfFV1I42MEfKvx40UI {
  filter: drop-shadow(1px 0px 0px rgba(39, 85, 254, 0.5)) drop-shadow(-1px 0px 0px rgba(39, 85, 254, 0.25)) drop-shadow(0px 1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(0px -1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(1px 1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(-1px -1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(-1px 1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(1px -1px 0px rgba(39, 85, 254, 0.25));
}

.FmLVCV5FbximWfA32dCw {
  color: #2755fe;
  cursor: pointer;
}

.NmdhQWCnPGfS4TBJNTb_ {
  background-color: #ffffff;
  margin-bottom: 32px;
  border: none;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA {
  padding: 24px;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA .dBsxFJe96fYtGC4eKtrq .wdDmOo3_InZPJxyW2H10 .y0y1VnPDWbTKghKLB0xS {
  color: #333333;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 3px;
  line-height: 18.15px;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA .dBsxFJe96fYtGC4eKtrq .wdDmOo3_InZPJxyW2H10 .fIsPBuqEJ5kCRLmKVRwW {
  color: #4f4f4f;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 3px;
  line-height: 18.15px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA .dBsxFJe96fYtGC4eKtrq .wdDmOo3_InZPJxyW2H10 .vyJMKYhLjOIzaBZHfqz7:hover {
  cursor: pointer;
  background: #ebefff;
  border: 1px #ebefff solid;
  border-radius: 50%;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA .dBsxFJe96fYtGC4eKtrq .wdDmOo3_InZPJxyW2H10 .nBV1VphFuYt7XsgpQyJX {
  font-weight: 700;
  font-size: 13px;
  color: #595959;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA .dBsxFJe96fYtGC4eKtrq .wdDmOo3_InZPJxyW2H10 .oZVATT4D0f_FbyNV2jHA {
  margin-top: 8px;
  display: flex;
  padding: 9px 16px;
  background: #e6f7ff;
  align-items: baseline;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA .dBsxFJe96fYtGC4eKtrq .wdDmOo3_InZPJxyW2H10 .MFNGOvLTN2BJdpwtJcYK {
  color: #262626;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: normal;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA .dBsxFJe96fYtGC4eKtrq .wdDmOo3_InZPJxyW2H10 .R6eohxFd9p9pfHSQbpAB {
  margin-top: 24px;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA .dBsxFJe96fYtGC4eKtrq .wdDmOo3_InZPJxyW2H10 .R6eohxFd9p9pfHSQbpAB .tAuhbfhkmkgaCx1EtX8A {
  color: #45a41c;
  font-weight: 600;
  font-size: 12px;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA .dBsxFJe96fYtGC4eKtrq .tOIqIcYMg0_FKViJKlUI .XmFc_uBbe0NvOw8YxAEt {
  margin-bottom: 0;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA .dBsxFJe96fYtGC4eKtrq .tOIqIcYMg0_FKViJKlUI .VwBgKRWNzv9NX5qjlQzV {
  font-family: Roboto;
  font-weight: 500;
  font-size: 32px;
  color: #333333;
}
.NmdhQWCnPGfS4TBJNTb_ .hpN8OXFoc0aVGmKXp6OA .dBsxFJe96fYtGC4eKtrq .tOIqIcYMg0_FKViJKlUI .LAhdwRW1Rn3YUWmmlR3z {
  font-weight: normal;
  font-size: 14px;
  color: #999999;
  font-family: Roboto;
}
.NmdhQWCnPGfS4TBJNTb_ .JfwCIJoDVMSU07itGVqr {
  margin-bottom: 6px;
}

.veheQqu3D02UU6YVD3VA {
  margin-left: 0.2rem;
  display: flex;
  align-items: center;
}

.dWrt38CM5LJs41B_2XbA {
  height: 14px;
}

.p84Fs3OGor8cKf3N4gBC {
  font-size: 13px;
  font-weight: 600;
  color: #d3455b;
  margin-left: 0.3rem;
  vertical-align: text-bottom;
}

.NR4BR4GzpbCxGvpoFrb5 {
  font-size: 13px;
  font-weight: 600;
  color: #ffc107;
  margin-left: 0.3rem;
  vertical-align: text-bottom;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `cFEVPRI9whTzN3ZrUPM3`,
	"showBorder": `OwrfFV1I42MEfKvx40UI`,
	"addNickName": `FmLVCV5FbximWfA32dCw`,
	"packageDetailsCard": `NmdhQWCnPGfS4TBJNTb_`,
	"packageDetailsCardBody": `hpN8OXFoc0aVGmKXp6OA`,
	"packageDetailsRow": `dBsxFJe96fYtGC4eKtrq`,
	"packageDetailsColLeft": `wdDmOo3_InZPJxyW2H10`,
	"subtypeName": `y0y1VnPDWbTKghKLB0xS`,
	"friendlyName": `fIsPBuqEJ5kCRLmKVRwW`,
	"createIcon": `vyJMKYhLjOIzaBZHfqz7`,
	"balanceText": `nBV1VphFuYt7XsgpQyJX`,
	"balanceSharedWithContainer": `oZVATT4D0f_FbyNV2jHA`,
	"balanceSharedWithContainerDescription": `MFNGOvLTN2BJdpwtJcYK`,
	"checksIncludedSection": `R6eohxFd9p9pfHSQbpAB`,
	"checkIncludedText": `tAuhbfhkmkgaCx1EtX8A`,
	"packageDetailsColRight": `tOIqIcYMg0_FKViJKlUI`,
	"amountInfoContainer": `XmFc_uBbe0NvOw8YxAEt`,
	"amount": `VwBgKRWNzv9NX5qjlQzV`,
	"amountDescription": `LAhdwRW1Rn3YUWmmlR3z`,
	"marginBottom6": `JfwCIJoDVMSU07itGVqr`,
	"packgeUnitIndication": `veheQqu3D02UU6YVD3VA`,
	"unitIndicationIcon": `dWrt38CM5LJs41B_2XbA`,
	"unitNillIndicationText": `p84Fs3OGor8cKf3N4gBC`,
	"unitLowIndicationText": `NR4BR4GzpbCxGvpoFrb5`
};
export default ___CSS_LOADER_EXPORT___;
