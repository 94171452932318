// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZtcPxG8aPlX1lRl148wu {
  filter: drop-shadow(1px 0px 0px rgba(225, 225, 225, 0.5)) drop-shadow(-1px 0px 0px rgba(225, 225, 225, 0.5)) drop-shadow(0px 1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(0px -1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(1px 1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(-1px -1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(-1px 1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(1px -1px 0px rgba(225, 225, 225, 0.5));
}

.w8q3jDPunu1xRs7u7Ory {
  filter: drop-shadow(1px 0px 0px rgba(39, 85, 254, 0.5)) drop-shadow(-1px 0px 0px rgba(39, 85, 254, 0.25)) drop-shadow(0px 1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(0px -1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(1px 1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(-1px -1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(-1px 1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(1px -1px 0px rgba(39, 85, 254, 0.25));
}

.ay0gSI0WLIoEcbRH0CBY {
  background-color: #ffffff;
  margin-bottom: 32px;
  clip-path: polygon(100% 0, 100% 82%, 93% 100%, 0 100%, 0 0);
  border: none;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt {
  padding: 24px;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .EfLa66cS3hjF3HN7uPIJ .pDJeEk0qps91DDbDICUu {
  color: #333333;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 3px;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .EfLa66cS3hjF3HN7uPIJ .f2EiNuaTxWOxrV19S4wN {
  color: #4f4f4f;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 3px;
  line-height: 18.15px;
  display: flex;
  flex-direction: row;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .EfLa66cS3hjF3HN7uPIJ .N1s14W0B0VgVE6bxldJN {
  font-weight: 600;
  font-size: 14px;
  color: #595959;
  margin-bottom: 3px;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .EfLa66cS3hjF3HN7uPIJ .AJu7yilWkEU64k8VO2in {
  margin-top: 8px;
  display: flex;
  padding: 9px 16px;
  background: #e6f7ff;
  align-items: baseline;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .EfLa66cS3hjF3HN7uPIJ .xitqh3uWIR5ltQf4xopr {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .EfLa66cS3hjF3HN7uPIJ .k8fTrS1edLEIGawhgv_1 {
  margin-top: 24px;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .EfLa66cS3hjF3HN7uPIJ .k8fTrS1edLEIGawhgv_1 .AllXx20b8REDVelk4yNC {
  color: #45a41c;
  font-weight: 600;
  font-size: 12px;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .wBioWWgqDkOz1qTVbvf9 {
  flex-direction: row-reverse;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .wBioWWgqDkOz1qTVbvf9 .XyPrcvw0pH_bkLnWCrxX {
  background: #2755fe;
  border-radius: 2px;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .wBioWWgqDkOz1qTVbvf9 .Dt0NW5mmAE3HlvQmQNCN {
  background: #c4c4c4;
  font-weight: 500;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .wBioWWgqDkOz1qTVbvf9 .RPIrMQfpRmIINT0vFyJK {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .wBioWWgqDkOz1qTVbvf9 .LoP1Rzd4x1eNUV4C4fOt {
  color: #2755fe;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .wBioWWgqDkOz1qTVbvf9 .uA3h9vvpBHg95fJKBiuG {
  margin-bottom: 0;
}
.ay0gSI0WLIoEcbRH0CBY .lgp9ex2mLQdDC0WmWKBt .l2byYZo_C2akzoLOw8WP .wBioWWgqDkOz1qTVbvf9 .jndNWGIN7YJikO3ApfTj {
  font-family: Roboto;
  font-weight: 500;
  font-size: 32px;
  color: #333333;
}
.ay0gSI0WLIoEcbRH0CBY .EkDL_m_8dW9IGHd0PtpR {
  margin-bottom: 6px;
}

._Sk9A5F0Ei3dd7koUk6o {
  margin-left: 0.2rem;
}

.pD_kiqcpmZ8SYp2BJD8t {
  height: 14px;
}

.mfEtN0n8m6pinMHj_vZp {
  font-size: 13px;
  color: #d3455b;
  margin-left: 0.3rem;
  vertical-align: text-bottom;
}

.EkPAWmJp6iGzgBtLVF7r {
  font-size: 13px;
  color: #ffc107;
  margin-left: 0.3rem;
  vertical-align: text-bottom;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ZtcPxG8aPlX1lRl148wu`,
	"showBorder": `w8q3jDPunu1xRs7u7Ory`,
	"packageDetailsCard": `ay0gSI0WLIoEcbRH0CBY`,
	"packageDetailsCardBody": `lgp9ex2mLQdDC0WmWKBt`,
	"packageDetailsRow": `l2byYZo_C2akzoLOw8WP`,
	"packageDetailsColLeft": `EfLa66cS3hjF3HN7uPIJ`,
	"subtypeName": `pDJeEk0qps91DDbDICUu`,
	"friendlyName": `f2EiNuaTxWOxrV19S4wN`,
	"balanceText": `N1s14W0B0VgVE6bxldJN`,
	"balanceSharedWithContainer": `AJu7yilWkEU64k8VO2in`,
	"balanceSharedWithContainerDescription": `xitqh3uWIR5ltQf4xopr`,
	"checksIncludedSection": `k8fTrS1edLEIGawhgv_1`,
	"checkIncludedText": `AllXx20b8REDVelk4yNC`,
	"buttonGrid": `wBioWWgqDkOz1qTVbvf9`,
	"button": `XyPrcvw0pH_bkLnWCrxX`,
	"buttonDisabled": `Dt0NW5mmAE3HlvQmQNCN`,
	"isCustomisableText": `RPIrMQfpRmIINT0vFyJK`,
	"customiseButton": `LoP1Rzd4x1eNUV4C4fOt`,
	"amountInfoContainer": `uA3h9vvpBHg95fJKBiuG`,
	"amount": `jndNWGIN7YJikO3ApfTj`,
	"marginBottom6": `EkDL_m_8dW9IGHd0PtpR`,
	"packgeUnitIndication": `_Sk9A5F0Ei3dd7koUk6o`,
	"unitIndicationIcon": `pD_kiqcpmZ8SYp2BJD8t`,
	"unitNillIndicationText": `mfEtN0n8m6pinMHj_vZp`,
	"unitLowIndicationText": `EkPAWmJp6iGzgBtLVF7r`
};
export default ___CSS_LOADER_EXPORT___;
