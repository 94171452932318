// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.venr9p2tds6AuweCDGue {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(91, 90, 90, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cr1EVaXbbqkIvkWRW6C8 {
  width: 785px;
  height: 454px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 5px;
  margin-left: 100px;
  font-family: "Poppins", sans-serif;
  padding: 24px 32px;
  position: relative;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
}

.h7FNX373zaUmVFUrZd44 {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 25px;
}

.iIH9N2OjR5jrlmggQbkr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.y6wbyHO87cy8HpS_drNh {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: #333333;
}

.o2fnZgEIGbvsb0FwwTtw {
  font-size: 14px;
  font-weight: 600;
  color: #348a38;
  width: 184px;
  height: 36px;
  border-radius: 30px;
  background: #e4f8e5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
}

.f_I8DH9XRVUrriJP6vZg {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
  margin-top: 24px;
}

.WEcblrkywblIiLMrkJbs {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.Da3m_n7Pnt9WUC1Yv62w {
  font-weight: 500;
  font-family: Inter;
}

.Qei7GOKKbABUaObHuiiw {
  height: 182px;
  width: 721px;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  margin-left: 7px;
}
.Qei7GOKKbABUaObHuiiw .KIvqUzfOx0StZDnMwWuk {
  width: 205px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15px;
}
.Qei7GOKKbABUaObHuiiw .KIvqUzfOx0StZDnMwWuk .pCdO8Moax4BsmDCLmY1V {
  color: #333333;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  word-wrap: break-word;
}

.s_fdpQ8UaEBGxzA_bgLm {
  width: 200px;
  height: 182px;
  padding-top: 15px;
}

.SOHwn4Yah4iUhWu4S0FK {
  width: 100%;
  height: 118px;
  display: flex;
  align-items: center;
}

.SOHwn4Yah4iUhWu4S0FK img {
  height: 90%;
  object-fit: contain;
  margin-left: 0;
}

.Bgg1YcQjZC2weUmQytwR {
  border-left: 1px solid #c0c0c0;
  height: 165px;
}

.g_uJu5HAJb7SoQ6DxtRH {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 18px;
  margin-top: 30px;
}
.g_uJu5HAJb7SoQ6DxtRH button {
  border-radius: 2px;
  width: 150px;
  padding: 8px, 16px, 8px, 16px;
  font-size: 16px;
  line-height: 24px;
  height: 42px;
  font-weight: 500;
}

.jxqrKiC3YKZ8r0HjkL6a {
  background: #ffffff;
  border: 1px solid #2755fe;
  color: #2755fe;
}
.jxqrKiC3YKZ8r0HjkL6a:hover {
  background: #2755fe;
  color: #ffffff;
}

.x2YEX1jdPHc3OYn1hcGW {
  background: #2755fe;
  border: 1px solid #2755fe;
  color: #ffffff;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-overlay": `venr9p2tds6AuweCDGue`,
	"modalContainer": `cr1EVaXbbqkIvkWRW6C8`,
	"close": `h7FNX373zaUmVFUrZd44`,
	"header": `iIH9N2OjR5jrlmggQbkr`,
	"title": `y6wbyHO87cy8HpS_drNh`,
	"timex": `o2fnZgEIGbvsb0FwwTtw`,
	"sub-heading": `f_I8DH9XRVUrriJP6vZg`,
	"sub-heading-item": `WEcblrkywblIiLMrkJbs`,
	"dolar": `Da3m_n7Pnt9WUC1Yv62w`,
	"stepContainer": `Qei7GOKKbABUaObHuiiw`,
	"stepBox": `KIvqUzfOx0StZDnMwWuk`,
	"stepText": `pCdO8Moax4BsmDCLmY1V`,
	"info-group": `s_fdpQ8UaEBGxzA_bgLm`,
	"image": `SOHwn4Yah4iUhWu4S0FK`,
	"divider": `Bgg1YcQjZC2weUmQytwR`,
	"buttonGroup": `g_uJu5HAJb7SoQ6DxtRH`,
	"remind": `jxqrKiC3YKZ8r0HjkL6a`,
	"setup": `x2YEX1jdPHc3OYn1hcGW`
};
export default ___CSS_LOADER_EXPORT___;
