// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VYoH7jlphetp1FDV1q7g {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.VYoH7jlphetp1FDV1q7g .ruvWXisSveOZc0Zgq_l7 {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 20px;
}
.VYoH7jlphetp1FDV1q7g .hXasa1YlMX3mDxK0dL2h {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
}
.VYoH7jlphetp1FDV1q7g .eIO_0_QELTLGn5CCw_SP {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  margin-top: 50px;
  align-items: center;
  gap: 15px;
  padding: 10px 15px;
  background-color: antiquewhite;
  border-radius: 10px;
}
.VYoH7jlphetp1FDV1q7g .eIO_0_QELTLGn5CCw_SP img {
  cursor: pointer;
}
.VYoH7jlphetp1FDV1q7g .yg12GkrHplaEyJk_98GZ {
  display: flex;
  gap: 28px;
  margin-top: 20px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"finishContainer": `VYoH7jlphetp1FDV1q7g`,
	"finishHeading": `ruvWXisSveOZc0Zgq_l7`,
	"finishText": `hXasa1YlMX3mDxK0dL2h`,
	"downloadBtnContainer": `eIO_0_QELTLGn5CCw_SP`,
	"go_back": `yg12GkrHplaEyJk_98GZ`
};
export default ___CSS_LOADER_EXPORT___;
