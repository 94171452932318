// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H99tDiS1s2qODzGVhWyi .LE8r3NfanWrTRE2l2dxq {
  font-size: 16px;
}
.H99tDiS1s2qODzGVhWyi .I3YW8fBT42ryTt6HTjBA {
  font-size: 16px;
  padding: 20px 20px 20px 0px;
}
.H99tDiS1s2qODzGVhWyi .I3YW8fBT42ryTt6HTjBA .vEKU_B2jEPbCTXw_mgaA {
  padding: 0px 9px;
  color: #2755fe;
}
.H99tDiS1s2qODzGVhWyi .I3YW8fBT42ryTt6HTjBA .vEKU_B2jEPbCTXw_mgaA:hover {
  padding: 0px 9px;
  color: #2755fe;
  cursor: pointer;
}

.kLYCCKiWxFlqIDeCumXa {
  color: #fff;
  margin: 10px 10px 6px 0px;
  width: 136px;
  height: 48px;
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyDiv": `H99tDiS1s2qODzGVhWyi`,
	"heading1": `LE8r3NfanWrTRE2l2dxq`,
	"heading2": `I3YW8fBT42ryTt6HTjBA`,
	"aTag": `vEKU_B2jEPbCTXw_mgaA`,
	"btn": `kLYCCKiWxFlqIDeCumXa`
};
export default ___CSS_LOADER_EXPORT___;
