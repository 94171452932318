// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gZDArM7Y9lP8MMqzU0wT {
  line-height: 24px;
}
.gZDArM7Y9lP8MMqzU0wT .wf75_hrgYPiQfP6gIEGf .F2sOy70gAJA7_gKYvBcA {
  text-align: center;
}
.gZDArM7Y9lP8MMqzU0wT .wf75_hrgYPiQfP6gIEGf .qlvdh48L6iYrf4pgFw4w {
  padding-left: 0;
}
.gZDArM7Y9lP8MMqzU0wT .JBIlccQRMECWxh3LkQDy {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
}
.gZDArM7Y9lP8MMqzU0wT .tv_XxQAql2IpwghfOUgH {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.gZDArM7Y9lP8MMqzU0wT .jmYqNIdC8qHBAFBbu4mP button {
  border-radius: 2px;
  padding: 5px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.gZDArM7Y9lP8MMqzU0wT .jmYqNIdC8qHBAFBbu4mP .k7nc2NUnemu7XiqaFvYg {
  background-color: #d3455b;
  color: #ffffff;
  cursor: pointer;
}
.gZDArM7Y9lP8MMqzU0wT .jmYqNIdC8qHBAFBbu4mP .NwuE2tdQSJHLDjwVdPFw {
  background-color: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.gZDArM7Y9lP8MMqzU0wT .jmYqNIdC8qHBAFBbu4mP .g3Cq60VqbXqNG9BrYzPg {
  background: #f5f5f5;
  color: #bfbfbf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
.gZDArM7Y9lP8MMqzU0wT .jmYqNIdC8qHBAFBbu4mP .T45uTlS1_psf0il7PIAQ {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
}
.gZDArM7Y9lP8MMqzU0wT .jmYqNIdC8qHBAFBbu4mP .TYC5CoZ3xsLP006V7Rls {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
  cursor: not-allowed;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationModal": `gZDArM7Y9lP8MMqzU0wT`,
	"confirModalBody": `wf75_hrgYPiQfP6gIEGf`,
	"questionImage": `F2sOy70gAJA7_gKYvBcA`,
	"confirmTextContainer": `qlvdh48L6iYrf4pgFw4w`,
	"confirmModalHeading": `JBIlccQRMECWxh3LkQDy`,
	"confirmText": `tv_XxQAql2IpwghfOUgH`,
	"confirmModalFooter": `jmYqNIdC8qHBAFBbu4mP`,
	"deleteBtn": `k7nc2NUnemu7XiqaFvYg`,
	"activeConfirmBtn": `NwuE2tdQSJHLDjwVdPFw`,
	"disabledConfirmBtn": `g3Cq60VqbXqNG9BrYzPg`,
	"cancelConfirmBtn": `T45uTlS1_psf0il7PIAQ`,
	"disabledCancelConfirmBtn": `TYC5CoZ3xsLP006V7Rls`
};
export default ___CSS_LOADER_EXPORT___;
