// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._gpNFpkHvdOgXpPPBdVU {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  padding: 20px 24px;
  margin-bottom: 24px;
  font-family: Poppins, sans-serif;
}
._gpNFpkHvdOgXpPPBdVU .QZopmhnty3tZb975Uo9r {
  display: flex;
  align-items: center;
  justify-content: end;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addAlumniContainerWrapper": `_gpNFpkHvdOgXpPPBdVU`,
	"addAlumniBox": `QZopmhnty3tZb975Uo9r`
};
export default ___CSS_LOADER_EXPORT___;
