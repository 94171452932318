// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=file] {
  display: none;
}

.geW6xKWJZwYmEeoIR72q {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  width: 100%;
  display: inline-block;
  margin-bottom: 32px;
}
.geW6xKWJZwYmEeoIR72q .FGRe_t5ebfBP427OQmHl {
  float: right;
  line-height: 38px !important;
  margin-bottom: 0 !important;
  background: #ebedf2;
  border: 1px solid #e7e9ef;
  border-radius: 0px 4px 4px 0px;
  width: 80px;
  text-align: center;
  cursor: pointer;
}
.geW6xKWJZwYmEeoIR72q .dzdorFoW0JKYIrjGFcnc {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  font-family: Poppins, sans-serif;
  padding: 12px;
}
.geW6xKWJZwYmEeoIR72q .yUIBMynDQFIBkJz2vsA5 {
  border: none;
}

.jywJ_EDIt0R9a88zn25Q {
  margin: 10px;
}

.UiKOXaUWIT_CUrDQbFa4 {
  font-size: 13px;
  color: #dc3545;
  margin-top: -23px !important;
  margin-bottom: 50px !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileInput": `geW6xKWJZwYmEeoIR72q`,
	"customFileUpload": `FGRe_t5ebfBP427OQmHl`,
	"fileName": `dzdorFoW0JKYIrjGFcnc`,
	"consentFile": `yUIBMynDQFIBkJz2vsA5`,
	"consentDocFile": `jywJ_EDIt0R9a88zn25Q`,
	"errorMsg": `UiKOXaUWIT_CUrDQbFa4`
};
export default ___CSS_LOADER_EXPORT___;
