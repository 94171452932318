import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { isEmpty, mergeWith } from 'lodash';
import { saveFieldMapping, triggerIntegrationStage } from '../../../../../../api/company';
import { toast } from 'react-toastify';
import {
  invalidateViewBuyPackages as invalidateViewBuyPackagesAPI,
  getViewBuyPackages as getViewBuyPackagesAPI,
  greenHouseCustomFieldJob as greenHouseCustomFieldJobAPI,
  getIntegrationFieldSubtypeMapping as getIntegrationFieldSubtypeMappingAPI,
  invalidateIntegrationFieldSubtypeMapping
} from '../../../../../../actions/company';
import { errToastMessage } from '../../../utils/Utilities';

const mapStateToProps = (state) => {
  return {
    viewBuyPackages:
      state.viewBuyPackages && !isEmpty(state.viewBuyPackages) ? state.viewBuyPackages : null,
    greenHouseCustomFieldJobs: state.greenHouseCustomFieldJobs || null,
    integrationData: state?.integrationData || {},
    integrationFieldSubtypeMapping: state.integrationFieldSubtypeMapping || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateViewBuyPackages: () => dispatch(invalidateViewBuyPackagesAPI()),
    invalidateIntegrationFieldSubtypeMapping: () =>
      dispatch(invalidateIntegrationFieldSubtypeMapping()),
    getGreenHouseCustomFieldJob: (companyId) => dispatch(greenHouseCustomFieldJobAPI(companyId)),
    getIntegrationFieldSubtypeMappingAPI: (data) =>
      dispatch(getIntegrationFieldSubtypeMappingAPI(data)),
    getViewBuyPackages: (data) => dispatch(getViewBuyPackagesAPI(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        customField: '',
        dropDownTags: [],
        customFieldOptions: {}
      };

      return mergeWith({}, storedValues);
    },
    enableReinitialize: true,
    handleSubmit: (values, { setStatus, setSubmitting }) => {
      const companyId = new URLSearchParams(document.location.search).get('company_id') || null;

      let statusForm = { customFieldOptions: false, trigger: false };
      let subtypesMapping = [];
      for (const [key, value] of Object.entries(values.customFieldOptions)) {
        if (!isEmpty(value)) {
          subtypesMapping.push({
            fieldName: 'customField',
            subtype_id: parseInt(key),
            package_id: value.packageId,
            fieldValue: value.tags.map((obj) => obj.label).join(';')
          });
        }
      }

      const payload = {
        integrationName: 'GREENHOUSE',
        companyId: companyId,
        stageDetails: {
          stage_id: values?.customField?.value
        }
      };

      const mappingPayload = {
        integrationName: 'GREENHOUSE',
        companyId: companyId,
        mappingData: subtypesMapping
      };
      triggerIntegrationStage(payload)
        .then((res) => {
          statusForm.trigger = true;
          setStatus({ ...statusForm, trigger: true });
          saveFieldMapping(mappingPayload)
            .then((res) => {
              toast.success('Custom Field Selection Successful. Package Mapping Successful.');
              statusForm.customFieldOptions = true;
              setStatus({ ...statusForm, customFieldOptions: true });
            })
            .catch((err) => {
              errToastMessage('Could not map packages with custom field option');
              setSubmitting(false);
            });
        })
        .catch((err) => {
          errToastMessage('Could not trigger integration');
          setSubmitting(false);
        });
    }
  })
);
