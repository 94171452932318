// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cUItJjskymQoiRvpo7bQ {
  width: calc(50% - 10px);
  height: 176px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
}

.qAcb1mvTAYfGQRAbGi7R {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Tv_U8M5BfrHOOUWj0HsA {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #333333;
}

.qvQp9UL93Myo0ezGxqia {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.kwutUIq2h1uteHAsjhqC {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #333333;
}

.sJiFcflxrPhM_07IwGjz {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #666666;
}

.zgWe7jzLiVzP2rVIzkCB {
  width: 158px;
}

.MVPV1xr8FO9vs9l82TtE {
  width: 180px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `cUItJjskymQoiRvpo7bQ`,
	"header": `qAcb1mvTAYfGQRAbGi7R`,
	"title": `Tv_U8M5BfrHOOUWj0HsA`,
	"info": `qvQp9UL93Myo0ezGxqia`,
	"unitValue": `kwutUIq2h1uteHAsjhqC`,
	"unitTitle": `sJiFcflxrPhM_07IwGjz`,
	"unit": `zgWe7jzLiVzP2rVIzkCB`,
	"dropdown": `MVPV1xr8FO9vs9l82TtE`
};
export default ___CSS_LOADER_EXPORT___;
