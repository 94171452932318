// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hbwJVc7yj_jvAOCDyvU7 {
  margin-bottom: 16px;
}
.hbwJVc7yj_jvAOCDyvU7 .nNpmKbpJXR7BH36XqcKu {
  color: #333333;
  font-size: 18px;
  font-weight: normal;
}
.hbwJVc7yj_jvAOCDyvU7 .NLTJSg52_dIrddRkZfOF {
  border: 1px solid #2755fe !important;
  box-shadow: 0px 2px 8px rgba(39, 85, 254, 0.24) !important;
}
.hbwJVc7yj_jvAOCDyvU7 .X0ohDmHFzXV7flddFUlg {
  cursor: pointer;
  margin-bottom: 16px;
  border: 1px solid rgba(204, 204, 204, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
.hbwJVc7yj_jvAOCDyvU7 .X0ohDmHFzXV7flddFUlg .xHHramFAAW__qbG1FX1o p {
  margin-bottom: 5px;
}
.hbwJVc7yj_jvAOCDyvU7 .X0ohDmHFzXV7flddFUlg .xHHramFAAW__qbG1FX1o .OQVQPFpvE5FlrJf8TZ0B,
.hbwJVc7yj_jvAOCDyvU7 .X0ohDmHFzXV7flddFUlg .xHHramFAAW__qbG1FX1o .Zw5hGvmWmeYrdqunbaEg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hbwJVc7yj_jvAOCDyvU7 .X0ohDmHFzXV7flddFUlg .xHHramFAAW__qbG1FX1o .OQVQPFpvE5FlrJf8TZ0B .IRKZWt0o23w6q9ktU9Dz,
.hbwJVc7yj_jvAOCDyvU7 .X0ohDmHFzXV7flddFUlg .xHHramFAAW__qbG1FX1o .Zw5hGvmWmeYrdqunbaEg .IRKZWt0o23w6q9ktU9Dz {
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  background: #389e0d;
  border: 1px solid #389e0d;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 1px 8px;
}
.hbwJVc7yj_jvAOCDyvU7 .X0ohDmHFzXV7flddFUlg .xHHramFAAW__qbG1FX1o .OQVQPFpvE5FlrJf8TZ0B {
  font-weight: 600;
  font-size: 16px;
  color: #2755fe;
}
.hbwJVc7yj_jvAOCDyvU7 .X0ohDmHFzXV7flddFUlg .xHHramFAAW__qbG1FX1o .Zw5hGvmWmeYrdqunbaEg {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}
.hbwJVc7yj_jvAOCDyvU7 .X0ohDmHFzXV7flddFUlg .xHHramFAAW__qbG1FX1o .x_zdPtP2LNqxDIQrYjqq {
  font-family: roboto;
}
.hbwJVc7yj_jvAOCDyvU7 .X0ohDmHFzXV7flddFUlg .xHHramFAAW__qbG1FX1o .qqmtXWkNoKnVEtpiwgZW {
  font-weight: normal;
  font-size: 14px;
  color: #333333;
}
.hbwJVc7yj_jvAOCDyvU7 .X0ohDmHFzXV7flddFUlg .xHHramFAAW__qbG1FX1o .AgF9vo3w1zK5VTiYxKDg {
  font-weight: normal;
  font-size: 14px;
  color: #666666;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"candidateOptionsContainer": `hbwJVc7yj_jvAOCDyvU7`,
	"heading": `nNpmKbpJXR7BH36XqcKu`,
	"activeCard": `NLTJSg52_dIrddRkZfOF`,
	"cardContainer": `X0ohDmHFzXV7flddFUlg`,
	"cardBodyContainer": `xHHramFAAW__qbG1FX1o`,
	"activeCandidateCount": `OQVQPFpvE5FlrJf8TZ0B`,
	"candidateCount": `Zw5hGvmWmeYrdqunbaEg`,
	"discountBadge": `IRKZWt0o23w6q9ktU9Dz`,
	"amountText": `x_zdPtP2LNqxDIQrYjqq`,
	"costPerCandidate": `qqmtXWkNoKnVEtpiwgZW`,
	"totalAmount": `AgF9vo3w1zK5VTiYxKDg`
};
export default ___CSS_LOADER_EXPORT___;
