// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aCAXmwSOtwSDiTjQffbN {
  height: 296px;
  width: 1028px;
  border-radius: 12px;
  background: linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%);
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 2fr;
}
.aCAXmwSOtwSDiTjQffbN .oVrMuRrkcmcqcB4glJGg {
  padding: 32px 0px 0px 45px;
}
.aCAXmwSOtwSDiTjQffbN .oVrMuRrkcmcqcB4glJGg .gsERoeFL4WKsiZH9FO2A {
  font-size: 36px;
  font-weight: 700;
  color: white;
  margin-top: 24px;
}
.aCAXmwSOtwSDiTjQffbN .oVrMuRrkcmcqcB4glJGg .gsERoeFL4WKsiZH9FO2A .bUrivnSlMqbCaNPZM_C4 {
  font-family: "roboto";
  margin-left: 5px;
  font-weight: 700;
  line-height: normal;
  color: #ffe39a;
}
.aCAXmwSOtwSDiTjQffbN .oVrMuRrkcmcqcB4glJGg .wGyJ_uomo4mlLQnRGQ7t {
  width: 273px;
  font-size: 18px;
  font-weight: 700;
  color: white;
}
.aCAXmwSOtwSDiTjQffbN .a1QGJgte9ZRXpf5dOh_w {
  align-self: end;
  justify-self: center;
  padding-bottom: 28px;
}
.aCAXmwSOtwSDiTjQffbN .gdcLLCXEbcUu6SP7LsmA {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.aCAXmwSOtwSDiTjQffbN .gdcLLCXEbcUu6SP7LsmA .lcVjTFeNGxL5tuZf9Z2r {
  display: flex;
  width: 374px;
  justify-content: space-between;
  height: 120px;
  padding: 10px 20px;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
}
.aCAXmwSOtwSDiTjQffbN .gdcLLCXEbcUu6SP7LsmA .lcVjTFeNGxL5tuZf9Z2r .oDTZtXkVzXJnmqjxJhbY {
  font-size: 18px;
  font-weight: 700;
  color: white;
}
.aCAXmwSOtwSDiTjQffbN .gdcLLCXEbcUu6SP7LsmA .lcVjTFeNGxL5tuZf9Z2r .IHfKCm0zuQ0gi5UruQLh {
  font-size: 16px;
  font-weight: 400;
  color: white;
}
.aCAXmwSOtwSDiTjQffbN .gdcLLCXEbcUu6SP7LsmA .lcVjTFeNGxL5tuZf9Z2r .IHfKCm0zuQ0gi5UruQLh .z0kbjp1sQYocCbXtoTm8 {
  margin: 0px 3px;
  font-family: roboto;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `aCAXmwSOtwSDiTjQffbN`,
	"firstCol": `oVrMuRrkcmcqcB4glJGg`,
	"firstColHeading": `gsERoeFL4WKsiZH9FO2A`,
	"firstColHeadingAmount": `bUrivnSlMqbCaNPZM_C4`,
	"firstColSubHeading": `wGyJ_uomo4mlLQnRGQ7t`,
	"secondCol": `a1QGJgte9ZRXpf5dOh_w`,
	"thirdCol": `gdcLLCXEbcUu6SP7LsmA`,
	"thirdColDiv": `lcVjTFeNGxL5tuZf9Z2r`,
	"thirdColHeading": `oDTZtXkVzXJnmqjxJhbY`,
	"thirdColSubHeading": `IHfKCm0zuQ0gi5UruQLh`,
	"thirdColSubHeadingAmount": `z0kbjp1sQYocCbXtoTm8`
};
export default ___CSS_LOADER_EXPORT___;
