// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CaZA2HJblDUfWI8WJztZ h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333333;
}
.CaZA2HJblDUfWI8WJztZ .ogknehPU1b2z76OAREAf,
.CaZA2HJblDUfWI8WJztZ .JWPNkAW7UBa0o58Y7mtF {
  border: 1px solid rgba(51, 51, 51, 0.1);
  min-height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CaZA2HJblDUfWI8WJztZ .tPQXW1P8RMIYhbVik9ln .nUxrnh5jP0T42ZwzaJ46:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.1);
}
.CaZA2HJblDUfWI8WJztZ .tPQXW1P8RMIYhbVik9ln .nUxrnh5jP0T42ZwzaJ46:nth-of-type(even) {
  background-color: #f7f8fa;
}
.CaZA2HJblDUfWI8WJztZ .tPQXW1P8RMIYhbVik9ln .nUxrnh5jP0T42ZwzaJ46 {
  border: 1px solid rgba(51, 51, 51, 0.1);
  padding: 32px 21px;
}
.CaZA2HJblDUfWI8WJztZ .P0ywGdiMGRHB9Cnq0W98 {
  flex-wrap: unset;
  padding: 24px 0;
}
.CaZA2HJblDUfWI8WJztZ .P0ywGdiMGRHB9Cnq0W98 .CxE38NDqUkpOLgzuFJuy {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.CaZA2HJblDUfWI8WJztZ .P0ywGdiMGRHB9Cnq0W98 .CxE38NDqUkpOLgzuFJuy .ib_5YVwZpEcqMcLSFQeo {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px;
  -webkit-appearance: menulist;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionHistoryContainer": `CaZA2HJblDUfWI8WJztZ`,
	"transactionHistoryLoading": `ogknehPU1b2z76OAREAf`,
	"noTransactionFound": `JWPNkAW7UBa0o58Y7mtF`,
	"transactionHistoryTable": `tPQXW1P8RMIYhbVik9ln`,
	"transactionDetail": `nUxrnh5jP0T42ZwzaJ46`,
	"paginationWrapper": `P0ywGdiMGRHB9Cnq0W98`,
	"showPagination": `CxE38NDqUkpOLgzuFJuy`,
	"selectPagination": `ib_5YVwZpEcqMcLSFQeo`
};
export default ___CSS_LOADER_EXPORT___;
