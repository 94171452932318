// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DsSBAL7MArFsKitxO3p2 {
  background-color: white;
  width: 100%;
  height: 100px;
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px rgba(51, 51, 51, 0.1);
  max-width: 1200px;
  margin: 15px auto 0 auto;
  position: relative;
  display: flex;
}

.YHRueZlCQpchYc1n5Hjc {
  position: absolute;
  left: -28px;
  opacity: 0.2;
  top: 19px;
  width: 100px;
  height: 100px;
}

.V7BtjjGcADVMCr39XtT4 {
  width: 481px;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-left: 71px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 13px 0px rgba(82, 103, 65, 0.16);
  cursor: pointer;
}
.V7BtjjGcADVMCr39XtT4 h2 {
  color: #666;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ds_AEcNOBCVq1kXxvBpG {
  width: 719px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 36px;
  padding-left: 5px;
}

.zqETI_nbJhIJXMbegdSa {
  width: 508px;
  height: 100%;
}

.A4CC5Glm4sc5mm5zdTo9 {
  color: #2755fe;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  border-radius: 4px;
  border: 1px solid #2755fe;
  background: #fff;
  height: 48px;
  width: 164px;
  box-shadow: none !important;
}
.A4CC5Glm4sc5mm5zdTo9:hover, .A4CC5Glm4sc5mm5zdTo9:active, .A4CC5Glm4sc5mm5zdTo9:focus {
  background: #2755fe;
  color: #fff;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `DsSBAL7MArFsKitxO3p2`,
	"svInIcon": `YHRueZlCQpchYc1n5Hjc`,
	"lsContainer": `V7BtjjGcADVMCr39XtT4`,
	"rsContainer": `ds_AEcNOBCVq1kXxvBpG`,
	"carouselContainer": `zqETI_nbJhIJXMbegdSa`,
	"exploreBtn": `A4CC5Glm4sc5mm5zdTo9`
};
export default ___CSS_LOADER_EXPORT___;
