// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FefRRDOw1lFzebkXW_rQ {
  border-top: 0.5px dashed rgba(153, 153, 153, 0.7);
  width: 100%; /* Adjust to your desired width */
  margin: 10px 0px 22px 0px;
}

.CilXdpkrQhDyKSHOH8TO {
  margin: 8px 0px 24px 30px;
}

.kY7XlDhhwG2wMpcEb4Xg .Qy_tmOA1kFxxdBODndjb input {
  transform: scale(1.4);
}
.kY7XlDhhwG2wMpcEb4Xg:hover {
  cursor: pointer;
}

.dzNomROFhDknG3MIJVOY {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.Wot8lQHAXIC8XnfmUhUc {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-right: 12px;
}

.KPWk1mmXwykOxZv8SRhs {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #666 !important;
}

.c826sZw8sJaA0satg1__ {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #333;
}

.RdQ5tDCn86W4E_hTUl_w {
  font-size: 14px !important;
  font-weight: 500 !important;
  align-items: center;
}
.RdQ5tDCn86W4E_hTUl_w:hover {
  cursor: pointer;
}

.gwbq4SKiIZzKA_nC5z77 {
  height: 20px;
  width: 20px;
  margin-right: 4px;
}

.WuRRq9T2CNR5bbcKM22A {
  color: #2755fe !important;
}

.e6yrk2MJN6JdfcyGcD6M {
  opacity: 0.5;
  cursor: not-allowed;
}

.iMnOf7VEUXXx4Xyt3FCn {
  color: #d3455b !important;
}

.S9zCz6ByHTujshSPRdJ_ {
  background-color: #2755fe !important;
  height: 40px;
  font-weight: 500;
  width: 164px;
  margin-top: 32px;
}
.S9zCz6ByHTujshSPRdJ_:hover {
  background-color: #1f44ca !important;
}

.KhBtvClixx4PggrbHP_8 {
  background-color: rgba(39, 85, 254, 0.5) !important;
  height: 40px;
  font-weight: 500;
  width: 164px;
  margin-top: 32px;
  border: none;
}
.KhBtvClixx4PggrbHP_8:hover {
  cursor: not-allowed;
}

.A_CSMQ24ipJGTBhH4HWK {
  width: 100%;
  font-size: 14px;
  color: #dc3545;
  margin-top: -9px;
}

.HbOXmtjfByyLC9bLhmQE {
  border-color: #dc3545;
  border-radius: 4px;
}

.YSHZupZbGPCqgCeshxRo {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.isCANjDqd2iKXsnLQIbM {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  display: block;
  text-align: right;
}

.jNqVNIzNvSUC1r8QvYml {
  font-size: 18px;
  font-weight: bold;
  color: #595959 !important;
}

.kn2HY8YaBkJU3IhMdopD {
  font-size: 12px;
  color: #666 !important;
  font-style: italic;
}

.WzY0u2JFyTvPUwnvybkS {
  margin-bottom: 20px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashedBorder": `FefRRDOw1lFzebkXW_rQ`,
	"contactsContainer": `CilXdpkrQhDyKSHOH8TO`,
	"formCheckbox": `kY7XlDhhwG2wMpcEb4Xg`,
	"inputCheckbox": `Qy_tmOA1kFxxdBODndjb`,
	"inputHeading": `dzNomROFhDknG3MIJVOY`,
	"contactCount": `Wot8lQHAXIC8XnfmUhUc`,
	"formLabel": `KPWk1mmXwykOxZv8SRhs`,
	"creditBalanceText": `c826sZw8sJaA0satg1__`,
	"addOrRemoveText": `RdQ5tDCn86W4E_hTUl_w`,
	"addOrRemoveIcon": `gwbq4SKiIZzKA_nC5z77`,
	"addTextColor": `WuRRq9T2CNR5bbcKM22A`,
	"addTextMaxThreshold": `e6yrk2MJN6JdfcyGcD6M`,
	"removeTextColor": `iMnOf7VEUXXx4Xyt3FCn`,
	"submitBtn": `S9zCz6ByHTujshSPRdJ_`,
	"disabledBtn": `KhBtvClixx4PggrbHP_8`,
	"inputError": `A_CSMQ24ipJGTBhH4HWK`,
	"inputGroupErrBorder": `HbOXmtjfByyLC9bLhmQE`,
	"inputGroupErrBorderFocus": `YSHZupZbGPCqgCeshxRo`,
	"visibleMsg": `isCANjDqd2iKXsnLQIbM`,
	"visibleMsgIcon": `jNqVNIzNvSUC1r8QvYml`,
	"referNote": `kn2HY8YaBkJU3IhMdopD`,
	"anonymous": `WzY0u2JFyTvPUwnvybkS`
};
export default ___CSS_LOADER_EXPORT___;
