// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LjkdRadwVdUEiF0c_BJ3 {
  font-weight: 700 !important;
  margin-bottom: 16px;
}

.haYWD_WNN5EFKUWAKPZ3 {
  margin-bottom: 35px;
}

.YPkh3GRGztYQb5sVH1oC {
  margin-bottom: 35px;
  font-weight: 700 !important;
}

.ftHCNqM3x0hHfgdnnRQg {
  padding: 5px;
  margin-bottom: 20px;
  font-size: 14px;
}
.ftHCNqM3x0hHfgdnnRQg .dJZRMgRWxi9z_ELmuUmw {
  color: #ed6363;
}
.ftHCNqM3x0hHfgdnnRQg .MLBSs4OJ6_zyTYZpAUZt {
  color: #ed6363;
  margin-bottom: 20px;
}

.IZkMC2QOZZep0S2YcBSD {
  position: absolute;
  left: 310px;
  margin-top: 10px;
}
@media screen and (max-width: 501px) {
  .IZkMC2QOZZep0S2YcBSD {
    left: 85%;
  }
}

.t_KFAI20LHNSRgcVIWSf {
  margin: 28px;
  padding: 0 !important;
  justify-content: flex-end !important;
}
.t_KFAI20LHNSRgcVIWSf button {
  padding: 0.3em 1.4rem;
  margin: 4px;
  width: 100%;
}
.t_KFAI20LHNSRgcVIWSf .EAJ5BUA0f9gGbQKWPkrh {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.t_KFAI20LHNSRgcVIWSf .SQxn7Zi4NMXxg7RaPg33 {
  background: #f5f5f5;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
@media screen and (max-width: 601px) {
  .t_KFAI20LHNSRgcVIWSf {
    display: flex;
    align-items: center;
  }
  .t_KFAI20LHNSRgcVIWSf .EAJ5BUA0f9gGbQKWPkrh {
    flex: 1;
  }
  .t_KFAI20LHNSRgcVIWSf .SQxn7Zi4NMXxg7RaPg33 {
    flex: 1;
  }
}

.kWzDyArlrVJ8AEsgrESB {
  background: #ffffff;
  color: #2755fe;
  border: none;
  margin-right: 32px;
  box-shadow: none;
  font-size: 16px;
  font-family: Poppins, sans-serif;
}
.kWzDyArlrVJ8AEsgrESB:hover {
  background: #ffffff;
  color: #2755fe;
  border: none;
}
.kWzDyArlrVJ8AEsgrESB:active {
  background: #ffffff !important;
  color: #2755fe !important;
  border: none !important;
}
.kWzDyArlrVJ8AEsgrESB:focus {
  box-shadow: none !important;
}

.WzkmDjovSe75W0IJk4mW {
  font-style: italic;
}
.WzkmDjovSe75W0IJk4mW .lWMVd_rIwnUdvNjNiuv5 {
  font-style: normal;
  font-weight: 600;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `LjkdRadwVdUEiF0c_BJ3`,
	"subHeading": `haYWD_WNN5EFKUWAKPZ3`,
	"important": `YPkh3GRGztYQb5sVH1oC`,
	"searchInput": `ftHCNqM3x0hHfgdnnRQg`,
	"error": `dJZRMgRWxi9z_ELmuUmw`,
	"maximumErrorMsg": `MLBSs4OJ6_zyTYZpAUZt`,
	"unlockSpinner": `IZkMC2QOZZep0S2YcBSD`,
	"passwordSubmitBtn": `t_KFAI20LHNSRgcVIWSf`,
	"activeBtn": `EAJ5BUA0f9gGbQKWPkrh`,
	"disabledBtn": `SQxn7Zi4NMXxg7RaPg33`,
	"cancelBtn": `kWzDyArlrVJ8AEsgrESB`,
	"fileName": `WzkmDjovSe75W0IJk4mW`,
	"heading": `lWMVd_rIwnUdvNjNiuv5`
};
export default ___CSS_LOADER_EXPORT___;
