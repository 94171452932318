// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Oiwwzt2aot45dWvhh6cK {
  font-size: 18px;
  color: #4c4c4c;
  margin-bottom: 28px;
  display: flex;
}

.OdxLrdE08iHHNimXP70x {
  margin-right: 24px;
  background-color: #fff;
  padding: 0 12px 12px 4px;
  font-size: 14px;
  color: #4c4c4c;
}
.OdxLrdE08iHHNimXP70x label {
  color: #666666;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
}
.OdxLrdE08iHHNimXP70x label span {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
}

.HN_OX0noWF7ZvvFa_Es2 {
  display: list-item;
  list-style-type: square;
  margin-left: 50px;
}

.uEWbSbLH06R_4jhD5vmo {
  display: list-item;
  list-style-type: square;
  margin-left: 59px;
}

.RLUlrBhoaqQbRxDIcW2I {
  margin-left: 22px;
}

.t5DVt4av_LgWo0dZigd6 {
  color: #333333 !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question": `Oiwwzt2aot45dWvhh6cK`,
	"option": `OdxLrdE08iHHNimXP70x`,
	"listItem": `HN_OX0noWF7ZvvFa_Es2`,
	"firstListItem": `uEWbSbLH06R_4jhD5vmo`,
	"listItemText": `RLUlrBhoaqQbRxDIcW2I`,
	"boldLabel": `t5DVt4av_LgWo0dZigd6`
};
export default ___CSS_LOADER_EXPORT___;
