// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i8lTe_OUWkiLpBv7ClOQ {
  margin-bottom: 3rem;
  padding: 16px;
}
.i8lTe_OUWkiLpBv7ClOQ .iWK7VsHIQ6zm80d_Sj2g {
  font-size: 20px;
  color: white;
  font-weight: 600;
  background-color: #2755fe;
  text-align: center;
  padding: 10px;
  font-family: Poppins, sans-serif;
  margin: -1rem -1rem 0 -1rem;
}
.i8lTe_OUWkiLpBv7ClOQ .iWK7VsHIQ6zm80d_Sj2g:hover {
  background-color: #1f44ca;
}
.i8lTe_OUWkiLpBv7ClOQ .Zzz0X09OyeTQL52LWiTB {
  display: grid;
  grid-template-columns: 90% 10%;
  grid-column-gap: 10px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 10px;
}
.i8lTe_OUWkiLpBv7ClOQ .Zzz0X09OyeTQL52LWiTB .Ee9L0R2SHQ7hNtuAvW_z {
  padding-right: 10px;
  font-size: 12px;
}
.i8lTe_OUWkiLpBv7ClOQ .wbVKDLpn0V0rWbzqqyvx {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 10% 55% 45%;
  font-size: 14px;
}
.i8lTe_OUWkiLpBv7ClOQ .wbVKDLpn0V0rWbzqqyvx .MOupk3SqKvIRDIJaHqFk {
  font-size: 10px;
}
.i8lTe_OUWkiLpBv7ClOQ .wbVKDLpn0V0rWbzqqyvx .umzO6xXg57IHXFoE_9tA {
  margin-right: 5px;
}
.i8lTe_OUWkiLpBv7ClOQ .wbVKDLpn0V0rWbzqqyvx .fOaEgEcdzQIOyW22KNHq {
  display: grid;
  grid-template-columns: 50% 12.5% 12.5% 12.5%;
}
.i8lTe_OUWkiLpBv7ClOQ .wbVKDLpn0V0rWbzqqyvx .fOaEgEcdzQIOyW22KNHq .wWpwdfklpd_oerf1SQwN {
  margin-top: -4px;
}
.i8lTe_OUWkiLpBv7ClOQ .wbVKDLpn0V0rWbzqqyvx .fOaEgEcdzQIOyW22KNHq .lO9XBNV8J99XkVsitdLQ,
.i8lTe_OUWkiLpBv7ClOQ .wbVKDLpn0V0rWbzqqyvx .fOaEgEcdzQIOyW22KNHq .tx0M5h4SQONVY3OeHoLi {
  color: #717171;
}
.i8lTe_OUWkiLpBv7ClOQ .DEYx9RJjprfYNB9Cd6Yi {
  display: flex;
  padding: 10px 25px;
  justify-content: space-between;
}
.i8lTe_OUWkiLpBv7ClOQ .DEYx9RJjprfYNB9Cd6Yi .AbKGF3VIWnNQeAzKFqhn {
  display: flex;
  align-items: center;
}
.i8lTe_OUWkiLpBv7ClOQ .DEYx9RJjprfYNB9Cd6Yi p {
  font-size: 13px;
  margin-bottom: -8px;
  text-align: right;
}
.i8lTe_OUWkiLpBv7ClOQ .DEYx9RJjprfYNB9Cd6Yi .VdhmN7tgloY66q8Pj906 {
  font-size: 13px;
  color: #808080;
  text-align: end;
}
.i8lTe_OUWkiLpBv7ClOQ .DEYx9RJjprfYNB9Cd6Yi .DWOy3XuuJWorK2ItKYiu {
  margin-top: -15px;
}
.i8lTe_OUWkiLpBv7ClOQ .DEYx9RJjprfYNB9Cd6Yi .JhchMtPqGrNYowf8zVIv {
  max-height: 45px;
  object-fit: cover;
}
.i8lTe_OUWkiLpBv7ClOQ .ue_7dJGkHJULkUCyemtt {
  margin: 0rem 1.5rem;
  background-color: #f8f9fb;
  padding: 10px;
  height: auto;
}
.i8lTe_OUWkiLpBv7ClOQ .ue_7dJGkHJULkUCyemtt .QQ_7ktav3xFdTN67Wkhl {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  background-color: #e9a1ab;
  text-align: center;
  padding: 8px;
  font-family: Poppins, sans-serif;
  margin: 0.5rem;
}
.i8lTe_OUWkiLpBv7ClOQ .ue_7dJGkHJULkUCyemtt .pNAO0hXUpmLNJiFuxpcw {
  background-color: white;
  padding: 20px;
  margin: 10px;
  font-size: 14px;
}
.i8lTe_OUWkiLpBv7ClOQ .ue_7dJGkHJULkUCyemtt .pNAO0hXUpmLNJiFuxpcw p {
  font-family: Arial, Helvetica, sans-serif;
  color: #000000;
}
.i8lTe_OUWkiLpBv7ClOQ .ue_7dJGkHJULkUCyemtt .pNAO0hXUpmLNJiFuxpcw .fCkTf4smAWGdugSqoD2z {
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 0;
}
.i8lTe_OUWkiLpBv7ClOQ .ue_7dJGkHJULkUCyemtt .pNAO0hXUpmLNJiFuxpcw .KR_ohm8ci_WB9WHcJStt {
  color: #d3445b;
}
.i8lTe_OUWkiLpBv7ClOQ .ue_7dJGkHJULkUCyemtt .pNAO0hXUpmLNJiFuxpcw .OEqW5nSQHyQwFDfzWhmR {
  color: #fff;
  text-decoration: none;
  background-color: #2755fe;
  margin-top: 20px;
  padding: 10px 15px;
  border-radius: 4px;
  display: inline-block;
}
.i8lTe_OUWkiLpBv7ClOQ .jvDpQ_siVcvwYJxWHioZ p {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: #808080;
  padding-left: 27px;
  padding-right: 15px;
}
.i8lTe_OUWkiLpBv7ClOQ .jvDpQ_siVcvwYJxWHioZ div {
  font-size: 12px;
  padding-left: 1.5rem;
  color: #000;
}

.qKVyJ4dW5OtIG0s6HzbQ {
  cursor: pointer;
  color: #2755fe;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emailPreviewContainer": `i8lTe_OUWkiLpBv7ClOQ`,
	"headingTitle": `iWK7VsHIQ6zm80d_Sj2g`,
	"headingContent": `Zzz0X09OyeTQL52LWiTB`,
	"fontAwesomeIcon": `Ee9L0R2SHQ7hNtuAvW_z`,
	"emailHeader": `wbVKDLpn0V0rWbzqqyvx`,
	"emailIcon": `MOupk3SqKvIRDIJaHqFk`,
	"svLogo": `umzO6xXg57IHXFoE_9tA`,
	"emailIcons": `fOaEgEcdzQIOyW22KNHq`,
	"starIcon": `wWpwdfklpd_oerf1SQwN`,
	"replyIcon": `lO9XBNV8J99XkVsitdLQ`,
	"moreIcon": `tx0M5h4SQONVY3OeHoLi`,
	"emailContentHeader": `DEYx9RJjprfYNB9Cd6Yi`,
	"logoNameHeader": `AbKGF3VIWnNQeAzKFqhn`,
	"friendlyName": `VdhmN7tgloY66q8Pj906`,
	"springVerifyTextImg": `DWOy3XuuJWorK2ItKYiu`,
	"brandLogo": `JhchMtPqGrNYowf8zVIv`,
	"emailContentBody": `ue_7dJGkHJULkUCyemtt`,
	"overdueBanner": `QQ_7ktav3xFdTN67Wkhl`,
	"emailPreviewContent": `pNAO0hXUpmLNJiFuxpcw`,
	"hrNote": `fCkTf4smAWGdugSqoD2z`,
	"hrWarningNote": `KR_ohm8ci_WB9WHcJStt`,
	"button": `OEqW5nSQHyQwFDfzWhmR`,
	"emailContentFooter": `jvDpQ_siVcvwYJxWHioZ`,
	"contactUs": `qKVyJ4dW5OtIG0s6HzbQ`
};
export default ___CSS_LOADER_EXPORT___;
