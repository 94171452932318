// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D5l8bOsUTKvphroR1Vnp {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #595959 !important;
  flex: none !important;
  align-self: flex-end !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `D5l8bOsUTKvphroR1Vnp`
};
export default ___CSS_LOADER_EXPORT___;
