// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cwrs8q0cEg_fk2HU1OA5 {
  border-radius: 5px;
  padding: 0 16px;
  border: 0;
  height: 45px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `cwrs8q0cEg_fk2HU1OA5`
};
export default ___CSS_LOADER_EXPORT___;
