import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';
import moment from 'moment/moment';
import { toast } from 'react-toastify';

import validationSchema from './DetailsStep.validation';
import logger from '../../../../../../../core-components/Logger';
import {
  getErrorMessage,
  compareObjects,
  errToastMessage
} from '../../../../../../../utils/utilities';

import {
  saveBgvCache as saveBgvCacheAPI,
  invalidateEmpDocType as invalidateEmpDocTypeAPI
} from '../../../../../../../actions/bgv';
import { isAllStepsCompleted } from '../../EmploymentHelper';

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    invalidateEmpDocType: () => dispatch(invalidateEmpDocTypeAPI())
  };
};

const mapStateToProps = (state) => {
  const employmentCachedData = !state?.bgvCachedData?.data ? null : state?.bgvCachedData?.data;
  return {
    cached_data: !state?.bgvCachedData?.data ? null : { ...state?.bgvCachedData?.data },
    employmentCachedData
  };
};

const mapDateFormat = (start_date, end_date, isSaving) => {
  if (isSaving) {
    start_date = moment(start_date).format('YYYY-MM-DD');
    end_date = end_date ? moment(end_date).format('YYYY-MM-DD') : null;
  } else {
    start_date = moment(moment(start_date, 'YYYY-MM-DD', true)).isValid()
      ? moment(start_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
      : null;
    end_date = moment(moment(end_date, 'YYYY-MM-DD', true)).isValid()
      ? moment(end_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
      : null;
  }
  return { start_date, end_date };
};

const formattedSaveObject = (props, values) => {
  let finalCacheObj = {};
  let saveObject = {};
  if (
    !props?.cached_data?.cache_data?.employment_check ||
    !props?.cached_data?.cache_data?.employment_check?.data
  ) {
    saveObject = {
      ...props.cached_data,
      cache_data: {
        ...props?.cached_data?.cache_data,
        employment_check: {
          data: [
            {
              step_1: {
                ...values
              }
            }
          ]
        }
      }
    };
  } else if (props?.cached_data?.cache_data?.employment_check?.data[props.currentSavingIndex]) {
    saveObject = {
      ...props.cached_data,
      cache_data: {
        ...props.cached_data.cache_data,
        employment_check: {
          data: [...props.cached_data.cache_data.employment_check.data]
        }
      }
    };
    saveObject.cache_data.employment_check.data[props.currentSavingIndex].step_1 = {
      ...values
    };
  } else {
    saveObject = {
      ...props.cached_data,
      cache_data: {
        ...props.cached_data.cache_data,
        employment_check: {
          data: [
            ...props?.cached_data?.cache_data?.employment_check?.data,
            {
              step_1: {
                ...values
              }
            }
          ]
        }
      }
    };
  }
  saveObject.current_state = '4#2';
  finalCacheObj['saveObject'] = {
    ...saveObject
  };
  return finalCacheObj;
};

let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        company_name: '',
        company_brand_name: '',
        employee_id: '',
        city: '',
        country: 'India',
        designation: '',
        start_date: null,
        valid_start_date: true,
        end_date: null,
        valid_end_date: true,
        currently_working: false,
        reason: '',
        reason_for_leaving: '',
        is_employee_id_not_available: false
      };
      if (
        props?.cached_data &&
        !isEmpty(props.cached_data) &&
        props.cached_data.cache_data?.employment_check?.data?.length > 0 &&
        props.cached_data.cache_data['employment_check'].data[props.currentSavingIndex] &&
        props?.cached_data?.cache_data['employment_check']?.data[props.currentSavingIndex]?.step_1
      ) {
        let empData =
          props.cached_data.cache_data['employment_check'].data[props.currentSavingIndex];
        propsValue = { ...empData.step_1 };
        let endingDate = !isEmpty(empData.step_1.duration.end_date)
          ? empData.step_1.duration.end_date
          : null;
        let dates = mapDateFormat(empData.step_1.duration.start_date, endingDate, false);
        propsValue.start_date = dates.start_date;
        propsValue.end_date = dates.end_date;
        propsValue.valid_start_date = empData?.step_1?.duration?.start_date
          ? moment(moment(empData?.step_1?.duration?.start_date, 'YYYY-MM-DD', true)).isValid()
          : true;
        propsValue.valid_end_date = empData.step_1.duration.end_date
          ? moment(moment(empData.step_1.duration.end_date, 'YYYY-MM-DD', true)).isValid()
          : true;
        propsValue.currently_working = empData.step_1.duration.currently_working;
        delete propsValue['duration'];
      } else {
        propsValue = {};
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {
      let valuesCopy = Object.assign({}, values);
      let endDate = valuesCopy.end_date;
      valuesCopy = {
        ...valuesCopy,
        duration: {
          ...mapDateFormat(valuesCopy.start_date, endDate, true),
          currently_working: valuesCopy.currently_working
        }
      };
      delete valuesCopy['start_date'];
      delete valuesCopy['end_date'];
      if (valuesCopy?.reason_for_leaving !== 'OTHER') {
        valuesCopy.reason = '';
      }
      if (!compareObjects(valuesCopy, propsValue)) {
        const finalCacheObj = formattedSaveObject(props, valuesCopy);
        props
          .setBgvCache(finalCacheObj)
          .then(() => {
            logger.push({
              message: `Employment step1 is ${props.isEdit ? 'Edited' : 'Saved'}`,
              data: JSON.stringify(finalCacheObj),
              category: `candidate-id-${props.candidateId}`,
              subCategory: 'Employment details#1',
              type: 'info'
            });
            setSubmitting(false);
            props.subNextStep();
          })
          .catch((error) => {
            console.error(error);
            setSubmitting(false);
            errToastMessage(error);
          });
      } else {
        setSubmitting(false);
        props.subNextStep();
      }
    },
    displayName: 'Contact Details'
  })
);
