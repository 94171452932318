// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KN0OtzhH3SqwzjMTJcAI {
  display: flex;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
}
.KN0OtzhH3SqwzjMTJcAI .nWPXqS95CPzY1dML6zn2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.PEoC5iGD4q61yruRXpxY {
  max-width: 1200px;
  margin: auto;
  background-color: white;
  padding: 0 24px 24px 24px;
  border-radius: 0.25rem;
}
@media only screen and (max-width: 1200px) {
  .PEoC5iGD4q61yruRXpxY {
    width: 1200px;
  }
}
.PEoC5iGD4q61yruRXpxY .oSYpw0EATveDJEXlwBKs {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 8px;
  overflow: hidden;
  margin: 17px auto 48px;
  border: none !important;
  min-height: 450px;
  width: 100%;
}
.PEoC5iGD4q61yruRXpxY .oSYpw0EATveDJEXlwBKs .Yp6eJMZN8kWcqtOzC545 {
  border: 0px;
}
.PEoC5iGD4q61yruRXpxY .IkZkYROoDlcls8Ta3lkD {
  text-align: right;
  padding-left: 5px;
  margin-right: 100px;
  color: #2755fe;
}
.PEoC5iGD4q61yruRXpxY .IkZkYROoDlcls8Ta3lkD a {
  color: #2755fe;
}
.PEoC5iGD4q61yruRXpxY .DRX7fkRwFw7XLy38AKSx {
  height: 90%;
  width: 100%;
  padding-left: 30px;
  padding-top: 30px;
}
.PEoC5iGD4q61yruRXpxY .DnaXN_YgBosAEJ9sPL7x {
  height: 10%;
  width: 100%;
  background-color: honeydew;
  display: flex;
  flex-direction: "row";
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  padding: 10px 0 10px 30px;
}
.PEoC5iGD4q61yruRXpxY .cvIFt2Fw9AEKTmzYeVLz {
  margin-right: 10px;
}
.PEoC5iGD4q61yruRXpxY .wAd7WoGRwkia2cle_AmB {
  text-align: right;
}
.PEoC5iGD4q61yruRXpxY .wAd7WoGRwkia2cle_AmB .kkK3cAZUsDBKJ8bqvgpv {
  margin-right: 24px;
  background: #ffffff;
  border: 1px solid #2755fe;
  border-radius: 5px;
  width: 136px;
  height: 48px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #2755fe;
}
.PEoC5iGD4q61yruRXpxY .wAd7WoGRwkia2cle_AmB .kkK3cAZUsDBKJ8bqvgpv:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.PEoC5iGD4q61yruRXpxY .wAd7WoGRwkia2cle_AmB .yCI3VzdQGTzOyGbJGJB6 {
  color: #fff;
  width: 150px;
  height: 48px;
  background-color: #2755fe;
  border-color: #2755fe;
  cursor: pointer;
}
.PEoC5iGD4q61yruRXpxY .wAd7WoGRwkia2cle_AmB .yCI3VzdQGTzOyGbJGJB6:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.PEoC5iGD4q61yruRXpxY .wAd7WoGRwkia2cle_AmB .qHe74PxWys8GMWZIKrzV {
  color: #fff;
  width: 136px;
  height: 48px;
  background-color: #388e3c;
  border-color: #388e3c;
  cursor: pointer;
}
.PEoC5iGD4q61yruRXpxY .wAd7WoGRwkia2cle_AmB .U_KguOD7b_cdQcQICMSs {
  color: #dfdfdf;
  width: 136px;
  height: 48px;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}

.ZUCkdIFl9S1D0YawyLFf {
  background: #2755fe;
  border: 1px solid #2755fe;
  border-radius: 5px;
  width: 136px;
  height: 48px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  min-width: 100px;
}

.nWPXqS95CPzY1dML6zn2 {
  font-weight: 500;
  font-size: 20px;
  margin-top: 15px;
  font-family: Poppins, sans-serif;
}

.FSueXlS8VYETI0mfOVrg {
  font-weight: 300;
  font-size: 14px;
  margin-top: -10px;
  font-family: Poppins, sans-serif;
}
.FSueXlS8VYETI0mfOVrg a {
  color: #2755fe !important;
}

.fmxfFBtIJHN6r8CfIN23 {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p9DvirPORbQbhLyDmLCs {
  font-family: Roboto;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `KN0OtzhH3SqwzjMTJcAI`,
	"heading": `nWPXqS95CPzY1dML6zn2`,
	"parentDivWrapper": `PEoC5iGD4q61yruRXpxY`,
	"bodyCard": `oSYpw0EATveDJEXlwBKs`,
	"tableCard": `Yp6eJMZN8kWcqtOzC545`,
	"knowMore": `IkZkYROoDlcls8Ta3lkD`,
	"passthroughCard": `DRX7fkRwFw7XLy38AKSx`,
	"footer": `DnaXN_YgBosAEJ9sPL7x`,
	"sparkle": `cvIFt2Fw9AEKTmzYeVLz`,
	"buttons": `wAd7WoGRwkia2cle_AmB`,
	"cancelBtn": `kkK3cAZUsDBKJ8bqvgpv`,
	"activeBtn": `yCI3VzdQGTzOyGbJGJB6`,
	"activeBtnSA": `qHe74PxWys8GMWZIKrzV`,
	"disabledBtn": `U_KguOD7b_cdQcQICMSs`,
	"okButton": `ZUCkdIFl9S1D0YawyLFf`,
	"body": `FSueXlS8VYETI0mfOVrg`,
	"loader": `fmxfFBtIJHN6r8CfIN23`,
	"robotoFont": `p9DvirPORbQbhLyDmLCs`
};
export default ___CSS_LOADER_EXPORT___;
