// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v8I2UkNBiziReuObNrkw {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 50px;
}
@media only screen and (max-width: 1200px) {
  .v8I2UkNBiziReuObNrkw {
    width: 1200px;
  }
}
.v8I2UkNBiziReuObNrkw .FWCz6FVYPq83pvY4gOLz {
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
}
.v8I2UkNBiziReuObNrkw .d5C7IYRPr1g2gxUlt5bJ {
  margin-top: -190px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 10px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companySettingsContainer": `v8I2UkNBiziReuObNrkw`,
	"cardContainer": `FWCz6FVYPq83pvY4gOLz`,
	"heading": `d5C7IYRPr1g2gxUlt5bJ`
};
export default ___CSS_LOADER_EXPORT___;
