// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iQmvfhm4F7CvnHn6NYuu {
  display: flex;
  width: 100%;
  padding: 11px 18px;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
}

.sv9dGrBmGbcMVX8Hi57w {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  width: 100%;
}

.Lk3OTTB7qB9M6YKZQ1zy {
  display: flex;
  align-items: center;
}
.Lk3OTTB7qB9M6YKZQ1zy img {
  width: 24px;
}
.Lk3OTTB7qB9M6YKZQ1zy .gOWbdwaFrrjYZk9eZ2t9 {
  margin-left: 10px;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
}

.R07UgJqrNvNBpfrra7A3 {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
}

.K08e_tRkf0zR5lpulZkg {
  flex: 1;
  width: 100%;
  margin-top: 26px;
}

.cFEkRCrPc8HAR73wKucp {
  padding: 0;
  margin: 0;
  list-style: none;
}
.cFEkRCrPc8HAR73wKucp li {
  margin-bottom: 12px;
}
.cFEkRCrPc8HAR73wKucp li a {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.CsNVesuOQOkjzUarykB0 {
  width: 100%;
  display: flex;
}

.M1EwgoWcRYNElHuYiapL {
  display: flex;
  align-items: center;
}
.M1EwgoWcRYNElHuYiapL img {
  width: 34px;
  margin-right: 5px;
}
.M1EwgoWcRYNElHuYiapL .gOWbdwaFrrjYZk9eZ2t9 {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarContainer": `iQmvfhm4F7CvnHn6NYuu`,
	"sidebarHeader": `sv9dGrBmGbcMVX8Hi57w`,
	"LogoContainer": `Lk3OTTB7qB9M6YKZQ1zy`,
	"LogoText": `gOWbdwaFrrjYZk9eZ2t9`,
	"CloseBtn": `R07UgJqrNvNBpfrra7A3`,
	"sidebarBody": `K08e_tRkf0zR5lpulZkg`,
	"sidebarMenu": `cFEkRCrPc8HAR73wKucp`,
	"sidebarFooter": `CsNVesuOQOkjzUarykB0`,
	"FooterLogoContainer": `M1EwgoWcRYNElHuYiapL`
};
export default ___CSS_LOADER_EXPORT___;
