// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._wEocKPL8oT9TX3Kg4JK {
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW {
  border: none;
  cursor: pointer;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf {
  display: flex;
  background: #ffffff;
  border: none;
  padding-left: 0;
  padding-right: 0;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf .IsNQDskxmnn7xtMdhWPV {
  position: relative;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf .IsNQDskxmnn7xtMdhWPV .LR8Ude_q7VOHjitaqeBg {
  position: absolute;
  top: -6px;
  left: 11px;
  font-size: 12px;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf .LtjilUNnr_CP4uDz0QaQ {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 8px;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf .LtjilUNnr_CP4uDz0QaQ .YAF6elNYKyxXQeRcpIAy {
  font-weight: 500;
  font-size: 16px;
  color: #aaaaaa;
  margin: 0;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf .LtjilUNnr_CP4uDz0QaQ .YAF6elNYKyxXQeRcpIAy .xw4mOCK7oTFNMcaxMTKc {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  margin: 0;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf .LtjilUNnr_CP4uDz0QaQ .fRO0iIENtCuWDHMSPMUg {
  display: flex;
  text-align: right;
  font-weight: normal;
  font-size: 14px;
  color: #666666;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf .LtjilUNnr_CP4uDz0QaQ .fRO0iIENtCuWDHMSPMUg .KqEiVRppt5k00mzoKzqp {
  font-family: roboto;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf .LtjilUNnr_CP4uDz0QaQ .fRO0iIENtCuWDHMSPMUg .hy8tNHtcY6w33d0bXavx {
  margin-right: 8px;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf .LtjilUNnr_CP4uDz0QaQ .o_OTcx4KzT6H5smuw2gl {
  display: flex;
  text-align: right;
  font-weight: normal;
  font-size: 14px;
  color: #bfbfbf;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf .LtjilUNnr_CP4uDz0QaQ .o_OTcx4KzT6H5smuw2gl .KqEiVRppt5k00mzoKzqp {
  font-family: roboto;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .RA2YbbGkVZUp6ODJM2bf .LtjilUNnr_CP4uDz0QaQ .o_OTcx4KzT6H5smuw2gl .hy8tNHtcY6w33d0bXavx {
  margin-right: 8px;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .KjNOMCnQHfGh2CIby7k5 {
  padding-left: 30px;
  padding-right: 0;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .KjNOMCnQHfGh2CIby7k5 .TXSnLOSWFcHXveybyYHr {
  margin-bottom: 18px;
}
._wEocKPL8oT9TX3Kg4JK .k3niGdQsxbDEjsIBQhHW .KjNOMCnQHfGh2CIby7k5 .TXSnLOSWFcHXveybyYHr .LJOjEDQqBHtxVYsr0J4o {
  font-weight: normal;
  font-size: 14px;
  color: #595959;
  margin-right: 18px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addonsContainer": `_wEocKPL8oT9TX3Kg4JK`,
	"addonsContainerCard": `k3niGdQsxbDEjsIBQhHW`,
	"addonsContainerHeader": `RA2YbbGkVZUp6ODJM2bf`,
	"iconContainer": `IsNQDskxmnn7xtMdhWPV`,
	"plusIcon": `LR8Ude_q7VOHjitaqeBg`,
	"headingRightContainer": `LtjilUNnr_CP4uDz0QaQ`,
	"heading": `YAF6elNYKyxXQeRcpIAy`,
	"headingDesc": `xw4mOCK7oTFNMcaxMTKc`,
	"headingRight": `fRO0iIENtCuWDHMSPMUg`,
	"rupee": `KqEiVRppt5k00mzoKzqp`,
	"startFrom": `hy8tNHtcY6w33d0bXavx`,
	"headingRightDisable": `o_OTcx4KzT6H5smuw2gl`,
	"cardBody": `KjNOMCnQHfGh2CIby7k5`,
	"refCounterContainer": `TXSnLOSWFcHXveybyYHr`,
	"refNumber": `LJOjEDQqBHtxVYsr0J4o`
};
export default ___CSS_LOADER_EXPORT___;
