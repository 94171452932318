// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pSgBqdBFf7yQ3xNkaK0H {
  font-family: Poppins, sans-serif;
  background: #000000;
  color: #ffffff;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  height: 117px;
  justify-content: center;
  padding: 0 16px;
  margin-bottom: 16px !important;
  cursor: pointer;
}
.pSgBqdBFf7yQ3xNkaK0H .uzHsHYN4Ujlmkpqq5WLy {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.pSgBqdBFf7yQ3xNkaK0H .uzHsHYN4Ujlmkpqq5WLy .H53rgQ3JAFPGrsm0VHRo {
  font-weight: normal !important;
  margin: 0;
  font-size: 14px;
  width: 165px;
}
.pSgBqdBFf7yQ3xNkaK0H .uzHsHYN4Ujlmkpqq5WLy .JN9JU6WJo7AsWj2WySUQ {
  align-self: flex-end;
  background: #ffffff;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 13px;
}
.pSgBqdBFf7yQ3xNkaK0H .uzHsHYN4Ujlmkpqq5WLy .JN9JU6WJo7AsWj2WySUQ img {
  margin-top: 2px;
}

.h8SPVnB9LckOetHF9Aqh {
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
  height: 117px;
  justify-content: center;
  margin-bottom: 16px !important;
}

.hMdOpnueKh4EI26zQxqK {
  position: absolute;
  top: 0;
  left: 15px;
  margin: 8px 0px;
  display: flex;
  align-items: center;
}
.hMdOpnueKh4EI26zQxqK .hrEI8CbvwNWXFA3igiU5 {
  margin-left: 8px;
  width: 24px;
  height: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.vNPdl8SPjcnBThNHsQQP {
  cursor: pointer;
  background: #f20000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 10px;
  margin-left: 8px;
}

.Zat1sZzEHDtl7WSgIS6O {
  position: absolute;
  margin: 8px 0px;
  display: flex;
  align-items: center;
  bottom: 19px;
  right: 22px;
}
.Zat1sZzEHDtl7WSgIS6O .FRhLPFkOksVlwyChfmH9 {
  margin-right: 3px;
  background: #ffffff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorClass": `pSgBqdBFf7yQ3xNkaK0H`,
	"errorClassContent": `uzHsHYN4Ujlmkpqq5WLy`,
	"errorText": `H53rgQ3JAFPGrsm0VHRo`,
	"refreshIconContainer": `JN9JU6WJo7AsWj2WySUQ`,
	"certificateUploadedFileContainer": `h8SPVnB9LckOetHF9Aqh`,
	"deletePreviewImageContainer": `hMdOpnueKh4EI26zQxqK`,
	"actionIconContainer": `hrEI8CbvwNWXFA3igiU5`,
	"deleteBadge": `vNPdl8SPjcnBThNHsQQP`,
	"passwordProtectIcon": `Zat1sZzEHDtl7WSgIS6O`,
	"functionBtn": `FRhLPFkOksVlwyChfmH9`
};
export default ___CSS_LOADER_EXPORT___;
