// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.cS39A5Pt9iFC5_alPpxw {
  border: unset;
}
.cS39A5Pt9iFC5_alPpxw .ZfOPpXrMWE23AmQmSoDJ {
  max-width: 500px;
  margin: 90px auto;
}
.cS39A5Pt9iFC5_alPpxw .ZfOPpXrMWE23AmQmSoDJ img {
  margin-bottom: 32px;
}
.cS39A5Pt9iFC5_alPpxw .ZfOPpXrMWE23AmQmSoDJ .qC33DBFh38NNG35TQEyw {
  color: #333;
  font-size: 24px;
  font-weight: 400;
}
.cS39A5Pt9iFC5_alPpxw .ZfOPpXrMWE23AmQmSoDJ span {
  font-size: 14px;
  color: #2755fe;
  font-weight: 400;
  cursor: pointer;
}
.cS39A5Pt9iFC5_alPpxw .ZfOPpXrMWE23AmQmSoDJ p {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-bottom: 24px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `cS39A5Pt9iFC5_alPpxw`,
	"body": `ZfOPpXrMWE23AmQmSoDJ`,
	"title": `qC33DBFh38NNG35TQEyw`
};
export default ___CSS_LOADER_EXPORT___;
