// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sir2Sr0LBoB6Gs3vrqF6 {
  min-height: 80vh;
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .Sir2Sr0LBoB6Gs3vrqF6 {
    width: 1200px;
  }
}
.Sir2Sr0LBoB6Gs3vrqF6 .hzA8mlFKoo0uZw1jrUbw {
  margin-top: -190px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
  padding: 2rem;
  font-family: Poppins, sans-serif;
  border: #d3d3d3 1px solid;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .yae09NlDHnMUdFGTqeyJ {
  border: #d3d3d3 1px solid;
  border-radius: 4px;
  padding: 1rem 1.25rem 5rem 1.25rem;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .k_Ytdkrh2gG92Kj0UNWK ol {
  background: none;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .k_Ytdkrh2gG92Kj0UNWK ol li {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .k_Ytdkrh2gG92Kj0UNWK ol li a {
  color: #8c8c8c;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .k_Ytdkrh2gG92Kj0UNWK ol li :active {
  color: #595959;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .hViHizfq5DIkiLnJD5Qd {
  padding: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .hViHizfq5DIkiLnJD5Qd .GLPFxUYqzrufOKpexsmK {
  color: #333333;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .hViHizfq5DIkiLnJD5Qd .OYB7HDR8Te3f5pofZ0OQ {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #999999;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .hViHizfq5DIkiLnJD5Qd .FqmZRM1YbJbCdbYWOTcV {
  color: #999999;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .ex6z08GnW5CZGE7ffbSR {
  border: none;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .ex6z08GnW5CZGE7ffbSR .B5RiWFGyXc6KXYfhyPeA {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #999999;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .ex6z08GnW5CZGE7ffbSR .ZTYH0oaY8xxxN9a5A4w0 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #333333;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .ex6z08GnW5CZGE7ffbSR .KF5JLKVGjap0bUgKhBxc {
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .ex6z08GnW5CZGE7ffbSR .pRPsdzlk_Oc4UHyaCi5y,
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .ex6z08GnW5CZGE7ffbSR .bv7ezUyyloqd8kxF_dKv {
  font-family: Roboto;
  color: #999999;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .BGowc8Nd_vywFDn87pZj {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem 1rem 1.25rem;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .BGowc8Nd_vywFDn87pZj .n6e2r6fxoHnjca6BVGSo {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .BGowc8Nd_vywFDn87pZj .B5RiWFGyXc6KXYfhyPeA {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .BGowc8Nd_vywFDn87pZj .nvv0mr3CZku_DOjV9zZ9 {
  color: #999999;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .JTVXfRZ44I9QOSfC9wMi {
  display: flex;
  align-items: center;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .JTVXfRZ44I9QOSfC9wMi .n6e2r6fxoHnjca6BVGSo {
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  padding: 1.25rem 0 1.25rem 1.25rem;
}
.Sir2Sr0LBoB6Gs3vrqF6 .EcI30Pbp8CjTs8T2e0bo .SWN3RmCoFLerJGw9XDyF {
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  margin-left: 800px;
  margin: auto;
  padding: 20px;
  border-left: 2px #333333;
}
.Sir2Sr0LBoB6Gs3vrqF6 .Kd81fkVAQvgjCjg6gz8w {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: -2.5rem auto;
  padding: 2rem;
  font-family: Poppins, sans-serif;
  border: #d3d3d3 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Sir2Sr0LBoB6Gs3vrqF6 .T4r78FHw17M51buVkUu2 {
  margin-left: 0.5rem;
  border-radius: 1rem;
  padding: 0.1rem 0.4rem;
  font-weight: 600;
  font-size: 12px;
}
.Sir2Sr0LBoB6Gs3vrqF6 .T4r78FHw17M51buVkUu2.b1nhDpNcfUfaAoaWuERu {
  background-color: #366cf3;
  color: white;
}
.Sir2Sr0LBoB6Gs3vrqF6 .T4r78FHw17M51buVkUu2.t7TlAyftx8Il4Ov2sjLd {
  background-color: #7f8a93;
  color: white;
}

.xvESb8pbyrOlzf7agjBG .zugowwyfF2HGMuqkPonL {
  visibility: hidden;
  position: absolute;
  bottom: -25%;
  right: 7%;
  background-color: grey;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 3px 5px;
}

.xvESb8pbyrOlzf7agjBG:hover .zugowwyfF2HGMuqkPonL {
  visibility: visible;
}

.dE3bVSwn2HZpi0xhIrI1 {
  width: 21px;
  height: 21px;
  margin-left: 15px;
}

.dE3bVSwn2HZpi0xhIrI1:hover {
  cursor: pointer;
}

.WcJ_pr4erQ09zefj3qg0 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c4e8d0;
  color: #4ea56c;
  padding: 3px 7px;
  border-radius: 24px;
}
.WcJ_pr4erQ09zefj3qg0 .xImtUkKGQnmyKkp7ttK9 {
  font-size: 15px;
  font-weight: 700;
  margin-top: 1px;
  margin-left: 2px;
}
.WcJ_pr4erQ09zefj3qg0 .fw3buOhJI6V9y8y0OIVh {
  stroke-width: 40px !important;
}

.LffjGa9GKn9eUB0VhKPV {
  color: #2755fe;
}
.LffjGa9GKn9eUB0VhKPV:hover {
  color: #1f44ca;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"purchaseDetailsWrapper": `Sir2Sr0LBoB6Gs3vrqF6`,
	"heading": `hzA8mlFKoo0uZw1jrUbw`,
	"purchaseDetailsCard": `EcI30Pbp8CjTs8T2e0bo`,
	"purchaseDetailsCardContainer": `yae09NlDHnMUdFGTqeyJ`,
	"breadcrumb": `k_Ytdkrh2gG92Kj0UNWK`,
	"summary": `hViHizfq5DIkiLnJD5Qd`,
	"packageName": `GLPFxUYqzrufOKpexsmK`,
	"tax": `OYB7HDR8Te3f5pofZ0OQ`,
	"candidates": `FqmZRM1YbJbCdbYWOTcV`,
	"summaryCard": `ex6z08GnW5CZGE7ffbSR`,
	"text": `B5RiWFGyXc6KXYfhyPeA`,
	"total": `ZTYH0oaY8xxxN9a5A4w0`,
	"amount": `KF5JLKVGjap0bUgKhBxc`,
	"taxAmount": `pRPsdzlk_Oc4UHyaCi5y`,
	"tdsAmount": `bv7ezUyyloqd8kxF_dKv`,
	"infoCard": `BGowc8Nd_vywFDn87pZj`,
	"title": `n6e2r6fxoHnjca6BVGSo`,
	"deletedUser": `nvv0mr3CZku_DOjV9zZ9`,
	"purchaseTypeHeading": `JTVXfRZ44I9QOSfC9wMi`,
	"summaryHeading": `SWN3RmCoFLerJGw9XDyF`,
	"completedOrderCard": `Kd81fkVAQvgjCjg6gz8w`,
	"txnPill": `T4r78FHw17M51buVkUu2`,
	"pendingTxn": `b1nhDpNcfUfaAoaWuERu`,
	"otherTxn": `t7TlAyftx8Il4Ov2sjLd`,
	"tooltip": `xvESb8pbyrOlzf7agjBG`,
	"tooltiptext": `zugowwyfF2HGMuqkPonL`,
	"copyToClipboard": `dE3bVSwn2HZpi0xhIrI1`,
	"connectedFlag": `WcJ_pr4erQ09zefj3qg0`,
	"connectedFlagText": `xImtUkKGQnmyKkp7ttK9`,
	"icon": `fw3buOhJI6V9y8y0OIVh`,
	"blueColor": `LffjGa9GKn9eUB0VhKPV`
};
export default ___CSS_LOADER_EXPORT___;
