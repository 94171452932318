// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wSbxD0u0Il71yy8tpo4U {
  background: #ffffff;
  color: #2755fe;
  border: none;
  margin-right: 32px;
  box-shadow: none;
  font-size: 16px;
  font-family: Poppins, sans-serif;
}
.wSbxD0u0Il71yy8tpo4U:hover {
  background: #ffffff;
  color: #2755fe;
  border: none;
}
.wSbxD0u0Il71yy8tpo4U:active {
  background: #ffffff !important;
  color: #2755fe !important;
  border: none !important;
}
.wSbxD0u0Il71yy8tpo4U:focus {
  box-shadow: none !important;
}

.fhy4FJWBt5okLQ2JlGXR {
  height: 25px;
  margin-right: 24px;
  padding: 0 10px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"goBackBtn": `wSbxD0u0Il71yy8tpo4U`,
	"goBackBtnMobile": `fhy4FJWBt5okLQ2JlGXR`
};
export default ___CSS_LOADER_EXPORT___;
