// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gMZw1bu_FdUYGYB_w9EG {
  display: inline-flex;
  width: 100%;
}

.aEpe_1AZ7xg8MWezcHTQ {
  width: 50%;
  margin-left: 10px;
}

.V8GQlBzoKtKUeMQgWPIx {
  margin-left: 16px;
  margin-right: 56px;
}

._7QXUeZSBrOYSj988zjcj {
  border: 1px solid red;
}

.IvWGwSVKxDfTVfcnv9do {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
}

.x9983aM7ENF41wFjjoRj {
  padding-top: 0.5rem;
}

.iuKMzrIyhjvpnk4RDxV4 {
  background-color: #2755fe;
  color: white;
  padding: 0.5rem;
  border: none;
}

.txxiyCJllEBjn1SqGxPT {
  background-color: #1bae9f;
  color: white;
  padding: 0.5rem;
  border: none;
}

.iuKMzrIyhjvpnk4RDxV4:hover {
  background-color: #1f44ca;
}

.txxiyCJllEBjn1SqGxPT:hover {
  background-color: #11a294;
}

.OdJoIw8q5xQZI92VAzot {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.mShM4jhlLLE07EIRncbd {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  color: #2755fe;
  border: 1px solid #2755fe;
}

.OMr1Kaye1ZvwrXW6YIYw {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formMainDiv": `gMZw1bu_FdUYGYB_w9EG`,
	"formField": `aEpe_1AZ7xg8MWezcHTQ`,
	"parentDivConnectButton": `V8GQlBzoKtKUeMQgWPIx`,
	"removeButton": `_7QXUeZSBrOYSj988zjcj`,
	"validationErrorMsg": `IvWGwSVKxDfTVfcnv9do`,
	"inputLabel": `x9983aM7ENF41wFjjoRj`,
	"connectButton": `iuKMzrIyhjvpnk4RDxV4`,
	"connectedButton": `txxiyCJllEBjn1SqGxPT`,
	"step1FooterContainer": `OdJoIw8q5xQZI92VAzot`,
	"goBackBtn": `mShM4jhlLLE07EIRncbd`,
	"listItems": `OMr1Kaye1ZvwrXW6YIYw`
};
export default ___CSS_LOADER_EXPORT___;
