// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GN8awscgoMRyUNI7Uhao {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.coPLJd9FTMs4ty6qOxRI {
  display: flex;
  border-radius: 4px;
  align-items: center;
  max-width: 633px;
  border: 1px solid #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.BAhVvZ3owOjkGYKEfBXU {
  margin-bottom: 32px;
  width: 100%;
}

.rkk6WJDrD8iwRYcPuB_p {
  border: none;
  outline: none;
  box-shadow: none;
  border-color: #ced4da;
}

.rx0IJfNQEKiyoIetvWHI {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  align-items: center;
  max-width: 633px;
}

.ev1WXeh0ZIkaqtg5_g7w {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  align-items: center;
  max-width: 633px;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.A1wgDjMmqa7IHqZQO0o5 {
  padding: 2px;
  padding-right: 10px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputError": `GN8awscgoMRyUNI7Uhao`,
	"error": `coPLJd9FTMs4ty6qOxRI`,
	"wrapper": `BAhVvZ3owOjkGYKEfBXU`,
	"inputDiv": `rkk6WJDrD8iwRYcPuB_p`,
	"container": `rx0IJfNQEKiyoIetvWHI`,
	"containerFocus": `ev1WXeh0ZIkaqtg5_g7w`,
	"iconDiv": `A1wgDjMmqa7IHqZQO0o5`
};
export default ___CSS_LOADER_EXPORT___;
