// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* In your SCSS or CSS file */
.xlxKavBv7RtM4zpmG6T2 {
  width: 720px;
  height: auto !important;
  max-width: none !important;
}

.D5D_JXtIra9txkbtwNsi {
  padding-top: 20px !important;
  box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}

.MtdHRwmSrxKzCGnX4amb {
  background: #2755fe;
  width: 155px;
  height: 48px;
  border-radius: 5px;
  font-size: 14px;
  justify-self: flex-end;
}

.gVbgNiWWS43XpHrGY84d {
  padding: 0 24px 0px 24px !important;
  max-height: 460px;
  overflow-y: auto;
}
.gVbgNiWWS43XpHrGY84d::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.gVbgNiWWS43XpHrGY84d::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.IoQH5BrcsppYBSacOavw {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  padding: 24px 30px 0px 24px !important;
}
.IoQH5BrcsppYBSacOavw .l2uWSshFNW3Vdrdc68wO {
  color: #333333;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.IoQH5BrcsppYBSacOavw .lX8nrzfcZZSAlx2tztc2 {
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  width: 643px;
}
.IoQH5BrcsppYBSacOavw .VPKKpIbdIHY2CqLWiOxK {
  color: #666666;
  margin-bottom: 15px;
}
.IoQH5BrcsppYBSacOavw .INKcquSoBaV0ncRASig_ {
  color: #2755fe;
  font-family: "Poppins", Helvetica;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.nlQVApW1GoWBYxTYDKOq {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
}
.nlQVApW1GoWBYxTYDKOq .po__UQFjUmmLVFOKLAZa {
  height: 18px;
  width: 125px;
}
.nlQVApW1GoWBYxTYDKOq .po__UQFjUmmLVFOKLAZa .DCNJA2rsSo9E4vlpWBIC {
  color: #333333;
  font-family: "Poppins", Helvetica;
  font-size: 12px !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}
.nlQVApW1GoWBYxTYDKOq .gHBoOguSxTDqDnpVLw4i {
  height: 1px;
  width: 655x;
  margin-top: 10px;
  background-color: #c6c8cc;
}

.OfwJHq_9PM42g0fQCPJp {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}
.OfwJHq_9PM42g0fQCPJp .qDNp2Ej4NWBQz2X8P5Wa {
  height: 18px;
  width: 125px;
}
.OfwJHq_9PM42g0fQCPJp .qDNp2Ej4NWBQz2X8P5Wa .UlDEKVdQwsgeakd5JUwJ {
  color: #333333;
  font-family: "Poppins", Helvetica;
  font-size: 12px !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}
.OfwJHq_9PM42g0fQCPJp .gHBoOguSxTDqDnpVLw4i {
  height: 1px;
  width: 655px;
  margin-top: 10px;
  background-color: #c6c8cc;
}

.He6BnFfT2mOFDwu0TRy8 {
  width: 644px;
  height: 70px;
  align-items: flex-start;
  display: inline-flex;
  gap: 16px;
  position: relative;
}
.He6BnFfT2mOFDwu0TRy8 .OeG2JL3PL5i7wa7FA2Qw {
  width: 80px;
  height: 48px;
  align-items: baseline;
  display: inline-flex;
}
.He6BnFfT2mOFDwu0TRy8 .OeG2JL3PL5i7wa7FA2Qw .YFqf3dX7zDpgABsSoB3U {
  width: 32px;
  height: 48px;
}
.He6BnFfT2mOFDwu0TRy8 .OeG2JL3PL5i7wa7FA2Qw .i2wWd27rDi5Wmp3QnxM2 {
  width: 48px;
  height: 48px;
}
.He6BnFfT2mOFDwu0TRy8 .gKc_5EHCU6je1qs61oZB {
  width: 548px;
  height: 67px;
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
}
.He6BnFfT2mOFDwu0TRy8 .gKc_5EHCU6je1qs61oZB .zscGEpJ_n6zl83502k4r {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
}
.He6BnFfT2mOFDwu0TRy8 .gKc_5EHCU6je1qs61oZB .zscGEpJ_n6zl83502k4r .SXuea2HuFSVpXanviCVE {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
}
.He6BnFfT2mOFDwu0TRy8 .gKc_5EHCU6je1qs61oZB .zscGEpJ_n6zl83502k4r .SXuea2HuFSVpXanviCVE .Sg_XCAKpxYEAzonxStlI {
  color: #212529;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  padding-top: 1px;
  padding-bottom: 1px;
  width: fit-content;
  text-transform: capitalize;
}
.He6BnFfT2mOFDwu0TRy8 .gKc_5EHCU6je1qs61oZB .zscGEpJ_n6zl83502k4r .bCbuteiqp1DboTL_z65F {
  color: rgba(33, 37, 41, 0.8);
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  padding-top: 1px;
  padding-bottom: 5px;
  line-height: normal;
  width: 548px;
  margin: 0;
}

.hHaBMTGzE3_x8KpOJYQ6 input[type=checkbox] {
  width: 16px;
  height: 16px;
}

.BxqglWsTI9XYRItJRI8F {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10000;
}

.kSV3YkiA8JH7BBYDmNrn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.s_d_EdPcZLPXHz0SJYRc {
  color: #2755fe !important;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
  margin-bottom: 10px;
}

.AoiVBlCVtBrBaVLxVwC8 {
  visibility: hidden;
}

.ZYMBgcjH7wy3AowZJSvA .ESJMgWkVtUirmh6VQse9:last-child .gHBoOguSxTDqDnpVLw4i {
  visibility: hidden;
}

.k03z3DxVvUs9mC8oy2vI {
  background: rgba(39, 85, 254, 0.1019607843);
  border-radius: 8px;
  padding: 12px 8px 12px 8px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 17px;
  font-size: 12px;
}

.gRFsv74Hc7KoWHf0bCJ5 {
  margin: 0px !important;
  color: #666666;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-90w": `xlxKavBv7RtM4zpmG6T2`,
	"modal-footer": `D5D_JXtIra9txkbtwNsi`,
	"explore-button": `MtdHRwmSrxKzCGnX4amb`,
	"modal-body": `gVbgNiWWS43XpHrGY84d`,
	"frame": `IoQH5BrcsppYBSacOavw`,
	"modal-heading": `l2uWSshFNW3Vdrdc68wO`,
	"text": `lX8nrzfcZZSAlx2tztc2`,
	"text-wrapper": `VPKKpIbdIHY2CqLWiOxK`,
	"span": `INKcquSoBaV0ncRASig_`,
	"most-used-checks-parent": `nlQVApW1GoWBYxTYDKOq`,
	"most-used-checks-div": `po__UQFjUmmLVFOKLAZa`,
	"most-used-checks-label": `DCNJA2rsSo9E4vlpWBIC`,
	"lineStyle": `gHBoOguSxTDqDnpVLw4i`,
	"other-checks-parent": `OfwJHq_9PM42g0fQCPJp`,
	"other-checks-div": `qDNp2Ej4NWBQz2X8P5Wa`,
	"other-checks-label": `UlDEKVdQwsgeakd5JUwJ`,
	"check-item-parent": `He6BnFfT2mOFDwu0TRy8`,
	"checkbox-and-icon-parent": `OeG2JL3PL5i7wa7FA2Qw`,
	"item-checkbox": `YFqf3dX7zDpgABsSoB3U`,
	"item-check-icon": `i2wWd27rDi5Wmp3QnxM2`,
	"checkname-and-info-parent": `gKc_5EHCU6je1qs61oZB`,
	"checkname-and-info-wrapper": `zscGEpJ_n6zl83502k4r`,
	"checkname-div": `SXuea2HuFSVpXanviCVE`,
	"checkname": `Sg_XCAKpxYEAzonxStlI`,
	"check-info-text": `bCbuteiqp1DboTL_z65F`,
	"checkbox": `hHaBMTGzE3_x8KpOJYQ6`,
	"closeContainer": `BxqglWsTI9XYRItJRI8F`,
	"footerWrapper": `kSV3YkiA8JH7BBYDmNrn`,
	"view-all-checks-text": `s_d_EdPcZLPXHz0SJYRc`,
	"visibleHidden": `AoiVBlCVtBrBaVLxVwC8`,
	"checksL3": `ZYMBgcjH7wy3AowZJSvA`,
	"checkContainer": `ESJMgWkVtUirmh6VQse9`,
	"ribbon": `k03z3DxVvUs9mC8oy2vI`,
	"ribbonText": `gRFsv74Hc7KoWHf0bCJ5`
};
export default ___CSS_LOADER_EXPORT___;
