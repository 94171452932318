// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.udkIqZMOWRue_OLoWWp7 {
  color: #333333 !important;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.aLoQmdOMMvugfdBYFN1K {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Hy7J6EgKisHVicfRiTkM {
  width: 476px;
  min-height: 156px;
  align-items: center;
  background-color: #f8fafc;
  border: 1px dashed;
  border-color: #cbd5e1;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.Hy7J6EgKisHVicfRiTkM .x5S_T2maskf4265cqxJW {
  color: #1a1a1a;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.Hy7J6EgKisHVicfRiTkM .mXZo1D_wYeZ679RWBdoX {
  line-height: 24px;
  margin: 12px 0 8px;
}
.Hy7J6EgKisHVicfRiTkM .rq_B90CKn7t3bsuz1iLx {
  line-height: 21px;
  margin: 10px 0;
}
.Hy7J6EgKisHVicfRiTkM .nsC5eqGZ3EzOotfqt_dD {
  display: flex;
  flex-direction: column;
}
.Hy7J6EgKisHVicfRiTkM .Q7IknhdbPGdcpPbvMTVK {
  height: 25px;
  display: flex;
  gap: 3px;
}
.Hy7J6EgKisHVicfRiTkM .Ai7lcjXHFYW_Cc5_QdRQ {
  display: flex;
  align-items: center;
  padding: 9px 13px;
  width: 270px;
  min-height: 52px;
}
.Hy7J6EgKisHVicfRiTkM .Ai7lcjXHFYW_Cc5_QdRQ .NcjyPl4P5aEmGzsYJs9W {
  display: flex;
  align-items: center;
}
.Hy7J6EgKisHVicfRiTkM .Ai7lcjXHFYW_Cc5_QdRQ .NcjyPl4P5aEmGzsYJs9W .NJ1tmytMAFjQl7_cgOJa {
  color: #000000;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  width: 173px;
  margin-left: 12px;
  margin-right: 8px;
}
.Hy7J6EgKisHVicfRiTkM .zahlsDFzZ7CBSvhDzfRf {
  color: #5d5cf5;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.Hy7J6EgKisHVicfRiTkM .YAB1ZgV8HVMvvroxhc3K {
  color: #000000;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  width: 173px;
}
.Hy7J6EgKisHVicfRiTkM .atMIGFlUNPIl5nZ8JJBj {
  color: #666666;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 20px;
  text-align: center;
}
.Hy7J6EgKisHVicfRiTkM .Rn6JNI4j0eatqwtqF1Hh {
  color: #2755fe;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
  cursor: pointer;
}
.Hy7J6EgKisHVicfRiTkM .j9h1AukXRYXiR61TEOoY {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.Hy7J6EgKisHVicfRiTkM .j9h1AukXRYXiR61TEOoY .jzWn27Wwx3gGT7atSEUb {
  color: #535961;
  font-family: "Poppins-Italic", Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.j585zG56uu2rtYVUKc2x {
  margin: 0px !important;
  padding-left: 12px !important;
  padding-top: 32px !important;
}

.JgbRiwLi27LzKqeaBhzF {
  color: #333333;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  margin-bottom: 8px;
}

._0QiWRAt06EkTVH4SKgG {
  color: #2755fe;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.Z5t3dX5njPWU7VrBExEO {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
}
.Z5t3dX5njPWU7VrBExEO .wddrwIZ1ZTHuAmrwzZoQ {
  color: #2755fe;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 16px;
  width: 142px;
  border-radius: 2px;
}
.Z5t3dX5njPWU7VrBExEO .wddrwIZ1ZTHuAmrwzZoQ:hover {
  color: #ffffff;
}
.Z5t3dX5njPWU7VrBExEO .gGZ1DV7bxZ34iNaKocok {
  background-color: #2755fe;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 16px;
  width: 142px;
  border-radius: 2px;
}

.jy88JavHo6bo6VL1ODbX button {
  border: none;
  background: transparent;
  font-size: 1.5rem;
}

.NH39Y8dUrCrmTMClq4nA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 216px;
  border: "1px dashed #000";
  border-radius: 4px;
  gap: 3px;
  cursor: pointer;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerTitle": `udkIqZMOWRue_OLoWWp7`,
	"rowOne": `aLoQmdOMMvugfdBYFN1K`,
	"uploadFileContainer": `Hy7J6EgKisHVicfRiTkM`,
	"titleText": `x5S_T2maskf4265cqxJW`,
	"upload": `mXZo1D_wYeZ679RWBdoX`,
	"uploaded": `rq_B90CKn7t3bsuz1iLx`,
	"flexDiv": `nsC5eqGZ3EzOotfqt_dD`,
	"containerNotUploadedInfoText": `Q7IknhdbPGdcpPbvMTVK`,
	"fileNameCard": `Ai7lcjXHFYW_Cc5_QdRQ`,
	"body": `NcjyPl4P5aEmGzsYJs9W`,
	"fileNameText": `NJ1tmytMAFjQl7_cgOJa`,
	"browseText": `zahlsDFzZ7CBSvhDzfRf`,
	"uploadedFileName": `YAB1ZgV8HVMvvroxhc3K`,
	"errorInfoText": `atMIGFlUNPIl5nZ8JJBj`,
	"tryAgainText": `Rn6JNI4j0eatqwtqF1Hh`,
	"fileInfoContainer": `j9h1AukXRYXiR61TEOoY`,
	"fileInfoText": `jzWn27Wwx3gGT7atSEUb`,
	"listBody": `j585zG56uu2rtYVUKc2x`,
	"listText": `JgbRiwLi27LzKqeaBhzF`,
	"activeLinkText": `_0QiWRAt06EkTVH4SKgG`,
	"footer": `Z5t3dX5njPWU7VrBExEO`,
	"cancelButton": `wddrwIZ1ZTHuAmrwzZoQ`,
	"saveButton": `gGZ1DV7bxZ34iNaKocok`,
	"customHeader": `jy88JavHo6bo6VL1ODbX`,
	"logo": `NH39Y8dUrCrmTMClq4nA`
};
export default ___CSS_LOADER_EXPORT___;
