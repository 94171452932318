// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lYw50fQhbJW_ygtFnb4d p {
  color: #666666;
  font-weight: normal;
  font-size: 14px;
}

.juSSb8qVQS8A4IdHaZSd {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
  padding: 5px 20px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addEditEducationModalBody": `lYw50fQhbJW_ygtFnb4d`,
	"activeBtn": `juSSb8qVQS8A4IdHaZSd`
};
export default ___CSS_LOADER_EXPORT___;
