// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zriUC3tRflusqD6pADPw h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333333;
}
.zriUC3tRflusqD6pADPw .uCORAvcP_mIjDTmyECkn .k4NPvzyx_0emBkIA93KA {
  background-color: #fffbe6;
  display: flex;
  padding: 13px 17px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.zriUC3tRflusqD6pADPw .uCORAvcP_mIjDTmyECkn .k4NPvzyx_0emBkIA93KA ._BLAttmJENHy9kv8LdlF p {
  margin-bottom: 8px;
  font-weight: normal;
  font-size: 14px;
  color: #262626;
}
.zriUC3tRflusqD6pADPw .uCORAvcP_mIjDTmyECkn .k4NPvzyx_0emBkIA93KA ._BLAttmJENHy9kv8LdlF .PxFljYl36eM485HGZh1c {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  background: #fffbe6;
  border: 1px solid rgba(51, 51, 51, 0.1);
}
.zriUC3tRflusqD6pADPw .S9Rd0c5WZ_MPCVgSBEuO .bONDPo7hWtyLEkrucFEQ:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.1);
}
.zriUC3tRflusqD6pADPw .S9Rd0c5WZ_MPCVgSBEuO .bONDPo7hWtyLEkrucFEQ:nth-of-type(even) {
  background-color: #f7f8fa;
}
.zriUC3tRflusqD6pADPw .S9Rd0c5WZ_MPCVgSBEuO .bONDPo7hWtyLEkrucFEQ {
  border: 1px solid rgba(51, 51, 51, 0.1);
  padding: 32px 21px;
}
.zriUC3tRflusqD6pADPw .ZuZhs0dR7WQTcpZeQi09 {
  flex-wrap: unset;
  padding: 24px 24px 15px;
}
.zriUC3tRflusqD6pADPw .ZuZhs0dR7WQTcpZeQi09 .G0q30IDxI2BL4JQh9Nw2 {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.zriUC3tRflusqD6pADPw .ZuZhs0dR7WQTcpZeQi09 .G0q30IDxI2BL4JQh9Nw2 .oogGU2ugtcyCjWUApRwX {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px;
  -webkit-appearance: menulist;
}
.zriUC3tRflusqD6pADPw .SICQ6G04q2bk2CkXkbsj p {
  margin-bottom: 5px;
}
.zriUC3tRflusqD6pADPw .SICQ6G04q2bk2CkXkbsj .kYtTvpT25GSL1gz4nmtW {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.zriUC3tRflusqD6pADPw .SICQ6G04q2bk2CkXkbsj .H2HN1IrMavS4SvL5eQOJ {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.zriUC3tRflusqD6pADPw .SICQ6G04q2bk2CkXkbsj .EZz2hOXkaAonnzFRu8pX {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}
.zriUC3tRflusqD6pADPw .SICQ6G04q2bk2CkXkbsj .JJmn0Tnv4aYkvQXeCnqm {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.zriUC3tRflusqD6pADPw .KzIWnmO_Nm0K06Om6C0K {
  text-align: right;
}
.zriUC3tRflusqD6pADPw .KzIWnmO_Nm0K06Om6C0K p {
  margin-bottom: 5px;
}
.zriUC3tRflusqD6pADPw .KzIWnmO_Nm0K06Om6C0K .euuYSQ5yEFbZUVkuNkiw {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.zriUC3tRflusqD6pADPw .KzIWnmO_Nm0K06Om6C0K .GOvjl2E2FQRm0QkiuQ0n {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
.zriUC3tRflusqD6pADPw .KzIWnmO_Nm0K06Om6C0K .fB1FpdBTx2LqXYU4OwqZ {
  color: #faad14;
}
.zriUC3tRflusqD6pADPw .GpxmmL4GTOzWeOPgMhdA {
  font-family: roboto;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionHistoryContainer": `zriUC3tRflusqD6pADPw`,
	"alertContainer": `uCORAvcP_mIjDTmyECkn`,
	"alert": `k4NPvzyx_0emBkIA93KA`,
	"actionsContainer": `_BLAttmJENHy9kv8LdlF`,
	"addCred": `PxFljYl36eM485HGZh1c`,
	"insuffDataTable": `S9Rd0c5WZ_MPCVgSBEuO`,
	"transactionDetail": `bONDPo7hWtyLEkrucFEQ`,
	"paginationWrapper": `ZuZhs0dR7WQTcpZeQi09`,
	"showPagination": `G0q30IDxI2BL4JQh9Nw2`,
	"selectPagination": `oogGU2ugtcyCjWUApRwX`,
	"detailsContainer": `SICQ6G04q2bk2CkXkbsj`,
	"packageAddedText": `kYtTvpT25GSL1gz4nmtW`,
	"addedRemovedBy": `H2HN1IrMavS4SvL5eQOJ`,
	"transactionId": `EZz2hOXkaAonnzFRu8pX`,
	"addonText": `JJmn0Tnv4aYkvQXeCnqm`,
	"priceContainer": `KzIWnmO_Nm0K06Om6C0K`,
	"date": `euuYSQ5yEFbZUVkuNkiw`,
	"amount": `GOvjl2E2FQRm0QkiuQ0n`,
	"amountDebit": `fB1FpdBTx2LqXYU4OwqZ`,
	"rupee": `GpxmmL4GTOzWeOPgMhdA`
};
export default ___CSS_LOADER_EXPORT___;
