// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J0EuNBTY9K0gDe6HaFCI {
  display: flex;
  align-items: center;
  border: 1px solid #c2cfff;
  border-radius: 4px;
}
.J0EuNBTY9K0gDe6HaFCI .J4x4IdW7fmEDk_BeAZdq {
  width: 85px;
}
.J0EuNBTY9K0gDe6HaFCI .jeQhAmxwdjCLHHA9AuUW {
  box-sizing: border-box;
  border-left: 1px solid #999;
  height: 24px;
  width: 260px;
  align-items: center;
  display: flex;
  padding-left: 12px;
  padding-right: 15px;
}
.J0EuNBTY9K0gDe6HaFCI .jeQhAmxwdjCLHHA9AuUW .FBIJFi0OXn_NbWSg37sQ {
  border: none;
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 0 14px !important;
  height: 24px;
}
.J0EuNBTY9K0gDe6HaFCI .jeQhAmxwdjCLHHA9AuUW .FBIJFi0OXn_NbWSg37sQ:focus {
  box-shadow: unset;
}

.wY03QvtCSVup6eDuiHCg {
  color: #333333;
}

.B6BtQbhlHBntLOjR5Bq7 {
  color: #333333 !important;
  font-size: 14px;
  height: 45px !important;
  border-color: #c2cfff;
}

.VeoU3opeoCjnh0SoHnHu {
  border: none !important;
  box-shadow: none !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchFilter": `J0EuNBTY9K0gDe6HaFCI`,
	"searchFilterType": `J4x4IdW7fmEDk_BeAZdq`,
	"searchInput": `jeQhAmxwdjCLHHA9AuUW`,
	"inputText": `FBIJFi0OXn_NbWSg37sQ`,
	"dropDownPlaceholder": `wY03QvtCSVup6eDuiHCg`,
	"dropDownStyle": `B6BtQbhlHBntLOjR5Bq7`,
	"dropDownBorderNone": `VeoU3opeoCjnh0SoHnHu`
};
export default ___CSS_LOADER_EXPORT___;
