// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xEV9SPJoQ2CSx0LXtmPP {
  padding: 0 32px;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  min-height: 658px;
}
@media screen and (max-width: 601px) {
  .xEV9SPJoQ2CSx0LXtmPP {
    padding: 0 0 65px 0;
    box-shadow: none;
    border: none;
  }
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .LoeOka3nqSs67AxtfOK1 {
  font-size: 24px;
  line-height: 32px;
  color: #262626;
}
@media screen and (max-width: 601px) {
  .xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .LoeOka3nqSs67AxtfOK1 {
    margin-bottom: 10px;
  }
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .rP6wRYCDHVx1pSbHy7X1 {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  align-items: center;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .rP6wRYCDHVx1pSbHy7X1 ._1eWy0yUG00iAaz_Zv0U {
  margin-right: 7px;
}
@media screen and (max-width: 501px) {
  .xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .rP6wRYCDHVx1pSbHy7X1 ._1eWy0yUG00iAaz_Zv0U {
    position: absolute;
    top: 45px;
    left: 160px;
    margin-bottom: 10px;
  }
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .rP6wRYCDHVx1pSbHy7X1 .hx98K0bR8eA1npG3cf6S {
  padding: 8px;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .nlijE6T2gXj_Z3R93BQQ {
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  color: #262626;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .SzenUOlZJiG0UkYPop9a {
  font-weight: normal;
  font-size: 14px;
  color: #999999 !important;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .fEPcON2GKtpvyWN2GNTH {
  margin-top: 30px;
  display: flex;
  margin-left: 50%;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .qPctzOCZO1n6MciDAmz6 {
  padding: 0px;
  min-height: 115px;
  margin-top: 10px;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .qPctzOCZO1n6MciDAmz6 .XUH1_UBhxko2ZD1Ykv9t {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .qPctzOCZO1n6MciDAmz6 .XUH1_UBhxko2ZD1Ykv9t .bdfrUUm5OcymU7qGYrvH {
  color: red;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .qPctzOCZO1n6MciDAmz6 .XUH1_UBhxko2ZD1Ykv9t .pJbrja472rYxJQZdiS0G {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8c8c8c;
  margin-top: -5px;
  margin-bottom: 10px;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .qPctzOCZO1n6MciDAmz6 .UL2THHERBq7pcqV4EN8R {
  margin-top: 15px;
  width: 240px;
  height: 40px;
  background: #ffffff;
  border: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border-radius: 2px;
  text-align: center;
  color: #2755fe;
}
@media screen and (max-width: 993px) {
  .xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .qPctzOCZO1n6MciDAmz6 .UL2THHERBq7pcqV4EN8R {
    width: 100%;
  }
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .qPctzOCZO1n6MciDAmz6 .UL2THHERBq7pcqV4EN8R .zKQ57aVrsvXMtlDqLTTP {
  padding: 4px;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .bENGPIHZkXl9WNFTC4Ka {
  padding: 10px 10px 10px 0px;
  min-height: 78px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .bENGPIHZkXl9WNFTC4Ka .wGYgEkc2ONYbOMJ_CjDi {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .bENGPIHZkXl9WNFTC4Ka .wGYgEkc2ONYbOMJ_CjDi .pJbrja472rYxJQZdiS0G {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8c8c8c;
  margin-top: -5px;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .bENGPIHZkXl9WNFTC4Ka .QMT04cAGIXN8ZJRq4EKy {
  font-family: Poppins;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #898989;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .bENGPIHZkXl9WNFTC4Ka .z2y2i3LBS8pQfS7M7phB {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #999999;
}
.xEV9SPJoQ2CSx0LXtmPP .ubgWpsrFIqV41xbSXR0A .bENGPIHZkXl9WNFTC4Ka .U0avTwnfx3xm8JEpWMgM {
  cursor: pointer;
  margin-top: 4px;
}
.xEV9SPJoQ2CSx0LXtmPP .xIul1ecJQVNzQpJMOrRG {
  border: none;
  margin-right: 0 !important;
  background-color: white;
}
.xEV9SPJoQ2CSx0LXtmPP .xIul1ecJQVNzQpJMOrRG .bUuvjS0bXMBT6FzgsGDx {
  cursor: pointer;
}
.xEV9SPJoQ2CSx0LXtmPP .xIul1ecJQVNzQpJMOrRG .bUuvjS0bXMBT6FzgsGDx :disabled {
  cursor: not-allowed;
}
.xEV9SPJoQ2CSx0LXtmPP .xIul1ecJQVNzQpJMOrRG .bUuvjS0bXMBT6FzgsGDx label {
  color: #595959 !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.xEV9SPJoQ2CSx0LXtmPP .xIul1ecJQVNzQpJMOrRG button {
  border-radius: 2px;
  padding: 0.3em 1.4rem;
  margin: 4px;
}
.xEV9SPJoQ2CSx0LXtmPP .xIul1ecJQVNzQpJMOrRG .PBHX5BC50kc5oO2lGMxF {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.xEV9SPJoQ2CSx0LXtmPP .xIul1ecJQVNzQpJMOrRG .IgiwAnICzWDROkBiQPCc {
  background: #f5f5f5;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
@media screen and (max-width: 601px) {
  .xEV9SPJoQ2CSx0LXtmPP .xIul1ecJQVNzQpJMOrRG ._xasYy9OGpxAiLrkfunn {
    position: fixed;
    left: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .xEV9SPJoQ2CSx0LXtmPP .xIul1ecJQVNzQpJMOrRG ._xasYy9OGpxAiLrkfunn button {
    height: 40px;
  }
  .xEV9SPJoQ2CSx0LXtmPP .xIul1ecJQVNzQpJMOrRG ._xasYy9OGpxAiLrkfunn .PBHX5BC50kc5oO2lGMxF {
    flex: 1;
  }
  .xEV9SPJoQ2CSx0LXtmPP .xIul1ecJQVNzQpJMOrRG ._xasYy9OGpxAiLrkfunn .IgiwAnICzWDROkBiQPCc {
    flex: 1;
  }
}

.HuQ05uKL1p3FszTRk9wl {
  margin-top: 10px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addressCard": `xEV9SPJoQ2CSx0LXtmPP`,
	"addressBody": `ubgWpsrFIqV41xbSXR0A`,
	"addressHeading": `LoeOka3nqSs67AxtfOK1`,
	"progressBar": `rP6wRYCDHVx1pSbHy7X1`,
	"label": `_1eWy0yUG00iAaz_Zv0U`,
	"component": `hx98K0bR8eA1npG3cf6S`,
	"heading": `nlijE6T2gXj_Z3R93BQQ`,
	"info": `SzenUOlZJiG0UkYPop9a`,
	"editDelete": `fEPcON2GKtpvyWN2GNTH`,
	"addAddressCard": `qPctzOCZO1n6MciDAmz6`,
	"addressCheckName": `XUH1_UBhxko2ZD1Ykv9t`,
	"required": `bdfrUUm5OcymU7qGYrvH`,
	"otherTag": `pJbrja472rYxJQZdiS0G`,
	"addBtn": `UL2THHERBq7pcqV4EN8R`,
	"addSign": `zKQ57aVrsvXMtlDqLTTP`,
	"idInfoCard": `bENGPIHZkXl9WNFTC4Ka`,
	"checkName": `wGYgEkc2ONYbOMJ_CjDi`,
	"currentTag": `QMT04cAGIXN8ZJRq4EKy`,
	"checkInfo": `z2y2i3LBS8pQfS7M7phB`,
	"badge": `U0avTwnfx3xm8JEpWMgM`,
	"addressFooter": `xIul1ecJQVNzQpJMOrRG`,
	"skipBtn": `bUuvjS0bXMBT6FzgsGDx`,
	"activeBtn": `PBHX5BC50kc5oO2lGMxF`,
	"disabledBtn": `IgiwAnICzWDROkBiQPCc`,
	"addressSubmitBtn": `_xasYy9OGpxAiLrkfunn`,
	"Mt10": `HuQ05uKL1p3FszTRk9wl`
};
export default ___CSS_LOADER_EXPORT___;
