// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yC9LgWbtGqvW3xgtjQW6 {
  background: #1f1630;
  padding-bottom: 20px;
  min-width: 1200px;
  font-family: Poppins, sans-serif;
}

.kDwlBWWW7FgEUbZAntXV {
  min-height: 25vh;
}

.PhKqeXgwM3FxkSyoD7Rq {
  min-height: 47vh;
}

.EEerTVunQq8GnMq_bySG {
  min-height: 40vh;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerDiv": `yC9LgWbtGqvW3xgtjQW6`,
	"footerDivHeight25": `kDwlBWWW7FgEUbZAntXV`,
	"footerDivHeight47": `PhKqeXgwM3FxkSyoD7Rq`,
	"footerDivHeight80": `EEerTVunQq8GnMq_bySG`
};
export default ___CSS_LOADER_EXPORT___;
