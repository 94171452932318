// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZAGQAlRc1Bl8BhnNoDHs {
  width: 105%;
  height: 30%;
  background: #f3fbff;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  padding: 18px;
  display: flex;
  margin-bottom: 25px;
  margin-top: 25px;
}
.ZAGQAlRc1Bl8BhnNoDHs .X0o0iys5OSft5GXzJttd {
  font-size: 20px;
  font-style: normal;
  font-weight: 250;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.ZAGQAlRc1Bl8BhnNoDHs .XaKN6YW7u7zi58iZkT_8 {
  margin-left: -20px;
}
.ZAGQAlRc1Bl8BhnNoDHs .fjDPdlDvJfzpUV_Sk7x1 {
  color: #595959;
}
.ZAGQAlRc1Bl8BhnNoDHs .hSN9uOns6khxGQdfnSLB {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `ZAGQAlRc1Bl8BhnNoDHs`,
	"heading": `X0o0iys5OSft5GXzJttd`,
	"listItems": `XaKN6YW7u7zi58iZkT_8`,
	"listItem": `fjDPdlDvJfzpUV_Sk7x1`,
	"image": `hSN9uOns6khxGQdfnSLB`
};
export default ___CSS_LOADER_EXPORT___;
