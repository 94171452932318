import React from 'react';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Banner.module.scss';

export default (props) => {
  return (
    <div className={styles.bannerContainer}>
      <div>
        <FontAwesomeIcon icon={faInfoCircle} color={'#1890FF'} className={styles.image} />
      </div>
      <div>
        <h3 className={styles.heading}>IMPORTANT</h3>
        <div>
          <ul className={styles.listItems}>
            <li className={styles.listItem}>
              Selecting one package applies to all candidates in a single file upload
            </li>
            <li>Any chosen add-on checks will be enabled and billed for every candidate</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
