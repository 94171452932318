// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UHvI0goIbXQopbuEGCfb,
.axRYxOu74iI2r5LkaHnr {
  width: 100%;
  height: 60px;
  background: #fffbe6;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  padding: 18px;
}
.UHvI0goIbXQopbuEGCfb a,
.axRYxOu74iI2r5LkaHnr a {
  color: #faad14;
  text-decoration: underline;
}

.dF3nBw8NlqbG1bS3vbLO {
  text-align: center;
}

.axRYxOu74iI2r5LkaHnr {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.lxRn_JRgtES_nSCfkp9g {
  margin-right: 6px;
  height: 50%;
}

.ajZs5bnuo5OS4cuHoC9y {
  background: #f3c19d;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid #e8833a;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0px 6px;
  height: 33px;
}

.gTuWUjTI3GWHjGlcsQWg {
  display: flex;
  background-color: #d3455b;
  margin-bottom: 30px;
  color: white;
  padding-left: 10px;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  height: 33px;
}

.dmWaYu3GI9XBrkWMzJmW {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d3455b;
  color: white;
  width: 100%;
  height: 60px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding: 12px;
  margin-bottom: 4px;
}
.dmWaYu3GI9XBrkWMzJmW a {
  color: #faad14;
  text-decoration: underline;
  margin: 0px 4px;
}

.JQ0DIqfFxfmXi6GJ6ELw {
  width: 100%;
  background: #fffbe6;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #464457;
  padding: 0.5rem 0 0.5rem 1.25rem;
  text-align: left;
  margin-bottom: 1rem;
  border: 1px solid rgba(255, 170, 0, 0.3019607843);
}

.mCpZoueTG1aNOgOms_ir {
  width: 100%;
  background: rgba(255, 227, 227, 0.3137254902);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #464457;
  padding: 0.5rem 0 0.5rem 1.25rem;
  text-align: left;
  margin-bottom: 1rem;
  border: 1px solid #ffe3e3;
}

.lcrRyXrRK3QgmgpmCtH2 {
  width: 100%;
  background: #fffbe6;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #464457;
  padding: 0.5rem 0 0.5rem 1.25rem;
  text-align: left;
  margin-bottom: 1rem;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `UHvI0goIbXQopbuEGCfb`,
	"addCreditsWarning": `axRYxOu74iI2r5LkaHnr`,
	"textCenter": `dF3nBw8NlqbG1bS3vbLO`,
	"image": `lxRn_JRgtES_nSCfkp9g`,
	"warningBox": `ajZs5bnuo5OS4cuHoC9y`,
	"bounceEmailBanner": `gTuWUjTI3GWHjGlcsQWg`,
	"adminBounceEmailBanner": `dmWaYu3GI9XBrkWMzJmW`,
	"lowPackageBalanceBanner": `JQ0DIqfFxfmXi6GJ6ELw`,
	"criticalPackageBalanceBanner": `mCpZoueTG1aNOgOms_ir`,
	"noPackageBanner": `lcrRyXrRK3QgmgpmCtH2`
};
export default ___CSS_LOADER_EXPORT___;
