// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UKgivXt6t0prRPqk3OG3 {
  padding: 0 32px;
  box-shadow: 0px 0px 16px rgba(0, 45, 211, 0.25);
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  min-height: 554px;
}
@media screen and (max-width: 601px) {
  .UKgivXt6t0prRPqk3OG3 {
    padding: 0 0 65px 0;
    box-shadow: none;
    border: none;
  }
}
.UKgivXt6t0prRPqk3OG3 .FDIsNQPjtm9aFc6UEcMq {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}
@media screen and (max-width: 601px) {
  .UKgivXt6t0prRPqk3OG3 .FDIsNQPjtm9aFc6UEcMq {
    padding-bottom: 12px;
  }
}
.UKgivXt6t0prRPqk3OG3 .FDIsNQPjtm9aFc6UEcMq .N3xT1jvvYphbGiXdI8gl {
  margin-right: 20px;
  color: #8c8c8c;
}
@media screen and (max-width: 601px) {
  .UKgivXt6t0prRPqk3OG3 .FDIsNQPjtm9aFc6UEcMq .N3xT1jvvYphbGiXdI8gl {
    margin-right: 0;
    margin-left: 20px;
  }
}
.UKgivXt6t0prRPqk3OG3 .pXjmmaEI5d7zGS2NIHo0 {
  color: #262626;
  font-size: 24px;
}
.UKgivXt6t0prRPqk3OG3 .Ty07GKsyTU2wo9PW6swT {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #262626;
}
@media screen and (max-width: 601px) {
  .UKgivXt6t0prRPqk3OG3 .Ty07GKsyTU2wo9PW6swT {
    margin-top: 24px;
  }
}
.UKgivXt6t0prRPqk3OG3 .nvcci2YvRZXs2W4yA7Hp {
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  color: #999999;
  padding-bottom: 25px;
}
.UKgivXt6t0prRPqk3OG3 .nvcci2YvRZXs2W4yA7Hp p {
  margin-bottom: 10px;
}
.UKgivXt6t0prRPqk3OG3 .nvcci2YvRZXs2W4yA7Hp .OEGhiLtIe1BBorWR3oNc {
  color: rgba(255, 0, 0, 0.7019607843);
}
.UKgivXt6t0prRPqk3OG3 .JhD5YD_CTJDG8Bo58Tbr {
  padding: 20px;
}
.UKgivXt6t0prRPqk3OG3 .eudYKaGTKCeKiiYkkNX2 {
  border: none;
  width: 32px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
}
.UKgivXt6t0prRPqk3OG3 .eudYKaGTKCeKiiYkkNX2:focus {
  outline: 0;
}
.UKgivXt6t0prRPqk3OG3 .dfygLWkkmLIK6vqWq1cc {
  padding-top: 20px;
}
.UKgivXt6t0prRPqk3OG3 .dfygLWkkmLIK6vqWq1cc .hIEwCRANuPQtzhP6MrpM {
  width: 100%;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-radius: 2px;
  border: none;
  text-align: center;
  color: #2755fe;
}
.UKgivXt6t0prRPqk3OG3 .dfygLWkkmLIK6vqWq1cc .hIEwCRANuPQtzhP6MrpM .g6hz_MEVQFQylk0oJZA2 {
  padding: 4px;
}
.UKgivXt6t0prRPqk3OG3 .VPv26he5y8R3NhpbYzg2 {
  padding-bottom: 30px;
}
.UKgivXt6t0prRPqk3OG3 .KIHKHlBubRngryLZQOlt {
  border: none;
  background-color: white;
  margin-top: 90px !important;
}
.UKgivXt6t0prRPqk3OG3 .KIHKHlBubRngryLZQOlt .pvbUjr0c8jaf90akgqRN label {
  color: #595959 !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
}
.UKgivXt6t0prRPqk3OG3 .KIHKHlBubRngryLZQOlt .pvbUjr0c8jaf90akgqRN input:disabled {
  color: rgba(89, 89, 89, 0.4784313725) !important;
  cursor: not-allowed;
}
.UKgivXt6t0prRPqk3OG3 .KIHKHlBubRngryLZQOlt .xUcDAL22mPCTbOtxNJp9 button {
  border-radius: 2px;
  padding: 0.3em 1.4rem;
}
.UKgivXt6t0prRPqk3OG3 .KIHKHlBubRngryLZQOlt .xUcDAL22mPCTbOtxNJp9 .o9AMnjbC4E4zH04NDXPw {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.UKgivXt6t0prRPqk3OG3 .KIHKHlBubRngryLZQOlt .xUcDAL22mPCTbOtxNJp9 .ibTbKJ5ewfHXOUBAWIR9 {
  background: #f5f5f5;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
.UKgivXt6t0prRPqk3OG3 .KIHKHlBubRngryLZQOlt .s2hbqZ60eKolwHMWHOFE {
  background: #ffffff;
  border-radius: 2px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2755fe;
}
.UKgivXt6t0prRPqk3OG3 .Vj1sjBnRAKaUIzXaC_q2 {
  width: 32px;
  height: 32px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 24px;
}
.UKgivXt6t0prRPqk3OG3 .Vj1sjBnRAKaUIzXaC_q2:focus {
  outline: 0;
}
.UKgivXt6t0prRPqk3OG3 .Vj1sjBnRAKaUIzXaC_q2:hover {
  cursor: not-allowed;
}

.xUcDAL22mPCTbOtxNJp9 {
  justify-content: unset !important;
}
.xUcDAL22mPCTbOtxNJp9 button {
  border-radius: 2px;
  padding: 0.3em 1.4rem;
  margin: 4px;
}
.xUcDAL22mPCTbOtxNJp9 .o9AMnjbC4E4zH04NDXPw {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.xUcDAL22mPCTbOtxNJp9 .ibTbKJ5ewfHXOUBAWIR9 {
  background: #f5f5f5;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
@media screen and (max-width: 601px) {
  .xUcDAL22mPCTbOtxNJp9 {
    position: fixed;
    left: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .xUcDAL22mPCTbOtxNJp9 button {
    height: 40px;
  }
  .xUcDAL22mPCTbOtxNJp9 .o9AMnjbC4E4zH04NDXPw {
    flex: 1;
  }
  .xUcDAL22mPCTbOtxNJp9 .ibTbKJ5ewfHXOUBAWIR9 {
    flex: 1;
  }
}

.hanp2yijmPR0f77sTUW4 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  color: #999999;
  margin-left: 6px;
}
@media screen and (max-width: 601px) {
  .hanp2yijmPR0f77sTUW4 {
    display: flex;
    align-items: center;
  }
}

.rUaAkIIoS1O_TgoMzB9e {
  display: flex;
  align-items: center;
}
.rUaAkIIoS1O_TgoMzB9e::before {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 13px;
  height: 13px;
  border-radius: 3px;
  margin-right: 7px;
  content: "";
  display: inline-block;
  cursor: not-allowed;
  background-color: #f8f8f8;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"employmentCard": `UKgivXt6t0prRPqk3OG3`,
	"employmentProgressContainer": `FDIsNQPjtm9aFc6UEcMq`,
	"stepText": `N3xT1jvvYphbGiXdI8gl`,
	"employmentHeading": `pXjmmaEI5d7zGS2NIHo0`,
	"employmentTitle": `Ty07GKsyTU2wo9PW6swT`,
	"employmentSubtitle": `nvcci2YvRZXs2W4yA7Hp`,
	"employmentSubtitleInfo": `OEGhiLtIe1BBorWR3oNc`,
	"counterText": `JhD5YD_CTJDG8Bo58Tbr`,
	"incrementButton": `eudYKaGTKCeKiiYkkNX2`,
	"addIdBody": `dfygLWkkmLIK6vqWq1cc`,
	"addBtn": `hIEwCRANuPQtzhP6MrpM`,
	"addSign": `g6hz_MEVQFQylk0oJZA2`,
	"prevJobContainer": `VPv26he5y8R3NhpbYzg2`,
	"empFooter": `KIHKHlBubRngryLZQOlt`,
	"checkBoxContainer": `pvbUjr0c8jaf90akgqRN`,
	"employmentSubmitBtn": `xUcDAL22mPCTbOtxNJp9`,
	"activeBtn": `o9AMnjbC4E4zH04NDXPw`,
	"disabledBtn": `ibTbKJ5ewfHXOUBAWIR9`,
	"goBtn": `s2hbqZ60eKolwHMWHOFE`,
	"disableIncrementButton": `Vj1sjBnRAKaUIzXaC_q2`,
	"maxCountText": `hanp2yijmPR0f77sTUW4`,
	"disabledCheckBox": `rUaAkIIoS1O_TgoMzB9e`
};
export default ___CSS_LOADER_EXPORT___;
