import * as Yup from 'yup';
import moment from 'moment';
import {
  employeeIdValidationSchema,
  getCityNameValidation,
  getStrictNameValidationSchema
} from '../../../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  is_employee_id_not_available: Yup.boolean(),
  company_name: getStrictNameValidationSchema({ message: 'Company Legal Name', isRequired: true }),
  company_brand_name: getStrictNameValidationSchema({
    message: 'Company Brand Name',
    isRequired: false
  }),
  city: Yup.string().required(''),
  designation: getStrictNameValidationSchema({ message: 'designation', isRequired: true }),
  employee_id: Yup.string().when('is_employee_id_not_available', {
    is: false,
    then: employeeIdValidationSchema({ message: 'Employee ID', isRequired: true })
  }),
  // .matches(/^[^-\s][a-zA-Z0-9_\s-]+$/, 'Please enter a valid Employee ID'),
  start_date: Yup.mixed()
    .required('Provide a valid Start date.')
    .test({
      name: 'start_date',
      message: 'Please enter a valid date',
      test: (value) => {
        if (value !== null) {
          return moment(value, 'YYYY-MM-DD', true).isValid();
        } else {
          return false;
        }
      }
    }),
  end_date: Yup.mixed()
    .required('Provide a valid End date (or tentative Last working date).')
    .test({
      name: 'end_date',
      message: 'Provide a valid End date (or tentative Last working date).',
      test: (value) => {
        if (value !== null) {
          return moment(value, 'YYYY-MM-DD', true).isValid();
        } else {
          return false;
        }
      }
    }),
  reason: Yup.string().nullable().trim().max(500, 'Max character limit (500) reached')
});
