// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VvJODj22Z0vDwhgbd5MB {
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
}

.kZhQQjuIJFh3ctxtp76Q {
  width: 100%;
  margin: auto;
}
.kZhQQjuIJFh3ctxtp76Q .ObYNriokuJ3LgeVcD9Qu {
  margin-top: -190px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH {
  background: #ffffff;
  border-radius: 4px;
  font-family: Poppins, sans-serif;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .c0iHJm2m3dyeo2fFEsw_ {
  display: flex;
  justify-content: space-between;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .c0iHJm2m3dyeo2fFEsw_ .FGPEwZT9fo277XPh79rA {
  padding-top: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .c0iHJm2m3dyeo2fFEsw_ .gRnzwrTEDvOnjvnvlTQs {
  border: 1px solid #c2cfff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 174px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  height: 40px;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK {
  border: 0px;
  padding: 0 24px 24px 24px;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ {
  margin: auto;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 8px;
  overflow: hidden;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ thead {
  background-color: #f2f8ff;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ thead tr th {
  border: 0px;
  height: 57px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ thead tr .K8C5Q8kHlITqoOHSCXTl {
  text-align: center;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ thead tr .TG5ISu5jDR_fDNFmfeLN {
  text-align: right;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody .TG5ISu5jDR_fDNFmfeLN {
  text-align: right;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody tr {
  cursor: default;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody tr td {
  border: 0px;
  font-weight: 500;
  font-size: 14px;
  height: 57px;
  line-height: 21px;
  color: #333333;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody tr .K8C5Q8kHlITqoOHSCXTl {
  text-align: center;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody tr .TG5ISu5jDR_fDNFmfeLN {
  text-align: right;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody tr:hover {
  background-color: rgba(54, 108, 243, 0.1254901961);
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody .TGL0UAPEjpLHcnHDyKfk {
  color: #2755fe;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody .TGL0UAPEjpLHcnHDyKfk:hover {
  color: #366cf3;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody .TGL0UAPEjpLHcnHDyKfk:hover p {
  cursor: pointer;
  width: fit-content;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody .lLnc5o9eOLr3PYT_BiNR {
  color: #999999;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ tbody .nMC_UQwaCKIZGYPqSYju {
  cursor: "pointer";
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ .Tc7yZ33Ffxq6h1o_leyb {
  text-align: center;
  padding-top: 64px;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ .Tc7yZ33Ffxq6h1o_leyb .MpM4tdrUB66G8JrjiJeI {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .klHLueXxAWE2Lik4ZynQ .VvJODj22Z0vDwhgbd5MB {
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .lBU_Zu48e9foY1Skc1fr {
  flex-wrap: unset;
  padding: 24px 24px 15px;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .lBU_Zu48e9foY1Skc1fr .fdiKeYDxGSLyeMbSdLVL {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.kZhQQjuIJFh3ctxtp76Q .aXKPER9gUlzkmnEn4ZHH .xKdnIGoSnVXF_4UZ3ZEK .lBU_Zu48e9foY1Skc1fr .fdiKeYDxGSLyeMbSdLVL .biumLONpVdO5A6HfkQfd {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px;
  -webkit-appearance: menulist;
}

.XtIQCokIjMp6ByOJPoG6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  margin-bottom: 17px;
}

.NKfE6D_spa8AXxvKllXF {
  color: #2755fe;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amount": `VvJODj22Z0vDwhgbd5MB`,
	"usageLogsWrapper": `kZhQQjuIJFh3ctxtp76Q`,
	"heading": `ObYNriokuJ3LgeVcD9Qu`,
	"usageLogsCard": `aXKPER9gUlzkmnEn4ZHH`,
	"purchaseHistoryCardHeader": `c0iHJm2m3dyeo2fFEsw_`,
	"headingContent": `FGPEwZT9fo277XPh79rA`,
	"dropdown": `gRnzwrTEDvOnjvnvlTQs`,
	"tableCard": `xKdnIGoSnVXF_4UZ3ZEK`,
	"usageLogsTable": `klHLueXxAWE2Lik4ZynQ`,
	"alignCenter": `K8C5Q8kHlITqoOHSCXTl`,
	"alignRight": `TG5ISu5jDR_fDNFmfeLN`,
	"mainField": `TGL0UAPEjpLHcnHDyKfk`,
	"deletedUser": `lLnc5o9eOLr3PYT_BiNR`,
	"pointerTypeCursor": `nMC_UQwaCKIZGYPqSYju`,
	"noDataWrapper": `Tc7yZ33Ffxq6h1o_leyb`,
	"noDataContent": `MpM4tdrUB66G8JrjiJeI`,
	"paginationWrapper": `lBU_Zu48e9foY1Skc1fr`,
	"showPagination": `fdiKeYDxGSLyeMbSdLVL`,
	"selectPagination": `biumLONpVdO5A6HfkQfd`,
	"tabInfoText": `XtIQCokIjMp6ByOJPoG6`,
	"descriptionData": `NKfE6D_spa8AXxvKllXF`
};
export default ___CSS_LOADER_EXPORT___;
