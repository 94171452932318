// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg .f8Qj5ew3rLWAHq7oqSbd {
  width: 582px;
  margin: 50px 0;
}
.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg .f8Qj5ew3rLWAHq7oqSbd .cG37mPStCAvZNUEegLO5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg .f8Qj5ew3rLWAHq7oqSbd .s6d1GGvka4PJU9wQ1ryK {
  font-size: 14px;
  line-height: 21px;
  color: #898989;
  margin-top: 8px;
}
.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg .xXtnvBOBT6UPDWXvnG_W {
  margin: 48px 88px 48px 0;
}
.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg .xXtnvBOBT6UPDWXvnG_W .cG37mPStCAvZNUEegLO5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 8px;
}
.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg .xXtnvBOBT6UPDWXvnG_W .mF4YGGIzJwXPNjTqO7F9 {
  display: flex;
  justify-content: space-between;
}
.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg .xXtnvBOBT6UPDWXvnG_W .mF4YGGIzJwXPNjTqO7F9 .BNcuixM7g5ls0p7Uerve {
  margin-top: 4px;
  font-size: 14px;
  line-height: 30px;
  color: #cbcbcb;
  cursor: pointer;
}
.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg .jbOlwgwEqBCjnY5d615u {
  margin: 50px 0;
  text-align: right;
}
.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg .jbOlwgwEqBCjnY5d615u .BNcuixM7g5ls0p7Uerve a {
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
  color: #cbcbcb;
  margin-left: 16px;
}
.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg .lp2bgRI5IJKR4AjNWOf4 {
  margin-top: 3rem;
}
.IFX2KeidL7Zaa0h2wTVL .s7xviL2S2ky3wbcgpvWg .lp2bgRI5IJKR4AjNWOf4 .cG37mPStCAvZNUEegLO5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.IFX2KeidL7Zaa0h2wTVL .FvvcWvoWGGBS7OdHUXUQ {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: -60px;
}
.IFX2KeidL7Zaa0h2wTVL .FvvcWvoWGGBS7OdHUXUQ .VHM5BZ5HaCyqlrrLkph_ {
  display: flex;
}
.IFX2KeidL7Zaa0h2wTVL .FvvcWvoWGGBS7OdHUXUQ .VHM5BZ5HaCyqlrrLkph_ .NlZ8znEdDCrH1N0w97gc {
  margin-right: 25px;
}
.IFX2KeidL7Zaa0h2wTVL .FvvcWvoWGGBS7OdHUXUQ .VHM5BZ5HaCyqlrrLkph_ .s6d1GGvka4PJU9wQ1ryK {
  font-size: 14px;
  line-height: 21px;
  color: #898989;
  margin-top: 13px;
}
.IFX2KeidL7Zaa0h2wTVL .FvvcWvoWGGBS7OdHUXUQ .VHM5BZ5HaCyqlrrLkph_ .Z988w_yWe_l5wRzaAfRL {
  margin-left: 4px;
  margin-top: 12px;
}
.IFX2KeidL7Zaa0h2wTVL .FvvcWvoWGGBS7OdHUXUQ .VHM5BZ5HaCyqlrrLkph_ .Z988w_yWe_l5wRzaAfRL span {
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
  color: #366cf3;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerParent": `IFX2KeidL7Zaa0h2wTVL`,
	"footerMain": `s7xviL2S2ky3wbcgpvWg`,
	"aboutSection": `f8Qj5ew3rLWAHq7oqSbd`,
	"heading": `cG37mPStCAvZNUEegLO5`,
	"content": `s6d1GGvka4PJU9wQ1ryK`,
	"quickLinkSection": `xXtnvBOBT6UPDWXvnG_W`,
	"linksList": `mF4YGGIzJwXPNjTqO7F9`,
	"link": `BNcuixM7g5ls0p7Uerve`,
	"linkSection": `jbOlwgwEqBCjnY5d615u`,
	"footerFindUsSection": `lp2bgRI5IJKR4AjNWOf4`,
	"footerBase": `FvvcWvoWGGBS7OdHUXUQ`,
	"logoSection": `VHM5BZ5HaCyqlrrLkph_`,
	"logo": `NlZ8znEdDCrH1N0w97gc`,
	"name": `Z988w_yWe_l5wRzaAfRL`
};
export default ___CSS_LOADER_EXPORT___;
