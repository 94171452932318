// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AXkmjnuSGGZisJF8nYDA {
  max-width: 602px;
  width: 100%;
}

.Otb308he6XTt8LRfHH18 {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 28px 0 12px 0 !important;
}

.Kk_imlVQZfR0GaJRK4K8 {
  padding-top: 0 !important;
}

.hPmQCuMRP3kokRRpu8SE {
  margin: 0 0 12px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #333333;
}

.l46NPhx81RmQjZ7aqZiK {
  border: 1px solid #2755FE;
  color: #2755FE;
  border-radius: 4px;
  width: 124px;
  height: 36px;
  font-weight: 500;
  font-size: 14px;
  color: #2755FE;
}
.l46NPhx81RmQjZ7aqZiK:hover, .l46NPhx81RmQjZ7aqZiK:focus {
  background-color: #2755FE;
  border-color: #2755FE;
}

.KVzqRpyvjzOSZXB36aH6 {
  margin-top: 34px;
}

.BS0weD_qjHjPWcLBLMvw {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #333333;
  text-align: center;
}

.i3QPpvvld28sOe3tSWEP {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border-top: 0.5px dashed rgba(102, 102, 102, 0.5);
  padding-top: 12px;
  list-style: none;
  padding-left: 0;
}
.i3QPpvvld28sOe3tSWEP > li {
  border-right: 0.5px dashed rgba(102, 102, 102, 0.5);
  flex: 1;
}
.i3QPpvvld28sOe3tSWEP > li:not(:first-child) {
  padding-left: 20px;
}
.i3QPpvvld28sOe3tSWEP > li h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-top: 0;
  margin: 10px 0;
}
.i3QPpvvld28sOe3tSWEP > li p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  margin-bottom: 0;
}
.i3QPpvvld28sOe3tSWEP > li:last-child {
  border: none;
}

.DJowqMlrqxw2hZqfV2rQ {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute !important;
  top: 12px;
  right: 20px;
}
.DJowqMlrqxw2hZqfV2rQ:focus {
  outline: none !important;
}

.FEEauJC_mkOknNo7TWmu {
  width: 538px;
  border-top: 0.5px dashed rgba(102, 102, 102, 0.5);
  margin: 16px auto 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
}
.FEEauJC_mkOknNo7TWmu .arU0aEcD0tN5Cvse6x3r {
  width: 286px;
  height: 256px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  padding: 0 5px;
  background-size: cover;
  background-position: center;
  position: relative;
}
.FEEauJC_mkOknNo7TWmu .arU0aEcD0tN5Cvse6x3r img {
  position: relative;
  z-index: 100;
  max-width: 100%;
  max-height: 100%;
}
.FEEauJC_mkOknNo7TWmu .arU0aEcD0tN5Cvse6x3r::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
}

.jORRVpEztOdavf2kBhYw {
  width: 100%;
  margin-top: 16px;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #666666;
}

.v1AaOvnqsCCp3CfDj9k7 {
  border-radius: 4px;
  position: absolute !important;
  left: 0;
  top: 0;
  z-index: 1000 !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdcInfoModal": `AXkmjnuSGGZisJF8nYDA`,
	"AdcModalHeader": `Otb308he6XTt8LRfHH18`,
	"AdcModalBody": `Kk_imlVQZfR0GaJRK4K8`,
	"AdcModalContent": `hPmQCuMRP3kokRRpu8SE`,
	"SetAdcBtn": `l46NPhx81RmQjZ7aqZiK`,
	"ApprovalDelayInfoContainer": `KVzqRpyvjzOSZXB36aH6`,
	"LostText": `BS0weD_qjHjPWcLBLMvw`,
	"ApprovalDelayStats": `i3QPpvvld28sOe3tSWEP`,
	"CloseModalBtn": `DJowqMlrqxw2hZqfV2rQ`,
	"MemesContainer": `FEEauJC_mkOknNo7TWmu`,
	"MemeMask": `arU0aEcD0tN5Cvse6x3r`,
	"delayInfoText": `jORRVpEztOdavf2kBhYw`,
	"memeSkeleton": `v1AaOvnqsCCp3CfDj9k7`
};
export default ___CSS_LOADER_EXPORT___;
