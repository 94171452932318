// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gsWP2QbOUmZzaHZXCWRB {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 14px;
  color: #dc3545;
}

/* Hide up and down arrows for number input */
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputError": `gsWP2QbOUmZzaHZXCWRB`
};
export default ___CSS_LOADER_EXPORT___;
