// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rpmO3wEQP160FdfcjMPw {
  margin-top: 24px;
}
.rpmO3wEQP160FdfcjMPw .bhC5mZu3_SkPb6eA97_I {
  font-weight: 500 !important;
  line-height: 26px;
  font-size: 16px;
  color: #666666 !important;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN {
  border-radius: 0;
  margin: 12px 0px;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH {
  padding: 15px 22px 14px 22px;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .ELt_xukhWXXFElLhHt9V {
  cursor: pointer;
  padding-left: 2px;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .ELt_xukhWXXFElLhHt9V ._ksr1ijixdSTGJQJ2M60 {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 2px;
  color: #333333 !important;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .ELt_xukhWXXFElLhHt9V .uNcpxZOip0wZJrximdEB {
  font-family: "Poppins";
  font-weight: 400;
  color: #666666;
  line-height: 21px;
  font-size: 14px;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .ELt_xukhWXXFElLhHt9V .lIFRsXqpHSLqeMrFoxWx {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 2px;
  color: #333333 !important;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .xSxJQSlqqMsl_D5QgXd1 {
  display: flex;
  margin: 10px 0px 10px 1.5rem;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .xSxJQSlqqMsl_D5QgXd1 .bL2_divhdCar5MJSX3FC {
  color: #495057;
  border: 0px solid rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
  border-radius: 2px;
  width: 284px;
  display: flex;
  align-items: center;
  padding-left: 13px;
  padding-right: 15px;
  height: 40px;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .xSxJQSlqqMsl_D5QgXd1 .bL2_divhdCar5MJSX3FC .gw5Dn1AK1hITT_BHaxNp {
  font-size: 20px;
  line-height: 38px;
  width: 4px;
  font-family: "Roboto";
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .xSxJQSlqqMsl_D5QgXd1 .bL2_divhdCar5MJSX3FC .Npf7fZUoAuc4BKjZwahI {
  border: none;
  color: #666666;
  font-size: 17px;
  margin-bottom: -1px !important;
  line-height: 20px;
  background-color: transparent;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .xSxJQSlqqMsl_D5QgXd1 .bL2_divhdCar5MJSX3FC .Npf7fZUoAuc4BKjZwahI:focus {
  box-shadow: unset;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .xSxJQSlqqMsl_D5QgXd1 .QX84knu2JRGGxnTDK6E5 {
  margin-left: 30px;
  display: flex;
  height: 40px;
  cursor: pointer;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .xSxJQSlqqMsl_D5QgXd1 .QX84knu2JRGGxnTDK6E5 .WXTxxAe4jlEKVLQsh_QX {
  min-width: 120px;
  text-align: center;
  align-self: center;
  padding: 6px 3px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .xSxJQSlqqMsl_D5QgXd1 .QX84knu2JRGGxnTDK6E5 .WXTxxAe4jlEKVLQsh_QX .obHE2gcMRcd2tnIxxE3w {
  font-weight: 400;
  color: #666666;
  font-size: 15px;
  line-height: 21px;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .xSxJQSlqqMsl_D5QgXd1 .QX84knu2JRGGxnTDK6E5 .WXTxxAe4jlEKVLQsh_QX .iJopQHkoj_nJulrJ_5Me {
  padding: 0px 6px 0px 3px;
  vertical-align: unset;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .xSxJQSlqqMsl_D5QgXd1 .QX84knu2JRGGxnTDK6E5 .S8ZLX3XrJvVqBX0BWX8V {
  border-left: none;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .xSxJQSlqqMsl_D5QgXd1 .ixqXecCp41kiIkfgyoXt {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.2509803922);
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .Ibd5VTVye1XSeKLXMmIg {
  margin: 10px 0px 10px 1.5rem;
  color: #e97070;
  font-size: 14px;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .Ibd5VTVye1XSeKLXMmIg .XyLm9zWlJV6a8UzAjI1_ {
  vertical-align: unset;
  padding: 4px 6px 0px 3px;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .Ibd5VTVye1XSeKLXMmIg .yANG9b_wAgLSDGKcTUxC {
  color: #495057 !important;
}
.rpmO3wEQP160FdfcjMPw .NoHyERqfcH4Ai1pw0kMN ._BE1Z9DG7NDfm6lHJGiH .GU7yfI8x2WWzvfWJSc_F {
  color: #595959;
  font-size: 14px;
  border-radius: 20px;
  background-color: rgba(62, 211, 163, 0.5);
  padding: 4px 8px;
}

._ksr1ijixdSTGJQJ2M60 {
  font-family: "Roboto";
}

.EGSYKh1PQWxgNrilXfTd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `rpmO3wEQP160FdfcjMPw`,
	"containerSubHeader": `bhC5mZu3_SkPb6eA97_I`,
	"cardContainer": `NoHyERqfcH4Ai1pw0kMN`,
	"cardSubContainer": `_BE1Z9DG7NDfm6lHJGiH`,
	"label": `ELt_xukhWXXFElLhHt9V`,
	"amount": `_ksr1ijixdSTGJQJ2M60`,
	"text": `uNcpxZOip0wZJrximdEB`,
	"customAmountText": `lIFRsXqpHSLqeMrFoxWx`,
	"inputFieldAndQSBContainer": `xSxJQSlqqMsl_D5QgXd1`,
	"searchInput": `bL2_divhdCar5MJSX3FC`,
	"amountText": `gw5Dn1AK1hITT_BHaxNp`,
	"inputText": `Npf7fZUoAuc4BKjZwahI`,
	"quickSelectButtons": `QX84knu2JRGGxnTDK6E5`,
	"quickSelectButton1": `WXTxxAe4jlEKVLQsh_QX`,
	"textOfQSB": `obHE2gcMRcd2tnIxxE3w`,
	"quickSelectButtonPng": `iJopQHkoj_nJulrJ_5Me`,
	"noBorder": `S8ZLX3XrJvVqBX0BWX8V`,
	"searchInputFocus": `ixqXecCp41kiIkfgyoXt`,
	"fieldError": `Ibd5VTVye1XSeKLXMmIg`,
	"warningRedIcon": `XyLm9zWlJV6a8UzAjI1_`,
	"percentageText": `yANG9b_wAgLSDGKcTUxC`,
	"badge": `GU7yfI8x2WWzvfWJSc_F`,
	"recommended": `EGSYKh1PQWxgNrilXfTd`
};
export default ___CSS_LOADER_EXPORT___;
