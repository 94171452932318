// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nx2txoCKOUPGoQVmVmhw {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  margin-left: 12px;
}

.VcLSCvEpEz0VxT9rQcMG {
  font-family: Poppins;
  font-style: normal;
}
.VcLSCvEpEz0VxT9rQcMG .l_9ZgN8t5Krr12p9zvT1 {
  margin-bottom: 20px;
}
.VcLSCvEpEz0VxT9rQcMG .gZVWiTrH5XOO6kBN673h {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
}
.VcLSCvEpEz0VxT9rQcMG .OPjat98DMsFPCoJ3tN43 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  margin-bottom: 12px;
}
.VcLSCvEpEz0VxT9rQcMG .WedKdnKMIpVJDZEDdPbz {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.VcLSCvEpEz0VxT9rQcMG .zmyjICCFFAfsL05cbpxY {
  margin-left: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
}
.VcLSCvEpEz0VxT9rQcMG .uWTetmm1gmxqikp3VuJm {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
}
.VcLSCvEpEz0VxT9rQcMG .t54ma0JLSdeTbJJrHDRv {
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.VcLSCvEpEz0VxT9rQcMG .WntjSseHwF7_3ZRzqda_ {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  margin-top: 12px;
}
.VcLSCvEpEz0VxT9rQcMG .CtBMD8AGpqy4E15feXXL {
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.VcLSCvEpEz0VxT9rQcMG .cCGmAzwFr3gTVbvWkP1s {
  margin-bottom: 20px;
}
.VcLSCvEpEz0VxT9rQcMG .cCGmAzwFr3gTVbvWkP1s .d4ZMbjYkallhdW81R5tA {
  padding-left: 0px;
  padding-bottom: 20px;
}

.Jg_KrMHyjbFOWFw8bnRI .AwA6utpCPYw1pJnSPmdg {
  justify-content: center;
}

.WVpvn_wdbNik2rgbDOp7 {
  margin-top: 20px;
  padding: 0 !important;
  justify-content: flex-end !important;
}
.WVpvn_wdbNik2rgbDOp7 button {
  border-radius: 2px;
  padding: 0.3em 1.4rem;
  margin: 4px;
}
.WVpvn_wdbNik2rgbDOp7 .ORzZgsjH2TKqBzRTQREU {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .WVpvn_wdbNik2rgbDOp7 {
    display: flex;
    align-items: center;
  }
  .WVpvn_wdbNik2rgbDOp7 .ORzZgsjH2TKqBzRTQREU {
    flex: 1;
  }
  .WVpvn_wdbNik2rgbDOp7 .pEnMB1m_BrN78T_RPgVq {
    flex: 1;
  }
}

.DObhDUr4s9Xr3N1wjZq5 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.2rem;
  padding: 5px 10px;
  background-color: #e0fcff;
  width: 100%;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainTitle": `Nx2txoCKOUPGoQVmVmhw`,
	"detailsContainer": `VcLSCvEpEz0VxT9rQcMG`,
	"headingRow": `l_9ZgN8t5Krr12p9zvT1`,
	"checkHeading": `gZVWiTrH5XOO6kBN673h`,
	"checkSubHeading": `OPjat98DMsFPCoJ3tN43`,
	"checkDetails": `WedKdnKMIpVJDZEDdPbz`,
	"priceText": `zmyjICCFFAfsL05cbpxY`,
	"creditSubHeading": `uWTetmm1gmxqikp3VuJm`,
	"creditDetails": `t54ma0JLSdeTbJJrHDRv`,
	"customNoteHeading": `WntjSseHwF7_3ZRzqda_`,
	"customNoteDetails": `CtBMD8AGpqy4E15feXXL`,
	"checksDescripContainer": `cCGmAzwFr3gTVbvWkP1s`,
	"checksDescripCol": `d4ZMbjYkallhdW81R5tA`,
	"loaderContainer": `Jg_KrMHyjbFOWFw8bnRI`,
	"loader": `AwA6utpCPYw1pJnSPmdg`,
	"modalFooter": `WVpvn_wdbNik2rgbDOp7`,
	"activeBtn": `ORzZgsjH2TKqBzRTQREU`,
	"disabledBtn": `pEnMB1m_BrN78T_RPgVq`,
	"bannerContainer": `DObhDUr4s9Xr3N1wjZq5`
};
export default ___CSS_LOADER_EXPORT___;
