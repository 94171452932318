// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qwDiWIIf68UpIeNntFEt {
  font-family: Poppins, sans-serif;
  color: #333333;
}
.qwDiWIIf68UpIeNntFEt .cxskJPFROZyiPafEd_Fp {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.qwDiWIIf68UpIeNntFEt .cxskJPFROZyiPafEd_Fp img {
  margin-right: 16px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS {
  margin-left: 40px;
  margin-top: 32px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .frE7NzVR8DOGkxlrqpSQ {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 3px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .frE7NzVR8DOGkxlrqpSQ .CYAUXteWpA2FpJgmnFKS {
  display: inline;
  margin-left: 16px;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .HI_ssNgajupWLLphcUXd {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 14px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .HI_ssNgajupWLLphcUXd svg {
  margin-right: 8px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv {
  display: flex;
  margin-bottom: 8px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .xLCJ8oEKNkVtg4DGf9bx {
  width: 154px;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .FTqh9jpZY3CUDoj0ysdD {
  width: 448px;
  font-size: 14px;
  line-height: 21px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc {
  width: 674px;
  display: flex;
  flex-wrap: wrap;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc .UFv9vbjOgsdAs7eqK3Cn {
  margin-right: 12px;
  display: inline-block;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc .UFv9vbjOgsdAs7eqK3Cn a,
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc .UFv9vbjOgsdAs7eqK3Cn a:hover {
  text-decoration: none;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc .UFv9vbjOgsdAs7eqK3Cn a img,
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS .rHGWVI3yReLxZBF7Gh4z .YMvFIQpO1bXab0w61flT .KIOKW_YQRX3SCpAkjCWv .qNt1HTNSQr6j0bhhMSCc .UFv9vbjOgsdAs7eqK3Cn a:hover img {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}
.qwDiWIIf68UpIeNntFEt .U7t6YLBIba5Od0BfbtQS hr {
  margin: 0;
  margin-top: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}
.qwDiWIIf68UpIeNntFEt .KXr8xgBecM8TFiGha5T4 {
  max-width: 200px;
  max-height: 200px;
}
.qwDiWIIf68UpIeNntFEt .TLjy8bAql3x_cOPupbHv {
  overflow-y: auto;
  overflow-x: hidden;
}
.qwDiWIIf68UpIeNntFEt canvas {
  width: 200px !important;
  height: 200px !important;
  margin-bottom: 10px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verificationDetailsContainer": `qwDiWIIf68UpIeNntFEt`,
	"verificationDetailsHeading": `cxskJPFROZyiPafEd_Fp`,
	"verificationDetailsContent": `U7t6YLBIba5Od0BfbtQS`,
	"verificationCard": `rHGWVI3yReLxZBF7Gh4z`,
	"cardHeader": `frE7NzVR8DOGkxlrqpSQ`,
	"duration": `CYAUXteWpA2FpJgmnFKS`,
	"cardStatus": `HI_ssNgajupWLLphcUXd`,
	"cardContentWrapper": `YMvFIQpO1bXab0w61flT`,
	"cardContent": `KIOKW_YQRX3SCpAkjCWv`,
	"label": `xLCJ8oEKNkVtg4DGf9bx`,
	"value": `FTqh9jpZY3CUDoj0ysdD`,
	"documents": `qNt1HTNSQr6j0bhhMSCc`,
	"docValue": `UFv9vbjOgsdAs7eqK3Cn`,
	"viewPdfWrapper": `KXr8xgBecM8TFiGha5T4`,
	"docs": `TLjy8bAql3x_cOPupbHv`
};
export default ___CSS_LOADER_EXPORT___;
