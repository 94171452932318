// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kPeon83nsY6X9NUg3VvQ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(91, 90, 90, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OI1OhERbnC2zekMiLu0I {
  width: 680px;
  height: 341px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  padding: 24px 32px;
  position: relative;
}

.rvsEoGkdzt7Yt1wYWJs0 {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 25px;
}

.R8_VkoDzsXeDUmDT7SqL {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.yl3qa2EnBgk1MTYwtgUJ {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: #333333;
}

.YxnTcCQ8IAGtMG23a6hc {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-top: 36px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #333333;
}

.LpvFUXnUjoNPPTW_qDJ1 {
  width: 240px;
  height: 196px;
  border-radius: 4px;
  border: 1px solid rgba(153, 153, 153, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.LpvFUXnUjoNPPTW_qDJ1:hover {
  border: 2px solid #000000;
  background-color: rgba(208, 208, 208, 0.2156862745);
}

.RivQGQaVzEfeg8nzFIFY {
  font-size: 20px;
  font-weight: 500;
}

.Oibqecq26umjhvtT7Vhn {
  height: 100px;
}

.Oibqecq26umjhvtT7Vhn img {
  height: 80%;
  object-fit: contain;
}

.PIsW9Ww0EXKHi7LGsD92 {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 5px;
}

.aODwT6e2bDRa2CnjfOuh {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #666666;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-overlay": `kPeon83nsY6X9NUg3VvQ`,
	"modalContainer": `OI1OhERbnC2zekMiLu0I`,
	"close": `rvsEoGkdzt7Yt1wYWJs0`,
	"header": `R8_VkoDzsXeDUmDT7SqL`,
	"title": `yl3qa2EnBgk1MTYwtgUJ`,
	"body": `YxnTcCQ8IAGtMG23a6hc`,
	"box": `LpvFUXnUjoNPPTW_qDJ1`,
	"orText": `RivQGQaVzEfeg8nzFIFY`,
	"boxIcon": `Oibqecq26umjhvtT7Vhn`,
	"boxText": `PIsW9Ww0EXKHi7LGsD92`,
	"boxSubText": `aODwT6e2bDRa2CnjfOuh`
};
export default ___CSS_LOADER_EXPORT___;
