// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hLWepb6fBnLcayKIANjU {
  margin-right: 24px;
  background-color: #fff;
  padding: 0 12px 0 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}

.EfqMoJeQgoP8U4bZd7vi {
  color: #788896;
}

.g3JJGLg6OwNOuByLwVnI {
  color: #293845;
}

.DUIB298lAOpol5_1C1q6 {
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  color: #4b5c6b;
  margin-bottom: 6px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": `hLWepb6fBnLcayKIANjU`,
	"disableOption": `EfqMoJeQgoP8U4bZd7vi`,
	"enableOption": `g3JJGLg6OwNOuByLwVnI`,
	"dropDownLabel": `DUIB298lAOpol5_1C1q6`
};
export default ___CSS_LOADER_EXPORT___;
