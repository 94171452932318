// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GC_cD7X4hAHZGsBICCwt {
  display: flex;
}
.GC_cD7X4hAHZGsBICCwt .xhCnBFVdlVyIgP7lZ5ZJ {
  font-size: 14px;
  margin-right: 55px;
  margin-top: 8px;
}

.utnH6T7MmbWrILuqMwJw {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin-right: 8px;
}

.XJS_qIaCZz1HyYqThGzQ {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.WkwUIvi_RRsoshP6ohhI {
  font-size: 10.9px;
  margin-top: -20px;
  margin-bottom: 15px;
  color: red;
}

.yNvcqFG4vyJuYvWA1Z7b {
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  margin-right: 0px;
}
.yNvcqFG4vyJuYvWA1Z7b:hover {
  background: #2755fe;
  color: #ffffff;
}

.FF4RWZHgJYWHeQHPDBkZ {
  background: #f5f5f5;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  margin-right: 0px;
}

.arg3uCXnG93NEWWea0QA {
  border: 1px solid #2755fe;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2755fe;
  width: 136px;
  margin-right: 15px;
}
.arg3uCXnG93NEWWea0QA:hover {
  background: #ffffff;
  color: #2755fe;
}
.arg3uCXnG93NEWWea0QA:active {
  background-color: #ffffff !important;
  color: #2755fe !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `GC_cD7X4hAHZGsBICCwt`,
	"footerLabel": `xhCnBFVdlVyIgP7lZ5ZJ`,
	"title": `utnH6T7MmbWrILuqMwJw`,
	"subtypeName": `XJS_qIaCZz1HyYqThGzQ`,
	"errorMsg": `WkwUIvi_RRsoshP6ohhI`,
	"submitButton": `yNvcqFG4vyJuYvWA1Z7b`,
	"disableBtn": `FF4RWZHgJYWHeQHPDBkZ`,
	"cancelButton": `arg3uCXnG93NEWWea0QA`
};
export default ___CSS_LOADER_EXPORT___;
