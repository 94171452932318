// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.K2uzSaKfm0ZlFOeYgoXV {
  width: 1200px;
  margin-bottom: 100px !important;
  margin: auto;
  height: auto;
  margin-top: -190px;
}
.K2uzSaKfm0ZlFOeYgoXV .DzYBuAEsbbAw4UQxZUng {
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: space-between;
}
.K2uzSaKfm0ZlFOeYgoXV .DzYBuAEsbbAw4UQxZUng .tFZ2RT3GCo6jOHxSZUrr {
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  margin-bottom: 10px;
  padding: 8px 16px;
  background-color: #2755fe;
  border: none;
}
.K2uzSaKfm0ZlFOeYgoXV .DzYBuAEsbbAw4UQxZUng .tFZ2RT3GCo6jOHxSZUrr:focus, .K2uzSaKfm0ZlFOeYgoXV .DzYBuAEsbbAw4UQxZUng .tFZ2RT3GCo6jOHxSZUrr:active, .K2uzSaKfm0ZlFOeYgoXV .DzYBuAEsbbAw4UQxZUng .tFZ2RT3GCo6jOHxSZUrr:hover {
  box-shadow: none !important;
  outline: none;
  background-color: #1f44ca !important;
}
.K2uzSaKfm0ZlFOeYgoXV .DzYBuAEsbbAw4UQxZUng .CLIBXWomo8tG8df8nes9 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.f7hg9BqjmEXIkNLH7uxm {
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
}
.f7hg9BqjmEXIkNLH7uxm ._HdAQ_TnQiSGtez7NIJ2 {
  display: flex;
  min-height: 600px;
  margin-bottom: 30px;
  width: "100%";
}
.f7hg9BqjmEXIkNLH7uxm ._HdAQ_TnQiSGtez7NIJ2 .rdKB8nkYC92ItH8szFJw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  background-color: aliceblue;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 20px;
  font-weight: 600;
  color: #333;
  width: 100%;
  box-sizing: border-box;
}
.f7hg9BqjmEXIkNLH7uxm ._HdAQ_TnQiSGtez7NIJ2 .rdKB8nkYC92ItH8szFJw .KpPLIdFUAIgy4KppmO46 {
  font-size: 16px;
  font-weight: 500;
  color: #8d8d8d;
  display: flex;
  align-items: center;
  gap: 10px;
}
.f7hg9BqjmEXIkNLH7uxm ._HdAQ_TnQiSGtez7NIJ2 .iSTv4O7imnkBc_pXK7oN {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.f7hg9BqjmEXIkNLH7uxm .ZnKhZ5kvavmj0rfaQMW7 {
  margin-top: 0px !important;
  border: none !important;
  box-shadow: none !important;
}
.f7hg9BqjmEXIkNLH7uxm .tgwwKb6Bm7zS42E9clmw {
  margin-top: unset;
  border: unset;
}

.UyLceZBv3pQ59KFbpPEH {
  width: 1200px;
  height: 600px;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  color: #333333;
}
.UyLceZBv3pQ59KFbpPEH .eu8VKvNC0QYpOV80ch2f {
  position: fixed;
  top: 40%;
  left: 50%;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"candWrapperWrapper": `K2uzSaKfm0ZlFOeYgoXV`,
	"subHeading": `DzYBuAEsbbAw4UQxZUng`,
	"addinBulk": `tFZ2RT3GCo6jOHxSZUrr`,
	"header": `CLIBXWomo8tG8df8nes9`,
	"cardSection": `f7hg9BqjmEXIkNLH7uxm`,
	"addCandidateCard": `_HdAQ_TnQiSGtez7NIJ2`,
	"headerx": `rdKB8nkYC92ItH8szFJw`,
	"right": `KpPLIdFUAIgy4KppmO46`,
	"heading": `iSTv4O7imnkBc_pXK7oN`,
	"bulkUploadCard": `ZnKhZ5kvavmj0rfaQMW7`,
	"failedCard": `tgwwKb6Bm7zS42E9clmw`,
	"loadingCard": `UyLceZBv3pQ59KFbpPEH`,
	"loader": `eu8VKvNC0QYpOV80ch2f`
};
export default ___CSS_LOADER_EXPORT___;
