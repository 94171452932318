// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mGY8hRvGDAhflXElV_BY {
  background-color: white;
  width: 100%;
  height: 100px;
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px rgba(51, 51, 51, 0.1);
  max-width: 1200px;
  margin: 15px auto 0 auto;
  position: relative;
  display: flex;
}

.H8MshddypVfXJreS9Mah {
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 15px;
  padding-right: 20px;
  padding-left: 10px;
}

.tDR0dgz2yNNvPf1Wu9fO {
  padding-top: 10px;
}

.WwYC3Oh7Gez5AZ6Aqcao {
  width: 720px;
  font-family: Poppins;
  padding-left: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(102, 102, 102, 0.7725490196);
}

.aWcbmigBDSDenA3G3DoH {
  position: absolute;
  left: 665px;
  bottom: 23px;
}

.cGVX3upJrcw6hBBUvhzw {
  position: fixed;
}

.wTjhYgQIsZHabuzB4Csr {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  padding-left: 15px;
}

.TGEKuSuDcOFQxnKXTXQ7 {
  color: #2755fe;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid #2755fe;
  background: #fff;
  height: 48px;
  width: 164px;
  box-shadow: none !important;
}
.TGEKuSuDcOFQxnKXTXQ7:hover, .TGEKuSuDcOFQxnKXTXQ7:active, .TGEKuSuDcOFQxnKXTXQ7:focus {
  background: #2755fe;
  color: #fff;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `mGY8hRvGDAhflXElV_BY`,
	"walletIcon": `H8MshddypVfXJreS9Mah`,
	"svInIcon": `tDR0dgz2yNNvPf1Wu9fO`,
	"lsContainer": `WwYC3Oh7Gez5AZ6Aqcao`,
	"wavyLine": `aWcbmigBDSDenA3G3DoH`,
	"waveIcon": `cGVX3upJrcw6hBBUvhzw`,
	"rsContainer": `wTjhYgQIsZHabuzB4Csr`,
	"addBtn": `TGEKuSuDcOFQxnKXTXQ7`
};
export default ___CSS_LOADER_EXPORT___;
