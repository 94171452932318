// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TNwOsA94bS3XpOPRuCJM {
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .TNwOsA94bS3XpOPRuCJM {
    width: 1200px;
  }
}
.TNwOsA94bS3XpOPRuCJM .LMJyPPCs6G5D9qk9ADC4 {
  display: flex;
  justify-content: space-between;
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.TNwOsA94bS3XpOPRuCJM .LMJyPPCs6G5D9qk9ADC4 .onQf38_VrAkQNfYV0Fvk {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.TNwOsA94bS3XpOPRuCJM .yqz76OXhg3XNZb7q_wfu {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 24px auto 32px;
  padding: 48px;
  font-family: Poppins, sans-serif;
  min-height: 695px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"creditContainer": `TNwOsA94bS3XpOPRuCJM`,
	"header": `LMJyPPCs6G5D9qk9ADC4`,
	"heading": `onQf38_VrAkQNfYV0Fvk`,
	"creditCard": `yqz76OXhg3XNZb7q_wfu`
};
export default ___CSS_LOADER_EXPORT___;
