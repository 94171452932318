import React, { useState, useEffect } from 'react';
import { GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import Geocode from 'react-geocode';

import CurrentLocation from './CurrentLocation';

// Google Maps Geocoding API
Geocode.setApiKey('AIzaSyD-hVZvTapYvIKIckWM1v-GB48jWlEaCxI');

// response language
Geocode.setLanguage('en');

// region language optional
Geocode.setRegion('en');

// logs
Geocode.enableDebug();

const MapContainer = (props) => {
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [latLong, setLatLong] = useState({ lat: '', lng: '' });
  const [isReset, setIsReset] = useState(false);
  const [save, setSaved] = useState(false);
  const onMarkerClick = (childProps, marker, e) => {
    setSelectedPlace(childProps);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const onMarkerDragEnd = (coord, index) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const place_id = response.results[0].place_id;
        const mapAddress = response.results[0].formatted_address;
        const { lat, lng } = response.results[0].geometry.location;
        let latitudeLongitude = {
          lat: lat,
          lng: lng
        };
        setLatLong(latitudeLongitude);
        setSaved(true);
        props.whenBlocked({ latlng: latitudeLongitude, placeId: place_id, mapAddress: mapAddress });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onClose = (childProps) => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };

  const handleResetLocation = ({ lat, lng }) => {
    setIsReset(true);
    setLatLong({ lat, lng });
    setSaved({ lat, lng });
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const place_id = response.results[0].place_id;
        const mapAddress = response.results[0].formatted_address;
        const { lat, lng } = response.results[0].geometry.location;
        let latitudeLongitude = {
          lat: lat,
          lng: lng
        };
        setLatLong(latitudeLongitude);
        setSaved(true);
        props.whenBlocked({
          latlng: latitudeLongitude,
          placeId: place_id,
          mapAddress: mapAddress
        });
        props.onLocationReset({
          latlng: latitudeLongitude,
          placeId: place_id,
          mapAddress: mapAddress
        });
      },
      (error) => {
        console.error(error);
      }
    );

    setIsReset(false);
  };

  useEffect(() => {
    if (isReset && props.step1Address) {
      setLatLong({
        lat: props.step1Address.latitude,
        lng: props.step1Address.longitude
      });
      setSaved(true);
      setIsReset(false);
    }
  }, [isReset, props.step1Address]);

  useEffect(() => {
    if (props.address) {
      if (props?.isStep2Address) {
        let newLatLong = { lat: props?.address?.latitude, lng: props?.address?.longitude };

        setLatLong(newLatLong);
        setSaved(true);
        props.whenBlocked({
          latlng: {
            ...newLatLong,
            placeId: props?.address?.placeId
          },
          mapAddress: props?.address?.mapAddress
        });
      } else {
        var tempAddress = `${props.address.houseNo},${props.address.colony.trim()},${
          props.address.city
        },${props.address.state},${props.address.country},${props.address.pincode}`;
        Geocode.fromAddress(tempAddress).then(
          (response) => {
            const place_id = response.results[0].place_id;
            const mapAddress = response.results[0].formatted_address;
            const { lat, lng } = response.results[0].geometry.location;
            let latitudeLongitude = {
              lat: lat,
              lng: lng
            };
            setLatLong(latitudeLongitude);
            setSaved(true);
            props.whenBlocked({
              latlng: latitudeLongitude,
              placeId: place_id,
              mapAddress: mapAddress
            });
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  }, [props.address]);

  return (
    <>
      {save && (
        <CurrentLocation
          centerAroundCurrentLocation
          google={props.google}
          zoom={14}
          savedLat={latLong.lat}
          savedLng={latLong.lng}
          initialCenter={latLong}
          visible={true}
          step1Address={props.step1Address}
          onResetLocation={handleResetLocation}
          whenBlocked={props.whenBlocked}
        >
          <Marker
            onClick={onMarkerClick}
            name={'current location'}
            draggable={true}
            onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
          />
          <InfoWindow marker={activeMarker} visible={showingInfoWindow} onClose={onClose}>
            <div>
              <h4>{selectedPlace.name}</h4>
            </div>
          </InfoWindow>
        </CurrentLocation>
      )}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyA5YGnxR433KkTRiWpBKY3POAmXqCKlktg'
})(MapContainer);
