// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LMhlJvFQXH2jpu4xUyqJ {
  margin-top: 30px;
  font-family: Poppins, sans-serif;
  font-style: normal;
}
.LMhlJvFQXH2jpu4xUyqJ .K_3_QpYFRnyfTtWYSZnJ {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: #333333;
}
.LMhlJvFQXH2jpu4xUyqJ .BcUOpiV25L1VWNzIcgen {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #666666;
}
.LMhlJvFQXH2jpu4xUyqJ .yawLhETwvuzcXsUfyWYe {
  text-align: right;
  vertical-align: middle;
}
.LMhlJvFQXH2jpu4xUyqJ .yawLhETwvuzcXsUfyWYe img {
  cursor: pointer;
}
.LMhlJvFQXH2jpu4xUyqJ .yawLhETwvuzcXsUfyWYe .rg0lE5OaoOObD0bTgIuM {
  margin-right: 10px;
  padding: 5px;
}
.LMhlJvFQXH2jpu4xUyqJ .yawLhETwvuzcXsUfyWYe .IvJKy8tuA19vUwQO66jN {
  padding: 5px;
}
.LMhlJvFQXH2jpu4xUyqJ .K2xXo2Br7dbTTNd7zgj6 {
  cursor: pointer;
  background: #f20000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 16px;
}
.LMhlJvFQXH2jpu4xUyqJ .Ooko1jbWT_weJBp5KVsX {
  color: rgba(255, 0, 0, 0.7019607843);
  font-size: 14px;
}

hr {
  margin-bottom: 15px;
  margin-top: 15px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"savedEmpInfo": `LMhlJvFQXH2jpu4xUyqJ`,
	"empDisplayTitle": `K_3_QpYFRnyfTtWYSZnJ`,
	"empDisplaySubTitle": `BcUOpiV25L1VWNzIcgen`,
	"actionBtnContainer": `yawLhETwvuzcXsUfyWYe`,
	"editIcon": `rg0lE5OaoOObD0bTgIuM`,
	"deleteIcon": `IvJKy8tuA19vUwQO66jN`,
	"deleteBadge": `K2xXo2Br7dbTTNd7zgj6`,
	"incompleteText": `Ooko1jbWT_weJBp5KVsX`
};
export default ___CSS_LOADER_EXPORT___;
