// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n7ez2sgXq2AvrVxTZqAr {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pdnudgsU1_hXNGCHLrfv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ObbIADu1TOhJ8kJXlqG5 {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -2rem;
  margin-bottom: 10px;
}

.tpufPFogdFgGnVIAP_v0 {
  height: 100px;
  width: 100px;
}

.p5yEcDUt3zK2pj5TP_Gz {
  height: 80px;
  width: 80px;
}

.CiWHqfwPoiwiiOhEOn66 {
  height: 50px;
  width: 50px;
}

.ABlj7Jfgm5kVMNvVunQn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.BK99TY4jYjYDmoy01Db4 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.IcS47AxpUdvToIEckq5F {
  text-align: center;
  margin-top: 2rem;
}

.YzmhO_lAICUkC1T_7czA {
  font-size: 24px;
  font-weight: 600;
}

.D5B3WCC3fiKQkUxrlDUT {
  font-size: 18px;
}

.GMUBS3cVpXdazBi6yGmR {
  text-align: center;
  margin-top: 2rem;
  width: 600px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `n7ez2sgXq2AvrVxTZqAr`,
	"innerContainer": `pdnudgsU1_hXNGCHLrfv`,
	"iconContainer": `ObbIADu1TOhJ8kJXlqG5`,
	"largeImage": `tpufPFogdFgGnVIAP_v0`,
	"mediumImage": `p5yEcDUt3zK2pj5TP_Gz`,
	"smallImage": `CiWHqfwPoiwiiOhEOn66`,
	"loaderContainer": `ABlj7Jfgm5kVMNvVunQn`,
	"bottomContainer": `BK99TY4jYjYDmoy01Db4`,
	"textContainer": `IcS47AxpUdvToIEckq5F`,
	"headerText": `YzmhO_lAICUkC1T_7czA`,
	"bodyText": `D5B3WCC3fiKQkUxrlDUT`,
	"strictTextContainer": `GMUBS3cVpXdazBi6yGmR`
};
export default ___CSS_LOADER_EXPORT___;
