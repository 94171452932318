// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M0EM0lNfj0sPSG7VAuNk,
.YLaGocFcjzZ38AoXBmke {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  background: #fffbe6;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  padding: 18px;
}
.M0EM0lNfj0sPSG7VAuNk a,
.YLaGocFcjzZ38AoXBmke a {
  color: #2755fe;
  font-weight: 600;
  text-decoration: underline;
}

.VyQGeWO2gZtxGkSmHkqe {
  text-align: center;
}

.knJvtvxYbv9AeuziD5ta {
  color: #333333 !important;
}

.tNDQm0l5x4THTykrhDgj {
  color: #ffffff !important;
}

.YLaGocFcjzZ38AoXBmke {
  display: flex;
  place-content: center;
  align-items: center;
  margin-bottom: 4px;
}

.IYVnmJHcpTx8KCSBUCFj {
  margin-right: 8px;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HiYd5YDEy2VWRydXK29A {
  background: #f3c19d;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid #e8833a;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0px 6px;
  height: 33px;
}

.jd2KIWQMPhiNFTWRQRcn {
  display: flex;
  background-color: #d3455b;
  margin-bottom: 30px;
  color: white;
  padding-left: 10px;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  height: 33px;
}

.YlDp6Xd1lL3SEWtDvPXT {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d3455b;
  color: white;
  width: 100%;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  padding: 12px;
  margin-bottom: 4px;
}
.YlDp6Xd1lL3SEWtDvPXT a {
  color: #ffffff;
  text-decoration: underline;
  margin: 0px 4px;
  font-weight: 600;
}

.hoSHUy0ESxCPz5YO55L8 {
  width: 100%;
  background: #fffbe6;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #464457;
  padding: 0.5rem 0 0.5rem 1.25rem;
  text-align: left;
  margin-bottom: 1rem;
  border: 1px solid rgba(255, 170, 0, 0.3019607843);
}

.BsqypoZqev3Ot62vyTpp {
  width: 100%;
  background: rgba(255, 227, 227, 0.3137254902);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #464457;
  padding: 0.5rem 0 0.5rem 1.25rem;
  text-align: left;
  margin-bottom: 1rem;
  border: 1px solid #ffe3e3;
}

.Aqrvo8Fq9OgPHcny8208 {
  width: 100%;
  background: #fffbe6;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #464457;
  padding: 0.5rem 0 0.5rem 1.25rem;
  text-align: left;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `M0EM0lNfj0sPSG7VAuNk`,
	"addCreditsWarning": `YLaGocFcjzZ38AoXBmke`,
	"textCenter": `VyQGeWO2gZtxGkSmHkqe`,
	"textColor": `knJvtvxYbv9AeuziD5ta`,
	"whiteTextColor": `tNDQm0l5x4THTykrhDgj`,
	"image": `IYVnmJHcpTx8KCSBUCFj`,
	"warningBox": `HiYd5YDEy2VWRydXK29A`,
	"bounceEmailBanner": `jd2KIWQMPhiNFTWRQRcn`,
	"adminBounceEmailBanner": `YlDp6Xd1lL3SEWtDvPXT`,
	"lowPackageBalanceBanner": `hoSHUy0ESxCPz5YO55L8`,
	"criticalPackageBalanceBanner": `BsqypoZqev3Ot62vyTpp`,
	"noPackageBanner": `Aqrvo8Fq9OgPHcny8208`
};
export default ___CSS_LOADER_EXPORT___;
