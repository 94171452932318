// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtXuuP7TSdYNkjtFHvnJ {
  width: 680px;
  margin: -190px auto 0 auto;
}
.dtXuuP7TSdYNkjtFHvnJ h4 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 30px;
}

.ydMqivNQ1Q3EEs36nngk .g57p94neP0jct6ZhAXXc .h_YB5ZU0VRpHz0dkbqYv {
  height: fit-content;
  width: 680px;
  margin: 0 auto;
  display: block;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
}
@media only screen and (max-width: 1024px) {
  .ydMqivNQ1Q3EEs36nngk .g57p94neP0jct6ZhAXXc .h_YB5ZU0VRpHz0dkbqYv {
    margin-left: 350px;
  }
}
.ydMqivNQ1Q3EEs36nngk .g57p94neP0jct6ZhAXXc form .GM606xJYlMPehgPMW_jf {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4c4c4c;
}
.ydMqivNQ1Q3EEs36nngk .g57p94neP0jct6ZhAXXc form .GOkP7BD5OGhTpIm7vKxw {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  color: #4c4c4c;
  margin-bottom: 4px;
}

.mRVNaaQ8SH_hILMkM9me {
  padding: 0 28px;
}

.RqNo89ZWS8ayPu9V7VPy {
  background: #2755fe;
  width: 136px;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.M29Nm9ER4UfVTFvV2zBs {
  margin-top: -1rem;
  margin-bottom: 30px;
}

.QtnUNlUFPnXzKnYIRCFv {
  margin-top: 25px;
  width: 377px;
  height: 36px;
  background: #f6ffed;
  border: 1px solid #52c41a;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: #333333;
}

.qdumlElMkORUri5kUJgj {
  padding: 10px;
}

.PnM16kBSTrFlYQScR2SA {
  margin-top: 4px;
  width: 381px;
  height: 63px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #666666;
}

.E6pK25Ls4sK51oE211mj {
  margin-top: 25px;
  width: 377px;
  height: 36px;
  background: #fff2f0;
  border: 1px solid #f03d3d;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: #333333;
}

.XkmISd4OgDPqCe9lVGwQ {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
  margin-bottom: 30px !important;
  padding-left: 25px;
}

.KYH3wzK4Q5a5VvsyhjLB {
  margin-left: 10px;
}

.begJvVBkNMgZ5a69mjBW {
  margin-right: 8px;
  height: 14px;
  width: 14px;
  color: #9ed689;
}

.shFHOTWxSuHbeo2AWcOS {
  padding: 24px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `dtXuuP7TSdYNkjtFHvnJ`,
	"exportExcel": `ydMqivNQ1Q3EEs36nngk`,
	"cardSection": `g57p94neP0jct6ZhAXXc`,
	"exportExcelCard": `h_YB5ZU0VRpHz0dkbqYv`,
	"dateLabel": `GM606xJYlMPehgPMW_jf`,
	"customRangeMessage": `GOkP7BD5OGhTpIm7vKxw`,
	"radioBtn": `mRVNaaQ8SH_hILMkM9me`,
	"exportBtn": `RqNo89ZWS8ayPu9V7VPy`,
	"datePicker": `M29Nm9ER4UfVTFvV2zBs`,
	"successAlert": `QtnUNlUFPnXzKnYIRCFv`,
	"successAlertImage": `qdumlElMkORUri5kUJgj`,
	"successText": `PnM16kBSTrFlYQScR2SA`,
	"failureAlert": `E6pK25Ls4sK51oE211mj`,
	"errorMsg": `XkmISd4OgDPqCe9lVGwQ`,
	"spinner": `KYH3wzK4Q5a5VvsyhjLB`,
	"checkItemSvg": `begJvVBkNMgZ5a69mjBW`,
	"cardBody": `shFHOTWxSuHbeo2AWcOS`
};
export default ___CSS_LOADER_EXPORT___;
