import React, { useState, useEffect } from 'react';
import { Card, Form, Spinner } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import styles from './CommonStyles.module.scss';
import ImageUpload from './ImageUpload';
import SampleModal from './SampleModal';

const BrandPage = (props) => {
  const { Button, fileUploadAPI, brandSettings, getBrandSettings, editBrandSettings, isSa } = props;

  /**
   * State Variables
   */
  const [formData, setFormData] = useState({
    name: '',
    friendlyName: '',
    logoUrlId: '',
    companyLogos: {}
  });
  const [logoFile, setLogoFile] = useState({
    dark: null,
    light: null
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [brandNameError, setBrandNameError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logoError, setLogoError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showLogoDeleteModal, setShowLogoDeleteModal] = useState(false);
  /**
   * Use-Effect Hooks
   */

  useEffect(() => {
    getBrandSettingsApi();
  }, []);

  const updateForm = () => {
    setFormData({
      name: brandSettings?.data?.name || '',
      friendlyName: brandSettings?.data?.friendlyName || '',
      logoUrlId: brandSettings?.data?.logoUrlId || '',
      companyLogos: brandSettings?.data?.companyLogos?.reduce(
        (logoObject, logo) => ({ ...logoObject, [logo.logoType]: logo }),
        {}
      )
    });
  };

  const isValidImageUrl = async (url) => {
    if (!url) return null;

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve(img.height > 60);
      };
      img.onerror = () => {
        resolve(false);
      };
    });
  };

  const setLogoErrors = async () => {
    const logos = Object.values(formData.companyLogos).map((e) => e.logoUrl);
    const isValid = await Promise.all(logos.map(isValidImageUrl));
    if (isValid.includes(false)) {
      setLogoError('Upload an image of size more than 60px');
    } else {
      setLogoError(null);
    }
  };

  useEffect(() => {
    if (brandSettings?.data) {
      updateForm();
    }
  }, [brandSettings]);

  useEffect(() => {
    setLogoErrors();
  }, [formData]);

  /**
   * Helper Functions
   */

  const getBrandSettingsApi = () => {
    if (isSa) getBrandSettings(props?.companyId);
    else getBrandSettings();
  };

  const handleFormChange = (field, value) => {
    setHasUnsavedChanges(true);
    if (field === 'friendlyName') {
      const brandNameRegex = /^[a-zA-Z0-9 &().]*$/;
      if (!brandNameRegex.test(value)) {
        setBrandNameError('Please enter a valid Brand Name');
      } else {
        setBrandNameError(null);
      }
    } else {
      // if logo is changed
      let logoType = field;
      let logoUrl = value;

      field = 'companyLogos';
      value = {
        ...formData.companyLogos,
        [logoType]: {
          ...formData.companyLogos[logoType],
          logoUrl
        }
      };
    }
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const onCancel = () => {
    getBrandSettingsApi();
    setHasUnsavedChanges(false);
    setLogoFile({
      dark: null,
      light: null
    });
  };

  const uploadLogo = async (file, fileType) => {
    if (file) {
      const resp = await fileUploadAPI({
        file,
        companyId: props?.companyId || props?.profile?.company_user_mapping?.company_id || null,
        candidateId: props?.profile?.company_user_mapping?.company_id || props?.companyId || null,
        fileType: fileType
      });

      return resp.data.data;
    }
    return null;
  };

  const deleteLogo = (deletingLogo) => {
    handleFormChange(deletingLogo, '');
    setLogoError(null);
    setShowLogoDeleteModal(false);
  };

  const onSave = async () => {
    setIsSubmitting(true);
    try {
      const [newDarkLogoUrl, newLightLogoUrl] = await Promise.all([
        uploadLogo(logoFile.dark, 'COMPANY_LOGO'),
        uploadLogo(logoFile.light, 'COMPANY_LOGO')
      ]);

      const request = {
        friendlyName: formData.friendlyName,
        logoUrlId: formData.logoUrlId || null,
        companyLogos: formData?.companyLogos || { dark: {}, light: {} }
      };

      if (newDarkLogoUrl) {
        request.companyLogos.dark.logoUrl = newDarkLogoUrl;
      }
      if (newLightLogoUrl) {
        request.companyLogos.light.logoUrl = newLightLogoUrl;
      }

      request.companyLogos = Object.keys(request.companyLogos).reduce((acc, key) => {
        const brandLogo =
          brandSettings?.data?.companyLogos.find((logo) => logo.logoType === key) || {};
        if (request.companyLogos[key].logoUrl !== brandLogo?.logoUrl) {
          acc[key] = request.companyLogos[key];
        }
        return acc;
      }, {});

      if (isSa) {
        request.company_id = props?.companyId;
        await editBrandSettings({ companyId: props?.companyId, data: request });
      } else {
        await editBrandSettings(request);
      }
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
    setHasUnsavedChanges(false);
    setLogoFile({
      dark: null,
      light: null
    });
  };

  return (
    <>
      <Form>
        <Card className={styles.communicationCard}>
          <div className={styles.tabInfoText}>
            This is the designated space to Tailor your Brand information.
          </div>
          <Card className={styles.communicationContainer}>
            <div className={styles.fields}>
              <div className={styles.sectionHeader}>Legal Name</div>
              <div className={styles.input}>
                <Form.Control
                  type='name'
                  value={formData?.name || ''}
                  placeholder='Legal Name'
                  disabled
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.sectionHeader}>Brand Name</div>
              <div className={styles.sectionSubHeader}>
                Enter your brand / short name that you are commonly known as
              </div>
              <div className={styles.input}>
                <Form.Control
                  type='name'
                  placeholder='Brand Name'
                  value={formData.friendlyName}
                  onChange={(e) => handleFormChange('friendlyName', e.target.value)}
                  onBlur={(e) =>
                    handleFormChange('friendlyName', e.target.value.trim().replace(/ +/g, ' '))
                  }
                />
              </div>
              <div className={styles.inputError}>{brandNameError ? brandNameError : null}</div>
            </div>
            <div className={styles.fields}>
              <div className={styles.sectionHeader}>Company Logo</div>

              <div className={styles.logoContainer}>
                {/* Image 1 */}
                <ImageUpload
                  style={{
                    background: '#ebedf2',
                    border: '1px dashed #cbd5e1'
                  }}
                  title={'Upload dark logo'}
                  subtitle={'(To use on light BG)'}
                  setLogo={(e) => handleFormChange('dark', e)}
                  logo={formData.companyLogos?.dark?.logoUrl || ''}
                  setLogoFile={(e) => setLogoFile({ ...logoFile, dark: e })}
                  setLogoError={setLogoError}
                  isSa={isSa}
                  onLogoDelete={() => {
                    deleteLogo('dark');
                  }}
                />
                {/* Image 2  */}
                <ImageUpload
                  style={{ background: '#6a778b', color: 'white' }}
                  title={'Upload light logo'}
                  setLogo={(e) => handleFormChange('light', e)}
                  subtitle={'(To use on dark BG)'}
                  logo={formData.companyLogos?.light?.logoUrl || ''}
                  setLogoFile={(e) => setLogoFile({ ...logoFile, light: e })}
                  setLogoError={setLogoError}
                  isSa={isSa}
                  onLogoDelete={() => {
                    deleteLogo('light');
                  }}
                />

                <div className={styles.sample} onClick={() => setShowModal(true)}>
                  See sample images
                </div>
              </div>
              {logoError && <div className={styles.logoError}>{logoError}</div>}
            </div>
          </Card>
          <div className={styles.buttonGroup}>
            <Button
              disabled={!hasUnsavedChanges}
              className={styles.cancelButton}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              className={styles.submitButton}
              disabled={!hasUnsavedChanges || isSubmitting || brandNameError || logoError}
              onClick={() => setShowLogoDeleteModal(true)}
            >
              Save
              <Spinner
                className={styles.spinner}
                animation='border'
                variant='light'
                size='sm'
                hidden={!isSubmitting}
                style={{ marginLeft: '5px', verticalAlign: 'middle' }}
              />
            </Button>
          </div>
        </Card>
      </Form>
      <SampleModal show={showModal} onHide={() => setShowModal(false)} />
      <Modal
        size='md'
        show={showLogoDeleteModal}
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => setShowLogoDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Updating Logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Updating or deleting logos will disable the use of the logo and will have to be setup
            again through Communication/ Alumni Verification Settings Tab.
          </div>
          <div className={styles.btnContainer}>
            <Button
              className={styles.deleteLogoBtn}
              onClick={() => {
                onCancel();
                setShowLogoDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={styles.cancelLogoBtn}
              onClick={() => {
                setShowLogoDeleteModal(false);
                onSave();
              }}
            >
              Continue
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BrandPage;
