// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ut2w3kUtoSE91JTdVUQk {
  background: #D3455B;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  margin-right: 0px;
}
.ut2w3kUtoSE91JTdVUQk:hover {
  background-color: #e97070;
}
.ut2w3kUtoSE91JTdVUQk:active {
  background: #e97070 !important;
}

.Fqf4mO_kZ44KqFsV0lrF {
  border: 1px solid #2755fe;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2755fe;
  width: 136px;
  margin-right: 15px;
}
.Fqf4mO_kZ44KqFsV0lrF:hover {
  background-color: #ffffff;
  color: #2755fe;
}
.Fqf4mO_kZ44KqFsV0lrF:active {
  background-color: #ffffff !important;
  color: #2755fe !important;
}

.MGrRS8TFsV7FZfKQxiZg {
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  margin-right: 0px;
}
.MGrRS8TFsV7FZfKQxiZg:hover {
  background: #2755fe;
  color: #ffffff;
}
.MGrRS8TFsV7FZfKQxiZg:disabled {
  background: #2755fe !important;
  opacity: 0.5 !important;
  cursor: not-allowed;
}

.lahiy3TBORWMidTP3Zzs {
  display: flex;
  flex-direction: column;
}

.Ax6NgP_x4O5fVD1SXpos {
  color: #788896;
  font-weight: 800;
  font-size: 12px;
}

.ACBPzfCXSgxbPuFtfTh6 {
  color: #f13131;
  font-size: 14px;
}

.dvnhEGXko2fCEGrx2xiR {
  margin-left: -6px;
  margin-top: 6px;
}

.DH8Cyx1PE7ULgF2g79Iw {
  max-width: 1200px;
  margin: auto;
  background-color: white;
  padding: 0 24px 24px 24px;
  border-radius: 0.25rem;
}
@media only screen and (max-width: 1200px) {
  .DH8Cyx1PE7ULgF2g79Iw {
    width: 1200px;
  }
}
.DH8Cyx1PE7ULgF2g79Iw .t_EaMlepa4eZANoTDv8k {
  display: flex;
  justify-content: space-between;
}
.DH8Cyx1PE7ULgF2g79Iw .t_EaMlepa4eZANoTDv8k .bdbVBsWwK4mKQmlmlncn {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
}
.DH8Cyx1PE7ULgF2g79Iw .t_EaMlepa4eZANoTDv8k .lAhvJJeXKVnbKZaCtuLw {
  background: #2755fe;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding: 14px 24px 14px 23px;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  border: none !important;
  padding: 25px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 8px;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .R3SpN7kGCYFh92m0g_3J {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
  padding: 24px 28px 0px;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML {
  border: 0px;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ {
  word-break: break-all;
  margin: auto;
  margin-top: 22px;
  margin-bottom: 24px;
  width: 100%;
  text-align: left;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ thead {
  background-color: #f2f8ff;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ thead tr th {
  white-space: normal;
  word-wrap: break-word;
  border: 0px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 15px 25px;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ thead tr .ydnq5H3bADWYpqx5jWXw {
  text-align: right;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ tbody tr td {
  max-width: 20vw;
  white-space: normal;
  /* Only needed when it's set differntly somewhere else */
  word-wrap: break-word;
  cursor: default;
  border: 0px;
  text-align: center;
  vertical-align: middle;
  padding: 15px 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ tbody tr td .l3oNVcCR8YF3ZPmGmH7k {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
  align-items: center;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ tbody tr td .l3oNVcCR8YF3ZPmGmH7k .CEg6WMJWRxMoKSKErCF2 {
  border: 1px transparent solid;
  background: transparent;
  padding: 3px;
  border-radius: 50%;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ tbody tr td .l3oNVcCR8YF3ZPmGmH7k .CEg6WMJWRxMoKSKErCF2:hover {
  background: #e2ebf6;
  border: 1px #e2ebf6 solid;
  border-radius: 50%;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ tbody tr td .l3oNVcCR8YF3ZPmGmH7k .CEg6WMJWRxMoKSKErCF2:hover svg path {
  fill: #2755fe;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ tbody tr td .l3oNVcCR8YF3ZPmGmH7k .n7D2dxrrSMMjtSRGspuk {
  margin-left: 24px;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ tbody tr .ydnq5H3bADWYpqx5jWXw {
  text-align: right;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ tbody tr:hover {
  background-color: #f5f5f5;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ tbody tr:hover .jyx0BTGDTGcnEl4_vQKy {
  color: #366cf3;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ .BcKs4POjY8wcggE2pcmk {
  text-align: center;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ .EuIQlF8y1eN_NQtAkcDY {
  text-align: left;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ .bQssyE6hCAkEnIVnabyg {
  text-align: center;
  padding-top: 64px;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .YFplozJgXCRVpHctGMSZ .bQssyE6hCAkEnIVnabyg .r6bbw5SmMuV5fcdU6RAP {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .ieS9NHeZP3gmVX1eVYVf {
  flex-wrap: unset;
  padding: 0 24px 15px;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .ieS9NHeZP3gmVX1eVYVf .L_TbK42IYquZxChlq0_x {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.DH8Cyx1PE7ULgF2g79Iw .UZziRQIGtS8kybQAJbTA .rR7Xq4PfWCvUwZoh3vML .ieS9NHeZP3gmVX1eVYVf .L_TbK42IYquZxChlq0_x .df0RcleSTqbOOyLaXNnU {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px;
  -webkit-appearance: menulist;
}

.WXEPd2QVY2pXMIBmFiI9 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  margin-bottom: 17px;
  display: flex;
}
.WXEPd2QVY2pXMIBmFiI9 a {
  color: #2755fe;
  display: inline-block;
  margin-left: 5px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteButton": `ut2w3kUtoSE91JTdVUQk`,
	"cancelButton": `Fqf4mO_kZ44KqFsV0lrF`,
	"submitButton": `MGrRS8TFsV7FZfKQxiZg`,
	"parentDivMinMax": `lahiy3TBORWMidTP3Zzs`,
	"minMaxText": `Ax6NgP_x4O5fVD1SXpos`,
	"errorMsg": `ACBPzfCXSgxbPuFtfTh6`,
	"expiryTimeLimit": `dvnhEGXko2fCEGrx2xiR`,
	"parentDivWrapper": `DH8Cyx1PE7ULgF2g79Iw`,
	"header": `t_EaMlepa4eZANoTDv8k`,
	"heading": `bdbVBsWwK4mKQmlmlncn`,
	"generateTokenButton": `lAhvJJeXKVnbKZaCtuLw`,
	"bodyCard": `UZziRQIGtS8kybQAJbTA`,
	"adminCardHeading": `R3SpN7kGCYFh92m0g_3J`,
	"tableCard": `rR7Xq4PfWCvUwZoh3vML`,
	"mainTable": `YFplozJgXCRVpHctGMSZ`,
	"alignRight": `ydnq5H3bADWYpqx5jWXw`,
	"icons": `l3oNVcCR8YF3ZPmGmH7k`,
	"icon": `CEg6WMJWRxMoKSKErCF2`,
	"space": `n7D2dxrrSMMjtSRGspuk`,
	"mainField": `jyx0BTGDTGcnEl4_vQKy`,
	"subHeader": `BcKs4POjY8wcggE2pcmk`,
	"subHeaderLeftAligned": `EuIQlF8y1eN_NQtAkcDY`,
	"noDataWrapper": `bQssyE6hCAkEnIVnabyg`,
	"noDataContent": `r6bbw5SmMuV5fcdU6RAP`,
	"paginationWrapper": `ieS9NHeZP3gmVX1eVYVf`,
	"showPagination": `L_TbK42IYquZxChlq0_x`,
	"selectPagination": `df0RcleSTqbOOyLaXNnU`,
	"tabHeader": `WXEPd2QVY2pXMIBmFiI9`
};
export default ___CSS_LOADER_EXPORT___;
