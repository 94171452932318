// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.visUO8cKlzRiOIhlXKGX {
  color: #000000;
  font-size: 24px;
}

.fyUcWkOiFVdeeIYBNzsp {
  color: #8c8c8c;
  font-size: 16px;
}

.XrjQJL6qO8F9xZWYeaZV .HF2k7dQ5AJx5KYRfxml1 .N55Zzi2f1dMSYjlSoIX1 {
  color: #262626;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}
.XrjQJL6qO8F9xZWYeaZV .HF2k7dQ5AJx5KYRfxml1 .VBQyc1oDQYxOL9mNI3W2 {
  color: #999999;
  font-size: 14px;
  font-weight: normal;
}
.XrjQJL6qO8F9xZWYeaZV .NqhcyQABZHACB9SLC8UR .i7TXXcZ2GIoWdNUQ2_4o {
  font-weight: 600;
  font-size: 14px;
  color: #262626;
}
.XrjQJL6qO8F9xZWYeaZV .NqhcyQABZHACB9SLC8UR .i7TXXcZ2GIoWdNUQ2_4o span {
  color: #f5222d;
}
.XrjQJL6qO8F9xZWYeaZV .xQiw1jW_kFsnHZjPT7AW {
  background: #f5f7ff;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
  height: 117px;
  justify-content: center;
  margin-bottom: 16px !important;
  cursor: pointer;
}
.XrjQJL6qO8F9xZWYeaZV .xQiw1jW_kFsnHZjPT7AW p {
  font-weight: normal !important;
  margin: 0;
}
.XrjQJL6qO8F9xZWYeaZV .qNz_ieA4mrABN21Iyt1a {
  background: #f5f5f5;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
  height: 117px;
  justify-content: center;
  margin-bottom: 16px !important;
  cursor: not-allowed;
}
.XrjQJL6qO8F9xZWYeaZV .qNz_ieA4mrABN21Iyt1a p {
  font-weight: normal !important;
  margin: 0;
  color: #bfbfbf;
}
.XrjQJL6qO8F9xZWYeaZV .QAEXRZln1VoZvc6n5xVg {
  background: #000000;
  color: #ffffff;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  height: 117px;
  justify-content: center;
  padding: 0 16px;
  cursor: pointer;
}
.XrjQJL6qO8F9xZWYeaZV .QAEXRZln1VoZvc6n5xVg .ctr6AR7yn9lubLIZJmMa {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.XrjQJL6qO8F9xZWYeaZV .QAEXRZln1VoZvc6n5xVg .ctr6AR7yn9lubLIZJmMa .VM5HYzgA4vQe98dFU90s {
  font-weight: normal !important;
  margin: 0;
  font-size: 14px;
  width: 165px;
}
.XrjQJL6qO8F9xZWYeaZV .QAEXRZln1VoZvc6n5xVg .ctr6AR7yn9lubLIZJmMa .QJjDI2sLsJF0FskLLV9r {
  align-self: flex-end;
  background: #ffffff;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 13px;
}
.XrjQJL6qO8F9xZWYeaZV .QAEXRZln1VoZvc6n5xVg .ctr6AR7yn9lubLIZJmMa .QJjDI2sLsJF0FskLLV9r img {
  margin-top: 2px;
}
.XrjQJL6qO8F9xZWYeaZV .FwyMLo9bvilUGiRKSC4L {
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
  height: 117px;
  justify-content: center;
  margin-bottom: 16px;
}
.XrjQJL6qO8F9xZWYeaZV .HvrzYlEG38jz6dj0ppiC {
  position: absolute;
  top: 0;
  left: 15px;
  margin: 8px 0px;
  display: flex;
  align-items: center;
}
.XrjQJL6qO8F9xZWYeaZV .HvrzYlEG38jz6dj0ppiC span {
  margin-left: 8px;
  width: 24px;
  height: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.h9GXw4Sg7nlwP0L8TGiM {
  margin-top: 20px;
  justify-content: flex-end !important;
}
@media screen and (max-width: 601px) {
  .h9GXw4Sg7nlwP0L8TGiM {
    position: fixed;
    left: 0;
    margin: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
  }
  .h9GXw4Sg7nlwP0L8TGiM button {
    height: 40px;
    margin: 0 !important;
  }
  .h9GXw4Sg7nlwP0L8TGiM .uyCP0vkkcX4g4OH3cEQF {
    flex: 1;
  }
  .h9GXw4Sg7nlwP0L8TGiM .ZFoM7MzRD0y4cmtU31cm {
    flex: 1;
  }
}

.HWeQhdMDof9tlcKD9Wml {
  margin-bottom: 32px;
}

.SNev8q22mpC_5BMHMF4H {
  padding: 0;
}
.SNev8q22mpC_5BMHMF4H .tGwUC6D_WD54TyVQ5URJ {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  color: #2755fe;
  padding: 8px 18px;
  margin-bottom: 16px;
  text-align: center;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
}
.SNev8q22mpC_5BMHMF4H .tGwUC6D_WD54TyVQ5URJ:active {
  background: #ffffff !important;
  color: #2755fe !important;
  border: none !important;
}

.p8uKRNwhambYMccL4hA6 {
  padding: 0;
}

.XrjQJL6qO8F9xZWYeaZV {
  padding-bottom: 40px !important;
  min-height: 500px;
}
@media screen and (max-width: 601px) {
  .XrjQJL6qO8F9xZWYeaZV {
    padding-bottom: 65px !important;
  }
}

.vw4BojDrr6JOPsos9uAD {
  color: #f5222d;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"employmentModalTitle": `visUO8cKlzRiOIhlXKGX`,
	"employmentModalSubTitle": `fyUcWkOiFVdeeIYBNzsp`,
	"addEditEducationModalBody": `XrjQJL6qO8F9xZWYeaZV`,
	"employmentProofContainer": `HF2k7dQ5AJx5KYRfxml1`,
	"employmentProofTitle": `N55Zzi2f1dMSYjlSoIX1`,
	"employmentProofDescription": `VBQyc1oDQYxOL9mNI3W2`,
	"degreeCertificateContainer": `NqhcyQABZHACB9SLC8UR`,
	"degreeCertificateTitle": `i7TXXcZ2GIoWdNUQ2_4o`,
	"certificateInputFileContainer": `xQiw1jW_kFsnHZjPT7AW`,
	"disabledInputFileContainer": `qNz_ieA4mrABN21Iyt1a`,
	"errorClass": `QAEXRZln1VoZvc6n5xVg`,
	"errorClassContent": `ctr6AR7yn9lubLIZJmMa`,
	"errorText": `VM5HYzgA4vQe98dFU90s`,
	"refreshIconContainer": `QJjDI2sLsJF0FskLLV9r`,
	"certificateUploadedFileContainer": `FwyMLo9bvilUGiRKSC4L`,
	"deletePreviewImageContainer": `HvrzYlEG38jz6dj0ppiC`,
	"employmentSubmitBtn": `h9GXw4Sg7nlwP0L8TGiM`,
	"activeBtn": `uyCP0vkkcX4g4OH3cEQF`,
	"disabledBtn": `ZFoM7MzRD0y4cmtU31cm`,
	"customDropdown": `HWeQhdMDof9tlcKD9Wml`,
	"takePhotoBtnContainer": `SNev8q22mpC_5BMHMF4H`,
	"takePhotoBtn": `tGwUC6D_WD54TyVQ5URJ`,
	"uploadContainer": `p8uKRNwhambYMccL4hA6`,
	"maxFileError": `vw4BojDrr6JOPsos9uAD`
};
export default ___CSS_LOADER_EXPORT___;
