// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IlD0cvVvEhgS1p_NqTi5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}

.SId0JPHmeaEDUOhZwMkm {
  margin-bottom: 20px;
}

.j9WnLidjRZvyBDLxK2Fg {
  margin-top: 20px;
  padding: 0 !important;
  justify-content: flex-end !important;
}
.j9WnLidjRZvyBDLxK2Fg button {
  border-radius: 2px;
  padding: 0.3em 1.4rem;
  margin: 4px;
}
.j9WnLidjRZvyBDLxK2Fg .OyptCqYgXUwL7WqkymUx {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.j9WnLidjRZvyBDLxK2Fg .ft33CKNCNOJaksG7N_5W {
  background: #f5f5f5;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
@media only screen and (max-width: 600px) {
  .j9WnLidjRZvyBDLxK2Fg {
    display: flex;
    align-items: center;
  }
  .j9WnLidjRZvyBDLxK2Fg .OyptCqYgXUwL7WqkymUx {
    flex: 1;
  }
  .j9WnLidjRZvyBDLxK2Fg .ft33CKNCNOJaksG7N_5W {
    flex: 1;
  }
}
.j9WnLidjRZvyBDLxK2Fg .NZJ2zUDncqihUQFsrffU {
  background: #ffffff;
  color: #2755fe;
  border: none;
  margin-right: 32px;
  box-shadow: none;
  font-size: 16px;
  font-family: Poppins, sans-serif;
}
.j9WnLidjRZvyBDLxK2Fg .NZJ2zUDncqihUQFsrffU:hover {
  background: #ffffff;
  color: #2755fe;
  border: none;
}
.j9WnLidjRZvyBDLxK2Fg .NZJ2zUDncqihUQFsrffU:active {
  background: #ffffff !important;
  color: #2755fe !important;
  border: none !important;
}
.j9WnLidjRZvyBDLxK2Fg .NZJ2zUDncqihUQFsrffU:focus {
  box-shadow: none !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainTitle": `IlD0cvVvEhgS1p_NqTi5`,
	"checkContainer": `SId0JPHmeaEDUOhZwMkm`,
	"modalFooter": `j9WnLidjRZvyBDLxK2Fg`,
	"activeBtn": `OyptCqYgXUwL7WqkymUx`,
	"disabledBtn": `ft33CKNCNOJaksG7N_5W`,
	"cancelBtn": `NZJ2zUDncqihUQFsrffU`
};
export default ___CSS_LOADER_EXPORT___;
