// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kb_nNDMNez8a6Jq8Jd6t {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.anlOARWzgUmzOQ4vZ2lY {
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: #f8fafc;
  border: 2px dashed;
  border-color: #cbd5e1;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 30px;
  justify-content: center;
  cursor: pointer;
}
.anlOARWzgUmzOQ4vZ2lY .yzU4mTFgkGaCjis0MLMp {
  color: #1a1a1a;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.anlOARWzgUmzOQ4vZ2lY .yzU4mTFgkGaCjis0MLMp .OF7zB02YBiUb1lnFl1Me {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.anlOARWzgUmzOQ4vZ2lY .YQKA24reidLO7UybckCb {
  display: flex;
  flex-direction: column;
}
.anlOARWzgUmzOQ4vZ2lY .vp5BMOr86jjNIUdSIsO1 {
  margin-bottom: 2px !important;
}
.anlOARWzgUmzOQ4vZ2lY .QuNFQ89ZfxUK9GWHa5eW {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
.anlOARWzgUmzOQ4vZ2lY .HXzM3idGwVbgNDpwfLKl {
  max-height: 25px;
}
.anlOARWzgUmzOQ4vZ2lY .oUEWugw7mqrkAW8lHEVS {
  display: flex;
  align-items: center;
  padding: 9px 13px;
  width: 270px;
  min-height: 52px;
}
.anlOARWzgUmzOQ4vZ2lY .oUEWugw7mqrkAW8lHEVS .KB0Jl7if2iPQZnS7AHvE {
  display: flex;
  align-items: center;
}
.anlOARWzgUmzOQ4vZ2lY .oUEWugw7mqrkAW8lHEVS .KB0Jl7if2iPQZnS7AHvE .BC2hVOHqj1DpyNqlNGO9 {
  color: #000000;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  width: 173px;
  margin-left: 12px;
  margin-right: 8px;
}
.anlOARWzgUmzOQ4vZ2lY ._avEBMrhSeL5gt8O5DgA {
  color: #2755fe;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.anlOARWzgUmzOQ4vZ2lY .jGn9aZTogdD4Z608ZfCI {
  color: #000000;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  width: 173px;
}
.anlOARWzgUmzOQ4vZ2lY .rxrhTQoLKPz24OGCUZlR {
  color: #666666;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  letter-spacing: 0.1px;
  line-height: 20px;
  text-align: center;
}
.anlOARWzgUmzOQ4vZ2lY .ZbBkD5SpXI5irXjy1puc {
  color: #2755fe;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
}
.anlOARWzgUmzOQ4vZ2lY .qBZQkYdCaKiWl8d0k3Oc {
  color: #535961;
  font-family: "Poppins-Italic", Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowOne": `kb_nNDMNez8a6Jq8Jd6t`,
	"uploadFileContainer": `anlOARWzgUmzOQ4vZ2lY`,
	"titleText": `yzU4mTFgkGaCjis0MLMp`,
	"subtext": `OF7zB02YBiUb1lnFl1Me`,
	"flexDiv": `YQKA24reidLO7UybckCb`,
	"iconGroup": `vp5BMOr86jjNIUdSIsO1`,
	"containerNotUploadedInfoText": `QuNFQ89ZfxUK9GWHa5eW`,
	"browseFileUploadForm": `HXzM3idGwVbgNDpwfLKl`,
	"fileNameCard": `oUEWugw7mqrkAW8lHEVS`,
	"body": `KB0Jl7if2iPQZnS7AHvE`,
	"fileNameText": `BC2hVOHqj1DpyNqlNGO9`,
	"browseText": `_avEBMrhSeL5gt8O5DgA`,
	"uploadedFileName": `jGn9aZTogdD4Z608ZfCI`,
	"errorInfoText": `rxrhTQoLKPz24OGCUZlR`,
	"tryAgainText": `ZbBkD5SpXI5irXjy1puc`,
	"fileInfoText": `qBZQkYdCaKiWl8d0k3Oc`
};
export default ___CSS_LOADER_EXPORT___;
