// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s2flZuYzLGO0KFzmmeyY .VhhPZxUbdABhj7y6scp3 {
  width: 100%;
  background: #2755fe;
  border-radius: 2px;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.5rem;
}
.s2flZuYzLGO0KFzmmeyY .zT8IaskOUeyCqO1wSlhP {
  font-family: roboto;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addBtnContainer": `s2flZuYzLGO0KFzmmeyY`,
	"payBtn": `VhhPZxUbdABhj7y6scp3`,
	"rupee": `zT8IaskOUeyCqO1wSlhP`
};
export default ___CSS_LOADER_EXPORT___;
