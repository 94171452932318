// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MFKV6xEmbs9r_I2r9QVU {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  background-color: var(--white);
  font-size: var(--para-font-size);
  font-weight: normal;
  line-height: 45px;
  height: 80px;
  width: 100%;
  color: #4c4c4c;
}
.MFKV6xEmbs9r_I2r9QVU::placeholder {
  color: #c2cfff;
}
@media (min-width: 768px) {
  .MFKV6xEmbs9r_I2r9QVU {
    font-size: var(--label-font-size);
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `MFKV6xEmbs9r_I2r9QVU`
};
export default ___CSS_LOADER_EXPORT___;
