// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tMNcCddiwLv9WcdJnimb {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: -190px auto 190px;
  font-family: Poppins, sans-serif;
  min-height: 375px;
  max-width: 504px;
}
.tMNcCddiwLv9WcdJnimb ._zlEoO_03xI8HYxIM37_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tMNcCddiwLv9WcdJnimb ._zlEoO_03xI8HYxIM37_ .tmhPR1HXnmbo8HY8Bfcw {
  height: 65px;
  width: 65px;
}
.tMNcCddiwLv9WcdJnimb ._zlEoO_03xI8HYxIM37_ .rocqRjdl07c0bOLE_wF6 {
  color: #898989;
}
.tMNcCddiwLv9WcdJnimb ._zlEoO_03xI8HYxIM37_ .asSfFQTyjiJLXvQw9gjg {
  color: #898989;
  margin-top: 30px;
  margin-bottom: 0px;
}
.tMNcCddiwLv9WcdJnimb ._zlEoO_03xI8HYxIM37_ .QAIi7toUzmzUfcZMkjzt {
  font-weight: 600;
  font-size: 24px;
  margin-top: 24px;
  line-height: 32px;
}
.tMNcCddiwLv9WcdJnimb ._zlEoO_03xI8HYxIM37_ .vJLUMf3KFuU7zlsVrRb2 {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
  margin-top: 8px;
  line-height: 32px;
  font-family: roboto;
}
.tMNcCddiwLv9WcdJnimb ._zlEoO_03xI8HYxIM37_ .uEqFEvyNZd75KGBOQF4e button {
  width: auto;
  min-width: 130px;
  font-size: 14px;
  margin-right: 8px;
  margin-top: 56px;
}
.tMNcCddiwLv9WcdJnimb ._zlEoO_03xI8HYxIM37_ .icbbOsvYJA67mJlUWmqh {
  margin-top: 40px;
}
.tMNcCddiwLv9WcdJnimb ._zlEoO_03xI8HYxIM37_ .icbbOsvYJA67mJlUWmqh a {
  color: #2755fe;
  font-weight: normal;
  padding: inherit;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `tMNcCddiwLv9WcdJnimb`,
	"body": `_zlEoO_03xI8HYxIM37_`,
	"image": `tmhPR1HXnmbo8HY8Bfcw`,
	"referenceText": `rocqRjdl07c0bOLE_wF6`,
	"invoiceText": `asSfFQTyjiJLXvQw9gjg`,
	"payment_title": `QAIi7toUzmzUfcZMkjzt`,
	"status_description": `vJLUMf3KFuU7zlsVrRb2`,
	"btnContainer": `uEqFEvyNZd75KGBOQF4e`,
	"help": `icbbOsvYJA67mJlUWmqh`
};
export default ___CSS_LOADER_EXPORT___;
