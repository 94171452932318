// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kvZeZ8QIIALVL0i_94Gl {
  font-family: Poppins, sans-serif;
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .kvZeZ8QIIALVL0i_94Gl {
    width: 1200px;
  }
}
.kvZeZ8QIIALVL0i_94Gl .QeWiYg_yokPMHUMiwAz2 {
  margin-top: -190px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 10px;
}
.kvZeZ8QIIALVL0i_94Gl .tyRTJxejR_wNvcth1w2c {
  display: flex;
}
.kvZeZ8QIIALVL0i_94Gl .tyRTJxejR_wNvcth1w2c .UnYYcoaDgtvphoqzXRgh {
  border-radius: 4px;
  margin-bottom: 6rem;
}
.kvZeZ8QIIALVL0i_94Gl .tyRTJxejR_wNvcth1w2c .NgB3359Pgcc5OzxOG0br {
  margin-top: 20px;
}

.vS54My52FdY_m3p_NFFZ {
  font-family: "Roboto";
}

.tMv13wcMhcw7BMBV1HWQ {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.tMv13wcMhcw7BMBV1HWQ .A8LcqvpoTtVWLE6tsh7I {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.tMv13wcMhcw7BMBV1HWQ .JhhHkW2o6VIrPCKgWrC7 {
  font-family: roboto;
}

.bk0CHKOAmjMtYVbcbnaB {
  border: none;
  padding: 25px 30px 18px !important;
}
.bk0CHKOAmjMtYVbcbnaB .FSSrNq3RoHg8fxCG_9T8 {
  background: #ffffff;
  border: 1px solid #2755fe;
  box-sizing: border-box;
  border-radius: 2px;
  height: 32px;
  color: #2755fe;
}
.bk0CHKOAmjMtYVbcbnaB .okTRkkd25S0_urEopcCf {
  background: #2755fe;
  height: 32px;
  border-radius: 2px;
}

.k16obDcpKdOwXPBFRihS {
  display: flex;
  justify-content: center;
}

.TuZ_nrHe9tT6IdPqgIrO {
  min-height: 20vh;
  width: 40vw;
  margin: auto;
  display: flex;
  margin-top: -4rem;
  justify-content: center;
  align-items: center;
}

.TQF65z68oV16lkFnEsNH {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 1200px;
  left: 50%;
  transform: translate(-50%, 0);
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  height: 72px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buyPackageContainer": `kvZeZ8QIIALVL0i_94Gl`,
	"heading": `QeWiYg_yokPMHUMiwAz2`,
	"cardContainer": `tyRTJxejR_wNvcth1w2c`,
	"leftContainer": `UnYYcoaDgtvphoqzXRgh`,
	"rightContainerTds": `NgB3359Pgcc5OzxOG0br`,
	"amount": `vS54My52FdY_m3p_NFFZ`,
	"body": `tMv13wcMhcw7BMBV1HWQ`,
	"title": `A8LcqvpoTtVWLE6tsh7I`,
	"rupee": `JhhHkW2o6VIrPCKgWrC7`,
	"modalFooter": `bk0CHKOAmjMtYVbcbnaB`,
	"cancelBtn": `FSSrNq3RoHg8fxCG_9T8`,
	"confirmBtn": `okTRkkd25S0_urEopcCf`,
	"loader": `k16obDcpKdOwXPBFRihS`,
	"loaderCard": `TuZ_nrHe9tT6IdPqgIrO`,
	"stepNavigationCard": `TQF65z68oV16lkFnEsNH`
};
export default ___CSS_LOADER_EXPORT___;
