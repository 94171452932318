// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/googleIcon.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/microsoftIcon.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../images/LoginPageFooter.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blH8X5YHIoGqIwcLNjR5 {
  margin: auto;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT {
  box-sizing: border-box;
  display: block;
  margin: 20px 0px;
}
@media only screen and (max-width: 670px) {
  .blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT {
    width: 100%;
  }
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .RdW9gulYCtpSLVDGt6IJ {
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 13px;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .CgHaW2ykhpCAYfuznLt3 {
  display: flex;
  width: 100%;
  margin-bottom: 1.2rem;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT ._GXcRAYW9VX6EkcAKLEy {
  color: white;
  font-size: 1.4rem;
  padding: 0.5rem;
  margin: auto;
  margin-left: 2rem;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .gQMg2ze3IE1xaXfOifOT {
  background-color: #ffffff;
  background-size: 45% 35px;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid #4f86ec;
  width: 80px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .SFQ4h7YNTlZwRvrE_0fl {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  background-color: #ffffff;
  color: #fff;
  width: 100%;
  height: 48px;
  border-radius: 5px;
  gap: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .SFQ4h7YNTlZwRvrE_0fl img {
  height: 35px;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .bthRnzkxbOSPap0FKxdV {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  flex: 1;
  text-align: center;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .ZsBzks8VGPeFUbqLIXmj {
  background-color: #ffffff;
  background-size: 70% 28px;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid #2f2f2f;
  width: 40px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .eNIOvNyej4D4wca1cqWq {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: bold;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .eNIOvNyej4D4wca1cqWq::before,
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .eNIOvNyej4D4wca1cqWq::after {
  content: "";
  flex: 1;
  border-bottom: 2px solid #9eadba;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .eNIOvNyej4D4wca1cqWq:not(:empty)::before {
  margin-right: 0.5em;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .eNIOvNyej4D4wca1cqWq:not(:empty)::after {
  margin-left: 0.5em;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .sVWa5kNfABNVYrc_eJrv {
  margin-bottom: 30px;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .sVWa5kNfABNVYrc_eJrv .zpjkO10zdZ6_uA5v3awd,
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .sVWa5kNfABNVYrc_eJrv .bMSDyxIsl6Y9o0ce_Zpa {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  color: #333333;
  margin: 0;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .sVWa5kNfABNVYrc_eJrv .zpjkO10zdZ6_uA5v3awd {
  font-size: 20px;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .sVWa5kNfABNVYrc_eJrv .bMSDyxIsl6Y9o0ce_Zpa {
  font-size: 36px;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT label {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4c4c4c;
  margin-bottom: 7px;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT ._f8op_4UclIhBd5HFwYu {
  margin-bottom: 13px !important;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT input {
  background: #ffffff;
  border: 1px solid #c2cfff;
  border-radius: 4px;
  height: 44px;
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .xoCe_ocUD6p4jeX5EKFT {
  width: auto;
  background: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #2755fe;
  padding: 0;
  text-align: right;
  margin-bottom: 13px;
  box-shadow: unset;
  height: 20px;
}
@media only screen and (max-width: 670px) {
  .blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .xoCe_ocUD6p4jeX5EKFT {
    width: 100%;
  }
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .MtjYrEQUQfss5Cdcdg5t {
  width: 100%;
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  height: 48px;
}
@media only screen and (max-width: 670px) {
  .blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .MtjYrEQUQfss5Cdcdg5t {
    width: 100%;
  }
}
.blH8X5YHIoGqIwcLNjR5 .hO71gXntXUuGEUPFhXtT .WzYr0oAfAGWUeNVzDosQ {
  margin-top: 1rem;
  font-size: 16px;
  color: #dc3545;
  text-align: left;
}
.blH8X5YHIoGqIwcLNjR5 .a46ncL0FurIP8FoNwd78 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  background-repeat: no-repeat;
  background-size: 100% 310px;
}
.blH8X5YHIoGqIwcLNjR5 .a46ncL0FurIP8FoNwd78 .aaUO6C7AZPhtP3qTqxD8 p {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
}
.blH8X5YHIoGqIwcLNjR5 .a46ncL0FurIP8FoNwd78 .eZoOqfYbXxKMK5nEXJOh {
  padding-top: 124px;
  margin-bottom: 16px;
}
.blH8X5YHIoGqIwcLNjR5 .a46ncL0FurIP8FoNwd78 .Q5ACrjX_IXV9Sj1GcGHg {
  margin-top: 16px;
  padding-bottom: 28px;
}

.KorszluPQmdJaiK9nRh3 {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginWrapper": `blH8X5YHIoGqIwcLNjR5`,
	"loginCard": `hO71gXntXUuGEUPFhXtT`,
	"authContainer": `RdW9gulYCtpSLVDGt6IJ`,
	"googleLoginContainer": `CgHaW2ykhpCAYfuznLt3`,
	"googleLoginText": `_GXcRAYW9VX6EkcAKLEy`,
	"googleLoginIcon": `gQMg2ze3IE1xaXfOifOT`,
	"microsoftLoginContainer": `SFQ4h7YNTlZwRvrE_0fl`,
	"microsoftLoginText": `bthRnzkxbOSPap0FKxdV`,
	"microsoftLoginIcon": `ZsBzks8VGPeFUbqLIXmj`,
	"separator": `eNIOvNyej4D4wca1cqWq`,
	"loginWelcomeNote": `sVWa5kNfABNVYrc_eJrv`,
	"h1x": `zpjkO10zdZ6_uA5v3awd`,
	"h2x": `bMSDyxIsl6Y9o0ce_Zpa`,
	"form-group": `_f8op_4UclIhBd5HFwYu`,
	"forgotPasswordBtn": `xoCe_ocUD6p4jeX5EKFT`,
	"signInBtn": `MtjYrEQUQfss5Cdcdg5t`,
	"errorMsg": `WzYr0oAfAGWUeNVzDosQ`,
	"footer": `a46ncL0FurIP8FoNwd78`,
	"footerContent": `aaUO6C7AZPhtP3qTqxD8`,
	"footerContentMail": `eZoOqfYbXxKMK5nEXJOh`,
	"footerContentAddress": `Q5ACrjX_IXV9Sj1GcGHg`,
	"overlayLoader": `KorszluPQmdJaiK9nRh3`
};
export default ___CSS_LOADER_EXPORT___;
