// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ho2Mj6oOYiSAybiophB3 {
  display: flex;
}
.Ho2Mj6oOYiSAybiophB3 .J7I22c5Lz3wW0m7Pjh2r {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: Poppins, sans-serif;
  color: #333333;
  cursor: pointer;
  padding: 24px 16px 24px 16px;
}
.Ho2Mj6oOYiSAybiophB3 .J7I22c5Lz3wW0m7Pjh2r:first-child {
  padding-left: 24px;
}
.Ho2Mj6oOYiSAybiophB3 .J7I22c5Lz3wW0m7Pjh2r .hzzJpxcnt6GwzknsKk96 {
  color: #2755FE;
  border-bottom: 3px solid #2755FE;
  padding-bottom: 6px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabComponentWrapper": `Ho2Mj6oOYiSAybiophB3`,
	"tabStyles": `J7I22c5Lz3wW0m7Pjh2r`,
	"tabActive": `hzzJpxcnt6GwzknsKk96`
};
export default ___CSS_LOADER_EXPORT___;
