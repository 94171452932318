// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xxLYTdUb9b5HUYraF52o {
  min-height: 80vh;
}
@media only screen and (max-width: 1200px) {
  .xxLYTdUb9b5HUYraF52o {
    width: 1200px;
  }
}
.xxLYTdUb9b5HUYraF52o .YvL3HEBE99gtOozjfqJ2 {
  margin-top: -190px;
  display: flex;
  justify-content: space-between;
}
.xxLYTdUb9b5HUYraF52o .YvL3HEBE99gtOozjfqJ2 .AR8c98kH8ryWTq89IxpD {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.xxLYTdUb9b5HUYraF52o .AdeEveSD6PwVrFk5UF5q {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
  min-height: 406px;
}
.xxLYTdUb9b5HUYraF52o .Yz3ySNVFPNuBTAY63OUq {
  width: 100%;
  height: 100vh;
}
.xxLYTdUb9b5HUYraF52o .mX6aKjqT80H472kZSv8g {
  border: none;
  width: 100%;
  height: 100%;
}
.xxLYTdUb9b5HUYraF52o .TfpgWZXPVqm8YSkFRXtX {
  width: 100%;
  height: 100vh;
}

.jkKeUxT5g23__aQixkyC {
  max-width: 1200px;
  margin: auto;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentDivWrapper": `xxLYTdUb9b5HUYraF52o`,
	"header": `YvL3HEBE99gtOozjfqJ2`,
	"heading": `AR8c98kH8ryWTq89IxpD`,
	"bodyCard": `AdeEveSD6PwVrFk5UF5q`,
	"iframeDiv": `Yz3ySNVFPNuBTAY63OUq`,
	"iframe": `mX6aKjqT80H472kZSv8g`,
	"spinner": `TfpgWZXPVqm8YSkFRXtX`,
	"clientSideDimensions": `jkKeUxT5g23__aQixkyC`
};
export default ___CSS_LOADER_EXPORT___;
