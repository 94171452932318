// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ii10BeWALCyizlWB3zAz {
  width: 100%;
  height: 100px;
  background-size: cover;
  margin: 15px auto 0 auto;
  max-width: 1200px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `ii10BeWALCyizlWB3zAz`
};
export default ___CSS_LOADER_EXPORT___;
