export const CA_ROLES = {
  5: 'Admin',
  10: 'HR'
};

export const completedStatus = [1, 2, -2, 6, '1', '2', '-2', '6', 12, '12'];

export const displayDateReportFormat = 'DD-MMM-YYYY';
export const INPUT_VALUE_DATEFORMAT = 'YYYY-MM-DD';
export const displayMonthAndYearFormat = 'MMM YYYY';

export const WORLDCHECKSOURCE = {
  TransUnion: 'TLOxp Global Watch List Search',
  Refinitiv: 'WORLD-CHECK ONE'
};

export const UPSELL_CHECK_LABELS_FOR_REPORT = {
  CREDIT: `CREDIT CHECK`,
  EDUCATION: `EDUCATION CHECK (REGIONAL/HYBRID)`,
  REFERENCE: `REFERENCE VERIFICATION`,
  RESUME: `RESUME CHECK`,
  SOCIAL: `SOCIAL MEDIA`,
  POSTAL: `POSTAL ADDRESS VERIFICATION`,
  WORLD: `WORLD CHECK`,
  UAN: `UAN`,
  EMP_GAP: `EMPLOYMENT GAP`,
  DRUG: `DRUG TEST`
};

export const UPSELL_CHECKS_INFO_REPORT = {
  CREDIT: `Get informed about addresses associated with candidates in addition to their primary residency.`,
  EDUCATION: `Make informed hiring decisions by confirming your candidate's education details with this highest quality of Education check.`,
  REFERENCE: `Understand your candidates better from the perspective of their ex-managers/peers.`,
  RESUME: `Evaluate if resume details match the actual details of your candidates.`,
  SOCIAL: `Screen their online behaviour to ensure it aligns to your company's values.`,
  POSTAL: `An additional layer of address verification in case for any discrepancy with current mode of verification.`,
  WORLD: `Stay aware of potential threats by Identifying if a candidate is charged with legal or criminal lawsuits.`,
  UAN: `Fetch the service history instantly and get informed of employee moonlighting.`,
  EMP_GAP: `Get the right reasons for gaps in work experiences to ensure it's not a cause of concern for you.`,
  DRUG: `Help your company stay compliant and meet work policy requirements by screening pre/post hires.`
};

export const EMP_DOC_TYPES = {
  SalarySlip: 'Salary Slip',
  ExperienceLetter: 'Experience Letter',
  RelievingLetter: 'Relieving Letter',
  AppointmentLetter: 'Appointment Letter',
  Other: 'Other'
};
export const addressVerificationMediumObject = {
  DIGITAL: 'Digital',
  PHYSICAL: 'Physical',
  POSTAL: 'Postal'
};
export const courtVerificationMediumObject = {
  STANDARD: 'Standard',
  STANDARD_LAWFIRM: 'Standard via Lawfirm'
};

export const educationVerificationMediumObject = {
  OFFICIAL: 'Official',
  REGIONAL_PARTNER: 'Regional Partner',
  HYBRID: 'Hybrid'
};

export const courseType = [
  { label: 'No Education', value: 'NO_EDUCATION' },
  { label: 'Less than Fifth', value: 'LESS_THEN_FIFTH_STD' },
  { label: 'Fifth', value: 'FIFTH_STD' },
  { label: 'Tenth', value: 'TENTH_STD' },
  { label: 'Twelfth', value: 'TWELFTH_STD' },
  { label: 'Diploma', value: 'DIPLOMA' },
  { label: 'Graduate', value: 'GRADUATE' },
  { label: 'Masters', value: 'MASTERS' },
  { label: 'PhD', value: 'PHD' },
  { label: 'Postdoctoral', value: 'POST_DOC' },
  { label: 'Post Graduate Diploma', value: 'POST_GRADUATE_DIPLOMA' },
  { label: 'Other', value: 'OTHER' },
  { label: 'University', value: 'UNIVERSITY' },
  { label: 'High School', value: 'HIGH_SCHOOL' },
  { label: 'Professional Course', value: 'PROFESSIONAL_COURSE' },
  { label: 'Post Graduation', value: 'POST_GRADUATION' }
];

export const envMapping = {
  'production': 'production',
  'acceptance': 'acceptance',
  'acceptance-2': 'acc-2',
  'development': 'dev',
  'development-2': 'dev-2',
  'development-3': 'dev-3',
  'localhost': 'dev',
  'local': 'dev',
  'prod': 'production',
  'accept': 'acceptance',
  'accept-2': 'acc-2',
  'dev': 'dev',
  'dev-2': 'dev-2',
  'dev-3': 'dev-3',
};

export const trackerInfo = {
  'production': '66793f76e4a3e25e3d374856',
  'acceptance': '66793f459d2d8a6d3e71eb3c',
  'acc-2': '66793f5ca5c32602e8e2e0f7',
  'dev': '667179f686b2b1301eecf23e',
  'dev-2': '66793eeef546a03abab219fb',
  'dev-3': '66793f39b835f82fef8db84b',
};