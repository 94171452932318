// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eVmtt2T_yLe3P9Jf6Wnj {
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
}

.RZ3Np04U_AAFrGbxACSg {
  min-height: 80vh;
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .RZ3Np04U_AAFrGbxACSg {
    width: 1200px;
  }
}
.RZ3Np04U_AAFrGbxACSg .sUrT8xrfUzrRsOcgxl6g {
  margin-top: -190px;
  display: flex;
  justify-content: space-between;
}
.RZ3Np04U_AAFrGbxACSg .sUrT8xrfUzrRsOcgxl6g .aldFzVEfdBJew7nImuHW {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.RZ3Np04U_AAFrGbxACSg .CQKZYc37AqJQzroBE8Mh {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
  min-height: 406px;
}
.RZ3Np04U_AAFrGbxACSg .CQKZYc37AqJQzroBE8Mh .m09x6COe49O8V_N9bIYz {
  margin: 55px 0px 15px 0px;
}
.RZ3Np04U_AAFrGbxACSg .CQKZYc37AqJQzroBE8Mh .m09x6COe49O8V_N9bIYz .VlncnWlU0bQHJ8hd8XUA {
  position: absolute;
  left: 0;
}
.RZ3Np04U_AAFrGbxACSg .CQKZYc37AqJQzroBE8Mh .m09x6COe49O8V_N9bIYz .HXqNZnlhRfDt6_ERLBHK {
  width: 80%;
  height: 75%;
  font-size: 15px;
  margin: 6px;
  position: absolute;
  right: -5px;
}
.RZ3Np04U_AAFrGbxACSg .CQKZYc37AqJQzroBE8Mh .m09x6COe49O8V_N9bIYz .N8RENCldgZq6lDQXdxEq {
  font-size: 14px;
  font-weight: 700;
}
.RZ3Np04U_AAFrGbxACSg .CQKZYc37AqJQzroBE8Mh .ShUQhNXAeWSHWSSkVRAs {
  width: 100%;
}

.avTjyBlwHhh_P_BfayOy {
  display: flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
}
.avTjyBlwHhh_P_BfayOy .S9scFJiL8jADVZPMvRjs {
  color: #2755fe;
  padding: 24px 16px;
}
.avTjyBlwHhh_P_BfayOy .S9scFJiL8jADVZPMvRjs .feerh9ZlE6uHWNtDh4D3 {
  border-bottom: 3px solid #2755fe;
}
.avTjyBlwHhh_P_BfayOy .S9scFJiL8jADVZPMvRjs:first-child {
  padding-left: 24px;
}
.avTjyBlwHhh_P_BfayOy .k0z7tPRUuvASl6jOUgR6 {
  padding: 24px 16px;
}
.avTjyBlwHhh_P_BfayOy .k0z7tPRUuvASl6jOUgR6:first-child {
  padding-left: 24px;
}
.avTjyBlwHhh_P_BfayOy .feerh9ZlE6uHWNtDh4D3 {
  padding-bottom: 6px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amount": `eVmtt2T_yLe3P9Jf6Wnj`,
	"parentDivWrapper": `RZ3Np04U_AAFrGbxACSg`,
	"header": `sUrT8xrfUzrRsOcgxl6g`,
	"heading": `aldFzVEfdBJew7nImuHW`,
	"bodyCard": `CQKZYc37AqJQzroBE8Mh`,
	"topRow": `m09x6COe49O8V_N9bIYz`,
	"avaliableBalanceBox": `VlncnWlU0bQHJ8hd8XUA`,
	"addCreditsBtnStyle": `HXqNZnlhRfDt6_ERLBHK`,
	"availableBalanceTextStyle": `N8RENCldgZq6lDQXdxEq`,
	"tableCard": `ShUQhNXAeWSHWSSkVRAs`,
	"tabStyle": `avTjyBlwHhh_P_BfayOy`,
	"tabItemActive": `S9scFJiL8jADVZPMvRjs`,
	"tabInnerElement": `feerh9ZlE6uHWNtDh4D3`,
	"tabItem": `k0z7tPRUuvASl6jOUgR6`
};
export default ___CSS_LOADER_EXPORT___;
