// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nrc8OJjU7GuNnhhLy0RR {
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol {
  border: none;
  cursor: pointer;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol ._dIj0QvPQvpTbeMt82mk {
  display: flex;
  background: #ffffff;
  border: none;
  padding-left: 0;
  padding-right: 0;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol ._dIj0QvPQvpTbeMt82mk .QHkx3petyZjZRdddQaSl {
  position: relative;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol ._dIj0QvPQvpTbeMt82mk .QHkx3petyZjZRdddQaSl .UenzwR34qBLExwCjk5jg {
  position: absolute;
  top: -6px;
  left: 11px;
  font-size: 12px;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol ._dIj0QvPQvpTbeMt82mk .g5AMpEy8N9o8RNn0Qt6T {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 8px;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol ._dIj0QvPQvpTbeMt82mk .g5AMpEy8N9o8RNn0Qt6T .OFIH1X3qKTiYla_RUn10 {
  display: flex;
  text-align: right;
  font-weight: normal;
  font-size: 14px;
  color: #666666;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol ._dIj0QvPQvpTbeMt82mk .g5AMpEy8N9o8RNn0Qt6T .OFIH1X3qKTiYla_RUn10 .LAMEYWo8LMVLtqPuGfwK {
  font-family: roboto;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol ._dIj0QvPQvpTbeMt82mk .g5AMpEy8N9o8RNn0Qt6T .OFIH1X3qKTiYla_RUn10 .k7ba2QUHWASSKruqbaj1 {
  margin-right: 8px;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol .N8JSjJNvvkvHgs3l0TmN {
  padding-left: 0;
  padding-right: 0;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol .N8JSjJNvvkvHgs3l0TmN .poJuGcwgbUaC6akk9LEC {
  margin-left: 30px;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol .N8JSjJNvvkvHgs3l0TmN .poJuGcwgbUaC6akk9LEC .qI0rNf5dX30_yhUpzxzm {
  font-size: 15px;
  color: #aaaaaa;
  margin-top: 12px;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol .N8JSjJNvvkvHgs3l0TmN .poJuGcwgbUaC6akk9LEC .LOX6rD50SZ7_M4ShNwUt {
  border: none;
  padding: 0;
  display: flex;
  align-items: baseline;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol .N8JSjJNvvkvHgs3l0TmN .poJuGcwgbUaC6akk9LEC .LOX6rD50SZ7_M4ShNwUt .JReLHtWnftqEVIzqy4VU,
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol .N8JSjJNvvkvHgs3l0TmN .poJuGcwgbUaC6akk9LEC .LOX6rD50SZ7_M4ShNwUt .GBaQ5D61skBkZtEVEMC_ {
  font-weight: normal;
  font-size: 14px;
  color: #595959;
  margin-bottom: 12px;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol .N8JSjJNvvkvHgs3l0TmN .poJuGcwgbUaC6akk9LEC .LOX6rD50SZ7_M4ShNwUt .j2l2emnZD6ZneYlFEmcs {
  margin-bottom: 0;
}
.Nrc8OJjU7GuNnhhLy0RR .nS_XEPhJdndOOnjFhMol .N8JSjJNvvkvHgs3l0TmN .poJuGcwgbUaC6akk9LEC .LOX6rD50SZ7_M4ShNwUt .mTfBMywd_e1GHGs2zAJr {
  margin-left: 18px;
}

.E_g8GZvL7ANCXfHER98s label {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #595959 !important;
}

.j2l2emnZD6ZneYlFEmcs {
  display: flex;
  gap: 20px;
}

.x39_oeVNguF5ItKnCwmj {
  color: #db3131;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addonsContainer": `Nrc8OJjU7GuNnhhLy0RR`,
	"addonsContainerCard": `nS_XEPhJdndOOnjFhMol`,
	"addonsContainerHeader": `_dIj0QvPQvpTbeMt82mk`,
	"iconContainer": `QHkx3petyZjZRdddQaSl`,
	"plusIcon": `UenzwR34qBLExwCjk5jg`,
	"headingRightContainer": `g5AMpEy8N9o8RNn0Qt6T`,
	"headingRight": `OFIH1X3qKTiYla_RUn10`,
	"rupee": `LAMEYWo8LMVLtqPuGfwK`,
	"startFrom": `k7ba2QUHWASSKruqbaj1`,
	"cardBody": `N8JSjJNvvkvHgs3l0TmN`,
	"listGroup": `poJuGcwgbUaC6akk9LEC`,
	"headingDiv": `qI0rNf5dX30_yhUpzxzm`,
	"listGroupItem": `LOX6rD50SZ7_M4ShNwUt`,
	"otherText": `JReLHtWnftqEVIzqy4VU`,
	"label": `GBaQ5D61skBkZtEVEMC_`,
	"formGroup": `j2l2emnZD6ZneYlFEmcs`,
	"listGroupInside": `mTfBMywd_e1GHGs2zAJr`,
	"formCheck": `E_g8GZvL7ANCXfHER98s`,
	"required": `x39_oeVNguF5ItKnCwmj`
};
export default ___CSS_LOADER_EXPORT___;
