// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WQqOVIBC7bRc_kknhKND {
  font-weight: 500;
  font-family: Poppins;
  font-size: 20px !important;
  color: #333333;
}

.DHeBWeM1mv8vxBCidUft,
.gZd02q7r_Kki4Fy88ZCE {
  padding: 24px !important;
}

.GCgKcnlkdm0A_yE6urxi {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  margin-bottom: 24px;
}

.DtaN7m7GJlR3Mx0BoSd8 {
  padding: 0 24px !important;
}

.CHXlsd4nWUOUGQ4aZ1Lb {
  font-weight: 500;
  font-family: Poppins;
  font-size: 14px !important;
  color: #333333;
}

.FLcoSrf5F3tGXEXhkJl4 {
  width: 550px;
}

.aYUXvXfVMfVy42EtZWwC,
.zV3knyNgpXwazUAbw4tb {
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 136px;
  height: 48px;
}

.aYUXvXfVMfVy42EtZWwC {
  background: #2755fe;
  color: #ffffff;
  margin-right: 0px;
}
.aYUXvXfVMfVy42EtZWwC:hover {
  background: #2755fe;
  color: #ffffff;
}
.aYUXvXfVMfVy42EtZWwC:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.zV3knyNgpXwazUAbw4tb {
  background: #ffffff;
  border: 1px solid #2755fe;
  color: #2755fe;
  margin-right: 16px !important;
  height: 48px;
}
.zV3knyNgpXwazUAbw4tb:hover {
  background: #ffffff;
  border: 1px solid #2755fe;
  color: #2755fe;
}

.CKbO8MUfQGpDppXtdPLm {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 100001;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitle": `WQqOVIBC7bRc_kknhKND`,
	"modalHeader": `DHeBWeM1mv8vxBCidUft`,
	"modalFooter": `gZd02q7r_Kki4Fy88ZCE`,
	"content": `GCgKcnlkdm0A_yE6urxi`,
	"modalBody": `DtaN7m7GJlR3Mx0BoSd8`,
	"confirmHeader": `CHXlsd4nWUOUGQ4aZ1Lb`,
	"CPmodal": `FLcoSrf5F3tGXEXhkJl4`,
	"submitButton": `aYUXvXfVMfVy42EtZWwC`,
	"cancelButton": `zV3knyNgpXwazUAbw4tb`,
	"overlayLoader": `CKbO8MUfQGpDppXtdPLm`
};
export default ___CSS_LOADER_EXPORT___;
