// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/angleDownWhite.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tdEubeMBP09ZoCzXH5UY {
  width: 207px;
  height: 44px;
}

.JTacxc9WSJeuM3S87e0k {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background-color: #2755FE;
  border: none;
  padding: 0 8px 0 16px;
}
.JTacxc9WSJeuM3S87e0k:focus, .JTacxc9WSJeuM3S87e0k:active, .JTacxc9WSJeuM3S87e0k:hover {
  box-shadow: none !important;
  outline: none;
  background-color: #1F44CA !important;
}
.JTacxc9WSJeuM3S87e0k:focus + .oZ7F8wqH4pIk9t3TVSQY, .JTacxc9WSJeuM3S87e0k:active + .oZ7F8wqH4pIk9t3TVSQY, .JTacxc9WSJeuM3S87e0k:hover + .oZ7F8wqH4pIk9t3TVSQY {
  background-color: #1F44CA !important;
}

.oZ7F8wqH4pIk9t3TVSQY {
  background-color: #2755FE !important;
  border: none;
  position: relative;
  padding: 0 8px;
  display: flex;
  align-items: center;
}
.oZ7F8wqH4pIk9t3TVSQY::after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 2px;
}
.oZ7F8wqH4pIk9t3TVSQY::before {
  width: 0.5px;
  height: 24px;
  top: 10px;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  left: 1px;
  content: "";
}
.oZ7F8wqH4pIk9t3TVSQY:focus, .oZ7F8wqH4pIk9t3TVSQY:hover, .oZ7F8wqH4pIk9t3TVSQY:active {
  box-shadow: none !important;
  outline: none;
  background-color: #2755FE !important;
}
.oZ7F8wqH4pIk9t3TVSQY:focus::after, .oZ7F8wqH4pIk9t3TVSQY:hover::after, .oZ7F8wqH4pIk9t3TVSQY:active::after {
  background-color: rgba(255, 255, 255, 0.1);
}

.kbnnOpAN3bMlXUupynLZ {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 16.5px;
  top: -1.5px;
  display: block;
  border-radius: 100%;
  left: 21px;
  top: 9px;
  background: #FFAA00;
}

.akHU8qnFAGLe4jTUS0CQ {
  width: 207px;
  height: 44px;
  border-radius: 2px;
  border: none;
  display: flex !important;
  align-items: center;
  margin-top: 2px !important;
  box-shadow: 4px 4px 12px rgba(51, 51, 51, 0.1);
  padding: 0.1px;
}
.akHU8qnFAGLe4jTUS0CQ a {
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  border: 1px solid transparent;
  height: 100%;
  border-radius: 2px;
}
.akHU8qnFAGLe4jTUS0CQ a:hover {
  background: transparent;
  border-color: #2755FE;
  color: #2755FE;
}

.Wk2xaTvo70zZGe9YuzV4 {
  width: 154px;
  height: 43px;
}
.Wk2xaTvo70zZGe9YuzV4 div {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0;
  left: 30px;
  position: relative;
  max-width: 100%;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"splitButton": `tdEubeMBP09ZoCzXH5UY`,
	"addCandidatePrimaryBtn": `JTacxc9WSJeuM3S87e0k`,
	"addCandidateDropdownBtn": `oZ7F8wqH4pIk9t3TVSQY`,
	"indicator": `kbnnOpAN3bMlXUupynLZ`,
	"dropdownMenu": `akHU8qnFAGLe4jTUS0CQ`,
	"ACTooltip": `Wk2xaTvo70zZGe9YuzV4`
};
export default ___CSS_LOADER_EXPORT___;
