// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YUDC6DpEacNrrc6NEYhh {
  padding: 32px;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
@media screen and (max-width: 601px) {
  .YUDC6DpEacNrrc6NEYhh {
    padding: 16px;
    box-shadow: none;
    border: none;
    padding-bottom: 65px !important;
  }
}
.YUDC6DpEacNrrc6NEYhh .HVqEXAmiDTeL0LORmjzQ {
  padding: 0;
}
.YUDC6DpEacNrrc6NEYhh .y4hbaGIJDqebVy7ltVi8,
.YUDC6DpEacNrrc6NEYhh .Gs3dU9BfG7AhtzslD_Lj {
  display: flex;
  align-items: center;
}
.YUDC6DpEacNrrc6NEYhh .Gs3dU9BfG7AhtzslD_Lj {
  margin-bottom: 10px;
}
.YUDC6DpEacNrrc6NEYhh .f1SCRX75vFYFrgn2l1Ik {
  margin-right: 20px;
  color: #8c8c8c;
}
.YUDC6DpEacNrrc6NEYhh .g89GYkmzDP0De12gee60 {
  margin-left: 20px;
  color: #8c8c8c;
}
.YUDC6DpEacNrrc6NEYhh .Bb4vFD0ZiWb2QHir7HCq {
  color: #262626;
  font-size: 24px;
}
.YUDC6DpEacNrrc6NEYhh .K38BMFQxRz9ksV0W7EGU {
  color: #999999;
  font-style: italic;
  margin-top: 15px;
}
.YUDC6DpEacNrrc6NEYhh form {
  margin-top: 32px;
}
.YUDC6DpEacNrrc6NEYhh form label {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #333333;
  margin-bottom: 6px;
}
.YUDC6DpEacNrrc6NEYhh form label span {
  color: #f5222d;
}
.YUDC6DpEacNrrc6NEYhh form .vpodRLMcI5m1fzAgeziw {
  margin-top: 90px !important;
}
.YUDC6DpEacNrrc6NEYhh form .vpodRLMcI5m1fzAgeziw button {
  border-radius: 2px;
  padding: 0.3em 1.4rem;
}
.YUDC6DpEacNrrc6NEYhh form .vpodRLMcI5m1fzAgeziw .pajXb4SKSfifd97vmPAz {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.YUDC6DpEacNrrc6NEYhh form .vpodRLMcI5m1fzAgeziw .zSCvpIcvscmg3sZa6ALE {
  background: #f5f5f5;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
.YUDC6DpEacNrrc6NEYhh form .K6NWsH0yN5hMtnU5VoI7 {
  font-size: 14px;
  font-weight: 600;
  color: #f5222d;
}
.YUDC6DpEacNrrc6NEYhh form .mljmCt5Dnb92KPo1uQBm {
  margin-bottom: 0 !important;
}
.YUDC6DpEacNrrc6NEYhh form .mljmCt5Dnb92KPo1uQBm input {
  border: 1px solid #f5222d !important;
  margin-bottom: 0 !important;
}
.YUDC6DpEacNrrc6NEYhh .aqedvFkkaZpFPbdpCzYy {
  margin-left: 1.3rem;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 993px) {
  .YUDC6DpEacNrrc6NEYhh .aqedvFkkaZpFPbdpCzYy {
    display: block !important;
  }
}
@media screen and (max-width: 601px) {
  .YUDC6DpEacNrrc6NEYhh .K38BMFQxRz9ksV0W7EGU {
    display: none;
  }
  .YUDC6DpEacNrrc6NEYhh .Bb4vFD0ZiWb2QHir7HCq {
    font-size: 20px;
  }
  .YUDC6DpEacNrrc6NEYhh .vpodRLMcI5m1fzAgeziw {
    background: #fff;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    position: fixed;
    padding: 12px 16px;
    bottom: 0;
    right: 0;
    width: 100%;
    margin: 0 !important;
  }
  .YUDC6DpEacNrrc6NEYhh .vpodRLMcI5m1fzAgeziw button {
    width: 100%;
    height: 40px;
  }
  .YUDC6DpEacNrrc6NEYhh .vpodRLMcI5m1fzAgeziw .b9HdmwZpTMz19vK_fogX {
    padding: 0 !important;
  }
}

.vHUSqOeWF0bOhxomou9C:disabled {
  border-color: #e9ecef !important;
}

.SsDJsvfHJSYNtJNgvLlg {
  margin-left: 2px;
  margin-top: 4px;
  display: flex;
}
.SsDJsvfHJSYNtJNgvLlg .OwfTC_f29gpo03yooN5j {
  border: 2px solid;
  border-color: #ff0000;
}
.SsDJsvfHJSYNtJNgvLlg .AgGiJP4K5Dp4qnTcFsOM {
  align-self: center;
  color: #333333;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
}

.RloUua2WFxZc7MAjo2IT {
  margin-bottom: 0 !important;
}

.BuuwZynojwCI3V1kGC4q {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  background-color: var(--white);
  font-size: var(--para-font-size);
  font-weight: normal;
  line-height: 45px;
  height: 38px;
  width: 100%;
  color: #4c4c4c;
  padding: 0 12px;
}
.BuuwZynojwCI3V1kGC4q::placeholder {
  color: red;
}
.BuuwZynojwCI3V1kGC4q input:disabled {
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  color: -internal-light-dark-color(rgb(84, 84, 84), rgb(170, 170, 170));
}
.BuuwZynojwCI3V1kGC4q input:disabled:hover {
  cursor: not-allowed;
}

.U05pZ8g9oQKaa5ObSlOt {
  font-size: 14px;
  color: #dc3545;
  margin-top: 0.25rem;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"basicDetailsCard": `YUDC6DpEacNrrc6NEYhh`,
	"basicDetailsCardBody": `HVqEXAmiDTeL0LORmjzQ`,
	"basicDetailProgressContainer": `y4hbaGIJDqebVy7ltVi8`,
	"basicDetailProgressContainerMobile": `Gs3dU9BfG7AhtzslD_Lj`,
	"stepText": `f1SCRX75vFYFrgn2l1Ik`,
	"stepTextMobile": `g89GYkmzDP0De12gee60`,
	"basicDetailHeading": `Bb4vFD0ZiWb2QHir7HCq`,
	"basicDetailInfo": `K38BMFQxRz9ksV0W7EGU`,
	"basicDetailSubmitBtn": `vpodRLMcI5m1fzAgeziw`,
	"activeBtn": `pajXb4SKSfifd97vmPAz`,
	"disabledBtn": `zSCvpIcvscmg3sZa6ALE`,
	"emailErrorMsg": `K6NWsH0yN5hMtnU5VoI7`,
	"emailError": `mljmCt5Dnb92KPo1uQBm`,
	"sex": `aqedvFkkaZpFPbdpCzYy`,
	"mobileBtnContainer": `b9HdmwZpTMz19vK_fogX`,
	"uanInput": `vHUSqOeWF0bOhxomou9C`,
	"uanCheckBoxContainer": `SsDJsvfHJSYNtJNgvLlg`,
	"checkBoxField": `OwfTC_f29gpo03yooN5j`,
	"uanText": `AgGiJP4K5Dp4qnTcFsOM`,
	"noBottomMargin": `RloUua2WFxZc7MAjo2IT`,
	"datePicker": `BuuwZynojwCI3V1kGC4q`,
	"dobErrorMsg": `U05pZ8g9oQKaa5ObSlOt`
};
export default ___CSS_LOADER_EXPORT___;
