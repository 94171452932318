// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xFT95Vo5TqZ_Lq7tlqDQ {
  width: 100vw;
  min-height: 100vh;
  min-width: 410px;
  overflow-y: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (max-width: 670px) {
  .xFT95Vo5TqZ_Lq7tlqDQ {
    min-width: auto;
    width: 100%;
    overflow: visible;
  }
}
.xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz {
  display: flex;
  width: 100%;
  top: 0;
  padding: 50px;
  box-sizing: border-box;
  height: 100px;
  flex-shrink: 0;
}
.xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz .n2vX4WWnB701lA39br8j {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz .n2vX4WWnB701lA39br8j ._CdYM24tcUq37EjFpxyD {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz .n2vX4WWnB701lA39br8j ._CdYM24tcUq37EjFpxyD .iyh0rCaykM9sCDPmrgj8 {
  display: none;
}
.xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz .n2vX4WWnB701lA39br8j ._CdYM24tcUq37EjFpxyD .AXeFiDlC0Fl9_5pvHoVZ {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  color: rgb(50, 50, 50);
  font-weight: 400;
}
.xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz .n2vX4WWnB701lA39br8j ._CdYM24tcUq37EjFpxyD .AXeFiDlC0Fl9_5pvHoVZ:hover {
  color: #1a6fc3;
}
.xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz .n2vX4WWnB701lA39br8j ._CdYM24tcUq37EjFpxyD .AXeFiDlC0Fl9_5pvHoVZ img {
  width: 20px;
  height: 20px;
}
.xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz .n2vX4WWnB701lA39br8j ._CdYM24tcUq37EjFpxyD .AXeFiDlC0Fl9_5pvHoVZ .UT7zjj8B8oWMzuJpWj7x {
  appearance: none;
  background: #1a6fc3;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 7px 20px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #1a6fc3;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  font-weight: 400;
}
.xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz .n2vX4WWnB701lA39br8j ._CdYM24tcUq37EjFpxyD .AXeFiDlC0Fl9_5pvHoVZ .UT7zjj8B8oWMzuJpWj7x:hover {
  color: #1a6fc3;
  background: #ffffff;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb {
  position: relative;
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 70px;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .piADpZjreYqyHamrwQiP {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .piADpZjreYqyHamrwQiP .RsG9TURjHGjNEM3_kiCg {
  width: 480px;
  background: #ffffff;
  box-shadow: 0 24px 64px rgba(38, 33, 74, 0.168627451);
  border-radius: 12px;
  padding: 30px 60px;
  position: relative;
  z-index: 1;
  min-height: 300px;
  margin-bottom: 80px;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .bYxpKVbOU2ivkeBdN4Fr {
  background: linear-gradient(156deg, #24bbb1 35.43%, #1a6fc3 86.99%);
  position: absolute;
  height: calc(100% - 22vh);
  width: 100%;
  top: 22vh;
  left: 0;
  overflow: hidden;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .bYxpKVbOU2ivkeBdN4Fr .ZIgKtlZbfhV4j8AFZsfV {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  bottom: 20px;
  padding: 0 120px;
  display: flex;
  justify-content: space-between;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 .mepv04v2wWybARdJxtUg {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 .mepv04v2wWybARdJxtUg a {
  color: #ffffff;
  font-weight: 400;
  text-decoration: none;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 .mepv04v2wWybARdJxtUg a:hover {
  color: #ffffff;
  text-decoration: none;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 .mepv04v2wWybARdJxtUg a:visited {
  color: #ffffff;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 .mepv04v2wWybARdJxtUg::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.5450980392);
  bottom: -3px;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 .mepv04v2wWybARdJxtUg:hover::before {
  transform: scaleX(1);
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 .ecrDQ4c_NC0T0fJnBTex {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.4;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 .FuDvaMnBPY3_UXbDxv_N {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 .FuDvaMnBPY3_UXbDxv_N .QKv4XDLrfv1KBU4XlCkU {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3px;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 .FuDvaMnBPY3_UXbDxv_N .QKv4XDLrfv1KBU4XlCkU .swEMBqAftFjD1xt1rcOw {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;
  margin-top: 10px;
}
.xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .Lotmck4s_vMyxNC4y8w6 .FuDvaMnBPY3_UXbDxv_N .QKv4XDLrfv1KBU4XlCkU .swEMBqAftFjD1xt1rcOw img {
  width: 58px;
  height: 58px;
  opacity: 95%;
}

@media (max-width: 810px) {
  .xFT95Vo5TqZ_Lq7tlqDQ .EHJzmrlwOBHI3LGenFCb .piADpZjreYqyHamrwQiP .QxtHIdXvwrhbf_p7LJ_A {
    width: 345px !important;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ .QxtHIdXvwrhbf_p7LJ_A {
    padding: 30px !important;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ ._CdYM24tcUq37EjFpxyD {
    justify-content: flex-end;
    gap: 0;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ ._CdYM24tcUq37EjFpxyD .BvOJKpcCoCIy__VG2TGB {
    display: none !important;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ ._CdYM24tcUq37EjFpxyD .fiXUBLpjN3yrcwP2llHG {
    display: inline-block !important;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ ._CdYM24tcUq37EjFpxyD .UT7zjj8B8oWMzuJpWj7x {
    display: inline-block;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ ._CdYM24tcUq37EjFpxyD .iyh0rCaykM9sCDPmrgj8 {
    position: absolute;
    top: 80px;
    right: 50px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 2;
    display: flex !important;
    flex-direction: column;
    padding: 5px;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ ._CdYM24tcUq37EjFpxyD .iyh0rCaykM9sCDPmrgj8 .AXeFiDlC0Fl9_5pvHoVZ {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    color: rgb(50, 50, 50);
    font-weight: 400;
    padding: 10px 15px;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ ._CdYM24tcUq37EjFpxyD .iyh0rCaykM9sCDPmrgj8 .AXeFiDlC0Fl9_5pvHoVZ:hover {
    background-color: rgba(139, 139, 139, 0.1882352941);
  }
  .xFT95Vo5TqZ_Lq7tlqDQ ._CdYM24tcUq37EjFpxyD .iyh0rCaykM9sCDPmrgj8 .AXeFiDlC0Fl9_5pvHoVZ img {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 850px) {
  .xFT95Vo5TqZ_Lq7tlqDQ .bYxpKVbOU2ivkeBdN4Fr {
    height: calc(100% - 10vh) !important;
  }
}
@media screen and (max-width: 850px) and (max-width: 670px) {
  .xFT95Vo5TqZ_Lq7tlqDQ .bYxpKVbOU2ivkeBdN4Fr {
    height: 100% !important;
  }
}
@media screen and (max-width: 850px) and (max-height: 720px) {
  .xFT95Vo5TqZ_Lq7tlqDQ .bYxpKVbOU2ivkeBdN4Fr {
    height: 110% !important;
  }
}
@media screen and (max-width: 850px) and (max-height: 350px) {
  .xFT95Vo5TqZ_Lq7tlqDQ .bYxpKVbOU2ivkeBdN4Fr {
    height: 115% !important;
  }
}
@media screen and (max-width: 850px) {
  .xFT95Vo5TqZ_Lq7tlqDQ .Lotmck4s_vMyxNC4y8w6 {
    flex-direction: column;
    bottom: -100px !important;
  }
}
@media screen and (max-width: 850px) and (max-width: 670px) {
  .xFT95Vo5TqZ_Lq7tlqDQ .Lotmck4s_vMyxNC4y8w6 {
    bottom: -158px !important;
  }
}
@media screen and (max-width: 850px) and (max-width: 380px) {
  .xFT95Vo5TqZ_Lq7tlqDQ .Lotmck4s_vMyxNC4y8w6 {
    bottom: -140px !important;
  }
}
@media screen and (max-width: 850px) {
  .xFT95Vo5TqZ_Lq7tlqDQ .Lotmck4s_vMyxNC4y8w6 .ecrDQ4c_NC0T0fJnBTex {
    text-align: center;
    align-items: center !important;
    justify-content: center;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ .Lotmck4s_vMyxNC4y8w6 .FuDvaMnBPY3_UXbDxv_N {
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ .Lotmck4s_vMyxNC4y8w6 .FuDvaMnBPY3_UXbDxv_N .QKv4XDLrfv1KBU4XlCkU {
    align-items: center !important;
    margin-top: 10px;
  }
}
@media screen and (max-width: 500px) {
  .xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz {
    padding: 10px 20px !important;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz .n2vX4WWnB701lA39br8j ._CdYM24tcUq37EjFpxyD .AXeFiDlC0Fl9_5pvHoVZ {
    font-size: 13px;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ .CehGIFruIexY10M1ySvz .iyh0rCaykM9sCDPmrgj8 {
    right: 20px;
  }
  .xFT95Vo5TqZ_Lq7tlqDQ .Lotmck4s_vMyxNC4y8w6 {
    padding: 0 20px !important;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginPage-xxx": `xFT95Vo5TqZ_Lq7tlqDQ`,
	"loginHeader": `CehGIFruIexY10M1ySvz`,
	"loginHeaderLogo": `n2vX4WWnB701lA39br8j`,
	"loginHeaderOptions": `_CdYM24tcUq37EjFpxyD`,
	"dropdown": `iyh0rCaykM9sCDPmrgj8`,
	"option-item": `AXeFiDlC0Fl9_5pvHoVZ`,
	"getStartedBtn": `UT7zjj8B8oWMzuJpWj7x`,
	"loginContainer": `EHJzmrlwOBHI3LGenFCb`,
	"loginModal": `piADpZjreYqyHamrwQiP`,
	"container-xxx": `RsG9TURjHGjNEM3_kiCg`,
	"footerContainer": `bYxpKVbOU2ivkeBdN4Fr`,
	"svgOverlay": `ZIgKtlZbfhV4j8AFZsfV`,
	"footerContent": `Lotmck4s_vMyxNC4y8w6`,
	"valuttext": `mepv04v2wWybARdJxtUg`,
	"left": `ecrDQ4c_NC0T0fJnBTex`,
	"right": `FuDvaMnBPY3_UXbDxv_N`,
	"bgvFooterItemCopyright": `QKv4XDLrfv1KBU4XlCkU`,
	"logosx": `swEMBqAftFjD1xt1rcOw`,
	"container": `QxtHIdXvwrhbf_p7LJ_A`,
	"mobhide": `BvOJKpcCoCIy__VG2TGB`,
	"mobshow": `fiXUBLpjN3yrcwP2llHG`
};
export default ___CSS_LOADER_EXPORT___;
