// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dICOjrrGw31Q1_5q8Cjs {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;
}

.kNF33xCJczhbgjGlw5zw {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
  margin-bottom: -10px;
}

.MZuOhQipNh2aDJQUGLzP label {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  margin-bottom: 6px;
}
.MZuOhQipNh2aDJQUGLzP label span {
  color: red;
}
.MZuOhQipNh2aDJQUGLzP .Hx9msGqlOsAoeNpUm_cl {
  justify-content: space-between !important;
}
.MZuOhQipNh2aDJQUGLzP .nHj5DH0PjnKM_85iCJKy {
  margin-top: 20px;
}
@media screen and (max-width: 601px) {
  .MZuOhQipNh2aDJQUGLzP .nHj5DH0PjnKM_85iCJKy {
    margin-top: 20px;
  }
}
.MZuOhQipNh2aDJQUGLzP .pkUm8NEjogmGoCCN7nx9 {
  margin-top: 20px;
}
.MZuOhQipNh2aDJQUGLzP .eXgYMP1GHLUHMyGmDHII {
  justify-content: flex-end !important;
}
@media screen and (max-width: 601px) {
  .MZuOhQipNh2aDJQUGLzP .eXgYMP1GHLUHMyGmDHII {
    position: fixed;
    left: 0;
    margin: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
  }
  .MZuOhQipNh2aDJQUGLzP .eXgYMP1GHLUHMyGmDHII button {
    height: 40px;
    margin: 0 !important;
  }
  .MZuOhQipNh2aDJQUGLzP .eXgYMP1GHLUHMyGmDHII .hrJBSpYJPPOs_Wplo9oD {
    flex: 1;
  }
  .MZuOhQipNh2aDJQUGLzP .eXgYMP1GHLUHMyGmDHII .YlPYg2yQABkbqP2_S7gv {
    flex: 1;
  }
}
.MZuOhQipNh2aDJQUGLzP .WRBqlNfefhzuhif4uAT2 {
  margin-bottom: 32px;
}
.MZuOhQipNh2aDJQUGLzP .CSi01KrNAKPkbzNOQoyj {
  margin-left: 2px;
  margin-top: 4px;
  display: flex;
}
.MZuOhQipNh2aDJQUGLzP .CSi01KrNAKPkbzNOQoyj .m5fZYMIjTwtLFQiY4ALR {
  border: 2px solid;
  border-color: #ff0000;
}
.MZuOhQipNh2aDJQUGLzP .CSi01KrNAKPkbzNOQoyj .GJFcbFAhrDKQoUpEDxyr {
  align-self: center;
  color: #333333;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
}
.MZuOhQipNh2aDJQUGLzP .muJDv9jxOWFp0b6_lKNe {
  margin-bottom: 0 !important;
}

.BN9jt2I9pmHtbBKixLjW {
  font-size: 13px;
  color: #dc3545;
}

.o7l5K311LOiubC2q8bYP {
  font-size: 13px;
  color: #dc3545;
}

.KTqSiVoOzpgl5F4_960a {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  background-color: var(--white);
  font-size: var(--para-font-size);
  font-weight: normal;
  line-height: 45px;
  height: 38px;
  width: 100%;
  color: #4c4c4c;
  padding: 0 12px;
}
.KTqSiVoOzpgl5F4_960a::placeholder {
  color: red;
}
.KTqSiVoOzpgl5F4_960a input:disabled {
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  color: -internal-light-dark-color(rgb(84, 84, 84), rgb(170, 170, 170));
}
.KTqSiVoOzpgl5F4_960a input:disabled:hover {
  cursor: not-allowed;
}
@media (min-width: 768px) {
  .KTqSiVoOzpgl5F4_960a {
    font-size: var(--label-font-size);
  }
}

._3JbAqUIdesbGBG19gkc {
  border: 1px solid #e9ecef;
  background-color: #e9ecef;
  opacity: 1;
  border-radius: 4px;
  font-size: var(--para-font-size);
  font-weight: normal;
  line-height: 45px;
  height: 38px;
  width: 100%;
  color: #4c4c4c;
  padding: 0 12px;
}
._3JbAqUIdesbGBG19gkc::placeholder {
  color: red;
}
._3JbAqUIdesbGBG19gkc:hover {
  cursor: not-allowed;
}
@media (min-width: 768px) {
  ._3JbAqUIdesbGBG19gkc {
    font-size: var(--label-font-size);
  }
}

.DQAKXMAkghSOGRPJn8c_ {
  padding-bottom: 40px !important;
}
@media screen and (max-width: 601px) {
  .DQAKXMAkghSOGRPJn8c_ {
    padding-bottom: 65px !important;
  }
}

.FIaAgT6Sy0rQii5schNK {
  min-height: 80px;
  line-height: 24px;
  padding-bottom: 20px !important;
}

.gy3qNDoyVniKqQLrW_HB {
  position: relative;
}

.Iq1Zxipp427MXZjqXAAP {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #999;
  width: 100%;
  text-align: right;
  border: 1px solid #ebedf2;
  padding: 2px 5px;
  border-top: none;
  border-color: white !important;
}
.Iq1Zxipp427MXZjqXAAP.B5fODfUQEWAfDU9aS80g {
  bottom: 24px;
  color: #dc3545;
}

@media screen and (max-width: 769px) {
  .hfwvRYhWlOYwsukfR7wV {
    margin-bottom: 1rem;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"employmentModalTitle": `dICOjrrGw31Q1_5q8Cjs`,
	"employmentModalSubTitle": `kNF33xCJczhbgjGlw5zw`,
	"detailsStepForm": `MZuOhQipNh2aDJQUGLzP`,
	"form-row": `Hx9msGqlOsAoeNpUm_cl`,
	"checkBoxLabel": `nHj5DH0PjnKM_85iCJKy`,
	"textArea": `pkUm8NEjogmGoCCN7nx9`,
	"employmentSubmitBtn": `eXgYMP1GHLUHMyGmDHII`,
	"activeBtn": `hrJBSpYJPPOs_Wplo9oD`,
	"disabledBtn": `YlPYg2yQABkbqP2_S7gv`,
	"countries": `WRBqlNfefhzuhif4uAT2`,
	"empIdCheckBoxContainer": `CSi01KrNAKPkbzNOQoyj`,
	"checkBoxField": `m5fZYMIjTwtLFQiY4ALR`,
	"empIdText": `GJFcbFAhrDKQoUpEDxyr`,
	"noBottomMargin": `muJDv9jxOWFp0b6_lKNe`,
	"errorMsg": `BN9jt2I9pmHtbBKixLjW`,
	"mobErrorMsg": `o7l5K311LOiubC2q8bYP`,
	"datePicker": `KTqSiVoOzpgl5F4_960a`,
	"disabledDatePicker": `_3JbAqUIdesbGBG19gkc`,
	"modalBody": `DQAKXMAkghSOGRPJn8c_`,
	"otherReasonText": `FIaAgT6Sy0rQii5schNK`,
	"OtherReasonFieldContainer": `gy3qNDoyVniKqQLrW_HB`,
	"charCount": `Iq1Zxipp427MXZjqXAAP`,
	"errPosition": `B5fODfUQEWAfDU9aS80g`,
	"mb1rem": `hfwvRYhWlOYwsukfR7wV`
};
export default ___CSS_LOADER_EXPORT___;
