// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D6pT5jF1x4Yex3uPqh9O {
  filter: drop-shadow(1px 0px 0px rgba(225, 225, 225, 0.5)) drop-shadow(-1px 0px 0px rgba(225, 225, 225, 0.5)) drop-shadow(0px 1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(0px -1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(1px 1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(-1px -1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(-1px 1px 0px rgba(225, 225, 225, 0.5)) drop-shadow(1px -1px 0px rgba(225, 225, 225, 0.5));
  margin-top: 3px;
}

.j3sayoEilbnQJ6hILWtZ {
  filter: drop-shadow(1px 0px 0px rgba(39, 85, 254, 0.5)) drop-shadow(-1px 0px 0px rgba(39, 85, 254, 0.25)) drop-shadow(0px 1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(0px -1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(1px 1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(-1px -1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(-1px 1px 0px rgba(39, 85, 254, 0.25)) drop-shadow(1px -1px 0px rgba(39, 85, 254, 0.25));
}

.V0cnrG5vOSQ8aDqw5YN7 {
  color: #2755fe;
  cursor: pointer;
}

.exNLziXdObOFsd0MfxdM {
  background-color: #ffffff;
  margin-bottom: 32px;
  border: none;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV {
  padding: 24px;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV .c2AvpxFP4d19QKPSphQw .BWZ3bNbMLIk_BYKI1S4Q .u5ZCESfmIUwQVbPEayjJ {
  color: #333333;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 3px;
  line-height: 18.15px;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV .c2AvpxFP4d19QKPSphQw .BWZ3bNbMLIk_BYKI1S4Q .xzhewyF2iTZEZ7bzVuU2 {
  color: #4f4f4f;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 3px;
  line-height: 18.15px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV .c2AvpxFP4d19QKPSphQw .BWZ3bNbMLIk_BYKI1S4Q .Z1zG8nyy2qQlhC2BYNjO:hover {
  cursor: pointer;
  background: #ebefff;
  border: 1px #ebefff solid;
  border-radius: 50%;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV .c2AvpxFP4d19QKPSphQw .BWZ3bNbMLIk_BYKI1S4Q ._aYeCSFQwChq2a1MA05c {
  font-weight: 700;
  font-size: 13px;
  color: #595959;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV .c2AvpxFP4d19QKPSphQw .BWZ3bNbMLIk_BYKI1S4Q .JioCaxPCAAiTNO1kp0Uk {
  margin-top: 8px;
  display: flex;
  padding: 9px 16px;
  background: #e6f7ff;
  align-items: baseline;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV .c2AvpxFP4d19QKPSphQw .BWZ3bNbMLIk_BYKI1S4Q .Z9KR42iCpBdfGBMZQc4Q {
  color: #262626;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: normal;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV .c2AvpxFP4d19QKPSphQw .BWZ3bNbMLIk_BYKI1S4Q .HDiK8rXXzG01WXbcpm8h {
  margin-top: 24px;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV .c2AvpxFP4d19QKPSphQw .BWZ3bNbMLIk_BYKI1S4Q .HDiK8rXXzG01WXbcpm8h .puITYuf_LE0nRR2mhPQ0 {
  color: #45a41c;
  font-weight: 600;
  font-size: 12px;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV .c2AvpxFP4d19QKPSphQw .KPmEf7qRdsOlEpO77Clv .gmtg4ovSZ3DFiPXHPefr {
  margin-bottom: 0;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV .c2AvpxFP4d19QKPSphQw .KPmEf7qRdsOlEpO77Clv .zUpfstriiJlH4kQ79BL5 {
  font-family: Roboto;
  font-weight: 500;
  font-size: 32px;
  color: #333333;
}
.exNLziXdObOFsd0MfxdM .QjfthE5_Ea5SqgfwipxV .c2AvpxFP4d19QKPSphQw .KPmEf7qRdsOlEpO77Clv .eQ0QjiBpDJaLvoR_g6fP {
  font-weight: normal;
  font-size: 14px;
  color: #999999;
  font-family: Roboto;
}
.exNLziXdObOFsd0MfxdM .HcRgbxxBzX7zXzlvib68 {
  margin-bottom: 6px;
}

.owKVuNatgcLUZgKTNKrZ {
  margin-left: 0.2rem;
  display: flex;
  align-items: center;
}

.UT5dzeuhRqJ99xRFeYEq {
  height: 14px;
}

.w89purMQaNU2_7bHZ1ye {
  font-size: 13px;
  font-weight: 600;
  color: #d3455b;
  margin-left: 0.3rem;
  vertical-align: text-bottom;
}

.c_QMQr1wu_i98XK7bYVQ {
  font-size: 13px;
  font-weight: 600;
  color: #ffc107;
  margin-left: 0.3rem;
  vertical-align: text-bottom;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `D6pT5jF1x4Yex3uPqh9O`,
	"showBorder": `j3sayoEilbnQJ6hILWtZ`,
	"addNickName": `V0cnrG5vOSQ8aDqw5YN7`,
	"packageDetailsCard": `exNLziXdObOFsd0MfxdM`,
	"packageDetailsCardBody": `QjfthE5_Ea5SqgfwipxV`,
	"packageDetailsRow": `c2AvpxFP4d19QKPSphQw`,
	"packageDetailsColLeft": `BWZ3bNbMLIk_BYKI1S4Q`,
	"subtypeName": `u5ZCESfmIUwQVbPEayjJ`,
	"friendlyName": `xzhewyF2iTZEZ7bzVuU2`,
	"createIcon": `Z1zG8nyy2qQlhC2BYNjO`,
	"balanceText": `_aYeCSFQwChq2a1MA05c`,
	"balanceSharedWithContainer": `JioCaxPCAAiTNO1kp0Uk`,
	"balanceSharedWithContainerDescription": `Z9KR42iCpBdfGBMZQc4Q`,
	"checksIncludedSection": `HDiK8rXXzG01WXbcpm8h`,
	"checkIncludedText": `puITYuf_LE0nRR2mhPQ0`,
	"packageDetailsColRight": `KPmEf7qRdsOlEpO77Clv`,
	"amountInfoContainer": `gmtg4ovSZ3DFiPXHPefr`,
	"amount": `zUpfstriiJlH4kQ79BL5`,
	"amountDescription": `eQ0QjiBpDJaLvoR_g6fP`,
	"marginBottom6": `HcRgbxxBzX7zXzlvib68`,
	"packgeUnitIndication": `owKVuNatgcLUZgKTNKrZ`,
	"unitIndicationIcon": `UT5dzeuhRqJ99xRFeYEq`,
	"unitNillIndicationText": `w89purMQaNU2_7bHZ1ye`,
	"unitLowIndicationText": `c_QMQr1wu_i98XK7bYVQ`
};
export default ___CSS_LOADER_EXPORT___;
