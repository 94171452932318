// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hPr3HiO1EwMasp2mQtWd {
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt {
  border: none;
  cursor: pointer;
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt .VpZRdialIqEPbxGAISaE {
  display: flex;
  background: #ffffff;
  border: none;
  padding-left: 0;
  padding-right: 0;
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt .VpZRdialIqEPbxGAISaE .hY49aPPTpbCK1RunnoCA {
  position: relative;
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt .VpZRdialIqEPbxGAISaE .hY49aPPTpbCK1RunnoCA .cV6T63cFTwOg27mgpLQc {
  position: absolute;
  top: -6px;
  left: 11px;
  font-size: 12px;
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt .VpZRdialIqEPbxGAISaE .hYZCRJtG5SbVIgoPQwmA {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 8px;
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt .VpZRdialIqEPbxGAISaE .hYZCRJtG5SbVIgoPQwmA .ogDWOQS5QQ1DqTXIQOcg {
  display: flex;
  text-align: right;
  font-weight: normal;
  font-size: 14px;
  color: #666666;
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt .VpZRdialIqEPbxGAISaE .hYZCRJtG5SbVIgoPQwmA .ogDWOQS5QQ1DqTXIQOcg .qwW0TTMb35lzCLRYwg_7 {
  font-family: roboto;
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt .VpZRdialIqEPbxGAISaE .hYZCRJtG5SbVIgoPQwmA .ogDWOQS5QQ1DqTXIQOcg .a7ATUZyEls64OXnh28vb {
  margin-right: 8px;
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt .VpZRdialIqEPbxGAISaE .hYZCRJtG5SbVIgoPQwmA .eeU_Yj_Ss_qbO20sJwxY {
  display: flex;
  text-align: right;
  font-weight: normal;
  font-size: 14px;
  color: #bfbfbf;
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt .VpZRdialIqEPbxGAISaE .hYZCRJtG5SbVIgoPQwmA .eeU_Yj_Ss_qbO20sJwxY .qwW0TTMb35lzCLRYwg_7 {
  font-family: roboto;
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt .VpZRdialIqEPbxGAISaE .hYZCRJtG5SbVIgoPQwmA .eeU_Yj_Ss_qbO20sJwxY .a7ATUZyEls64OXnh28vb {
  margin-right: 8px;
}
.hPr3HiO1EwMasp2mQtWd .zpTkc61QTaLrrXdwLUNt .dNAYZYwPUxb5_QKB8NlA {
  padding-left: 30px;
  padding-right: 0;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addonsContainer": `hPr3HiO1EwMasp2mQtWd`,
	"addonsContainerCard": `zpTkc61QTaLrrXdwLUNt`,
	"addonsContainerHeader": `VpZRdialIqEPbxGAISaE`,
	"iconContainer": `hY49aPPTpbCK1RunnoCA`,
	"plusIcon": `cV6T63cFTwOg27mgpLQc`,
	"headingRightContainer": `hYZCRJtG5SbVIgoPQwmA`,
	"headingRight": `ogDWOQS5QQ1DqTXIQOcg`,
	"rupee": `qwW0TTMb35lzCLRYwg_7`,
	"startFrom": `a7ATUZyEls64OXnh28vb`,
	"headingRightDisable": `eeU_Yj_Ss_qbO20sJwxY`,
	"cardBody": `dNAYZYwPUxb5_QKB8NlA`
};
export default ___CSS_LOADER_EXPORT___;
