// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fqu1UAsKXuHtY_dfeSCO {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  padding: 20px 24px;
  height: 110px;
  margin-bottom: 24px;
  font-family: Poppins, sans-serif;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .EFAPPSbr3bqM6y6AzLN4 {
  width: 875px;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .EFAPPSbr3bqM6y6AzLN4 .tqrI4GqcGztdZnF0qSqv {
  color: #333;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .EFAPPSbr3bqM6y6AzLN4 ._uNtALMhGA2V5OvyxrvA {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .EFAPPSbr3bqM6y6AzLN4 ._uNtALMhGA2V5OvyxrvA .QohbFAnwQG3CnlvYa68G {
  font-weight: 500;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .azPRmGta3BrBKIy3gD0H {
  color: #2755fe;
  font-size: 16px;
  font-weight: 500;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .azPRmGta3BrBKIy3gD0H:hover {
  cursor: pointer;
}

.bQ0okiIozaVmdtAbK3mr {
  max-width: 653px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"urlContainerWrapper": `Fqu1UAsKXuHtY_dfeSCO`,
	"containerFlex": `jmisobRmNQXgJ76To2Ft`,
	"urlItem": `EFAPPSbr3bqM6y6AzLN4`,
	"urlText": `tqrI4GqcGztdZnF0qSqv`,
	"urlNote": `_uNtALMhGA2V5OvyxrvA`,
	"noteText": `QohbFAnwQG3CnlvYa68G`,
	"customizeItem": `azPRmGta3BrBKIy3gD0H`,
	"textWithCopyButton": `bQ0okiIozaVmdtAbK3mr`
};
export default ___CSS_LOADER_EXPORT___;
