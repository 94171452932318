// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b4lqYB9QKVdptuvrkJRa {
  display: flex;
  height: 22px;
  width: 98px;
  border: 1px solid #B7EB8F;
  border-radius: 2px;
  background: #F6FFED;
  margin-bottom: 16px;
}
.b4lqYB9QKVdptuvrkJRa .hiBePitPzonYR2HGR6xp {
  width: 26px;
}
.b4lqYB9QKVdptuvrkJRa .hiBePitPzonYR2HGR6xp .V6h5kME_IuBJYAHQyMvY {
  padding: 6px;
  margin-top: -5px;
}
.b4lqYB9QKVdptuvrkJRa .QbkpRb_cVC767S0gw64a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #52C41A;
}

.MRLWxqUnf3K5X1odPr8i {
  display: flex;
  height: 22px;
  width: 72px;
  border: 1px solid #FFD591;
  border-radius: 2px;
  background: #FFF7E6;
  margin-bottom: 16px;
}
.MRLWxqUnf3K5X1odPr8i .hiBePitPzonYR2HGR6xp {
  width: 26px;
}
.MRLWxqUnf3K5X1odPr8i .hiBePitPzonYR2HGR6xp .V6h5kME_IuBJYAHQyMvY {
  padding: 6px;
  margin-top: -5px;
}
.MRLWxqUnf3K5X1odPr8i .QbkpRb_cVC767S0gw64a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FA8C16;
}

.P9FbCRzbHBZKOerJvqMX {
  display: flex;
  height: 22px;
  width: 112px;
  border: 1px solid #FFA39E;
  border-radius: 2px;
  background: #FFF1F0;
  margin-bottom: 16px;
}
.P9FbCRzbHBZKOerJvqMX .hiBePitPzonYR2HGR6xp {
  width: 26px;
}
.P9FbCRzbHBZKOerJvqMX .hiBePitPzonYR2HGR6xp .V6h5kME_IuBJYAHQyMvY {
  padding: 6px;
  margin-top: -5px;
}
.P9FbCRzbHBZKOerJvqMX .QbkpRb_cVC767S0gw64a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #F5222D;
}

.NEULCgihIqdvaxi23GvR {
  font-style: normal;
}
.NEULCgihIqdvaxi23GvR tr th {
  width: 140px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.NEULCgihIqdvaxi23GvR tr td {
  color: #595959;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
.NEULCgihIqdvaxi23GvR .D_28GVrMHIhAImlJ0MEu th {
  padding-left: 0;
}

.FV7zQRuYQFtBLsGDeOHJ {
  height: 32px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeClose": `b4lqYB9QKVdptuvrkJRa`,
	"badgeLogo": `hiBePitPzonYR2HGR6xp`,
	"badgeLogoImg": `V6h5kME_IuBJYAHQyMvY`,
	"badgeTitle": `QbkpRb_cVC767S0gw64a`,
	"badgeOpen": `MRLWxqUnf3K5X1odPr8i`,
	"badgeUnresolved": `P9FbCRzbHBZKOerJvqMX`,
	"contentTable": `NEULCgihIqdvaxi23GvR`,
	"commentsTableDetails": `D_28GVrMHIhAImlJ0MEu`,
	"footer": `FV7zQRuYQFtBLsGDeOHJ`
};
export default ___CSS_LOADER_EXPORT___;
