// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rep7uGKJmex3r1eHmCaJ {
  width: 680px;
  margin: -190px auto 0 auto;
}
.Rep7uGKJmex3r1eHmCaJ h4 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 30px;
}

.poIhzZUeGSz4t4YhehQx {
  margin: 0 auto 0;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  width: 680px;
  padding: 24px;
}
.poIhzZUeGSz4t4YhehQx .r6DBoW721Ixc0isp091c {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 24px;
}
.poIhzZUeGSz4t4YhehQx .r6DBoW721Ixc0isp091c .YvmNtIhnzY0nhqug8pFx {
  margin: 0;
}
.poIhzZUeGSz4t4YhehQx .r6DBoW721Ixc0isp091c .YvmNtIhnzY0nhqug8pFx span:first-child:hover {
  color: #8c8c8c;
  cursor: pointer;
}
.poIhzZUeGSz4t4YhehQx .r6DBoW721Ixc0isp091c .FWWPRjd872inmsOAIqzk {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #464457;
}
.poIhzZUeGSz4t4YhehQx .r6DBoW721Ixc0isp091c .HXbqftFm8YNdFEr5fnKB {
  margin: 0;
  color: #333333;
}
.poIhzZUeGSz4t4YhehQx .Pe5X4BPsNdyg_wFuMdkl {
  margin-top: 8px;
}
.poIhzZUeGSz4t4YhehQx .Pe5X4BPsNdyg_wFuMdkl .drOgaCNkalT9SHyw36kY {
  margin-bottom: 16px;
}
.poIhzZUeGSz4t4YhehQx .Pe5X4BPsNdyg_wFuMdkl .uPiXbpZ7tlIhd0mH_iaA {
  margin-bottom: 16px;
}
.poIhzZUeGSz4t4YhehQx .Pe5X4BPsNdyg_wFuMdkl .sliVBuvxj3E5c_TIp4AD {
  width: 100%;
}
.poIhzZUeGSz4t4YhehQx .Pe5X4BPsNdyg_wFuMdkl .pJCJsYnW8BGDD_Nh_iTt {
  background-color: #2755fe;
  border-color: #2755fe;
}
.poIhzZUeGSz4t4YhehQx .Pe5X4BPsNdyg_wFuMdkl .oWEQ2dRcoqqkzZHG7x7d {
  background-color: #859fff;
  border-color: #859fff;
  display: flex;
  align-items: center;
}
.poIhzZUeGSz4t4YhehQx .UtMrmuBe_T46iMPVbUzN {
  color: #666666;
}
.poIhzZUeGSz4t4YhehQx .Olypuwimmfz3nutquvJg {
  font-size: 14px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `Rep7uGKJmex3r1eHmCaJ`,
	"adminCard": `poIhzZUeGSz4t4YhehQx`,
	"cardHeader": `r6DBoW721Ixc0isp091c`,
	"breadCrumb": `YvmNtIhnzY0nhqug8pFx`,
	"cardHeading": `FWWPRjd872inmsOAIqzk`,
	"description": `HXbqftFm8YNdFEr5fnKB`,
	"prefFormContainer": `Pe5X4BPsNdyg_wFuMdkl`,
	"freqContainer": `drOgaCNkalT9SHyw36kY`,
	"typeContainer": `uPiXbpZ7tlIhd0mH_iaA`,
	"dropdown": `sliVBuvxj3E5c_TIp4AD`,
	"submitBtn": `pJCJsYnW8BGDD_Nh_iTt`,
	"submittingBtn": `oWEQ2dRcoqqkzZHG7x7d`,
	"lightColor": `UtMrmuBe_T46iMPVbUzN`,
	"font14": `Olypuwimmfz3nutquvJg`
};
export default ___CSS_LOADER_EXPORT___;
