// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VELtNrIXvDoag7eWeP1E {
  display: flex;
  justify-content: space-between;
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.VELtNrIXvDoag7eWeP1E .bQ2e9maIEJswXADMHg5l {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.QOg4y4ehh9DChehIbBg3 {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
  padding: 48px;
  font-family: Poppins, sans-serif;
  min-height: 695px;
}
.QOg4y4ehh9DChehIbBg3 .O1BKaHjzCSr4qHKqokOk {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.QOg4y4ehh9DChehIbBg3 .O1BKaHjzCSr4qHKqokOk .pVcNWT7sa76eqVlcSDaj {
  color: #666666;
  font-weight: 500;
  font-size: 12px;
}
.QOg4y4ehh9DChehIbBg3 .O1BKaHjzCSr4qHKqokOk .UuVwzgTY6Do640Uqqy2C {
  cursor: pointer;
  color: #2755fe;
  font-weight: 500;
  font-size: 14px;
}
.QOg4y4ehh9DChehIbBg3 .yq96FHWK3jEv_1TIOOUU {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  margin-bottom: 20px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `VELtNrIXvDoag7eWeP1E`,
	"heading": `bQ2e9maIEJswXADMHg5l`,
	"packagesCard": `QOg4y4ehh9DChehIbBg3`,
	"packageDetailsText": `O1BKaHjzCSr4qHKqokOk`,
	"packageDetailsTextLeft": `pVcNWT7sa76eqVlcSDaj`,
	"packageDetailsTextRight": `UuVwzgTY6Do640Uqqy2C`,
	"loaderContainer": `yq96FHWK3jEv_1TIOOUU`
};
export default ___CSS_LOADER_EXPORT___;
