// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PpAPAvEeuD_SFhmisdLa {
  display: flex;
  align-content: center;
  align-items: center;
}
.PpAPAvEeuD_SFhmisdLa .hL03poLm4575J4JuOxTB {
  position: relative;
}
.PpAPAvEeuD_SFhmisdLa .hL03poLm4575J4JuOxTB button {
  background-color: transparent !important;
  border-color: transparent !important;
}
.PpAPAvEeuD_SFhmisdLa .wTPkKPPNr3PoxtHpjOeJ {
  top: 56px !important;
  left: auto !important;
  right: 0 !important;
  transform: none !important;
  padding: 8px 4px 0px;
  width: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}
.PpAPAvEeuD_SFhmisdLa .wTPkKPPNr3PoxtHpjOeJ .T2dwkPDREfqvnOWUKl9H {
  font-size: 16px;
  line-height: 24px;
  padding: 4px 12px;
  margin-bottom: 8px;
}
.PpAPAvEeuD_SFhmisdLa .wTPkKPPNr3PoxtHpjOeJ .T2dwkPDREfqvnOWUKl9H:hover {
  background: #ebefff;
  color: #2755fe;
}
.PpAPAvEeuD_SFhmisdLa .wTPkKPPNr3PoxtHpjOeJ .BlF48zzxSV7TZVt8Babu {
  cursor: not-allowed;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 12px;
  margin-bottom: 8px;
  color: inherit;
  opacity: 0.5;
}
.PpAPAvEeuD_SFhmisdLa .wTPkKPPNr3PoxtHpjOeJ .BlF48zzxSV7TZVt8Babu:hover {
  background: "transparent";
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownMenuWrapper": `PpAPAvEeuD_SFhmisdLa`,
	"mainDropdown": `hL03poLm4575J4JuOxTB`,
	"dropdownMenu": `wTPkKPPNr3PoxtHpjOeJ`,
	"dropdownParticularCard": `T2dwkPDREfqvnOWUKl9H`,
	"disabledDropdownParticularCard": `BlF48zzxSV7TZVt8Babu`
};
export default ___CSS_LOADER_EXPORT___;
