// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RmruE2J2VgzcS4t9zX18 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(91, 90, 90, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flsjnRXc_FjEmoNZpY1f {
  width: 680px;
  height: 341px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  padding: 24px 32px;
  position: relative;
}

.LZdooOLQDLcE4YiAjndL {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 25px;
}

.C9zNVGx7EazFzu_J0FwN {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.KyftbtDmZYwyojATl4Xx {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: #333333;
}

.uWK8s76B8QA1YLbaQEMN {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-top: 36px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #333333;
}

.ms5t55ZlOskVa4A5rpkA {
  width: 240px;
  height: 196px;
  border-radius: 4px;
  border: 1px solid rgba(153, 153, 153, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ms5t55ZlOskVa4A5rpkA:hover {
  border: 2px solid #000000;
  background-color: rgba(208, 208, 208, 0.2156862745);
}

.EOnYyEwkqiRNYZ6kn_NA {
  font-size: 20px;
  font-weight: 500;
}

.fMogO3vbyQrBva0_Fy6w {
  height: 100px;
}

.fMogO3vbyQrBva0_Fy6w img {
  height: 80%;
  object-fit: contain;
}

.cgYMqONxqAEw3jkNN4Nu {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 5px;
}

.JGvKaSNH_ZXEd1Fgp2YC {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  padding: 0 15px;
  color: #666666;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-overlay": `RmruE2J2VgzcS4t9zX18`,
	"modalContainer": `flsjnRXc_FjEmoNZpY1f`,
	"close": `LZdooOLQDLcE4YiAjndL`,
	"header": `C9zNVGx7EazFzu_J0FwN`,
	"title": `KyftbtDmZYwyojATl4Xx`,
	"body": `uWK8s76B8QA1YLbaQEMN`,
	"box": `ms5t55ZlOskVa4A5rpkA`,
	"orText": `EOnYyEwkqiRNYZ6kn_NA`,
	"boxIcon": `fMogO3vbyQrBva0_Fy6w`,
	"boxText": `cgYMqONxqAEw3jkNN4Nu`,
	"boxSubText": `JGvKaSNH_ZXEd1Fgp2YC`
};
export default ___CSS_LOADER_EXPORT___;
