// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o1UP4VxWPjive73L1loW {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  align-items: center;
  display: none;
}
.o1UP4VxWPjive73L1loW span {
  color: #999999;
  text-transform: uppercase;
  margin-left: 8px;
}

@media screen and (max-width: 601px) {
  .o1UP4VxWPjive73L1loW {
    display: flex;
  }
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"secureTextMobile": `o1UP4VxWPjive73L1loW`
};
export default ___CSS_LOADER_EXPORT___;
