// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o_fnJVOimt7XRY3ZvDaN {
  border-radius: 4px;
}

.lvFHk9YXwpPjPYAeqD0A {
  padding: 20px 20px 6px 20px;
}
.lvFHk9YXwpPjPYAeqD0A .nUU4S4dEj0CwW7wsIhoU {
  margin-bottom: 20px;
}
.lvFHk9YXwpPjPYAeqD0A .nUU4S4dEj0CwW7wsIhoU .f1sn82RQhR4BPcvsXimD {
  margin: 8px 0px !important;
}
.lvFHk9YXwpPjPYAeqD0A .nUU4S4dEj0CwW7wsIhoU .A1UYKNIH6ISYbIAczQKi {
  justify-content: space-between;
  align-items: center;
  margin: 0px !important;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
}
.lvFHk9YXwpPjPYAeqD0A .nUU4S4dEj0CwW7wsIhoU .A_WSMbMFfq_0aRqgZVQl {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
  margin: 0px !important;
}
.lvFHk9YXwpPjPYAeqD0A .nUU4S4dEj0CwW7wsIhoU .tHxR1xE6RX3HT66uqFIO {
  font-size: 14px;
  font-weight: 400;
  color: #464457 !important;
}
.lvFHk9YXwpPjPYAeqD0A .nUU4S4dEj0CwW7wsIhoU .tHxR1xE6RX3HT66uqFIO .IRGEOgyG1pweOZmNShJN {
  font-weight: 600;
  color: #464457 !important;
  font-family: "Roboto";
}
.lvFHk9YXwpPjPYAeqD0A .nUU4S4dEj0CwW7wsIhoU .tHxR1xE6RX3HT66uqFIO .vx2NpZxFnaVo0uKUlrmo {
  font-weight: 500;
  color: #464457 !important;
}
.lvFHk9YXwpPjPYAeqD0A .nUU4S4dEj0CwW7wsIhoU .V92TLsJ_9Nn27TUyUIQv {
  margin: 12px -18px 25px -18px !important;
}
.lvFHk9YXwpPjPYAeqD0A .i2k0yQDPVbLYe9C1MeEa {
  margin: 10px 0px 20px 0px;
}
.lvFHk9YXwpPjPYAeqD0A .i2k0yQDPVbLYe9C1MeEa .A1UYKNIH6ISYbIAczQKi {
  align-items: center;
  justify-content: space-between;
  margin: 0px !important;
}
.lvFHk9YXwpPjPYAeqD0A .JE_lGw13SuM_l5KgGL5n .A1UYKNIH6ISYbIAczQKi {
  align-items: center;
  justify-content: space-between;
  margin: 0px !important;
}
.lvFHk9YXwpPjPYAeqD0A .gCF70EjeaojE_azlLJlc {
  margin-top: 9x;
  font-size: 18px;
}
.lvFHk9YXwpPjPYAeqD0A .gCF70EjeaojE_azlLJlc .A1UYKNIH6ISYbIAczQKi {
  align-items: center;
  justify-content: space-between;
  margin: 0px !important;
}
.lvFHk9YXwpPjPYAeqD0A .gCF70EjeaojE_azlLJlc .dYte9vVaOtehP6tWUOCN {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #464457;
}
.lvFHk9YXwpPjPYAeqD0A .guEsXJZEvO7dkruA_d9j {
  margin: 8px 0px !important;
}

.WXuRZvkdvkkE16vq0GPA {
  margin-top: 20px;
}
.WXuRZvkdvkkE16vq0GPA .iLI8OSIZosmDQHQjV51Q {
  padding: 20px;
}
.WXuRZvkdvkkE16vq0GPA .iLI8OSIZosmDQHQjV51Q .f1sn82RQhR4BPcvsXimD {
  margin: 8px 0px !important;
}
.WXuRZvkdvkkE16vq0GPA .iLI8OSIZosmDQHQjV51Q .V9MLulKvzaPt1NZXxw2R {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 2px 16px !important;
}
.WXuRZvkdvkkE16vq0GPA .iLI8OSIZosmDQHQjV51Q .V9MLulKvzaPt1NZXxw2R .rb0oJFcLFu4aDDpKPBhF {
  margin-right: 24px;
  background-color: #fff;
  padding: 0 12px 0 4px;
  font-size: 14px;
  color: #4c4c4c;
}
.WXuRZvkdvkkE16vq0GPA .iLI8OSIZosmDQHQjV51Q .V9MLulKvzaPt1NZXxw2R .rb0oJFcLFu4aDDpKPBhF label .WQfxXqR6cWYMgdLFeVwN {
  color: #333333;
  font-size: 14px;
  display: inline-block;
  font-weight: 400;
}
.WXuRZvkdvkkE16vq0GPA .iLI8OSIZosmDQHQjV51Q .A1UYKNIH6ISYbIAczQKi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px !important;
}
.WXuRZvkdvkkE16vq0GPA .iLI8OSIZosmDQHQjV51Q .A1UYKNIH6ISYbIAczQKi .dYte9vVaOtehP6tWUOCN {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #464457;
}

.rmKS3HxufaTsE0xiWK7p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}

.m0C0Ptcw3QinNa_s5W_t {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.mzJuhd13Wnc0t5_tu3xL {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #464457;
}

.K9mblnfoEKwCXfZZZnbf {
  font-size: 18px !important;
}

.HStI8yvB04A2t4S2ud9K {
  padding: 0 0 0 1.4rem;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}

.BxXlv2lrqvjBwSBRmyvF {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #999999;
}

.gsNG1vCf2qIZtWSJVGod {
  font-family: "Roboto";
}

.HZhegtNHX2EELPZucHuj {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  color: #464457;
}

.f9RFIqwoK7j_REs0A4YC {
  font-family: "Roboto";
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #333333;
}

.VcUIS0cqZ6fHMMcN9vFv {
  pointer-events: none;
  opacity: 0.5;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `o_fnJVOimt7XRY3ZvDaN`,
	"container": `lvFHk9YXwpPjPYAeqD0A`,
	"subContainer": `nUU4S4dEj0CwW7wsIhoU`,
	"hrTag": `f1sn82RQhR4BPcvsXimD`,
	"selectedItem": `A1UYKNIH6ISYbIAczQKi`,
	"unSelectedItem": `A_WSMbMFfq_0aRqgZVQl`,
	"creditBalanceText": `tHxR1xE6RX3HT66uqFIO`,
	"creditBalanceAmount": `IRGEOgyG1pweOZmNShJN`,
	"creditBalanceNotRecommended": `vx2NpZxFnaVo0uKUlrmo`,
	"hrTagWalletBalance": `V92TLsJ_9Nn27TUyUIQv`,
	"walletBalanceSubContainer": `i2k0yQDPVbLYe9C1MeEa`,
	"taxSubContainer": `JE_lGw13SuM_l5KgGL5n`,
	"totalSubContainer": `gCF70EjeaojE_azlLJlc`,
	"totalAmount": `dYte9vVaOtehP6tWUOCN`,
	"hrTagSubContainer": `guEsXJZEvO7dkruA_d9j`,
	"rightContainerTds": `WXuRZvkdvkkE16vq0GPA`,
	"tdsContainer": `iLI8OSIZosmDQHQjV51Q`,
	"tdsOptions": `V9MLulKvzaPt1NZXxw2R`,
	"option": `rb0oJFcLFu4aDDpKPBhF`,
	"tdsOptionText": `WQfxXqR6cWYMgdLFeVwN`,
	"packageName": `rmKS3HxufaTsE0xiWK7p`,
	"packageAmount": `m0C0Ptcw3QinNa_s5W_t`,
	"summaryTitles": `mzJuhd13Wnc0t5_tu3xL`,
	"summaryTitlesTotal": `K9mblnfoEKwCXfZZZnbf`,
	"subtypesSelected": `HStI8yvB04A2t4S2ud9K`,
	"noSelection": `BxXlv2lrqvjBwSBRmyvF`,
	"amount": `gsNG1vCf2qIZtWSJVGod`,
	"summaryTitlesSmallSize": `HZhegtNHX2EELPZucHuj`,
	"summaryAmount": `f9RFIqwoK7j_REs0A4YC`,
	"maskDiv": `VcUIS0cqZ6fHMMcN9vFv`
};
export default ___CSS_LOADER_EXPORT___;
