// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dm2AQwQA1vw6JPaCaAKv .uj8UsAXI2M1YQNs_SyZK {
  width: 100%;
  background: #2755fe;
  border-radius: 2px;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dm2AQwQA1vw6JPaCaAKv .k73ENCUShxIgVvSx3s6G {
  font-family: roboto;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addBtnContainer": `dm2AQwQA1vw6JPaCaAKv`,
	"payBtn": `uj8UsAXI2M1YQNs_SyZK`,
	"rupee": `k73ENCUShxIgVvSx3s6G`
};
export default ___CSS_LOADER_EXPORT___;
