// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HcQDxOTA56JDomY1TBUA {
  margin-top: 30px;
}
.HcQDxOTA56JDomY1TBUA .MT4l7cknBv5c5Pazy3w5 {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
  margin-bottom: 10px;
}
.HcQDxOTA56JDomY1TBUA .tlIsTGaS_gIpRy48rDU4 {
  color: #333333;
  font-weight: 600;
  margin: 0;
}
.HcQDxOTA56JDomY1TBUA .riC2fLE9tXnWzaJu24rK {
  margin: 0;
  color: #666666;
}
.HcQDxOTA56JDomY1TBUA .xdG3aIQo5YcTDANNlpaA {
  text-align: right;
}
.HcQDxOTA56JDomY1TBUA .xdG3aIQo5YcTDANNlpaA img {
  color: #2755fe;
  cursor: pointer;
}
.HcQDxOTA56JDomY1TBUA .xdG3aIQo5YcTDANNlpaA .HwrjSXfRPDsuc1GeZOH1 {
  padding-right: 5px;
  width: 23px;
}
.HcQDxOTA56JDomY1TBUA .xdG3aIQo5YcTDANNlpaA .aLS3fQJk5X5peTJaDeYb {
  padding-left: 5px;
  width: 20px;
}
.HcQDxOTA56JDomY1TBUA .x9unxzdSrZN8oK1UK2jm {
  cursor: pointer;
  background: #f20000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 16px;
}
@media screen and (max-width: 601px) {
  .HcQDxOTA56JDomY1TBUA .x9unxzdSrZN8oK1UK2jm {
    position: absolute;
    right: 16px;
  }
}
.HcQDxOTA56JDomY1TBUA .TL9uKXDw7X6aOEcp9KSs {
  color: rgba(255, 0, 0, 0.7019607843);
  font-size: 14px;
}

hr {
  margin-bottom: 24px !important;
  margin-top: 24px !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"savedEducationInfo": `HcQDxOTA56JDomY1TBUA`,
	"educationLevel": `MT4l7cknBv5c5Pazy3w5`,
	"universityName": `tlIsTGaS_gIpRy48rDU4`,
	"timePeriod": `riC2fLE9tXnWzaJu24rK`,
	"actionBtnContainer": `xdG3aIQo5YcTDANNlpaA`,
	"editImage": `HwrjSXfRPDsuc1GeZOH1`,
	"deleteImage": `aLS3fQJk5X5peTJaDeYb`,
	"deleteBadge": `x9unxzdSrZN8oK1UK2jm`,
	"incompleteText": `TL9uKXDw7X6aOEcp9KSs`
};
export default ___CSS_LOADER_EXPORT___;
