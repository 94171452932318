// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bDvepCPEWuvbwML08nrB {
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL {
  border: none;
  cursor: pointer;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .Es64q8CpukKZBZvO5ReC {
  display: flex;
  background: #ffffff;
  border: none;
  padding-left: 0;
  padding-right: 0;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .Es64q8CpukKZBZvO5ReC .Hp2zfAzIKi5dmGVtWSDo {
  position: relative;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .Es64q8CpukKZBZvO5ReC .Hp2zfAzIKi5dmGVtWSDo .oOSRIdadOJNCdsbv4VUR {
  position: absolute;
  top: -6px;
  left: 11px;
  font-size: 12px;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .Es64q8CpukKZBZvO5ReC .Gshr1MbAdfkF48F6LkKa {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 8px;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .Es64q8CpukKZBZvO5ReC .Gshr1MbAdfkF48F6LkKa ._EJiNVnyRhxJyotgCv58 {
  font-weight: 500;
  font-size: 16px;
  color: #aaaaaa;
  margin: 0;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .Es64q8CpukKZBZvO5ReC .Gshr1MbAdfkF48F6LkKa .kmRFa4uVH_Re7XVa7Qav {
  display: flex;
  text-align: right;
  font-weight: normal;
  font-size: 14px;
  color: #666666;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .Es64q8CpukKZBZvO5ReC .Gshr1MbAdfkF48F6LkKa .kmRFa4uVH_Re7XVa7Qav .gKhGEtgkRLZgwZz9eUZG {
  font-family: roboto;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .Es64q8CpukKZBZvO5ReC .Gshr1MbAdfkF48F6LkKa .kmRFa4uVH_Re7XVa7Qav .LEANr6V_Pf0kMmcp3I6F {
  margin-right: 8px;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .lNodNTZwxE_UPL6KsetU {
  padding-left: 30px;
  padding-right: 0;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .lNodNTZwxE_UPL6KsetU .DAeTcm5uXh5dlwj8hIlu {
  font-size: 15px;
  color: #aaaaaa;
  margin-bottom: 12px;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .lNodNTZwxE_UPL6KsetU .krKrucZA6tMpgj5JssXL {
  font-size: 14px;
  color: #aaaaaa;
  margin-bottom: 12px;
  margin-top: 12px;
}
.bDvepCPEWuvbwML08nrB .ObIL4dDm2FdM8QQHJCgL .lNodNTZwxE_UPL6KsetU .krKrucZA6tMpgj5JssXL .SV6gQrkBOKpPSuAcQR4g {
  margin-bottom: 24px;
}

.PHOKG3KmnE3SeRdxDLr_ label {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #595959 !important;
}

.fNQ1oCgT1pefSCQ4k0z0 {
  color: #db3131;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addonsContainer": `bDvepCPEWuvbwML08nrB`,
	"addonsContainerCard": `ObIL4dDm2FdM8QQHJCgL`,
	"addonsContainerHeader": `Es64q8CpukKZBZvO5ReC`,
	"iconContainer": `Hp2zfAzIKi5dmGVtWSDo`,
	"plusIcon": `oOSRIdadOJNCdsbv4VUR`,
	"headingRightContainer": `Gshr1MbAdfkF48F6LkKa`,
	"heading": `_EJiNVnyRhxJyotgCv58`,
	"headingRight": `kmRFa4uVH_Re7XVa7Qav`,
	"rupee": `gKhGEtgkRLZgwZz9eUZG`,
	"startFrom": `LEANr6V_Pf0kMmcp3I6F`,
	"cardBody": `lNodNTZwxE_UPL6KsetU`,
	"headingDiv": `DAeTcm5uXh5dlwj8hIlu`,
	"subHeadingDiv": `krKrucZA6tMpgj5JssXL`,
	"customCounter": `SV6gQrkBOKpPSuAcQR4g`,
	"formCheck": `PHOKG3KmnE3SeRdxDLr_`,
	"required": `fNQ1oCgT1pefSCQ4k0z0`
};
export default ___CSS_LOADER_EXPORT___;
