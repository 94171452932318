// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cdX6SwcXoiQs6oy36KQm {
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `cdX6SwcXoiQs6oy36KQm`
};
export default ___CSS_LOADER_EXPORT___;
