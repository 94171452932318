// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lhQ2miJpEGKjOlFnRm_z {
  text-align: center;
}
.lhQ2miJpEGKjOlFnRm_z .WEtbA4SP5Xc80o9tHjaB {
  height: 100px;
  width: auto;
  margin-top: 5px;
}
.lhQ2miJpEGKjOlFnRm_z .BrobF91JKHMcByNw5Ztc {
  font-family: sans-serif !important;
  font-size: 20px !important;
  margin-bottom: -5px;
}
.lhQ2miJpEGKjOlFnRm_z .BNNlO_SGXyzEUH09Da3a {
  font-family: sans-serif !important;
  text-decoration: underline;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unAuthorized": `lhQ2miJpEGKjOlFnRm_z`,
	"imgStyle": `WEtbA4SP5Xc80o9tHjaB`,
	"labelStyle": `BrobF91JKHMcByNw5Ztc`,
	"aStyle": `BNNlO_SGXyzEUH09Da3a`
};
export default ___CSS_LOADER_EXPORT___;
