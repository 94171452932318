// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crdTvduJ5fLvyVgpH3V0 {
  min-height: 80vh;
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .crdTvduJ5fLvyVgpH3V0 {
    width: 1200px;
  }
}
.crdTvduJ5fLvyVgpH3V0 .OXPzPieweK3MMjNreDKE {
  margin-top: -190px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
  padding: 1rem 2rem 5rem 2rem;
  font-family: Poppins, sans-serif;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .dvp9qUWviOuZbduT3UhG ol {
  background: none;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .dvp9qUWviOuZbduT3UhG ol li {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .dvp9qUWviOuZbduT3UhG ol li a {
  color: #8c8c8c;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .dvp9qUWviOuZbduT3UhG ol li :active {
  color: #595959;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .ZIaoo1Uv5CCB8MVKXhUN {
  padding: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .ZIaoo1Uv5CCB8MVKXhUN .AY3oKHJrn0rQuUpmwpvE {
  color: #333333;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .ZIaoo1Uv5CCB8MVKXhUN .QY01Pz4VFzsdB4jmNnb9 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #999999;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .ZIaoo1Uv5CCB8MVKXhUN .jTeUjNuDSgRvBGKdi28q {
  color: #666666;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .s9aOjE8HnnrxAhcXJmx8 {
  border: none;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .s9aOjE8HnnrxAhcXJmx8 .m_hWXd7LBG5EwC78RkfI {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .s9aOjE8HnnrxAhcXJmx8 .PcPnNS_xyxD9kCocZqrr {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #333333;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .s9aOjE8HnnrxAhcXJmx8 .UZQQMx3xmlOwVcKz_QyR {
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .juf4P6aGDxLdw6v8RrOh {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem 1rem 1.25rem;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .juf4P6aGDxLdw6v8RrOh .dxU2GaBVgb2TcQ0cddcO {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .juf4P6aGDxLdw6v8RrOh .m_hWXd7LBG5EwC78RkfI {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .juf4P6aGDxLdw6v8RrOh .FbBk1ueL089Vq6vM2R0d {
  color: #999999;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .ykhEX1Q8W9GgZ76ijvCC {
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  margin: auto;
  padding: 20px;
}
.crdTvduJ5fLvyVgpH3V0 .DTmJp3SkNWfaFfPG2WCl .N_1ONnh7hwRt_OFsWe_T {
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  margin-left: 800px;
  margin: auto;
  padding: 20px;
  border-left: 2px #333333;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"purchaseDetailsWrapper": `crdTvduJ5fLvyVgpH3V0`,
	"heading": `OXPzPieweK3MMjNreDKE`,
	"purchaseDetailsCard": `DTmJp3SkNWfaFfPG2WCl`,
	"breadcrumb": `dvp9qUWviOuZbduT3UhG`,
	"summary": `ZIaoo1Uv5CCB8MVKXhUN`,
	"packageName": `AY3oKHJrn0rQuUpmwpvE`,
	"tax": `QY01Pz4VFzsdB4jmNnb9`,
	"candidates": `jTeUjNuDSgRvBGKdi28q`,
	"summaryCard": `s9aOjE8HnnrxAhcXJmx8`,
	"text": `m_hWXd7LBG5EwC78RkfI`,
	"total": `PcPnNS_xyxD9kCocZqrr`,
	"amount": `UZQQMx3xmlOwVcKz_QyR`,
	"infoCard": `juf4P6aGDxLdw6v8RrOh`,
	"title": `dxU2GaBVgb2TcQ0cddcO`,
	"deletedUser": `FbBk1ueL089Vq6vM2R0d`,
	"purchaseTypeHeading": `ykhEX1Q8W9GgZ76ijvCC`,
	"summaryHeading": `N_1ONnh7hwRt_OFsWe_T`
};
export default ___CSS_LOADER_EXPORT___;
