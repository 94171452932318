// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NcjUb6nB8RspBQo1pQfG {
  padding: 20px;
}

.Xq3Wvu24hXnJxSIjifSg {
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  gap: 20px;
}

.GhHia57lfQOWnMkOVCrs {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.oNuhZ3VAal4ZGdPU3uX9 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #464457;
}

._w_5Axp_lik_zky_qJVg {
  display: flex;
}

.Uozqy6HWXUDyKfY6_iJl {
  height: 30px;
  width: 25px;
  margin-right: 8px;
  margin-top: -6px;
}

.XkWLZ9ywXboO743bdsFs {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.U5YzTSjSwZ93yeBAJjuB {
  color: #666666;
  font-weight: normal;
  font-size: "13px";
}

.uXE3aMK8YZyYvL3VBFZr {
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.ZhntzwTpOIvz07FEULHs {
  color: #595959;
  width: 111px;
  height: 29px;
  border-radius: 20px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  background-color: rgba(250, 173, 20, 0.5019607843);
  gap: 10px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.olPSnwc9R_Mlgilz6Lox {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.y_FRVwwY9SGKYRZxEfHN {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.y_FRVwwY9SGKYRZxEfHN li {
  font-weight: normal !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NcjUb6nB8RspBQo1pQfG`,
	"cardContainer": `Xq3Wvu24hXnJxSIjifSg`,
	"containerHeader": `GhHia57lfQOWnMkOVCrs`,
	"cardHeadText": `oNuhZ3VAal4ZGdPU3uX9`,
	"whatsAppContainer": `_w_5Axp_lik_zky_qJVg`,
	"imageStyle": `Uozqy6HWXUDyKfY6_iJl`,
	"cardSubtext": `XkWLZ9ywXboO743bdsFs`,
	"cardSecondarySubtext": `U5YzTSjSwZ93yeBAJjuB`,
	"comingSoonBanner": `uXE3aMK8YZyYvL3VBFZr`,
	"comingSoonText": `ZhntzwTpOIvz07FEULHs`,
	"cardText": `olPSnwc9R_Mlgilz6Lox`,
	"listText": `y_FRVwwY9SGKYRZxEfHN`
};
export default ___CSS_LOADER_EXPORT___;
