// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wZKv_bVdQd_32ZwTbRD0 {
  padding: 24px !important;
  padding-bottom: 0px !important;
}

.hWZu7MikAVyPbWN_7h68 {
  margin-top: 12px;
}
.hWZu7MikAVyPbWN_7h68 thead {
  background-color: #f2f8ff;
}
.hWZu7MikAVyPbWN_7h68 thead tr th {
  border: 0px;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 21px 10px 16px 10px;
}
.hWZu7MikAVyPbWN_7h68 tbody {
  padding-left: 10px;
}
.hWZu7MikAVyPbWN_7h68 tbody tr:nth-child(even) {
  background-color: #f7f8fa;
}
.hWZu7MikAVyPbWN_7h68 tbody tr {
  cursor: default;
}
.hWZu7MikAVyPbWN_7h68 tbody tr td {
  border: 0px;
  vertical-align: middle !important;
  padding: 18px 10px 19px 10px;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  text-align: left;
}
.hWZu7MikAVyPbWN_7h68 tbody tr:hover {
  background-color: #f5f5f5;
}

.ra0yKCqHb8h_9ZubjjE2 {
  flex-wrap: unset;
  padding: 24px 24px 15px;
}
.ra0yKCqHb8h_9ZubjjE2 .UX5WYFsBeo02Ywc0HRcz {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.ra0yKCqHb8h_9ZubjjE2 .UX5WYFsBeo02Ywc0HRcz .VSdi_554LWC3yjiulf_z {
  border-radius: 2px !important;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  height: 24px !important;
  color: #000 !important;
  text-align: center !important;
  margin: 0 6px !important;
}
.ra0yKCqHb8h_9ZubjjE2 .UX5WYFsBeo02Ywc0HRcz .VSdi_554LWC3yjiulf_z:focus {
  outline-color: #2855ff !important;
}

.JVj8aZhFSqBTw_WHHeql {
  font-family: roboto;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": `wZKv_bVdQd_32ZwTbRD0`,
	"recentTable": `hWZu7MikAVyPbWN_7h68`,
	"paginationWrapper": `ra0yKCqHb8h_9ZubjjE2`,
	"showPagination": `UX5WYFsBeo02Ywc0HRcz`,
	"selectPagination": `VSdi_554LWC3yjiulf_z`,
	"rupeeFont": `JVj8aZhFSqBTw_WHHeql`
};
export default ___CSS_LOADER_EXPORT___;
