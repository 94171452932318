// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FWKEJRDnY2_qYAZViRlC {
  display: inline-flex;
  width: 100%;
}

.aJf96Xlq7DOs2TyDXB4E {
  width: 50%;
  margin-left: 10px;
}

.jj_4KkQejO8AxLCn3aBh {
  margin-left: 16px;
  margin-right: 56px;
}

.jF4oWRhpPtBovEIeLFRi {
  border: 1px solid red;
}

.fnhM3GtfmC5ysywEK7RL {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
}

.i3aSPuQrdzOj9gfgXTJd {
  padding-top: 0.5rem;
}

.StX3vMaHnb8cRywXzjWA {
  color: #fff;
  border-radius: 2px;
  min-width: 90px;
  background-color: #2755fe;
  border-color: #2755fe;
  cursor: pointer;
}
.StX3vMaHnb8cRywXzjWA:hover {
  background-color: #1d41c5;
  border-color: #1d41c5;
}

.DEZ3BZe4DMY6zb9VPQ2q {
  background-color: #1bae9f;
  color: white;
  border-radius: 2px;
  min-width: 90px;
}

.J1xy0jOj320LqD44Snyc {
  border-radius: 2px;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}

.StX3vMaHnb8cRywXzjWA:hover {
  background-color: #076dda;
}

.DEZ3BZe4DMY6zb9VPQ2q:hover {
  background-color: #11a294;
}

.ZsdE5kMqIlFJULENeX0d {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.oLCup2QoW8jOxM9x5jIT {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  color: #2755fe;
  border: 1px solid #2755fe;
}

.xRMBtl1bJIpgq4fYpEHZ {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.gvME9dvvFoQTfojfx9Yw {
  max-width: 900px;
}

.fDKm4OuHyHoKmyGAjAEQ {
  width: 21px;
  height: 21px;
  margin-left: 15px;
}

.fDKm4OuHyHoKmyGAjAEQ:hover {
  cursor: pointer;
}

.xH1Dw1KqV1Q7rJEg8VQY ._tYVEN5la7NbAryF2c01 {
  visibility: hidden;
  width: 120px;
  background-color: grey;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 3px 5px;
  margin-left: 10px;
}

.xH1Dw1KqV1Q7rJEg8VQY:hover ._tYVEN5la7NbAryF2c01 {
  visibility: visible;
}

.NmYevBmtpRSwbVq2IjTU {
  background-color: #2755fe;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formMainDiv": `FWKEJRDnY2_qYAZViRlC`,
	"formField": `aJf96Xlq7DOs2TyDXB4E`,
	"parentDivConnectButton": `jj_4KkQejO8AxLCn3aBh`,
	"removeButton": `jF4oWRhpPtBovEIeLFRi`,
	"validationErrorMsg": `fnhM3GtfmC5ysywEK7RL`,
	"inputLabel": `i3aSPuQrdzOj9gfgXTJd`,
	"connectButton": `StX3vMaHnb8cRywXzjWA`,
	"connectedButton": `DEZ3BZe4DMY6zb9VPQ2q`,
	"disabledBtn": `J1xy0jOj320LqD44Snyc`,
	"step1FooterContainer": `ZsdE5kMqIlFJULENeX0d`,
	"goBackBtn": `oLCup2QoW8jOxM9x5jIT`,
	"listItems": `xRMBtl1bJIpgq4fYpEHZ`,
	"listUrl": `gvME9dvvFoQTfojfx9Yw`,
	"copyToClipboard": `fDKm4OuHyHoKmyGAjAEQ`,
	"tooltip": `xH1Dw1KqV1Q7rJEg8VQY`,
	"tooltiptext": `_tYVEN5la7NbAryF2c01`,
	"accentColor": `NmYevBmtpRSwbVq2IjTU`
};
export default ___CSS_LOADER_EXPORT___;
