// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._O8eNttIGlnmpoMIwSMz {
  line-height: 24px;
}
._O8eNttIGlnmpoMIwSMz .N_ZoA9Mw6BRAibNDn_3R .ym8V2rCjswFHYtxZ4Miv {
  text-align: center;
}
._O8eNttIGlnmpoMIwSMz .N_ZoA9Mw6BRAibNDn_3R .liSZIO_7vryEXC6wSDrr {
  padding-left: 0;
}
._O8eNttIGlnmpoMIwSMz .N_ZoA9Mw6BRAibNDn_3R .oHE0WkdMqfmq5JQw5mfl {
  padding-left: 0;
  margin-top: 10px;
  font-size: 14px;
  color: #999999;
}
._O8eNttIGlnmpoMIwSMz .N_ZoA9Mw6BRAibNDn_3R .Aua12x9XiuHw4BoAX8n2 {
  padding-left: 0;
  font-size: 14px;
  margin-top: 10px;
  color: #e97070;
}
._O8eNttIGlnmpoMIwSMz .N_ZoA9Mw6BRAibNDn_3R .nfLeurMEUlXLWAqBZfNy {
  margin-top: 18px;
}
._O8eNttIGlnmpoMIwSMz .ZxI_H7jEs8stPsriqxi9 {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
}
._O8eNttIGlnmpoMIwSMz .GLxPmBmxTG7z8jsUUuD2 {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
._O8eNttIGlnmpoMIwSMz .hBzAKpa7xc1qNuzRac4Q button {
  border-radius: 2px;
  padding: 5px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
._O8eNttIGlnmpoMIwSMz .hBzAKpa7xc1qNuzRac4Q .Mf77CAm2E9h_jJhA1MhM {
  background-color: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
._O8eNttIGlnmpoMIwSMz .hBzAKpa7xc1qNuzRac4Q .EKmNB9CPKvaNVB1Mp5tA {
  background: #f5f5f5;
  color: #bfbfbf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
._O8eNttIGlnmpoMIwSMz .hBzAKpa7xc1qNuzRac4Q .kpsqui99QdfWyden2Nbn {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
}
._O8eNttIGlnmpoMIwSMz .hBzAKpa7xc1qNuzRac4Q .VCLpMWNIddxg1rRVroGf {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
  cursor: not-allowed;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationModal": `_O8eNttIGlnmpoMIwSMz`,
	"confirModalBody": `N_ZoA9Mw6BRAibNDn_3R`,
	"questionImage": `ym8V2rCjswFHYtxZ4Miv`,
	"confirmTextContainer": `liSZIO_7vryEXC6wSDrr`,
	"confirmTextBody": `oHE0WkdMqfmq5JQw5mfl`,
	"confirmTextBodyRed": `Aua12x9XiuHw4BoAX8n2`,
	"consent": `nfLeurMEUlXLWAqBZfNy`,
	"confirmModalHeading": `ZxI_H7jEs8stPsriqxi9`,
	"confirmText": `GLxPmBmxTG7z8jsUUuD2`,
	"confirmModalFooter": `hBzAKpa7xc1qNuzRac4Q`,
	"activeConfirmBtn": `Mf77CAm2E9h_jJhA1MhM`,
	"disabledConfirmBtn": `EKmNB9CPKvaNVB1Mp5tA`,
	"cancelConfirmBtn": `kpsqui99QdfWyden2Nbn`,
	"disabledCancelConfirmBtn": `VCLpMWNIddxg1rRVroGf`
};
export default ___CSS_LOADER_EXPORT___;
