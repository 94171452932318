// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zLRRgNvH8iwnu73oMb6n {
  display: flex;
  justify-content: space-between;
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.zLRRgNvH8iwnu73oMb6n .VFM6deGIfAmk6ccb2ekb {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.OSLguuyacYTgzisrPnA6 {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
  padding: 48px;
  font-family: Poppins, sans-serif;
  min-height: 695px;
}
.OSLguuyacYTgzisrPnA6 .rHAo8bvttYvgU7WY9T7I .PyU2TnV0Iu9S6ZWpuKIU {
  font-weight: 500;
  font-size: 32px;
  color: #333333;
}
.OSLguuyacYTgzisrPnA6 .rHAo8bvttYvgU7WY9T7I .MgWZpP_hiD8TNsawDJjD {
  max-height: 640px;
  overflow-y: auto;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `zLRRgNvH8iwnu73oMb6n`,
	"heading": `VFM6deGIfAmk6ccb2ekb`,
	"packagesCard": `OSLguuyacYTgzisrPnA6`,
	"packagesDetailsContainer": `rHAo8bvttYvgU7WY9T7I`,
	"packagesDetailsLeft": `PyU2TnV0Iu9S6ZWpuKIU`,
	"packagesDetailsRight": `MgWZpP_hiD8TNsawDJjD`
};
export default ___CSS_LOADER_EXPORT___;
