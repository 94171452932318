// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uddtElo0XNenR7ijIiyQ {
  margin-bottom: 10px;
}

.lyW2m7VvozLfjPLc9qUR {
  border: none;
  padding: 0;
  display: flex;
  align-items: baseline;
  background-color: inherit;
}

.SvJ5TU4bvRe_ITSWLHBg svg {
  margin-right: 8px;
  height: 14px;
  width: 14px;
}
.SvJ5TU4bvRe_ITSWLHBg svg path {
  fill: #2755fe;
}

.twiGz92mCIFPZQnzBRdT {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}

.RbcSIUqgiIv8ndfNmjAj {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #555555;
}

.V4zCOx7gQfDBCvkwE37w {
  display: flex;
  align-items: center;
}

.wp1RbOcXTojHY9fMD_be svg {
  margin-right: 8px;
  height: 14px;
  width: 14px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkListContainer": `uddtElo0XNenR7ijIiyQ`,
	"listGroupItem": `lyW2m7VvozLfjPLc9qUR`,
	"checkSvg": `SvJ5TU4bvRe_ITSWLHBg`,
	"checkTextHeading": `twiGz92mCIFPZQnzBRdT`,
	"checkText": `RbcSIUqgiIv8ndfNmjAj`,
	"listingText": `V4zCOx7gQfDBCvkwE37w`,
	"checkItemSvg": `wp1RbOcXTojHY9fMD_be`
};
export default ___CSS_LOADER_EXPORT___;
