// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._7z0a0SEwjREjHhECzrq {
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  margin-right: 0px;
}
._7z0a0SEwjREjHhECzrq:hover {
  background: #2755fe;
  color: #ffffff;
}

.lXZeCv_o1sO4YPACAMGb {
  display: block;
}

.IDS7bcEf2txWj9jIEBRB {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitButton": `_7z0a0SEwjREjHhECzrq`,
	"customFooter": `lXZeCv_o1sO4YPACAMGb`,
	"errorMsg": `IDS7bcEf2txWj9jIEBRB`
};
export default ___CSS_LOADER_EXPORT___;
