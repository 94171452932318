// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sSqbCoxqRZKLq8eS2VB9 {
  max-width: 1200px;
  margin: auto;
  position: relative;
}
@media only screen and (max-width: 1200px) {
  .sSqbCoxqRZKLq8eS2VB9 {
    width: 1200px;
  }
}
.sSqbCoxqRZKLq8eS2VB9 .TlnAQefWVpvdvf4yXIhB {
  display: flex;
  justify-content: space-between;
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.sSqbCoxqRZKLq8eS2VB9 .TlnAQefWVpvdvf4yXIhB .SDl2XnD6OrZ7boDvNS7B {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.sSqbCoxqRZKLq8eS2VB9 .WjU1eG_hx4N3xRrncZ64 {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 10px auto 7rem;
  padding: 34px 24px 0 24px;
  font-family: Poppins, sans-serif;
}
.sSqbCoxqRZKLq8eS2VB9 .WjU1eG_hx4N3xRrncZ64 .JHHUrm79XdoIckViSv7D {
  padding: 2rem 0 1rem 0;
}
.sSqbCoxqRZKLq8eS2VB9 .WjU1eG_hx4N3xRrncZ64 .JHHUrm79XdoIckViSv7D .R41FQXqC3Tp1d___FRUN {
  border: 1px solid rgba(51, 51, 51, 0.1215686275);
  border-radius: 4px;
}
.sSqbCoxqRZKLq8eS2VB9 .WjU1eG_hx4N3xRrncZ64 .Ti_jsJQvhv3OvoosK3nC {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
.sSqbCoxqRZKLq8eS2VB9 .WjU1eG_hx4N3xRrncZ64 .fdmWLwEUj2b2mP2hro3s {
  font-size: 14px;
}
.sSqbCoxqRZKLq8eS2VB9 .WjU1eG_hx4N3xRrncZ64 .Y5lgrf3CKWvmzduNQDmg {
  margin-left: 5px;
  color: #777676;
}
.sSqbCoxqRZKLq8eS2VB9 .WjU1eG_hx4N3xRrncZ64 .rHGHMOa5XjhOfGme2EA1 {
  margin-left: 6px;
  margin-bottom: 4px;
  color: rgb(228, 83, 83);
  font-size: 14px;
  height: 1.5rem;
}
.sSqbCoxqRZKLq8eS2VB9 .WjU1eG_hx4N3xRrncZ64 .Ql9mM8e4BU369GV1sTnZ {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sSqbCoxqRZKLq8eS2VB9 .WjU1eG_hx4N3xRrncZ64 .YWMeJshSKYY73D08RFZ6 {
  background: #ffe3e3;
  color: #666666;
  font-size: 12px;
  font-weight: 500;
}
.sSqbCoxqRZKLq8eS2VB9 .WjU1eG_hx4N3xRrncZ64 .aBzXrBLQojkcMccpBmYT {
  background: rgba(250, 173, 20, 0.2);
  color: #666666;
  font-size: 12px;
  font-weight: 500;
}
.sSqbCoxqRZKLq8eS2VB9 .hREPDjXCfnx2Bd3ucuJr {
  border-radius: 0;
  padding: 8.5px 20px;
  margin-bottom: -1px;
  z-index: 1;
}
.sSqbCoxqRZKLq8eS2VB9 .hREPDjXCfnx2Bd3ucuJr .g5fMkFHrbDqlNVOuOa8k {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.sSqbCoxqRZKLq8eS2VB9 .hREPDjXCfnx2Bd3ucuJr .g5fMkFHrbDqlNVOuOa8k .nFETkNFmBxdRwfudT1DA {
  vertical-align: unset;
  margin-right: 6px;
}
.sSqbCoxqRZKLq8eS2VB9 .ejpUTfjMb2hMwVV6dL1E {
  background-color: #fffbe6 !important;
  color: #595959 !important;
  border-color: #ffe58f !important;
}
.sSqbCoxqRZKLq8eS2VB9 .JYCqbkymlBrCgqIFgoC8 {
  border-color: #ffe3e3 !important;
  background-color: rgba(255, 227, 227, 0.6) !important;
  color: #464457 !important;
  font-weight: 400 !important;
}
.sSqbCoxqRZKLq8eS2VB9 .IxsZ0k8_414R2uTYY2P1 {
  color: #d6af2d !important;
}
.sSqbCoxqRZKLq8eS2VB9 .Ak4AkLGLV7ZN0r4A99DG {
  color: rgb(228, 83, 83) !important;
}

.fr0YY0bjRs7J8XahiWRm {
  border: 1px solid rgba(51, 51, 51, 0.1215686275);
  padding: 20px 0 24px 20px;
  margin: 12px 0;
  border-radius: 4px;
}
.fr0YY0bjRs7J8XahiWRm .GUmu38aHQp2QHuhz9SxW {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fr0YY0bjRs7J8XahiWRm .GUmu38aHQp2QHuhz9SxW .TJAQ2XsoZUTllFCTgGEb {
  display: flex;
  align-items: center;
  width: 70%;
}
.fr0YY0bjRs7J8XahiWRm .GUmu38aHQp2QHuhz9SxW .XGsi845CudaDtjjk8So0 {
  width: 30%;
  color: #333333;
  font-weight: 500;
}
.fr0YY0bjRs7J8XahiWRm .GUmu38aHQp2QHuhz9SxW .BJK_KjH2A0aJ8aJOZQmz {
  color: #333333;
  font-weight: 500;
}
.fr0YY0bjRs7J8XahiWRm .mo1OClNCQeLUnIDS1ya0 {
  color: #2755fe;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.bNAoeVZyuFJM8WGVzrAY {
  position: fixed;
  bottom: 0;
  background: white;
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  max-width: 1200px;
  padding: 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
}

.EP2nuBms_hlD9EQYZExF {
  font-size: 14px;
  font-weight: 400;
  color: #464457;
}

.lE5COx3OlCLCzM0mC597 {
  background-color: #2755fe !important;
  padding: 0.5rem 1.5rem;
}
.lE5COx3OlCLCzM0mC597:hover {
  background-color: #1f44ca !important;
}

.sJjA8aqiWYOXIS9eKaDg {
  margin-top: 10px;
}

.Y5lgrf3CKWvmzduNQDmg {
  margin-left: 5px;
  color: #777676;
  font-size: 16px;
}

.Cob7PMyV5boM0z4lS598 {
  font-size: 14px;
}

.t6lFgxXcixrSEud6SWS6 {
  border-radius: 0;
  border: 1px solid #2755fe;
  margin-bottom: 20px;
  padding-left: 13px;
  z-index: 1;
  height: 52px;
  background-color: rgba(39, 85, 254, 0.0784313725);
  cursor: pointer;
}
.t6lFgxXcixrSEud6SWS6 .g5fMkFHrbDqlNVOuOa8k {
  height: 20px;
  font-size: 14px;
  margin: auto 0;
  font-weight: 400;
  color: #666666;
}
.t6lFgxXcixrSEud6SWS6 .qlOLlmok9e57MKZGPPhy {
  font-weight: 500;
  color: #2755fe !important;
}
.t6lFgxXcixrSEud6SWS6 .qlOLlmok9e57MKZGPPhy:hover {
  text-decoration: underline;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summaryWrapper": `sSqbCoxqRZKLq8eS2VB9`,
	"header": `TlnAQefWVpvdvf4yXIhB`,
	"heading": `SDl2XnD6OrZ7boDvNS7B`,
	"walletCard": `WjU1eG_hx4N3xRrncZ64`,
	"walletContainer": `JHHUrm79XdoIckViSv7D`,
	"currentBalanceContainer": `R41FQXqC3Tp1d___FRUN`,
	"subheading": `Ti_jsJQvhv3OvoosK3nC`,
	"infoTooltip": `fdmWLwEUj2b2mP2hro3s`,
	"tooltipIcon": `Y5lgrf3CKWvmzduNQDmg`,
	"tooltipLockIcon": `rHGHMOa5XjhOfGme2EA1`,
	"lockToolTip": `Ql9mM8e4BU369GV1sTnZ`,
	"criticalPill": `YWMeJshSKYY73D08RFZ6`,
	"lowPill": `aBzXrBLQojkcMccpBmYT`,
	"bannerCardContainer": `hREPDjXCfnx2Bd3ucuJr`,
	"bannerDivContainer": `g5fMkFHrbDqlNVOuOa8k`,
	"warningRedIcon": `nFETkNFmBxdRwfudT1DA`,
	"lowBalance": `ejpUTfjMb2hMwVV6dL1E`,
	"criticalLow": `JYCqbkymlBrCgqIFgoC8`,
	"criticalLowColorText": `IxsZ0k8_414R2uTYY2P1`,
	"lowBalanceColorText": `Ak4AkLGLV7ZN0r4A99DG`,
	"packageCard": `fr0YY0bjRs7J8XahiWRm`,
	"packageHeader": `GUmu38aHQp2QHuhz9SxW`,
	"packageHeaderTitle": `TJAQ2XsoZUTllFCTgGEb`,
	"packageHeaderUnit": `XGsi845CudaDtjjk8So0`,
	"sectionTitle": `BJK_KjH2A0aJ8aJOZQmz`,
	"viewCheck": `mo1OClNCQeLUnIDS1ya0`,
	"bottomNav": `bNAoeVZyuFJM8WGVzrAY`,
	"rechargeText": `EP2nuBms_hlD9EQYZExF`,
	"rechargeButton": `lE5COx3OlCLCzM0mC597`,
	"noPackageBanner": `sJjA8aqiWYOXIS9eKaDg`,
	"tooltipText": `Cob7PMyV5boM0z4lS598`,
	"pendingOrderBanner": `t6lFgxXcixrSEud6SWS6`,
	"bannerText": `qlOLlmok9e57MKZGPPhy`
};
export default ___CSS_LOADER_EXPORT___;
