// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mQlTAwduT1qy_Wg8uBKf {
  width: 181px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.VXSDpuKVYT0HWWiQ1WOl {
  width: 181px;
  height: 45px;
  border: 1px solid #c2cfff;
  border-radius: 4px;
}
.VXSDpuKVYT0HWWiQ1WOl .td93d1SbgluWyI_H5_aT {
  font-size: 14px;
  color: #333333;
  display: flex;
  padding-top: 12px;
  place-content: space-between;
}
.VXSDpuKVYT0HWWiQ1WOl .td93d1SbgluWyI_H5_aT .k3r8_7Jh2jnB7gnvhZ3V {
  font-size: 13px;
  margin-top: 2px;
  padding-left: 1px;
}
.VXSDpuKVYT0HWWiQ1WOl .td93d1SbgluWyI_H5_aT .iD3nKVigZ4CkYhd6aRX2 {
  width: 150px;
}

.HfqcZ9E0AlJ_VfUFlIXw {
  font-size: 14px;
  margin-top: 1px;
}

.pPHgHRXR1dgCFo2fih4V {
  font-size: 14px;
  line-height: 20px;
}

.zY0ZPiLsZQDMNa6sGxvt {
  padding-left: 2px;
}

.hCVdfjQRWrhOKIK4dk3v {
  padding-inline: 8px;
}

.INPgtirypL_c5FCAS4Ej {
  padding-right: 2px;
}

.hCVdfjQRWrhOKIK4dk3v {
  padding-inline: 8px;
}

.ri19VtdfMhUrqcNu9bYl {
  z-index: 9999;
  position: absolute;
  margin: 7px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateFilterField": `mQlTAwduT1qy_Wg8uBKf`,
	"dateInputField": `VXSDpuKVYT0HWWiQ1WOl`,
	"dateTextField": `td93d1SbgluWyI_H5_aT`,
	"dateText": `k3r8_7Jh2jnB7gnvhZ3V`,
	"dateTextWidth": `iD3nKVigZ4CkYhd6aRX2`,
	"textDateRange": `HfqcZ9E0AlJ_VfUFlIXw`,
	"filterLabelText": `pPHgHRXR1dgCFo2fih4V`,
	"paddingLeft": `zY0ZPiLsZQDMNa6sGxvt`,
	"topTextPadding": `hCVdfjQRWrhOKIK4dk3v`,
	"paddingRight": `INPgtirypL_c5FCAS4Ej`,
	"dateRangePickerDiv": `ri19VtdfMhUrqcNu9bYl`
};
export default ___CSS_LOADER_EXPORT___;
