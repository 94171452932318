import React, { useEffect, useState } from 'react';
import styles from '../../Integrations.module.scss';
import Select from 'react-select';
import { Container, Button, Modal, Row, Col } from 'react-bootstrap';
import _, { isEmpty } from 'lodash';
import Image from '../../../core-components/Image';
import { DropdownList } from 'react-widgets';
import { deleteIntegrations, getGreenHouseCustomFields } from '../../../../../../api/company';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../core-components/Utilities/utilities';
import CreateCustomFieldModal from '../Components/Modal/CreateCustomFieldModal';
import PackageExistModal from '../Components/Modal/PackageExistModal';
import { errToastMessage } from '../../../utils/Utilities';

export default function GreenHouseSetting(props) {
  const { setFieldValue, values, handleSubmit, greenHouseConfig, status, isSubmitting } = props;
  const [customField, setCustomField] = useState([]);

  const [loading, setLoading] = useState(false);
  const [dropdownTags, setDropdownTags] = useState([]);
  const [selectedCustomFieldOptions, setSelectedCustomFieldOptions] = useState({});
  const [isOpenPackageAssignedModal, setIsOpenPackageAssignedModal] = useState(false);
  const [isOpenCreateCustomFieldModal, setIsOpenCreateCustomFieldModal] = useState(false);
  const [onCreateManuallyOrAutomatically, setOnCreateManuallyOrAutomatically] = useState('none');
  const [apiCalledForRefresh, setApiCalledForRefresh] = useState('none');
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;

  useEffect(() => {
    props.getViewBuyPackages({ limit: 50, offset: 0, company_id: companyId });
    props.getIntegrationFieldSubtypeMappingAPI({ integrationName: 'GREENHOUSE' });
    props.getGreenHouseCustomFieldJob();

    return () => {
      props?.invalidateViewBuyPackages();
      props.invalidateIntegrationFieldSubtypeMapping();
    };
  }, []);

  useEffect(() => {
    if (apiCalledForRefresh == 'none') {
      if (props?.viewBuyPackages?.rows?.length === 0) {
        setIsOpenPackageAssignedModal(true);
      } else if (
        props?.integrationFieldSubtypeMapping?.data?.length > 0 &&
        !props?.integrationFieldSubtypeMapping?.loading
      ) {
        prePopulateFieldsAndOption();
      } else if (
        props?.integrationFieldSubtypeMapping?.data?.length === 0 &&
        !props?.integrationFieldSubtypeMapping?.loading
      ) {
        setIsOpenCreateCustomFieldModal(true);
      }
    } else if (apiCalledForRefresh == 'customFieldOptionsRefreshed') {
      refreshCustomFieldOptionTags();
    }
  }, [
    props.viewBuyPackages,
    props.integrationFieldSubtypeMapping,
    props?.greenHouseCustomFieldJobs
  ]);

  const refreshCustomFieldOptionTags = () => {
    const extractedCustomFieldObj = props.greenHouseCustomFieldJobs.data.filter(
      (val) => val.id == values?.customField?.value
    );
    const extractedCustomFieldOptions = extractedCustomFieldObj[0]?.customFieldOptions.map(
      (options) => {
        return { value: options.id, label: options.name };
      }
    );
    const allTagsArr = Object.values(values?.customFieldOptions).map(
      (obj) => !isEmpty(obj) && obj.tags
    );

    setDropdownTags(_.differenceBy(extractedCustomFieldOptions, _.union(...allTagsArr), 'label'));

    let allSubtypesWithCustomFieldOptions = _.cloneDeep(selectedCustomFieldOptions);

    //This will remove the already selected customFieldOptions if not present in the API response
    Object.keys(allSubtypesWithCustomFieldOptions).forEach((key) => {
      allSubtypesWithCustomFieldOptions[key].tags = allSubtypesWithCustomFieldOptions[
        key
      ].tags.filter((val) => {
        return val.label == _.find(extractedCustomFieldOptions, { label: val.label })?.label;
      });
    });

    setSelectedCustomFieldOptions(allSubtypesWithCustomFieldOptions);
    setFieldValue('customFieldOptions', allSubtypesWithCustomFieldOptions);
  };

  const prePopulateFieldsAndOption = () => {
    if (
      props?.greenHouseCustomFieldJobs?.data &&
      props?.greenHouseCustomFieldJobs?.data.length > 0
    ) {
      const extractedCustomFieldObj = props?.greenHouseCustomFieldJobs?.data.filter(
        (val) => val.id == props?.integrationData.greenhouse?.stageDetails?.stageId
      );

      const extractedCustomFieldOptions = extractedCustomFieldObj[0]?.customFieldOptions.map(
        (options) => {
          return { value: options?.id, label: options?.name };
        }
      );

      const mappedCustomFieldOptions = {};

      props?.integrationFieldSubtypeMapping?.data.map((val) => {
        mappedCustomFieldOptions[val.subtype_id_fk] = {
          packageId: val?.package_id_fk,
          tags: val.integration_field_value.split(';').map((item, index) => {
            return { label: item, value: index };
          })
        };
      });
      const allTagsArr = Object.values(mappedCustomFieldOptions).map(
        (obj) => !isEmpty(obj) && obj?.tags
      );
      setDropdownTags(_.differenceBy(extractedCustomFieldOptions, _.union(...allTagsArr), 'label'));

      // setFieldValue(
      //   'dropDownTags',
      //   _.differenceBy(extractedCustomFieldOptions, _.union(...allTagsArr), 'label')
      // );
      setSelectedCustomFieldOptions(mappedCustomFieldOptions);
      setCustomField(
        props?.greenHouseCustomFieldJobs?.data.map((field, index) => {
          return { id: index, name: field.name, value: field.id };
        })
      );
      setFieldValue('customFieldOptions', mappedCustomFieldOptions);
      setFieldValue('customField', {
        value: extractedCustomFieldObj[0]?.id,
        name: extractedCustomFieldObj[0]?.name
      });
    }
  };

  useEffect(() => {
    if (onCreateManuallyOrAutomatically === 'automatic') {
      fetchCustomFieldsOptionsAutomatic();
    } else if (onCreateManuallyOrAutomatically === 'manual') {
      setCustomField(
        props?.greenHouseCustomFieldJobs?.data &&
          props?.greenHouseCustomFieldJobs?.data.length &&
          props?.greenHouseCustomFieldJobs?.data.map((field, index) => {
            return { id: index, name: field.name, value: field.id };
          })
      );
    }
  }, [onCreateManuallyOrAutomatically]);

  const fetchCustomFieldsOptionsAutomatic = () => {
    getGreenHouseCustomFields().then((res) => {
      const customFieldObj = { value: res?.data?.data?.id, name: res?.data?.data?.name };
      const customFieldOptionsArr = res.data.data.custom_field_options.map((options) => {
        return { value: options.id, label: options.name };
      });
      setCustomField([customFieldObj]);
      // setFieldValue('dropDownTags', customFieldOptionsArr);
      setDropdownTags(customFieldOptionsArr);
      setSelectedCustomFieldOptions([]);
      setFieldValue('customField', customFieldObj);
      setFieldValue('customFieldOptions', {});
    });
  };

  const updateDropDownTag = (value) => {
    //Function to updating dropdown values of options in case of manual
    if (onCreateManuallyOrAutomatically !== 'automatic') {
      const extractedCustomFieldOptions = props.greenHouseCustomFieldJobs.data[
        value.id
      ].customFieldOptions.map((options) => {
        return { value: options.id, label: options.name };
      });
      setDropdownTags(extractedCustomFieldOptions);
      // setFieldValue('dropDownTags', extractedCustomFieldOptions);
      setSelectedCustomFieldOptions([]);
      setFieldValue('customFieldOptions', {});
    }
  };

  const handleDelete = () => {
    setLoading(true);
    deleteIntegrations('GREENHOUSE', companyId)
      .then((resp) => {
        toast.success('Removed connection successfully.');
        backHandler();
      })
      .catch((error) => {
        errToastMessage(error);
        setLoading(false);
      });
  };

  const isFormValid = () => {
    return (
      !isEmpty(values.customField) &&
      values.customField?.name &&
      Object.values(values?.customFieldOptions).filter(
        (customFieldOption) => customFieldOption && !isEmpty(customFieldOption)
      ).length === props?.viewBuyPackages?.rows?.length
    );
  };

  if (status && status?.trigger && status?.customFieldOptions) {
    setTimeout(() => {
      backHandler();
    }, 1000);
  }

  const backHandler = () => {
    props.history.push('/integrations');
  };

  const tagDropdownStyles = {
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Poppins',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#666666',
      borderRadius: '4px',
      padding: '10px 20px',
      border: 'none',
      background: state.isFocused ? '#ffffff' : ''
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #ebedf2',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#333333',
      display: 'flex'
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#333333'
      };
    },
    valueContainer: (base) => ({
      ...base
    }),
    container: (base) => ({
      ...base
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '10'
    })
  };
  const tagsSeparator = ({ innerProps }) => {
    return <span style={{ display: 'none' }} {...innerProps} />;
  };

  const onClickRefreshHandler = (refreshClickedType) => {
    if (onCreateManuallyOrAutomatically === 'automatic') {
      fetchCustomFieldsOptionsAutomatic();
    } else {
      props.getGreenHouseCustomFieldJob();
    }
    setApiCalledForRefresh(refreshClickedType);
  };
  const setCustomFieldOptionsValue = (packageId, subtypeId, value, action) => {
    if (action) {
      //If action is present means value is removed so we have to again add that value to the dropdown array
      setDropdownTags([...dropdownTags, action]);
    } else {
      setDropdownTags(_.difference(dropdownTags, value));
    }

    let allSubtypesWithCustomFieldOptions = { ...selectedCustomFieldOptions };
    allSubtypesWithCustomFieldOptions[subtypeId] =
      value && value.length > 0 ? { packageId: packageId, tags: [...value] } : {};
    setSelectedCustomFieldOptions(allSubtypesWithCustomFieldOptions);
    setFieldValue('customFieldOptions', allSubtypesWithCustomFieldOptions);
  };
  const automaticallyOrManuallyHandler = (type) => {
    setIsOpenCreateCustomFieldModal(false);
    setOnCreateManuallyOrAutomatically(type);
  };
  if (isOpenPackageAssignedModal) {
    return (
      <PackageExistModal
        show={isOpenPackageAssignedModal}
        onHide={() => {
          setIsOpenPackageAssignedModal(false);
        }}
        title={'Packages Assigned'}
        onConfirm={() => {
          setIsOpenPackageAssignedModal(false);
        }}
      />
    );
  } else if (isOpenCreateCustomFieldModal) {
    return (
      <CreateCustomFieldModal
        show={isOpenCreateCustomFieldModal}
        onHide={() => {
          setIsOpenCreateCustomFieldModal(false);
        }}
        title={'Custom Field on Greenhouse'}
        onCreateManually={() => {
          automaticallyOrManuallyHandler('manual');
        }}
        onCreateAutomatically={() => {
          automaticallyOrManuallyHandler('automatic');
        }}
      />
    );
  } else {
    return (
      <Container>
        <div className={styles.settingPageContainer}>
          <Row className='justify-content-between'>
            <span className={styles.settingPageHeaderText}>
              Select Custom Field for Package Identification
            </span>
          </Row>
          <Row className={styles.settingPageHeaderSubText}>
            Select the custom field in Greenhouse that contains the package identifier
          </Row>
        </div>
        <Row className={styles.dropdownContainer}>
          <DropdownList
            style={{ width: '300px' }}
            data={customField}
            valueField='id'
            textField='name'
            name='customField'
            selectIcon={<Image name='dropdownArrow.svg' />}
            value={values.customField}
            onChange={(value) => {
              setFieldValue('customField', value);
              updateDropDownTag(value);
            }}
          />
          <div
            onClick={() => onClickRefreshHandler('customField')}
            className={styles.refreshContainer}
          >
            <Image className={styles.refreshImageContainer} name='reset.png' />
            <span className={styles.refreshText}>Refresh</span>
          </div>
        </Row>

        <div className={styles.secondPageSubSectionContainer}>
          <Row>
            <span className={styles.settingPageSubSectionHeader}> Setup Package Association</span>
          </Row>
          <Row className={styles.settingPageSubSectionList}>
            Match the values under SpringVerify BGV Package from Greenhouse to the package on
            SpringVerify
          </Row>
        </div>

        <Row className={styles.packageSelectorHeaderContainer} xs={2} md={4} lg={6}>
          <Col md={3} className={styles.packageNameHeader}>
            <div className={styles.packageTextStyle}>PACKAGE NAME</div>
          </Col>
          <Col className={styles.refreshPackageContainer}>
            <div className={styles.packageTextStyle}>SELECT TAG</div>
            <div
              className={styles.refreshPackageButton}
              onClick={() => onClickRefreshHandler('customFieldOptionsRefreshed')}
            >
              <Image className={styles.refreshImageContainer} name='reset.png' />
              <span className={styles.refreshText}>Refresh</span>
            </div>
          </Col>
        </Row>
        {props?.viewBuyPackages?.rows?.map((packageRow) => (
          <Row xs={2} md={4} lg={6} className='my-2'>
            <Col md={3} className={styles.packageName}>
              <div className={styles.text}>
                <span className={styles.packageNameText}>{packageRow.packageName}</span>
                <span className={styles.packageSubtypeNameText}>{packageRow.subtypeName}</span>
              </div>
            </Col>
            <Col>
              <Select
                className={styles.tagsDropDown}
                isMulti
                placeholder='No custom field options'
                name='customFieldOptions'
                components={{ tagsSeparator }}
                options={dropdownTags}
                styles={tagDropdownStyles}
                isClearable={false}
                noOptionsMessage={() => (
                  <span className={styles.dropDownNoOptionMessage}>
                    No custom field options available. Please create relevant custom field options
                    on GreenHouse and refresh.
                  </span>
                )}
                value={values?.customFieldOptions[packageRow.subtypeId]?.tags || []}
                onChange={(selected, action) => {
                  setCustomFieldOptionsValue(
                    packageRow.packageId,
                    packageRow.subtypeId,
                    selected,
                    action?.removedValue
                  );
                }}
              />
            </Col>
          </Row>
        ))}

        <div className={styles.footerButtonContainer}>
          <Button className={styles.goBackBtn} onClick={() => backHandler()}>
            Go back
          </Button>
          <div>
            <Button
              className={styles.cancelBtn}
              onClick={handleDelete}
              disabled={!greenHouseConfig || isEmpty(greenHouseConfig) || loading || isSubmitting}
            >
              Delete Connection
            </Button>
            <Button
              className={styles.activeBtn}
              onClick={handleSubmit}
              disabled={!isFormValid() || loading || isSubmitting}
            >
              Save
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}
