// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V3lKx4tqYgdZl1POWW0x {
  background: #1f1630;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}
@media screen and (max-width: 601px) {
  .V3lKx4tqYgdZl1POWW0x {
    font-size: 0.8em;
    display: none;
  }
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ {
  width: 100%;
  padding: 25px 80px;
  justify-content: space-between;
  margin: auto;
  max-width: 1500px;
  display: flex;
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ .bE3_NixZR2wTW2GBGnNi {
  max-width: 800px;
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ .bE3_NixZR2wTW2GBGnNi .dI9wK6nj6qiy5gMikdpw {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 3.04px;
  line-height: 27px;
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ .bE3_NixZR2wTW2GBGnNi ._ubGIx_1MSTx68jHo7js {
  color: #898989;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ ._uwSxi8aRNAyW15cfGF_ {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ ._uwSxi8aRNAyW15cfGF_ .QLC4DjtEC4Pmj5ijjtYs {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ ._uwSxi8aRNAyW15cfGF_ .QLC4DjtEC4Pmj5ijjtYs a {
  color: #ffffff;
  font-weight: 400;
  text-decoration: none;
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ ._uwSxi8aRNAyW15cfGF_ .QLC4DjtEC4Pmj5ijjtYs a:hover {
  color: #dddddd;
  text-decoration: none;
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ ._uwSxi8aRNAyW15cfGF_ .QLC4DjtEC4Pmj5ijjtYs a:visited {
  color: #ffffff;
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ ._uwSxi8aRNAyW15cfGF_ .QLC4DjtEC4Pmj5ijjtYs::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.5450980392);
  bottom: -3px;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s;
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ ._uwSxi8aRNAyW15cfGF_ .QLC4DjtEC4Pmj5ijjtYs:hover::before {
  transform: scaleX(1);
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ ._uwSxi8aRNAyW15cfGF_ .koVu3KIV7EVdFt7AR5hj {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;
}
.V3lKx4tqYgdZl1POWW0x .xfUi_sGO2mZ8aSI_QQ3_ ._uwSxi8aRNAyW15cfGF_ .koVu3KIV7EVdFt7AR5hj img {
  width: 58px;
  height: 58px;
  opacity: 60%;
}

.cv_FlNj8B7BWeNaRRiG7 {
  position: absolute;
  bottom: 0;
}

.bd_L7JOXdFQ8yCNNo30y {
  margin-top: 15px;
}
.bd_L7JOXdFQ8yCNNo30y .zAr5wCH_1pmrzJaf6QXm {
  color: #898989;
  margin-right: 4px;
  font-weight: 400;
}
.bd_L7JOXdFQ8yCNNo30y .jwMKSyybKJjcCeBF03fQ {
  color: #898989;
  margin-right: 5px;
  font-weight: 400;
}
.bd_L7JOXdFQ8yCNNo30y .WkYrFY7xqzjSKUjxLwW5 {
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
  color: #366cf3;
  font-weight: 400;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgvFooterContainer": `V3lKx4tqYgdZl1POWW0x`,
	"bgvFooterItems": `xfUi_sGO2mZ8aSI_QQ3_`,
	"aboutContainer": `bE3_NixZR2wTW2GBGnNi`,
	"aboutUs": `dI9wK6nj6qiy5gMikdpw`,
	"paragraph": `_ubGIx_1MSTx68jHo7js`,
	"bgvFooterItemCopyright": `_uwSxi8aRNAyW15cfGF_`,
	"valuttext": `QLC4DjtEC4Pmj5ijjtYs`,
	"logosx": `koVu3KIV7EVdFt7AR5hj`,
	"footerDivHeight10": `cv_FlNj8B7BWeNaRRiG7`,
	"copyright": `bd_L7JOXdFQ8yCNNo30y`,
	"copyRightText": `zAr5wCH_1pmrzJaf6QXm`,
	"andCopy": `jwMKSyybKJjcCeBF03fQ`,
	"linkText": `WkYrFY7xqzjSKUjxLwW5`
};
export default ___CSS_LOADER_EXPORT___;
