// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RLIazZ3LzSippAiSWKrD {
  display: inline-block;
  transition: all 0.5s ease;
  margin-right: 15px;
}

.RLIazZ3LzSippAiSWKrD:hover {
  transform: scale(2);
  color: black;
  fill: black;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"star": `RLIazZ3LzSippAiSWKrD`
};
export default ___CSS_LOADER_EXPORT___;
