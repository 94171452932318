// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fUXKYuhKRqhMvsgUQ0AX {
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz {
  border: none;
  cursor: pointer;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .eodJGMIssnooFhup7smj {
  display: flex;
  background: #ffffff;
  border: none;
  padding-left: 0;
  padding-right: 0;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .eodJGMIssnooFhup7smj .qFWRG8YBgsTLGQJgN3_X {
  position: relative;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .eodJGMIssnooFhup7smj .qFWRG8YBgsTLGQJgN3_X .zGfDGB7xO_onWkGvGjs2 {
  position: absolute;
  top: -6px;
  left: 11px;
  font-size: 12px;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .eodJGMIssnooFhup7smj .XUYH6Gy4IXGm7lW4BQwg {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 8px;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .eodJGMIssnooFhup7smj .XUYH6Gy4IXGm7lW4BQwg .vuRJFipPxS64vxbdmrTx {
  display: flex;
  text-align: right;
  font-weight: normal;
  font-size: 14px;
  color: #666666;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .eodJGMIssnooFhup7smj .XUYH6Gy4IXGm7lW4BQwg .vuRJFipPxS64vxbdmrTx .gU8m7Bp5Ug3ozlCUUAXY {
  font-family: roboto;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .eodJGMIssnooFhup7smj .XUYH6Gy4IXGm7lW4BQwg .vuRJFipPxS64vxbdmrTx .YNyfDRZucHHShbADBd3i {
  margin-right: 8px;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .XerdtlZ5ObGkEGS6cbKN {
  padding-left: 0;
  padding-right: 0;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .XerdtlZ5ObGkEGS6cbKN .odw4awqDK5oep_ild6Sb {
  margin-left: 30px;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .XerdtlZ5ObGkEGS6cbKN .odw4awqDK5oep_ild6Sb .Y8SxHT8C4paueBB_aShi {
  border: none;
  padding: 0;
  display: flex;
  align-items: baseline;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .XerdtlZ5ObGkEGS6cbKN .odw4awqDK5oep_ild6Sb .Y8SxHT8C4paueBB_aShi .yV_ITZWbuLg5CsK7uUmY,
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .XerdtlZ5ObGkEGS6cbKN .odw4awqDK5oep_ild6Sb .Y8SxHT8C4paueBB_aShi .dGcQcLDOFh2HY37SIMC8 {
  font-weight: normal;
  font-size: 14px;
  color: #595959;
  margin-bottom: 12px;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .XerdtlZ5ObGkEGS6cbKN .odw4awqDK5oep_ild6Sb .Y8SxHT8C4paueBB_aShi .beNjtUf78DYANEIy_j09 {
  margin-bottom: 0;
}
.fUXKYuhKRqhMvsgUQ0AX .al_o_IfBzOo1Qbk6IZUz .XerdtlZ5ObGkEGS6cbKN .odw4awqDK5oep_ild6Sb .Y8SxHT8C4paueBB_aShi .zKD54SmQmWjDCZKSAvkm {
  margin-left: 18px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addonsContainer": `fUXKYuhKRqhMvsgUQ0AX`,
	"addonsContainerCard": `al_o_IfBzOo1Qbk6IZUz`,
	"addonsContainerHeader": `eodJGMIssnooFhup7smj`,
	"iconContainer": `qFWRG8YBgsTLGQJgN3_X`,
	"plusIcon": `zGfDGB7xO_onWkGvGjs2`,
	"headingRightContainer": `XUYH6Gy4IXGm7lW4BQwg`,
	"headingRight": `vuRJFipPxS64vxbdmrTx`,
	"rupee": `gU8m7Bp5Ug3ozlCUUAXY`,
	"startFrom": `YNyfDRZucHHShbADBd3i`,
	"cardBody": `XerdtlZ5ObGkEGS6cbKN`,
	"listGroup": `odw4awqDK5oep_ild6Sb`,
	"listGroupItem": `Y8SxHT8C4paueBB_aShi`,
	"otherText": `yV_ITZWbuLg5CsK7uUmY`,
	"label": `dGcQcLDOFh2HY37SIMC8`,
	"formGroup": `beNjtUf78DYANEIy_j09`,
	"listGroupInside": `zKD54SmQmWjDCZKSAvkm`
};
export default ___CSS_LOADER_EXPORT___;
