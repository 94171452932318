// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BMmKvxUpk6LUf9TkHrqF {
  min-height: 600px;
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
  padding: 34px 24px 0 24px;
}
.BMmKvxUpk6LUf9TkHrqF .b6wA0STxknNdRAuJMpVB {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  margin-left: 12px;
}
.BMmKvxUpk6LUf9TkHrqF .sjBgJSUM7PiE8HzIy2OB {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
}
.BMmKvxUpk6LUf9TkHrqF .sjBgJSUM7PiE8HzIy2OB .EK6oztvvexlpl1dsjlNz {
  margin-top: 5px;
  margin-right: 25px;
}
.BMmKvxUpk6LUf9TkHrqF .sjBgJSUM7PiE8HzIy2OB .WlBLsqi4gx6kPlW3XGBQ {
  margin-right: 12px;
  border-color: #6558f5;
  color: #6558f5;
}
.BMmKvxUpk6LUf9TkHrqF .sjBgJSUM7PiE8HzIy2OB .WlBLsqi4gx6kPlW3XGBQ:hover {
  background-color: #6558f5;
  color: white;
}
.BMmKvxUpk6LUf9TkHrqF .sjBgJSUM7PiE8HzIy2OB .BWd4hPmUxGBCdRZI8x8A {
  background-color: #6558f5;
}
.BMmKvxUpk6LUf9TkHrqF .sjBgJSUM7PiE8HzIy2OB .bIf0pXb_WEqGATOeY9vf {
  background: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.26);
  margin-right: 4px;
}
.BMmKvxUpk6LUf9TkHrqF .V1WNu0gjIQL74W3iY57b {
  font-family: Poppins;
  padding-top: 22px;
  padding-left: 2px;
}
.BMmKvxUpk6LUf9TkHrqF .V1WNu0gjIQL74W3iY57b .lxVodq30ScFo7dLeV1v3 {
  margin-left: 12px;
  font-size: 16px;
}
.BMmKvxUpk6LUf9TkHrqF .V1WNu0gjIQL74W3iY57b .iJEItFx2yiVgWqU709BQ {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.BMmKvxUpk6LUf9TkHrqF .V1WNu0gjIQL74W3iY57b .iJEItFx2yiVgWqU709BQ .GVClkHhJxZu0uIMBsGoB {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 12px;
}
.BMmKvxUpk6LUf9TkHrqF .V1WNu0gjIQL74W3iY57b .iJEItFx2yiVgWqU709BQ .GVClkHhJxZu0uIMBsGoB .lPH4VcaQ5tXAw_520n95 {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  flex-direction: row;
}
.BMmKvxUpk6LUf9TkHrqF .V1WNu0gjIQL74W3iY57b .iJEItFx2yiVgWqU709BQ .GVClkHhJxZu0uIMBsGoB .DqfrleZc2fINAgAJ7OcA {
  background-color: #2755fe;
  border: none;
  margin-top: 50px;
}
.BMmKvxUpk6LUf9TkHrqF .V1WNu0gjIQL74W3iY57b .iJEItFx2yiVgWqU709BQ .GVClkHhJxZu0uIMBsGoB .DqfrleZc2fINAgAJ7OcA:hover {
  background-color: #2755fe;
  cursor: pointer;
}
.BMmKvxUpk6LUf9TkHrqF .V1WNu0gjIQL74W3iY57b .iJEItFx2yiVgWqU709BQ .GVClkHhJxZu0uIMBsGoB .Mtc1oYTTIit3LEBD_G_2 {
  margin-top: 12px;
  color: red;
  width: 100%;
  font-size: 80%;
}
.BMmKvxUpk6LUf9TkHrqF .V1WNu0gjIQL74W3iY57b .iJEItFx2yiVgWqU709BQ .GVClkHhJxZu0uIMBsGoB .xoLqfsjGsJsEVmeAI2U1 {
  margin-top: 10px;
}
.BMmKvxUpk6LUf9TkHrqF .V1WNu0gjIQL74W3iY57b .iJEItFx2yiVgWqU709BQ .GVClkHhJxZu0uIMBsGoB .xoLqfsjGsJsEVmeAI2U1 .lPH4VcaQ5tXAw_520n95 {
  color: #757e87;
}
.BMmKvxUpk6LUf9TkHrqF .V1WNu0gjIQL74W3iY57b .xQDiLZrcIbW4cTBbmDUc {
  border-left: 2px solid #9eadba;
  height: 100%;
  position: absolute;
}

.pHclevRdeS04oSPc2_Us {
  overflow-x: auto;
}
.pHclevRdeS04oSPc2_Us .lK2ZXzUXPpyb_oZxKgFa .oxfzNt1XlThE0tbY7vxP {
  font-weight: 600;
  font-size: 14px;
}
.pHclevRdeS04oSPc2_Us .lK2ZXzUXPpyb_oZxKgFa .XZKQaqk2HwAOMkukpkbC {
  background-color: #c3cfd9;
}
.pHclevRdeS04oSPc2_Us .lK2ZXzUXPpyb_oZxKgFa .JNtG7ogDChj0mJN1XcVu {
  background-color: #96c3ec;
}
.pHclevRdeS04oSPc2_Us .lK2ZXzUXPpyb_oZxKgFa .OVUaPjlbuN6BhHhAfi2m {
  color: #2b3a46;
  font-size: 12px;
}
.pHclevRdeS04oSPc2_Us .ZZDDNPr069PK7TvHT7ig {
  border: 1px solid red;
  width: 120px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exEmpCard": `BMmKvxUpk6LUf9TkHrqF`,
	"heading": `b6wA0STxknNdRAuJMpVB`,
	"buttons": `sjBgJSUM7PiE8HzIy2OB`,
	"footerLabel": `EK6oztvvexlpl1dsjlNz`,
	"backButton": `WlBLsqi4gx6kPlW3XGBQ`,
	"nextButton": `BWd4hPmUxGBCdRZI8x8A`,
	"disableNextButton": `bIf0pXb_WEqGATOeY9vf`,
	"exEmp": `V1WNu0gjIQL74W3iY57b`,
	"columns": `lxVodq30ScFo7dLeV1v3`,
	"secondheader": `iJEItFx2yiVgWqU709BQ`,
	"filters": `GVClkHhJxZu0uIMBsGoB`,
	"label": `lPH4VcaQ5tXAw_520n95`,
	"approveBtn": `DqfrleZc2fINAgAJ7OcA`,
	"errorLabel": `Mtc1oYTTIit3LEBD_G_2`,
	"filterItems": `xoLqfsjGsJsEVmeAI2U1`,
	"divider": `xQDiLZrcIbW4cTBbmDUc`,
	"tableContainer": `pHclevRdeS04oSPc2_Us`,
	"exEmpTable": `lK2ZXzUXPpyb_oZxKgFa`,
	"header": `oxfzNt1XlThE0tbY7vxP`,
	"noDataHeader": `XZKQaqk2HwAOMkukpkbC`,
	"dataHeader": `JNtG7ogDChj0mJN1XcVu`,
	"rowData": `OVUaPjlbuN6BhHhAfi2m`,
	"errorField": `ZZDDNPr069PK7TvHT7ig`
};
export default ___CSS_LOADER_EXPORT___;
