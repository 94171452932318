// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WJNUdN2Bm6gElJvKH05t {
  font-size: 18px;
  color: #4c4c4c;
  margin-bottom: 28px;
  display: flex;
}

.Ulyer07fR0Vi4jGsL51h {
  margin-right: 24px;
  background-color: #fff;
  padding: 0 12px 0 4px;
  font-size: 14px;
  color: #4c4c4c;
}
.Ulyer07fR0Vi4jGsL51h label {
  color: #4c4c4c;
  font-size: 14px;
  display: inline-block;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question": `WJNUdN2Bm6gElJvKH05t`,
	"option": `Ulyer07fR0Vi4jGsL51h`
};
export default ___CSS_LOADER_EXPORT___;
