// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.awj0Gdo5fDKqI_AbsX3w {
  position: relative;
  display: inline-flex;
  padding: 5px 12px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.nCmjYbaPBYfRN3e7UxGh {
  font-size: 14px;
  color: #333;
  border-right: 1px solid grey;
  padding-right: 4px;
}

.Mjk2EJYME9WRKmrixslb .HweFAMYmBl5KA_BLBtsd {
  visibility: hidden;
  position: absolute;
  top: 38px;
  right: 0px;
  background-color: grey;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 3px 5px;
}

.Mjk2EJYME9WRKmrixslb:hover .HweFAMYmBl5KA_BLBtsd {
  visibility: visible;
}

.spETDKFAbnP8SToFF6Ca {
  width: 21px;
  height: 21px;
  margin-left: 15px;
}

.spETDKFAbnP8SToFF6Ca:hover {
  cursor: pointer;
}

.OqGiM9NCAaoHIp8TIeAT {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c4e8d0;
  color: #4ea56c;
  padding: 3px 7px;
  border-radius: 24px;
}
.OqGiM9NCAaoHIp8TIeAT .xnM2S4cZRY32ujU3Hrdz {
  font-size: 15px;
  font-weight: 700;
  margin-top: 1px;
  margin-left: 2px;
}
.OqGiM9NCAaoHIp8TIeAT .r9pFSHRRI9bu_lAjfOyR {
  stroke-width: 40px !important;
}

.jPY46PC9CQUQ9XVIeMiI {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 170, 0, 0.1);
  color: #ffaa00;
  padding: 3px 7px;
  border-radius: 24px;
}
.jPY46PC9CQUQ9XVIeMiI .YGCryb2TlEikBs3fesO8 {
  font-size: 15px;
  font-weight: 700;
  margin-top: 1px;
  margin-left: 2px;
}
.jPY46PC9CQUQ9XVIeMiI .r9pFSHRRI9bu_lAjfOyR {
  stroke-width: 40px !important;
}

.T0WzeP3sKyzZIwrJOFme {
  display: flex;
  align-items: center;
}

.N7hb4O8E_K1SuZN1olGc {
  --max-number: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-number);
  -webkit-box-orient: vertical;
  font-size: 14px;
  color: #333;
  border-right: 1px solid grey;
  padding-right: 4px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generateTokenButton__container": `awj0Gdo5fDKqI_AbsX3w`,
	"generateTokenButton__text": `nCmjYbaPBYfRN3e7UxGh`,
	"tooltip": `Mjk2EJYME9WRKmrixslb`,
	"tooltiptext": `HweFAMYmBl5KA_BLBtsd`,
	"copyToClipboard": `spETDKFAbnP8SToFF6Ca`,
	"connectedFlag": `OqGiM9NCAaoHIp8TIeAT`,
	"connectedFlagText": `xnM2S4cZRY32ujU3Hrdz`,
	"icon": `r9pFSHRRI9bu_lAjfOyR`,
	"comingSoonFlag": `jPY46PC9CQUQ9XVIeMiI`,
	"comingSoonFlagText": `YGCryb2TlEikBs3fesO8`,
	"textContainer": `T0WzeP3sKyzZIwrJOFme`,
	"cutoffText": `N7hb4O8E_K1SuZN1olGc`
};
export default ___CSS_LOADER_EXPORT___;
