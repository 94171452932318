// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.GWR5Q4ptxXp9Jj_h3729 {
  color: #000000;
  font-weight: 500;
  font-size: 22px;
}

.woITa1fAEgpPFw6c4pgq {
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: -10px;
}

.hGaKmGMFpMbJ12zbXoiW {
  margin-top: 15px;
}

.KUjdhhriL74Go9XevnG6 {
  font-weight: 600;
  font-size: 16px;
}

.HtOdGa3ciGtDzER0nHYv {
  font-size: 14px;
  color: #999999;
}

.QxRU3bRwbUB5_0g0JzkR {
  color: #dc3545;
  font-size: 13px;
}

.HTQeK56_LM0hrnEDi7t9 {
  color: #dc3545;
  font-size: 13px;
  margin-bottom: 32px;
  margin-top: -28px;
}

.ILV_Fb6rp5Mx0c5_gdmy {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
}

.BHFznWrRICvTmxP5KQGl input {
  border: 1px solid #dc3545 !important;
  margin-bottom: 0 !important;
}

.RR78HQLb6VfpkQtmCGVk {
  margin-left: 20px;
}
.RR78HQLb6VfpkQtmCGVk label {
  font-weight: 400 !important;
}

.jqtNDaL7LC4CI2FPAR9K {
  justify-content: unset !important;
}

.nQ5xboI5GmYOSGW23s3E {
  font-size: 14px;
  margin-top: 3px;
  font-weight: 600;
}

.ZIlCRdKLCaJGBeNDZqyJ {
  margin-top: 20px;
  padding: 0 !important;
  justify-content: flex-end !important;
}
@media screen and (max-width: 601px) {
  .ZIlCRdKLCaJGBeNDZqyJ {
    position: fixed;
    left: 0;
    margin: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
  }
  .ZIlCRdKLCaJGBeNDZqyJ button {
    height: 40px;
    margin: 0 !important;
  }
  .ZIlCRdKLCaJGBeNDZqyJ .Y8TaAIxbQgwnspuKfpyp {
    flex: 1;
  }
  .ZIlCRdKLCaJGBeNDZqyJ .uiRwY_v27vgRFvKH62rQ {
    flex: 1;
  }
}

.L5w068skfKhJ3M_2yhUE {
  padding-bottom: 40px !important;
}
@media screen and (max-width: 601px) {
  .L5w068skfKhJ3M_2yhUE {
    padding-bottom: 65px !important;
  }
}

.zMspzDrvX5J3o0Mlol73 {
  padding: 0 5px !important;
  display: flex;
}
.zMspzDrvX5J3o0Mlol73 .OeiAIufwRlZPTVa91YLK {
  width: 150px;
}
.zMspzDrvX5J3o0Mlol73 .JPOvWpP9OsKp82eLebpa {
  width: 250px;
  margin-left: 10px;
}
.zMspzDrvX5J3o0Mlol73 .JPOvWpP9OsKp82eLebpa .CfCvjZEqsulF1KsFiyao {
  width: 100%;
}
.zMspzDrvX5J3o0Mlol73 .JPOvWpP9OsKp82eLebpa .ILV_Fb6rp5Mx0c5_gdmy {
  width: 100%;
}
.zMspzDrvX5J3o0Mlol73 .nULon57TuT8uTtJ3zlQe {
  padding: 0 !important;
}
@media screen and (max-width: 601px) {
  .zMspzDrvX5J3o0Mlol73 .OeiAIufwRlZPTVa91YLK {
    width: 130px;
  }
  .zMspzDrvX5J3o0Mlol73 .JPOvWpP9OsKp82eLebpa {
    width: auto;
    flex: 1;
  }
}

.GmE53oGt2rhNdehSzKEw {
  margin-bottom: 32px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step2Title": `GWR5Q4ptxXp9Jj_h3729`,
	"step2SubTitle": `woITa1fAEgpPFw6c4pgq`,
	"Row": `hGaKmGMFpMbJ12zbXoiW`,
	"warningHeading": `KUjdhhriL74Go9XevnG6`,
	"warningSubHeading": `HtOdGa3ciGtDzER0nHYv`,
	"error": `QxRU3bRwbUB5_0g0JzkR`,
	"relationError": `HTQeK56_LM0hrnEDi7t9`,
	"errorMsg": `ILV_Fb6rp5Mx0c5_gdmy`,
	"errorInput": `BHFznWrRICvTmxP5KQGl`,
	"radioBtn": `RR78HQLb6VfpkQtmCGVk`,
	"relativePhoneContainer": `jqtNDaL7LC4CI2FPAR9K`,
	"checkbox": `nQ5xboI5GmYOSGW23s3E`,
	"modalFooter": `ZIlCRdKLCaJGBeNDZqyJ`,
	"activeBtn": `Y8TaAIxbQgwnspuKfpyp`,
	"disabledBtn": `uiRwY_v27vgRFvKH62rQ`,
	"modalBody": `L5w068skfKhJ3M_2yhUE`,
	"formGridPhoneNumber": `zMspzDrvX5J3o0Mlol73`,
	"cCode": `OeiAIufwRlZPTVa91YLK`,
	"rPhoneNumber": `JPOvWpP9OsKp82eLebpa`,
	"phoneInput": `CfCvjZEqsulF1KsFiyao`,
	"countryCodeDropDown": `nULon57TuT8uTtJ3zlQe`,
	"mb32": `GmE53oGt2rhNdehSzKEw`
};
export default ___CSS_LOADER_EXPORT___;
