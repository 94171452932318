// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Kh08TuoRHpSith3GYyZQ {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  padding: 20px 24px;
  margin: 20px 0;
  font-family: Poppins, sans-serif;
}
.Kh08TuoRHpSith3GYyZQ .l2HbKCxtnBENRQ1VwdGm {
  display: flex;
  flex-direction: column;
}
.Kh08TuoRHpSith3GYyZQ .l2HbKCxtnBENRQ1VwdGm .h9qREjjfZbMu_wMl6m__ {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}
.Kh08TuoRHpSith3GYyZQ .l2HbKCxtnBENRQ1VwdGm .h9qREjjfZbMu_wMl6m__ .ArafOK0mV_BX8_agvz6P {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.Kh08TuoRHpSith3GYyZQ .l2HbKCxtnBENRQ1VwdGm .h9qREjjfZbMu_wMl6m__ .rEcnTNriEpjeYoWPuHkf {
  border-radius: 30px;
  background: #e4f8e5;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #348a38;
  padding: 8px 12px;
}
.Kh08TuoRHpSith3GYyZQ .l2HbKCxtnBENRQ1VwdGm .gVxYYMCqeezVND1yX8PI {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.Kh08TuoRHpSith3GYyZQ .l2HbKCxtnBENRQ1VwdGm .gVxYYMCqeezVND1yX8PI .hSBABCmGIVdhHyCWWHj3 {
  color: #666666;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  margin: 0.5rem 0;
}
.Kh08TuoRHpSith3GYyZQ .l2HbKCxtnBENRQ1VwdGm .e2L8zVN_aviootkp6VPf {
  margin: 0 auto;
}
.Kh08TuoRHpSith3GYyZQ .l2HbKCxtnBENRQ1VwdGm .e2L8zVN_aviootkp6VPf .trfNQ2CGG0iCnpS7J4Sy {
  width: 848px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem auto;
}
.Kh08TuoRHpSith3GYyZQ .l2HbKCxtnBENRQ1VwdGm .e2L8zVN_aviootkp6VPf .trfNQ2CGG0iCnpS7J4Sy .cv5Rcp6AQdVrVQBBGjlM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(153, 153, 153, 0.4);
  border-radius: 4px;
  width: 380px;
  height: 196px;
  gap: 20px;
  cursor: pointer;
}
.Kh08TuoRHpSith3GYyZQ .l2HbKCxtnBENRQ1VwdGm .e2L8zVN_aviootkp6VPf .trfNQ2CGG0iCnpS7J4Sy .cv5Rcp6AQdVrVQBBGjlM .DXCvz8mn9ZZMK5BpfAWk {
  color: #333333;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}
.Kh08TuoRHpSith3GYyZQ .l2HbKCxtnBENRQ1VwdGm .e2L8zVN_aviootkp6VPf .trfNQ2CGG0iCnpS7J4Sy .cv5Rcp6AQdVrVQBBGjlM .vOBAduQ3Utt2anCDHzYJ {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hrmsContainerWrapper": `Kh08TuoRHpSith3GYyZQ`,
	"containerFlex": `l2HbKCxtnBENRQ1VwdGm`,
	"headingContainer": `h9qREjjfZbMu_wMl6m__`,
	"headingText": `ArafOK0mV_BX8_agvz6P`,
	"headingPill": `rEcnTNriEpjeYoWPuHkf`,
	"subtextContainer": `gVxYYMCqeezVND1yX8PI`,
	"subtext": `hSBABCmGIVdhHyCWWHj3`,
	"outerContainer": `e2L8zVN_aviootkp6VPf`,
	"connectContainer": `trfNQ2CGG0iCnpS7J4Sy`,
	"connectBox": `cv5Rcp6AQdVrVQBBGjlM`,
	"connectText": `DXCvz8mn9ZZMK5BpfAWk`,
	"orText": `vOBAduQ3Utt2anCDHzYJ`
};
export default ___CSS_LOADER_EXPORT___;
