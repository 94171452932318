// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e35Ya6s7UpTQHPfSAUOw {
  font-family: Poppins !important;
  font-style: normal !important;
}
.e35Ya6s7UpTQHPfSAUOw .kMD1D7MQ6FDInAAd6R8Y {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 0px;
}
.e35Ya6s7UpTQHPfSAUOw .mG3Zs_9qqmiSvbSavSu0 {
  margin-left: 34px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}
.e35Ya6s7UpTQHPfSAUOw .jGihdCXHafxhQQL3tnx0 {
  margin-top: 12px;
  margin-left: 20px;
}
.e35Ya6s7UpTQHPfSAUOw .jGihdCXHafxhQQL3tnx0 .JuIOuu5q8N1TLbBToIsn {
  padding-bottom: 6px;
}
.e35Ya6s7UpTQHPfSAUOw .jGihdCXHafxhQQL3tnx0 .JuIOuu5q8N1TLbBToIsn .c7ovA7GiCsOJc1FJx3RO {
  margin-right: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.e35Ya6s7UpTQHPfSAUOw .jGihdCXHafxhQQL3tnx0 .vtl1h6tpAE5w7ASf_UTS {
  margin-left: 14px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #f5222d;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"idPackageComp": `e35Ya6s7UpTQHPfSAUOw`,
	"checkHeading": `kMD1D7MQ6FDInAAd6R8Y`,
	"subTitle": `mG3Zs_9qqmiSvbSavSu0`,
	"checkContainer": `jGihdCXHafxhQQL3tnx0`,
	"refContainer": `JuIOuu5q8N1TLbBToIsn`,
	"refText": `c7ovA7GiCsOJc1FJx3RO`,
	"refError": `vtl1h6tpAE5w7ASf_UTS`
};
export default ___CSS_LOADER_EXPORT___;
