import React from 'react';
import ReactDOM from 'react-dom';
import Geocode from 'react-geocode';

const mapStyles = {
  map: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  reset: {
    color: '#000',
    fontSize: '15px',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    backgroundColor: '#fff',
    padding: '8px',
    cursor: 'pointer',
    marginRight: '65px',
    marginTop: '-50px'
  }
};

export class CurrentLocation extends React.Component {
  mapRef = React.createRef();
  resetButtonRef = React.createRef();
  constructor(props) {
    super(props);

    const { lat, lng } = this.props.initialCenter || {};
    this.state = {
      currentLocation: {
        lat: this.props.savedLat || lat,
        lng: this.props.savedLng || lng
      },
      originalLatLng: {
        lat: '',
        lng: ''
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.google !== this.props.google) {
      this.loadMap(this.state.currentLocation.lat, this.state.currentLocation.lng);
    }
    if (prevState?.currentLocation !== this.state.currentLocation) {
      this.recenterMap();
    }
  }
  recenterMap() {
    const { google } = this.props;
    const map = this.map;
    const current = this.state.currentLocation;

    if (google && map) {
      const maps = google.maps;
      let center = new maps.LatLng(current.lat, current.lng);
      map.panTo(center);
    }
  }

  reset() {
    const { originalLatLng } = this.state;
    if (originalLatLng) {
      this.setState({
        currentLocation: {
          lat: originalLatLng.lat,
          lng: originalLatLng.lng
        }
      });
      this.loadMap(originalLatLng.lat, originalLatLng.lng);
    }
    if (this.props.onResetLocation) {
      this.props.onResetLocation({
        lat: originalLatLng.lat,
        lng: originalLatLng.lng
      });
    }
  }

  componentDidMount() {
    if (this.props.centerAroundCurrentLocation && this.props.savedLat && this.props.savedLng) {
      this.setState({
        currentLocation: {
          lat: this.props.savedLat,
          lng: this.props.savedLng
        }
      });
    }
    this.loadMap(this.state.currentLocation.lat, this.state.currentLocation.lng);

    const tempAddress = `${
      this.props.step1Address.houseNo
    },${this.props.step1Address.colony.trim()},${this.props.step1Address.city},${
      this.props.step1Address.state
    },${this.props.step1Address.country},${this.props.step1Address.pincode}`;

    Geocode.fromAddress(tempAddress)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const latitudeLongitude = { lat, lng };

        this.setState({
          originalLatLng: latitudeLongitude
        });

        this.props.whenBlocked({
          latlng: latitudeLongitude
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  loadMap(lat, lng) {
    // checks if google is available
    const { google } = this.props;
    if (google) {
      const maps = google.maps;
      const node = ReactDOM.findDOMNode(this.mapRef.current);
      const center = new maps.LatLng(lat, lng);
      const mapConfig = { center, zoom: this.props.zoom };

      // maps.Map() is constructor that instantiates the map
      this.map = new maps.Map(node, mapConfig);

      const resetButtonDiv = this.resetButtonRef.current;
      this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(resetButtonDiv);
    }
  }
  renderChildren() {
    const { children } = this.props;

    if (!children) return;

    return React.Children.map(children, (c) => {
      if (!c) return;
      return React.cloneElement(c, {
        map: this.map,
        google: this?.props?.google,
        mapCenter: this.state.currentLocation
      });
    });
  }

  render() {
    const style = { ...mapStyles.map };
    const reset = { ...mapStyles.reset };
    return (
      <div>
        <div style={style} ref={this.mapRef}>
          Loading map...
        </div>
        <div ref={this.resetButtonRef} style={reset} onClick={() => this.reset()}>
          Reset
        </div>
        {this.renderChildren()}
      </div>
    );
  }
}

export default CurrentLocation;
