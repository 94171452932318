// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xs9I8M2hXCI3_LsrX4Vw {
  max-width: 1200px;
  height: 1315px;
  background: #ffffff;
  border: none !important;
  margin: -250px auto 48px;
  font-family: Poppins, sans-serif;
  color: #333333;
}
@media only screen and (max-width: 1200px) {
  .xs9I8M2hXCI3_LsrX4Vw {
    width: 1200px;
  }
}
.xs9I8M2hXCI3_LsrX4Vw .OdWGIfbvyTXJghrE8xnY {
  position: fixed;
  top: 50%;
  left: 50%;
}

.vAs5lZ3cr_2vIeDTKbA3 {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.cKH4DMDMEDUL9boyUVsE {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.UEETDB7Qip5PqBqvDYq7 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: lightgray;
  border: 1px solid lightgray;
}

.aIBaQiMHzQkcgXXBBpsQ {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  border: 1px solid gray;
}

.XNffskHfa1NUMFNgQsCB {
  width: 1220px;
  margin: 0 auto;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingviewCandidateCard": `xs9I8M2hXCI3_LsrX4Vw`,
	"viewCandidateLoader": `OdWGIfbvyTXJghrE8xnY`,
	"dotContainer": `vAs5lZ3cr_2vIeDTKbA3`,
	"dotSelection": `cKH4DMDMEDUL9boyUVsE`,
	"dot": `UEETDB7Qip5PqBqvDYq7`,
	"selectedDot": `aIBaQiMHzQkcgXXBBpsQ`,
	"cContainer": `XNffskHfa1NUMFNgQsCB`
};
export default ___CSS_LOADER_EXPORT___;
